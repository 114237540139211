import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { Formik, FormikProps } from 'formik';
// Local Import
import { InputTypes } from '../../../types/InputTypes'
// MUI
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
// Component
import { AlertDialog, FormInput } from '../../../components';
// Store
import { changePassword } from '../../../store/user/userFunctions';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { resetChanged } from '../../../store/user/userSlice';

YupPassword(Yup);

// Change Password Form
export default function ChangePassword() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.user.loading);
    const passwordChanged = useAppSelector(state => state.user.passwordChanged);

    const [open, setOpen] = useState<boolean>(false)

    const ResetSchema = Yup.object().shape({
        currentPassword: Yup.string().password().required('Password is required'),
        password: Yup.string().password().required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
    });

    // Checks state if password has been changed
    useEffect(() => {
        if (!!passwordChanged) {
            setOpen(true)
        }
    }, [passwordChanged])

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Change Password
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Please enter your email address associated with your account. A password reset code will be sent to email.</Typography>
            <Formik
                initialValues={{
                    currentPassword: '',
                    password: '',
                    confirmPassword: ''
                }}
                validationSchema={ResetSchema}
                onSubmit={async (values: any) => {
                    dispatch(changePassword({ currentPassword: values.currentPassword, password: values.password }))
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        resetForm,
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit} id='login'>
                                <Grid container >
                                    <FormInput
                                        id='currentPassword'
                                        label='Current Password'
                                        fieldType={InputTypes.PASSWORD}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                    />
                                    <FormInput
                                        id='password'
                                        label='Password'
                                        fieldType={InputTypes.PASSWORD}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                    />
                                    <FormInput
                                        id='confirmPassword'
                                        label='Confirm Password'
                                        fieldType={InputTypes.PASSWORD}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                    />
                                    {loading ?
                                        <Grid container justifyContent='center' direction='row'>
                                            <CircularProgress />
                                        </Grid>
                                        :
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            sx={{ mx: 1, my: 2 }}
                                        >
                                            Reset Password
                                        </Button>
                                    }
                                </Grid>
                            </form>
                            <AlertDialog
                                title="Password Changed"
                                info='Your password has been successfully changed'
                                open={open}
                                handleClose={() => {
                                    resetForm();
                                    setOpen(!open)
                                }}
                                handleConfirm={() => {
                                    resetForm()
                                    dispatch(resetChanged())
                                    setOpen(!open)
                                }}
                            />
                        </>
                    )
                }}
            </Formik>
        </>
    );
}
