import {useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Summary from "./Summary";
import Page404 from "../Page404";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {retrieveInitialAFRData} from "../../store/afr/afrFunction";


const AssetFundRegisterPages = () => {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.afr.loading);
    const error = useAppSelector(state => state.afr.error);
    const dataSets = useAppSelector(state => state.afr.dataSets);

    // initial Load
    useEffect(() => {
        if (!dataSets && !error && !loading) {
            dispatch(retrieveInitialAFRData())
        }
    }, [dataSets, error, loading, dispatch])

    return (
        <Routes>
            <Route path='/' element={<Navigate to='summary'/>}/>

            <Route path='/summary' element={<Summary />} />

            <Route path='/*' element={<Page404/>}/>

        </Routes>
    )
}

export default AssetFundRegisterPages;