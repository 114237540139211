import {useState} from "react";
import {Box, IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {Add as AddIcon, Close as CloseIcon} from '@mui/icons-material';

interface DataDisplayProps {
    label: string,
    data?: string | null,
    subData?: Array<Array<DataDisplayProps>>,
    align?: 'left' | 'center' | 'right',
    alwaysExpand?: boolean,
    alwaysShow?: boolean
}

/**
 * Data Display simple format for displaying bulk data
 */
export default function DataDisplay({dataValues, inceptionLevel = 0}: { dataValues: Array<DataDisplayProps>, inceptionLevel?: number }) {

    return (
        <>
            <Table>
                <TableBody>
                    {dataValues.map((dataRow, i) => {
                        if (dataRow.subData) return (
                            <DataDisplaySub key={i} label={dataRow.label} dataValues={dataRow.subData} inceptionLevel={inceptionLevel} alwaysExpand={dataRow.alwaysExpand}/>
                        )
                        if (dataRow.data == null && !dataRow.alwaysShow) return null
                        return (
                            <TableRow key={i} sx={{p: 0, m: 0, border: 0}}>
                                <TableCell align='right' sx={{p: 0, m: 0, border: 0, width: 0.4}}>
                                    <Typography sx={{color: 'primary.main', fontWeight: 'bold', p: 0, m: 0}}>
                                        {dataRow.label}:
                                    </Typography>
                                </TableCell>
                                <TableCell align={(dataRow.align) ? dataRow.align : 'left'}
                                           sx={{p: 0.5, m: 0, border: 0, width: 0.6}}>
                                    <Typography>
                                        {dataRow.data}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}

function DataDisplaySub({label, dataValues, inceptionLevel, alwaysExpand = false}: { label: string, dataValues: Array<Array<DataDisplayProps>>, inceptionLevel: number, alwaysExpand?: boolean }) {

    const [open, setOpen] = useState(alwaysExpand);

    return (
        <>
            {!alwaysExpand &&
                <TableRow sx={{p: 0, m: 0, border: 0}}>
                    <TableCell align='right' sx={{p: 0, m: 0, width: 0.3, border: 0}}>
                        <Typography sx={{color: 'primary.main', fontWeight: 'bold', p: 0.5, m: 0}}>
                            {label}:
                        </Typography>
                    </TableCell>
                    <TableCell sx={{p: 0.5, m: 0, width: 0.7, border: 0}}>
                        <IconButton size='small' onClick={() => setOpen(!open)}>
                            {open ?
                                <CloseIcon fontSize='small' color='primary'/>
                                :
                                <AddIcon fontSize='small' color='primary'/>
                            }
                        </IconButton>
                    </TableCell>
                </TableRow>
            }
            {open &&
                dataValues.map((section, s) => (
                    <TableRow sx={{p: 0, m: 0, border: 0}} key={s}>
                        <TableCell colSpan={2} sx={{m: 0, p: 0, border: 0}}>
                            <Box sx={{
                                bgcolor: `grey.${2 + inceptionLevel}00`,
                                p: 2,
                                pl: 5,
                                m: 0.5
                            }}>
                                <DataDisplay dataValues={section} inceptionLevel={inceptionLevel + 1}/>
                            </Box>
                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    )
}