// MUI
import { AppBar, Toolbar } from '@mui/material';
// components
import { Logo } from '../../components';

export default function MinimalNavbar() {
    return (
        <AppBar
            position='fixed'
            elevation={0}
        >
            <Toolbar
                disableGutters
                sx={{
                    pl: '16px',
                    pr: '16px'
                }}
            >
                <Logo/>
            </Toolbar>
        </AppBar>
    )
}