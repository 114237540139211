//
import { Theme } from "@mui/system";

import DataGrid from "./DataGrid";
import Paper from './Paper';

// ------------------------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
    return Object.assign(
        DataGrid(theme),
        Paper(),
    );
}