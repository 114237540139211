// Types
import {
    CalculationLoanType,
    CapitalTransaction,
    FundBalance,
    MCPFund,
    OtherTransaction,
    ScenarioDataSet
} from '../../../../types/capitalBudgetTypes'
import {Period} from "../../../../types/GeneralTypes";
import {calculatePeriod} from "../index";
import {PeriodType} from "../../../../types/capitalBudgetEnums";


function calculateBudget(scenarioState: ScenarioDataSet, periods: {base: Period, weeks: Array<Period>, months: Array<Period>}, fund: MCPFund | null, portfolios: Array<CalculationLoanType>, newLoans: Array<CalculationLoanType>, capital: Array<CapitalTransaction>, otherTransactions: Array<OtherTransaction>, budgetDate: number | Date) {
    let startingCap = 0;
    let startingCash = 0;

    // Retrieve starting Account Balances
    if (!fund) {
        startingCap = scenarioState.accountBalances.capital;
        startingCash = scenarioState.accountBalances.cash
    } else {
        const fundBalance = scenarioState.accountBalances.funds.find((f: FundBalance) => f.id === fund.id);
        startingCap = fundBalance?.capital || 0;
        startingCash = fundBalance?.cash || 0;
    }

    let {
        base,
        weeks,
        months
    } = periods;

    let runningCapBalance = startingCap;
    let runningCashBalance = startingCash;
    let redemptionOffset = 0; // CASH based valued to offset redemptions for 1 period

    base = calculatePeriod(fund, base, PeriodType.WEEK, startingCap, startingCash, redemptionOffset, 0, budgetDate, portfolios, newLoans, capital, otherTransactions)

    // CALCULATE FOR RUNNING WEEKS
    weeks = weeks.map((week, w) => {
        const availableCap = (w === 0) ? startingCap : runningCapBalance; // IF not first week or month use running balance.
        const availableCash = (w === 0) ? startingCash : runningCashBalance; // IF not first week or month use running balance.

        const period = calculatePeriod(fund, week, PeriodType.WEEK, availableCap, availableCash, redemptionOffset, w, budgetDate, portfolios, newLoans, capital, otherTransactions);

        runningCapBalance = period.closingAvailableCap;
        runningCashBalance = period.closingAvailableCash;
        redemptionOffset = period.redemptions;

        return period;
    })

    runningCapBalance = startingCap;
    runningCashBalance = startingCash;
    redemptionOffset = 0;
    // CALCULATE FOR RUNNING MONTHS
    months = months.map((month, m) => {
        const availableCap = (m === 0) ? startingCap : runningCapBalance;
        const availableCash = (m === 0) ? startingCash : runningCashBalance;

        const period = calculatePeriod(fund, month, PeriodType.MONTH, availableCap, availableCash, redemptionOffset, m, budgetDate, portfolios, newLoans, capital, otherTransactions);

        runningCapBalance = period.closingAvailableCap;
        runningCashBalance = period.closingAvailableCash;
        redemptionOffset = period.redemptions;

        return period;
    })

    return {base, weeks, months}
}

export default calculateBudget;