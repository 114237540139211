// Packages
import {MCPFund, NewDeal} from "../../../types/capitalBudgetTypes";
import {DealInclusion, SaveStatus} from "../../../types/capitalBudgetEnums";
// Local Imports

// Types

// Store

/**
 * Checks retrieved WIP loans against scenario new loans
 * - Checks for correct default allocations,
 * - Checks for removed WIP loans
 * - Checks for new WIP loans
 */

function checkWIPLoans(scenarioId: number, wipLoans: Array<any>, newDeals: Array<NewDeal>, funds: Array<MCPFund>) {
    const confirmedNewDeals: Array<NewDeal> = [];

    // SET UP ALLOCATION STRUCTURE
    let fundsAllocation: any = {}

    funds.forEach((fund: MCPFund) => {
        fundsAllocation[fund.label] = 0;
    })

    wipLoans.forEach((loan) => {
        // CHECK NEW DEALS EXISTS
        const dealIndex = newDeals.findIndex((newDeal) => loan.id === newDeal.wipLoanId);
        const deal = newDeals[dealIndex]

        // IF EXISTING DEAL FOUND
        if (deal) {
            // CHECK IF LOAN HAS BEEN UPDATED
            if (deal.lastModified !== loan.last_modified) {
                // console.log(deal, loan)
                // IF COMMITMENT AMOUNT HAS BEEN CHANGED TREAT AS NEW LOAN
                if (deal.commitment !== loan.llc_bi_amount) {
                    confirmedNewDeals.push({...deal, status: SaveStatus.REMOVED})
                    confirmedNewDeals.push({
                        id: loan.id, //TEMP ID
                        scenarioId,
                        name: loan.name,
                        wipLoanId: loan.id,
                        ncinoId: loan.ncino_id,
                        include: DealInclusion.UNASSIGNED,
                        commitment: loan.llc_bi_amount,
                        allocation: {
                            ...fundsAllocation,
                            DASLF: loan.llc_bi_amount || 0,
                        },
                        lastModified: loan.last_modified,
                        expectedClose: loan.llc_bi_close_date,
                        amendedCloseDate: null,
                        commitmentOffset: null,
                        status: SaveStatus.NEW
                    })
                } else {
                    // OTHERWISE TREATED AS EDITED LOAN
                    confirmedNewDeals.push({
                        ...deal,
                        lastModified: loan.last_modified,
                        expectedClose: loan.llc_bi_close_date,
                        name: loan.name,
                        status: SaveStatus.EDITED
                    })

                }
            } else {
                // IF NO CHANGES PUSH TO NEW DEALS
                confirmedNewDeals.push({...deal, expectedClose: loan.llc_bi_close_date, name: loan.name})
            }
            // REMOVE FROM NEW DEALS ARRAY TO REDUCE COMPARISON
            newDeals.splice(dealIndex, 1);
        } else {
            // ADD LOAN AS NEW LOAN
            confirmedNewDeals.push({
                id: loan.id, //TEMP ID
                scenarioId,
                name: loan.name,
                wipLoanId: loan.id,
                ncinoId: loan.ncino_id,
                include: DealInclusion.UNASSIGNED,
                commitment: loan.llc_bi_amount,
                allocation: {
                    ...fundsAllocation,
                    DASLF: loan.llc_bi_amount || 0,
                },
                lastModified: loan.last_modified,
                expectedClose: loan.llc_bi_close_date,
                amendedCloseDate: null,
                commitmentOffset: null,
                status: SaveStatus.NEW
            })
        }
    })

    newDeals.forEach(loan => {
        confirmedNewDeals.push({...loan, status: SaveStatus.REMOVED})
    })

    return confirmedNewDeals;
}

export default checkWIPLoans;