import {createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GeneralState {
    millions: boolean
}

// INITIAL STATE
const initialState: GeneralState = {
    millions: false,
}

// REDUCER
export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setMillions: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== null) {
                state.millions = action.payload;
            }
        },
    }
})

export const {setMillions} = generalSlice.actions;

export default generalSlice.reducer;