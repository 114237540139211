import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import _ from "lodash";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {GridColDef} from "@mui/x-data-grid";
import {fCurrency} from "../../../utils/formatNumber";
import {formatAdjustmentTypeLabel} from "../../../utils/valuationUtils";
import {checkDateBetween, checkDateSame, formatDate} from "../../../utils/DateUtils";
import {Adjustment, ScheduledAdjustment} from "../../../types/valuationModelTypes";
import {AdjustmentType} from "../../../types/valuationModelEnums";

export const AdjustmentsExportColumns: Array<GridColDef> = [
    {
        field: 'trancheId',
        headerName: 'ID',
        minWidth: 60,
        flex: 1
    },
    {
        field: 'fund',
        headerName: 'Fund',
        minWidth: 80,
        flex: 1
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        minWidth: 150,
        flex: 2
    },
    {
        field: 'transactionType',
        headerName: 'Type',
        minWidth: 150,
        flex: 1,
        valueFormatter: (params) => formatAdjustmentTypeLabel(params.value)
    },
    {
        field: 'base_currency',
        headerName: 'Currency',
        minWidth: 80,
        flex: 1
    },
    {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => fCurrency(params.value)
    },
    {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 150,
        flex: 2
    },
    {
        field: 'createdName',
        headerName: 'Created By',
        hide: true,
        minWidth: 150,
        flex: 1,
        valueFormatter: (params) => (params.value) ? params.value: "(New)"
    }
]

export const ScheduledAdjustmentsExportColumns: Array<GridColDef> = [
    {
        field: 'trancheId',
        headerName: 'ID',
        minWidth: 60,
        flex: 1
    },
    {
        field: 'fund',
        headerName: 'Fund',
        minWidth: 80,
        flex: 1
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        minWidth: 150,
        flex: 2
    },
    {
        field: 'transactionType',
        headerName: 'Type',
        minWidth: 150,
        flex: 1,
        valueFormatter: (params) => formatAdjustmentTypeLabel(params.value)
    },
    {
        field: 'startDate',
        headerName: 'Start',
        type: 'date',
        minWidth: 80,
        flex: 1,
        valueFormatter: (params) => formatDate(params.value)
    },
    {
        field: 'endDate',
        headerName: 'End',
        type: 'date',
        minWidth: 80,
        flex: 1,
        valueFormatter: (params) => formatDate(params.value)
    },
    {
        field: 'base_currency',
        headerName: 'Currency',
        minWidth: 80,
        flex: 1
    },
    {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        minWidth: 120,
        flex: 1,
        valueFormatter: (params) => fCurrency(params.value)
    },
    {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 150,
        flex: 2
    },
    {
        field: 'createdName',
        headerName: 'Created By',
        hide: true,
        minWidth: 150,
        flex: 1,
        valueFormatter: (params) => (params.value) ? params.value: "(New)"
    }
]

type AdjustmentDisplay = Adjustment & {borrower: string, base_currency: string}
type ScheduledAdjustmentDisplay = ScheduledAdjustment & {borrower: string, base_currency: string}

export const adjustmentsExportSelector = createSelector(
    (state: RootState) => state.version.version?.valuationDate,
    (state: RootState) => state.valuationModel.fund,
    (state: RootState) => state.valuationModel.valuationModelData.funds,
    (state: RootState) => state.valuationModel.valuationModelData.adjustments,
    (state: RootState) => state.valuationModel.valuationModelData.manualAccrual,
    (state: RootState) => _.cloneDeep(state.valuationModel.thirdPartyData.axcess?.portfolio || []),
    (valuationDate, fund, funds, adjustments, scheduledAdjustments, tranches) => {
        const filteredFunds: string[] = [];
        if (fund === "ALL_VM_FUNDS" || fund === "ALL_FUNDS") {
            funds.forEach(f => filteredFunds.push(f.name));
        } else {
            filteredFunds.push(fund);
        }
        // Extract adjustments for fund list
        const filteredAdjustments: Array<AdjustmentDisplay> = [];
        for (let t in adjustments) {
            const tranche = tranches.find(a => a.tranche_id === parseInt(t));
            for (let f in adjustments[t]) {
                if (filteredFunds.includes(f) && tranche) {
                    adjustments[t][f].forEach(adjustment => {
                        if (adjustment.status !== SaveStatus.REMOVED && adjustment.transactionType !== AdjustmentType.PL_ACCRUAL)
                        filteredAdjustments.push({
                            ...adjustment,
                            borrower: tranche.borrower,
                            base_currency: tranche.base_currency
                        })
                    })
                }
            }
        }
        // Extract scheduled adjustments for fund list
        const filteredScheduledAdjustments: Array<ScheduledAdjustmentDisplay> = [];
        scheduledAdjustments.forEach(adjustment => {
            const tranche = tranches.find(a => a.tranche_id === adjustment.trancheId);
            if (filteredFunds.includes(adjustment.fund) && tranche && adjustment.status !== SaveStatus.REMOVED && adjustment.transactionType !== AdjustmentType.PL_ACCRUAL) {
                if (valuationDate && (checkDateSame(adjustment.startDate, valuationDate) || (adjustment.endDate && checkDateBetween(valuationDate, adjustment.startDate, adjustment.endDate)))) {
                    filteredScheduledAdjustments.push({
                        ...adjustment,
                        borrower: tranche.borrower,
                        base_currency: tranche.base_currency
                    })
                }
            }
        })
        return {adjustments: filteredAdjustments, scheduledAdjustments: filteredScheduledAdjustments};
    }
)