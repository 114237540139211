import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { signOut } from "../../store/user/userFunctions";

// User Logout Function
export default function Logout({ authenticated }: { authenticated: boolean }) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(signOut())
    }, [dispatch])

    if (authenticated) {
        return <></>
    } else {
        return <Navigate to="/login" />;
    }
}