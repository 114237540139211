import {Box, MenuItem, Select, Typography} from "@mui/material";
import React from "react";

/**
 * Filter for A
 */
const AdjustmentFundFilter = ({funds, filterFund, setFilterFund}: {
    funds: Array<string>,
    filterFund: string,
    setFilterFund: (fund: string) => void
}) => {

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Typography>Funds:</Typography>
                <Select
                    id="fund"
                    label="Fund"
                    value={filterFund}
                    size="small"
                    variant="outlined"
                    onChange={(event) => setFilterFund(event.target.value)}
                    sx={{
                        pl: 1,
                        color: 'common.white'
                    }}
                >
                    <MenuItem value={'All'}>All</MenuItem>
                    {funds.map(fund => (
                        <MenuItem key={fund} value={fund}>{fund}</MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
}

export default AdjustmentFundFilter