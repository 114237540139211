import {RootState} from "../../store";
import {fCurrency} from "../../../utils/formatNumber";
import {
    checkInPeriod, generatePeriods,
} from "../../../utils/DateUtils";
import {addValues} from "../../../utils/mathUtil";
import _ from "lodash";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalTransaction, MCPFund} from "../../../types/capitalBudgetTypes";
import {CellType} from "../../../types/InputTypes";
import {HorizontalTableRow} from "../../../components";
import {createSelector} from "@reduxjs/toolkit";

// Retrieves All Capital Transactions and filters if fund is selected
export const retrieveCapitalTransactions = createSelector(
    (state: RootState) => _.cloneDeep(state.capitalBudget.scenarioData?.capital) || [],
    (state: RootState) => state.capitalBudget.misc.fund,
    (state: RootState) => state.capitalBudget.scenarioData?.funds,
    (_state: RootState, raw?: boolean) => raw,
    (capitalTransactions: Array<CapitalTransaction>, fund: MCPFund | null, funds: Array<MCPFund> | undefined, raw = false) => {
        capitalTransactions.forEach(ct => {
            // IF RAW THEN CHECK IF EDITED AND RETRIEVE ORIGINAL
            if (raw && ct.status === SaveStatus.EDITED) {
                ct = ct.previous || ct;
            }
            ct.date = new Date(ct.date).getTime();
        })

        capitalTransactions = capitalTransactions.filter(ct => ct.status !== SaveStatus.REMOVED);

        if (fund) {
            const relevantFunds: Array<{ feeder: string, allocation: number }> = [];

            if (funds) {
                funds.forEach(f => {
                    if (f.allocation) {
                        f.allocation.forEach((a) => {
                            if (a.fund === fund.label) {

                                relevantFunds.push({feeder: f.label, allocation: a.allocation})
                            }
                        })
                    }
                })
            }
            // FILTER CAPITAL TRANSACTIONS FOR SUBS/REDS DIRECTLY INTO FUND OR VIA FEEDER FUND
            return capitalTransactions.reduce((result: Array<CapitalTransaction>, ct: CapitalTransaction) => {
                if (ct.fund === fund.label) {
                    result.push(ct);
                } else {
                    const fundAll = relevantFunds.find(f => f.feeder === ct.fund);
                    if (fundAll) {
                        result.push({...ct, amount: ct.amount * fundAll.allocation})
                    }
                }
                return result;
            }, []);
        } else {
            return capitalTransactions;
        }
    }
)

// Retrieves and Formats Capital Table Data, Formats the data into rows and columns
export const capitalTableSelector = createSelector(
    (state: RootState) => state.scenarios.scenario,
    (state: RootState) => state.capitalBudget,
    retrieveCapitalTransactions,
    (scenario, capitalBudget, capitalTransactions) => {
        if (!scenario) return {rows: [], columns: []}

        const {
            weeks,
            months
        } = generatePeriods(capitalBudget.misc.weeks, capitalBudget.misc.months, scenario.reportAsOf);

        // Group Capital Transactions by entity
        let entities: Array<{ name: string, transactions: Array<CapitalTransaction> }> = [];

        capitalTransactions.forEach(ct => {
            let found = false;

            for (const e in entities) {
                if (entities[e].name === ct.name) {
                    entities[e].transactions.push(ct);
                    found = true;
                    break;
                }
            }
            if (!found) {
                entities.push({
                    name: ct.name,
                    transactions: [ct]
                })
            }
        })

        months[0].sx = {borderLeft: 2};

        const periods = [...weeks, ...months];

        periods.forEach((period, p) => {
            entities.forEach(entity => {
                let amount = 0;

                entity.transactions.forEach(t => {
                    if (checkInPeriod(t.date, period)) {
                        amount = addValues(amount, t.amount);
                    }
                })

                period[entity.name] = amount;
                period.total = addValues(period.total, amount);
            })
            if (((p + 1) % 2) === 0) {
                period.sx = {bgcolor: 'grey.100', ...period.sx}
            }
        })

        let rows: Array<HorizontalTableRow> = entities.map(entity => {
            return {
                formatter: fCurrency,
                id: entity.name,
                label: entity.name,
                headSX: {minWidth: 200},
                type: CellType.CURRENCY
            }
        })

        rows = rows.sort((a, b) => (a.label > b.label) ? 1 : -1)

        if (rows.length > 0) {
            rows.push({
                formatter: fCurrency,
                id: 'total',
                label: 'Total',
                headSX: {
                    minWidth: 200,
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    bgcolor: 'primary.light',
                    borderBottomColor: 'primary.main',
                    fontWeight: 'bold',
                    color: 'common.white',
                },
                type: CellType.CURRENCY,
                sx: {
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    fontWeight: 'bold',
                    color: 'common.white',
                    bgcolor: 'primary.light',
                }
            })
        }

        return {
            rows,
            columns: periods
        }
    }
)

// Retrieves All Capital Transaction Names
export const retrieveAllCapitalTransactionNames = createSelector(
    (state: RootState) => state.capitalBudget.scenarioData?.capital || [],
    (capitalTransactions) => {
        // Group Capital Transactions by entity
        let names: Array<{ id: string }> = [];

        capitalTransactions.forEach(ct => {
            let found = false;

            for (const n in names) {
                if (names[n].id === ct.name) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                names.push({id: ct.name})
            }
        })

        return names;
    }
)