// Portfolio AmendmentType
export enum AmendmentType {
    EARLY_REPAYMENT = 'EARLY REPAYMENT',
    EXTENSION = 'EXTENSION',
    COMMITMENT = 'COMMITMENT'
}

// Capital Provide Actions
export enum CapitalAction {
    SUBSCRIPTION = 'SUBSCRIPTION',
    REDEMPTION = 'REDEMPTION',
    COMMITMENT = 'COMMITMENT',
    CANCELLATION = 'CANCELLATION'
}

export enum CapitalCategory {
    EXPECTED = 'EXPECTED',
    TARGET = 'TARGET',
    POTENTIAL = 'POTENTIAL'
}

// Capital Provider Types
export enum CapitalTypes {
    INVESTOR = 'INVESTOR',
    LENDER = 'LENDER'
}

// Other Transaction Types
export enum OtherTransactionTypes {
    DEBT_DRAW = 'DEBT DRAW',
    CASH_DISTRIBUTION = 'CASH DISTRIBUTION',
    REVOLVER_DRAWDOWN = 'REVOLVER DRAWDOWN',
    ADJUSTMENT = 'ADJUSTMENT',
    // FUND_FACILITIES_INCREASE = 'FUND FACILITIES INCREASE',
    FUND_FACILITIES_UNDRAWN = 'FUND FACILITIES UNDRAWN',
    INCREASED_BANK_FACILITIES = 'INCREASED BANK FACILITIES'
}

export const OtherTransactionsTypeInputLabels = [
    {id: OtherTransactionTypes.ADJUSTMENT, label: 'Adjustment'},
    {id: OtherTransactionTypes.CASH_DISTRIBUTION, label: 'Cash Distribution'},
    {id: OtherTransactionTypes.DEBT_DRAW, label: 'Debt Draw'},
    // {id: OtherTransactionTypes.FUND_FACILITIES_INCREASE, label: 'Fund Facilities Increase'},
    {id: OtherTransactionTypes.FUND_FACILITIES_UNDRAWN, label: 'Fund Facilities Unused'},
    {id: OtherTransactionTypes.INCREASED_BANK_FACILITIES, label: 'Increased Bank Facilities'},
    {id: OtherTransactionTypes.REVOLVER_DRAWDOWN, label: 'Revolver Drawdown'},
]

export enum CurrencyType {
    AUD = 'AUD',
    GBP = 'GBP',
    NZD = 'NZD',
    USD = 'USD'
}

export enum DealInclusion {
    BUDGET = 'BUDGET',
    WIP = 'WIP',
    UNASSIGNED = 'UNASSIGNED',
}

export enum FundType {
    UNDERLYING = 'UNDERLYING',
    FEEDER = 'FEEDER'
}

export enum InvestmentType {
    REAL_ESTATE = 'Real Estate',
    CORPORATE = 'Corporate',
    LEVERAGED = 'Leveraged',
    EQUITY = 'Equity',
    FUND_FINANCE = 'Fund Finance',
    PROJECT_FINANCE_INFRASTRUCTURE = 'Project Finance & Infrastructure',
    STRUCTURED = 'Structured',
    OTHER = 'Other'
}

export enum LoanTags {
    ACTIVE = 'ACTIVE',
    CORP_CTC_NEW = 'CORP_CTC_NEW',
    CRE_CTC = 'CRE_CTC',
    CRE_CTC_ACTIVE = 'CRE_CTC_ACTIVE',
    CRE_CTC_NON_ACTIVE = 'CRE_CTC_NON_ACTIVE',
    CRE_CTC_NEW = 'CRE_CTC_NEW',
    EARLY_REPAYMENT = 'EARLY_REPAYMENT',
    EXTENSION = 'EXTENSION',
    FIRST_DRAW = 'FIRST_DRAW',
    NEW_LOAN = 'NEW_LOAN',
    OFFSET_EARLY = 'OFFSET_EARLY',
    OFFSET_EXTENSION = 'OFFSET_EXTENSION',
    OFFSET_SELLDOWN = 'OFFSET_SELLDOWN',
    OFFSET_TRANSFER_IN = 'OFFSET_TRANSFER_IN',
    OFFSET_TRANSFER_OUT = 'OFFSET_TRANSFER_OUT',
    REPAYMENT = 'REPAYMENT',
    SELLDOWN = 'SELLDOWN',
    TRANSFER = 'TRANSFER',
    TRANSFER_IN = 'TRANSFER_IN',
    TRANSFER_OUT = 'TRANSFER_OUT'
}

export enum MCPRatings {
    'aa-' = 'aa-',
    'a+' = 'a+',
    'a' = 'a',
    'a-' = 'a-',
    'bbb+' = 'bbb+',
    'bbb' = 'bbb',
    'bbb-' = 'bbb-',
    'bb+' = 'bb+',
    'bb' = 'bb',
    'bb-' = 'bb-',
    'b+' = 'b+',
    'b' = 'b',
    'b-' = 'b-',
    'ccc+' = 'ccc+',
    'ccc' = 'ccc',
    'd' = 'd',
}

// Scenario Save Status of change
export enum SaveStatus {
    EDITED = 'EDITED',
    NEW = 'NEW',
    REMOVED = 'REMOVED',
    SAVED = 'SAVED',
}

export enum Seniority {
    EQUITY = 'Equity',
    OTHER = 'OTHER',
    SENIOR = 'Senior',
    SUBORDINATED = 'Subordindated',
    SUPER_SENIOR = 'Super Senior'
}

export enum SPSector {
    COMMUNICATIONS = 'Communications',
    CONSUMER_DISCRETIONARY = 'Consumer Discretionary',
    CONSUMER_STAPLES = 'Consumer Staples',
    ENERGY = 'Energy',
    FINANCIALS = 'Financials',
    HEALTH_CARE = 'Health Care',
    INDUSTRIALS = 'Industrials',
    INFORMATION_TECHNOLOGY = 'Information Technology',
    MATERIALS = 'Materials',
    REAL_ESTATE = 'Real Estate',
    UTILITIES = 'Utilities'
}

export enum States {
    ACT = 'ACT',
    NSW = 'NSW',
    NT = 'NT',
    OFFSHORE = 'Offshore',
    QLD = 'QLD',
    SA = 'SA',
    TAS = 'TAS',
    VIC = 'VIC',
    WA = 'WA'
}

export enum TrancheTypes {
    TERM_BULLET = 'Term Bullet',
    EQUITY = 'Equity',
    REVOLVING = 'Revolving',
    CONSTRUCTION = 'Construction',
    REVOLVING_MULTI_CURRENCY = 'Revolving Multi-currency',
    CAPEX = 'Capex',
    TERM_AMORTISING = 'Term Amortising',
    GUARANTEE = 'Guarantee',
    OTHER = 'Other'
}

export enum PeriodType {
    WEEK = 'week',
    MONTH = 'month'
}