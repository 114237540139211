import { RootState } from "../store";
import { User, userRoleTypes } from "./userSlice";

// Authenticated User Check
export function isAuthenticated(store: RootState): boolean {
    return !!(store.user.user);
}

export function getUserRole(store: RootState): userRoleTypes | null {
    if (!store.user.user) return null;
    return store.user.user?.role;
}

export function getUser(store: RootState): User | null {
    return store.user.user;
}

export function getUsers(store: RootState): Array<User> | [] {
    return store.user.users;
}