import {memo} from "react";
import {Formik, FormikProps} from "formik";
// MUI Components
import {TableRow, TableCell, IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, KeyboardArrowRight as KeyboardArrowRightIcon} from "@mui/icons-material";
// Components
import {EditableTableColumn, EditableTableRow} from ".";
import FormInput from "../FormInput";

import _ from 'lodash';

// ----------------------------------------------------------------------
// Props

interface EditableRowProps {
    row: EditableTableRow,
    columns: Array<EditableTableColumn>,

    setEditRow: (row: EditableTableRow) => void, // set Row for edit
    selectedRow?: EditableTableRow | null, // id of row selected
    handleRowSelection: (row: EditableTableRow) => void, // callback for selecting row

    handleRemove?: (id: number | string) => void, // callback for handling remove

    handleRowEdit: (row: EditableTableRow) => void,
    validationSchema?: any,
    formRowValueFunction?: (values: any, props: FormikProps<any>, columns?: Array<EditableTableColumn>) => void | Array<EditableTableColumn>
}

// ----------------------------------------------------------------------
function EditableRow(props: EditableRowProps) {
    const {
        row,
        columns,
        setEditRow,
        // Row Selection Props
        selectedRow,
        handleRowSelection,
        // Row removal
        handleRemove,
        // Row Edit Props
        handleRowEdit,
        validationSchema,
        formRowValueFunction
    } = props;

    return (
        <Formik
            initialValues={row}
            validationSchema={validationSchema}
            onSubmit={(values: any ) => {
                const transaction = Object.assign({}, values);
                handleRowEdit(transaction)
            }}
        >
            {(props: FormikProps<any>) => {
                const {
                    errors,
                    values,
                    handleSubmit,
                } = props;

                let rowColumns;

                if (formRowValueFunction) {
                    const colResults = formRowValueFunction(values, props, _.cloneDeep(columns));
                    if (colResults) rowColumns = colResults;
                }

                // Determines if the row has changed due to sort and forces update and re-render
                if (values.id !== row.id) {
                    props.setValues(row)
                }

                return (
                    <TableRow
                        sx={{
                            height: 30,
                            ...((!!selectedRow && row === selectedRow) ?
                                    // SELECTED ROW FORMATTING
                                    {
                                        bgcolor: 'grey.300',
                                        "&:hover": {
                                            bgcolor: 'grey.400',
                                        }
                                    }
                                    :
                                    // UNSELECTED ROW FORMATTING
                                    {
                                        "&:hover": {
                                            bgcolor: 'grey.300',
                                        }
                                    }
                            ),
                            userSelect: 'text'
                        }}
                    >
                        {/* EXPAND ICONS */}
                        {!!handleRowSelection &&
                            <TableCell
                                sx={{
                                    borderColor: 'primary.main',
                                    maxHeight: 25,
                                    p: 0,
                                    ...((Object.keys(errors).length > 0) ?
                                            {
                                                bgcolor: 'error.light',
                                            }
                                            :
                                            {}
                                    ),
                                }}
                                align='center'
                            >
                                <IconButton
                                    style={{padding: '0px', outline: 'none'}}
                                    onClick={() => handleRowSelection(row)}
                                    size="small">
                                    <KeyboardArrowRightIcon/>
                                </IconButton>
                            </TableCell>
                        }
                        {/* LOOP DATA CELLS */}
                        {(rowColumns ? rowColumns : columns).map((column, c) => (

                            <TableCell
                                id={`${row.id}-${column.id}`}
                                key={column.id + c}
                                align={column.align ? column.align : 'center'}
                                sx={{
                                    flex: 1,
                                    py: 0,
                                    ...row.sx,
                                    ...column.sx,
                                    borderColor: 'primary.main',
                                    ...column.edit ? {p: 0} : {px: 1},
                                    ...(!column.edit || column.edit.disabled) ? {
                                        bgcolor: 'grey.200',
                                        color: 'common.black'
                                    } : {},
                                    ...((Object.keys(errors).length > 0) ?
                                            {
                                                bgcolor: 'error.light',
                                            }
                                            :
                                            {}
                                    ),
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit()
                                        setEditRow(row)
                                    }
                                }}
                            >

                                {column.edit ?
                                    <FormInput
                                        id={column.id}
                                        label={''}
                                        {...column.edit}
                                        size='small'
                                        variant='standard'
                                        noUnderline
                                        noLabel
                                        blurFunction={handleSubmit}
                                        errorInIcon
                                    />
                                    :
                                    <Typography>
                                        {(column.formatter) ?
                                            column.formatter(row[column.id])
                                            :
                                            row[column.id]
                                        }
                                    </Typography>
                                }

                            </TableCell>
                        ))}
                        {handleRemove &&
                            <TableCell
                                sx={{
                                    ...row.sx,
                                    borderColor: 'primary.main',
                                    px: 1,
                                    ...((Object.keys(errors).length > 0) ?
                                            {
                                                bgcolor: 'error.light',
                                            }
                                            :
                                            {}
                                    ),
                                }}
                            >
                                <IconButton
                                    sx={{color: 'primary.main'}}
                                    style={{padding: '0px', outline: 'none'}}
                                    onClick={() => handleRemove(row.id)}
                                    size="small">
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell>
                        }
                    </TableRow>
                )
            }}
        </Formik>
    )
}

export default memo(EditableRow);