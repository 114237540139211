// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {NewDeal} from "../../../types/capitalBudgetTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {addValues} from "../../../utils/mathUtil";

// Reducers related to Capital Budget - New Deals (WIP)
const newDealReducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Update new deal (WIP Loan)
    updateNewDeal: (state, action: PayloadAction<NewDeal>) => {
        if (state.scenarioData) {
            const index = state.scenarioData.newDeals.findIndex(d => d.wipLoanId === action.payload.wipLoanId && d.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                // Perform change check
                let change = false;
                let currentDeal = state.scenarioData.newDeals[index];

                if (currentDeal.amendedCloseDate !== action.payload.amendedCloseDate) change = true;
                if (currentDeal.commitmentOffset !== action.payload.commitmentOffset) change = true;
                if (currentDeal.include !== action.payload.include) change = true;

                let total = 0;
                state.scenarioData.funds.forEach(fund => {
                    if (currentDeal.allocation[fund.label] !== action.payload.allocation[fund.label]) change = true
                    total = addValues(total, (action.payload.allocation[fund.label]));
                })
                if (total === action.payload.adjustedCommitment && change) {
                    if (state.scenarioData.newDeals[index].status !== SaveStatus.NEW) {
                        action.payload.previous = (!currentDeal.previous) ? currentDeal : currentDeal.previous;
                        action.payload.status = SaveStatus.EDITED;
                    }
                    state.scenarioData.newDeals[index] = action.payload;
                }
            }
        }
    }
}

export default newDealReducers;