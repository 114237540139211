// MUI
import {
    AccountBalance as AccountBalanceIcon,
    Analytics as AnalysticsIcon,
    Assessment as AssessmentIcon,
    AttachMoney as AttachMoneyIcon,
    Calculate as CalculateIcon,
    Computer as ComputerIcon,
    SsidChart as ChartIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    Construction as ConstructionIcon,
    Dashboard as DashboardIcon,
    DashboardCustomize as DashboardCustomizeIcon,
    Difference as DifferenceIcon,
    LibraryBooksTwoTone as BookIcon,
    List as ListIcon,
    ListAlt as ListAltIcon,
    ForkRight as ForkRightIcon,
    GroupAdd as GroupAddIcon,
    Home as HomeIcon,
    MoveDown as MoveDownIcon,
    PieChart as PieChartIcon,
    TrendingUpOutlined as TrendingUpOutlinedIcon,
    UploadFile as UploadFileIcon,
    RequestPage as RequestPageIcon,
    PriceChange as PriceChangeIcon,
    People as PeopleIcon,
    Article as ArticleIcon,
    FilterDrama as FilterDramaIcon

} from '@mui/icons-material';
// Components
import { Item } from './NavSection';
// Store
import { userRoleTypes } from '../../store/user/userSlice';
import {
    auxReports,
    financeReports,
    investorReports, itReports,
    loanAdminReports,
    portfolioReports
} from "../../config/BIReportsConfig";

// ----------------------------------------------------------------------

const navConfig: Item[] = [
    {
        title: 'Home',
        path: '/dashboard/home',
        icon: <HomeIcon/>,
    },
    {
        title: 'Asset Fund Register',
        path: '/dashboard/asset-fund-register',
        icon: <BookIcon/>,
    },
    {
        title: 'Capital Budget',
        path: '/dashboard/capital-budget',
        icon: <AccountBalanceIcon />,
        children: [
            {
                title: 'Summary',
                path: '/dashboard/capital-budget/summary',
                icon: <DashboardIcon />,
            },
            {
                title: 'Sources and Uses',
                path: '/dashboard/capital-budget/sourcesUses',
                icon: <PriceChangeIcon />,
            },
            {
                title: 'Budget Forecast',
                path: '/dashboard/capital-budget/budgetForecast',
                icon: <TrendingUpOutlinedIcon />,
            },
            {
                title: 'Capital Position',
                path: '/dashboard/capital-budget/capitalPosition',
                icon: <DifferenceIcon />,
            },
            {
                title: 'Capital',
                path: '/dashboard/capital-budget/capital',
                icon: <AttachMoneyIcon />,
            },
            {
                title: 'Portfolio',
                path: '/dashboard/capital-budget/portfolio',
                icon: <PieChartIcon />,
            },
            {
                title: 'New Deals',
                path: '/dashboard/capital-budget/newDeals',
                icon: <DashboardCustomizeIcon />,
            },
            {
                title: 'Interfund Transfers',
                path: '/dashboard/capital-budget/interfundTransfer',
                icon: <MoveDownIcon />,
            },
            {
                title: 'Selldowns & Repayments',
                path: '/dashboard/capital-budget/selldownRepayments',
                icon: <CurrencyExchangeIcon />,
            },
            {
                title: 'Account Balances',
                path: '/dashboard/capital-budget/accountBalances',
                icon: <AccountBalanceIcon />,
            },
            {
                title: 'Scenarios',
                path: '/dashboard/capital-budget/scenarios',
                icon: <ForkRightIcon />,
            }
        ]
    },
    {
        title: 'S Curve - ARM',
        path: '/dashboard/s-curve_arm',
        icon: <ChartIcon />,
        children: [
            {
                title: 'Summary',
                path: '/dashboard/s-curve_arm/summary',
                icon: <ConstructionIcon sx={{color: 'warning.main'}}/>,
            },
        ]
    },
    {
        title: 'Valuation Model',
        path: '/dashboard/valuation-model',
        icon: <AttachMoneyIcon />,
        children: [
            {
                title: 'Summary',
                path: '/dashboard/valuation-model/valuationSummary',
                icon: <ArticleIcon />,
            },
            {
                title: 'Valuation Worksheet',
                path: '/dashboard/valuation-model/valuationWorksheet',
                icon: <CalculateIcon />,
            },
            {
                title: 'Valuation Export',
                path: '/dashboard/valuation-model/valuationExport',
                icon: <RequestPageIcon />,
            },
            {
                title: 'Daily Adjustments',
                path: '/dashboard/valuation-model/adjustmentsExport',
                icon: <CurrencyExchangeIcon />,
            },
            {
                title: 'Bulk Transfer Upload',
                path: '/dashboard/valuation-model/bulkUpload',
                icon: <UploadFileIcon />,
            },
            {
                title: 'Exceptions',
                path: '/dashboard/valuation-model/exceptions',
                icon: <DifferenceIcon />,
            },
            {
                title: 'Data',
                path: '/dashboard/valuation-model/dataExport',
                icon: <FilterDramaIcon />,
            }
        ]
    },
    {
        title: 'Power BI Reports',
        path: '/dashboard/power-bi',
        icon: <AssessmentIcon />,
        children: [
            {
                title: 'Summary',
                path: '/dashboard/power-bi/reports',
                icon: <ListAltIcon />,
            },
            {
                title: 'Portfolio Reports',
                path: '/dashboard/power-bi/portfolio',
                icon: <AnalysticsIcon />,
                children: portfolioReports.map(report => ({
                    title: report.title,
                    path: `/dashboard/power-bi/portfolio/${report.id}`,
                    icon: report.icon
                }))
            },
            {
                title: 'Financial Reports',
                path: '/dashboard/power-bi/financial',
                icon: <CalculateIcon />,
                children: financeReports.map(report => ({
                    title: report.title,
                    path: `/dashboard/power-bi/financial/${report.id}`,
                    icon: report.icon
                }))
            },
            {
                title: 'Investor Reports',
                path: '/dashboard/power-bi/investor',
                icon: <PeopleIcon />,
                children: investorReports.map(report => ({
                    title: report.title,
                    path: `/dashboard/power-bi/investor/${report.id}`,
                    icon: report.icon
                }))
            },
            {
                title: 'Loan Admin Reports',
                path: '/dashboard/power-bi/loan-admin',
                icon: <AttachMoneyIcon />,
                children: loanAdminReports.map(report => ({
                    title: report.title,
                    path: `/dashboard/power-bi/loan-admin/${report.id}`,
                    icon: report.icon
                }))
            },
            {
                title: 'IT Reports',
                path: '/dashboard/power-bi/it',
                icon: <ComputerIcon />,
                children: itReports.map(report => ({
                    title: report.title,
                    path: `/dashboard/power-bi/it/${report.id}`,
                    icon: report.icon
                }))
            },
            {
                title: 'Auxiliary Reports',
                path: '/dashboard/power-bi/auxiliary',
                icon: <ListIcon />,
                children: auxReports.map(report => ({
                    title: report.title,
                    path: `/dashboard/power-bi/auxiliary/${report.id}`,
                    icon: report.icon
                }))
            },
        ]
    },
    {
        title: 'Users',
        path: '/dashboard/users',
        icon: <GroupAddIcon />,
        restrict: [userRoleTypes.ADMIN]
    }
];

export default navConfig;
