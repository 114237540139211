import {createSelector, nanoid} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import _ from "lodash";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {GridColDef} from "@mui/x-data-grid";
import {formatCurveTypeLabel} from "../../../utils/valuationUtils";

export const ExceptionsColumns: Array<GridColDef> = [
    {
        field: 'tranche_id',
        headerName: 'ID',
        width: 60,
        valueGetter: (params) => params.row.tranche.tranche_id
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        minWidth: 150,
        flex: 2,
        valueGetter: (params) => params.row.tranche.borrower
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        minWidth: 150,
        flex: 2,
        valueGetter: (params) => params.row.tranche.tranche
    },
    {
        field: 'trancheType',
        headerName: 'Tranche Type',
        minWidth: 150,
        flex: 2,
        valueGetter: (params) => params.row.tranche.tranche_type
    },
    {
        field: 'callProtected',
        headerName: 'Call Protected',
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => (params.value) ? "Yes" : "No"
    },
    {
        field: 'curveOverride',
        headerName: 'Curve Override',
        minWidth: 150,
        flex: 2,
        valueGetter: (params) => (params.row.curveOverride) ? formatCurveTypeLabel(params.row.curveOverride.curveType) : "-"
    },
    {
        field: 'accrualOverrides',
        headerName: 'Accruals Disabled',
        minWidth: 120,
        flex: 2,
        valueFormatter: (params) => (params.value.length) ? params.value : "-"
    },
    {
        field: 'modelException',
        headerName: 'Model Exception',
        minWidth: 150,
        flex: 3,
        valueGetter: (params) => {
            if (params.row.excluded) {
                return "Asset is excluded from pricing model due to assigned type " + params.row.tranche.tranche_type;
            } else if (params.row.accrueAbovePar) {
                return "Asset is above par but is accruing at a profit.";
            } else if (params.row.accrueBelowPar) {
                return "Asset is below par but is accruing at a loss.";
            } else {
                return "-";
            }
        }
    }

]

// Selector for Exceptions page
export const exceptionsSelector = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.callProtected,
    (state: RootState) => state.valuationModel.valuationModelData.accruedOverrides,
    (state: RootState) => state.valuationModel.valuationModelData.sCurveOverride,
    (state: RootState) => _.cloneDeep(state.valuationModel.thirdPartyData.axcess?.portfolio || []),
    (state: RootState) => state.valuationModel.valuationModelData.valuations,
    (callProtectedAssets, accrualOverrideAssets, curveOverrideAssets, tranches, valuations) => {
        const exceptionsList = [];
        for (let t in valuations) {
            // Check if valuation object is empty
            if (Object.keys(valuations[t]).length) {
                const tranche = tranches.find(a => a.tranche_id === parseInt(t));
                const callProtected = callProtectedAssets.some(a => a.trancheId === parseInt(t) && a.status !== SaveStatus.REMOVED);
                const curveOverride = curveOverrideAssets.find(a => a.trancheId === parseInt(t) && a.status !== SaveStatus.REMOVED);
                const accrualOverrides = accrualOverrideAssets.filter(a => a.trancheId === parseInt(t) && a.status !== SaveStatus.REMOVED).map(x => x.fund);
                const excluded = (tranche) ? ['Equity', 'Other'].includes(tranche.tranche_type) : false;
                let accrueAbovePar;
                let accrueBelowPar;
                // There is no expected situation where we accrue away from par, this is to highlight to user
                for (let f in valuations[t]) {
                    if (valuations[t][f].appliedPl > 0 && valuations[t][f].carry > 100) {
                        accrueAbovePar = true;
                        break;
                    }
                    if (valuations[t][f].appliedPl < 0 && valuations[t][f].carry < 100) {
                        accrueBelowPar = true;
                        break;
                    }
                }
                // If any of above conditions are true, add to exceptions page
                if (callProtected || curveOverride || accrualOverrides.length || accrueAbovePar || accrueBelowPar || excluded) {
                    exceptionsList.push({
                        id: nanoid(),
                        tranche: tranche,
                        valuation: valuations[t],
                        callProtected: callProtected,
                        curveOverride: curveOverride,
                        accrualOverrides: accrualOverrides,
                        accrueAbovePar: accrueAbovePar,
                        accrueBelowPar: accrueBelowPar,
                        excluded: excluded
                    })
                }
            }

        }
        return exceptionsList;
    }
)