import {useEffect, useState} from 'react';
// MUI
import {Grid, Container, Typography, CircularProgress} from '@mui/material';
// components
import {HorizontalTable, HorizontalTableColumn, HorizontalTableRow, Page} from '../../components';
// Data
import {capitalBudgetSelector} from "../../store/capitalBudget/selectors";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {setMillions} from "../../store/general/generalSlice";

function mergeDataComparison(initialSet: Array<HorizontalTableColumn>, newSet: Array<HorizontalTableColumn>): Array<HorizontalTableColumn> {
    if (initialSet.length === newSet.length) {
        const newDataSet = [];
        newDataSet.push({
            ...initialSet[0],
            sx: {borderColorLeft: 'primary.main', borderLeft: 1, ...initialSet[0].sx, bgcolor: 'success.lighter', },

        })
        for (let i = 1; i < initialSet.length; i++) {
            newDataSet.push({
                ...initialSet[i],
                sx: {borderColorLeft: 'primary.main', borderLeft: 1, ...initialSet[i].sx, bgcolor: 'success.lighter', },

            });
            newDataSet.push({
                ...newSet[i],
                label: `Updated ${newSet[i].label}`,
                sx: {borderLeft: 0}
            });
        }
        return newDataSet;
    } else {
        return initialSet
    }
}

const receiver = new BroadcastChannel('asset_allocation_parent')
const sender = new BroadcastChannel('asset_allocation_child')


// CLOSE WINDOW
window.addEventListener('beforeunload', () => {
    sender.postMessage({type: 'CLOSE'})
})


// Capital Budget Summary App Page
export default function AssetAllocationHelper() {
    const dispatch = useAppDispatch();

    const [receivedInitial, setReceivedInitial] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [rows, setRows] = useState<Array<HorizontalTableRow>>([]);
    const [data, setData] = useState<Array<HorizontalTableColumn>>([]);
    const [label, setLabel] = useState<string>('')

    const millions = useAppSelector(state => state.general.millions)

    // WHEN FIRST OPENED SEND CONFIRMATION TO MAIN APPLICATION
    useEffect(() => {
        if (!receivedInitial) {
            sender.postMessage({type: 'OPENED'})
        }
    }, [receivedInitial])

    // HANDLES ALL INCOMING MESSAGES
    receiver.onmessage = (e) => {
        const message = e.data;

        // setReceived([...received, `${formatDate(new Date, 'dd/MM/yy hh:mm:ss')} - ${message.type}`])

        switch (message.type) {
            // CLOSE WINDOW
            case 'CLOSE':
                receiver.close();
                window.close();
                break;

            case 'UPDATE':
                setReceivedInitial(true)
                setLoading(true);
                const {
                    base,
                    updated,
                    header
                } = message.data;
                const baseBudget = capitalBudgetSelector(base);
                const updatedBudget = capitalBudgetSelector(updated);
                setLabel(header);
                setRows(baseBudget.rows);
                setData(mergeDataComparison(baseBudget.data, updatedBudget.data))
                setLoading(false)
                sender.postMessage({type: 'RECEIVED'});
                break;

            case 'UPDATE_MILLIONS':
                dispatch(setMillions(message.data))
                sender.postMessage({type: 'RECEIVED'});
                break;

            case 'LOADER':
                setReceivedInitial(false)
                setLoading(true);
                sender.postMessage({type: 'RECEIVED'});
                break;

            default:
                break;
        }
    }

    return (
        <Page title="Metrics - Asset Allocation">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, pb: 20}}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                        Asset Allocation {label && '- ' + label} {millions && '($m)'}
                    </Typography>
                </Grid>
                {/*{received.map((r, i) => (*/}
                {/*    <p key={i}>*/}
                {/*        {r}*/}
                {/*    </p>*/}
                {/*))}*/}
                {!receivedInitial || loading ?
                    <Grid container alignItems='center' justifyContent='center' sx={{height: 1}}>
                        <CircularProgress size={100}/>
                    </Grid>
                    :
                    <Grid container sx={{height: 1, width: '100%'}}>
                        <Grid item xs={12}>
                            <HorizontalTable
                                rows={rows}
                                data={(data) ? data : []}
                            />
                        </Grid>
                    </Grid>
                }
            </Container>
        </Page>
    )
}