import { InputTypes } from "../../types/InputTypes";

export const handleFormValueChange = (field: string, type: InputTypes, handleChange: (value: any, field: string) => void, setTouched?: Function) => (event: any) => {
    let value;

    value = event.target.value;

    if (setTouched) {
        setTouched(field);
    }
    handleChange(value, field);
}