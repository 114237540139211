import {RootState} from "../../store";
import {MCPFund} from "../../../types/capitalBudgetTypes";
import {FundType} from "../../../types/capitalBudgetEnums";
import {fPercent} from "../../../utils/formatNumber";
import {HorizontalTableColumn, HorizontalTableRow} from "../../../components";
import {createSelector} from "@reduxjs/toolkit";


// Retrieves all funds allow filter for only underlying funds
export const retrieveAllFunds = createSelector(
    (state: RootState) => state.capitalBudget.scenarioData?.funds,
    (_state: RootState, underlyingOnly?: boolean) => underlyingOnly,
    (funds, underlyingOnly) => {
        if (!funds) return [];
        if (underlyingOnly) {
            return funds.filter(f => f.type === FundType.UNDERLYING);
        } else {
            return funds;
        }
    }
)

export const _retrieveAllFunds = (state: RootState, underlyingOnly?: boolean): Array<MCPFund> => {
    const funds = state.capitalBudget.scenarioData?.funds;
    if (!funds) return [];
    if (underlyingOnly) {
        return funds.filter(f => f.type === FundType.UNDERLYING);
    } else {
        return funds;
    }
}

// Retrieves all funds in select format allow filter for only underlying funds
export const retrieveFundsSelectFormat = createSelector(
    (state: RootState) => state.capitalBudget.scenarioData?.funds,
    (_state: RootState, underlyingOnly?: boolean) => underlyingOnly,
    (allFunds, underlyingOnly) => {
        const funds: Array<{ value: number, label: string }> = [];

        if (allFunds) {
            allFunds.forEach(fund => {
                if (!underlyingOnly || (underlyingOnly && fund.type === FundType.UNDERLYING)) {
                    funds.push({
                        value: fund.id,
                        label: fund.label
                    })
                }
            })
        }

        return funds;
    }
)

export const retrieveFundLabels = (state: RootState): Array<MCPFund> => {
    const allFunds = state.capitalBudget.scenarioData?.funds;

    let funds: Array<MCPFund> = [];

    if (allFunds) {
        funds = allFunds
    }
    return funds;
}

// Retrieves horizontal table data for fund holdings
export const retrieveFundPortfolioHoldings = createSelector(
    (state: RootState) => state.capitalBudget.thirdPartyData?.holding.data || [],
    (funds) => {
        const rows: Array<HorizontalTableRow> = [];
        const data: Array<HorizontalTableColumn> = [];

        const holders = funds.filter(f => !!f.holdings)

        holders.forEach(h => {
            rows.push({
                id: h.label,
                label: h.label,
                formatter: fPercent,
            })

            h.holdings.forEach(i => {
                const col = data.find(d => d.label === i.fund);
                if (col) {
                    col[`${h.label}`] = i.percentage;
                } else {
                    data.push({
                        label: i.fund,
                        [h.label]: i.percentage
                    })
                }
            })
        })

        return {
            data: data.sort((a, b) => a.label > b.label ? 1 : -1),
            rows: rows.sort((a, b) => a.label > b.label ? 1 : -1)
        }
    }
)

export const _retrieveFundPortfolioHoldings = (state: RootState) => {
    const funds = state.capitalBudget.thirdPartyData?.holding.data || [];
    const rows: Array<HorizontalTableRow> = [];
    const data: Array<HorizontalTableColumn> = [];

    const holders = funds.filter(f => !!f.holdings)

    holders.forEach(h => {
        rows.push({
            id: h.label,
            label: h.label,
            formatter: fPercent,
        })

        h.holdings.forEach(i => {
            const col = data.find(d => d.label === i.fund);
            if (col) {
                col[`${h.label}`] = i.percentage;
            } else {
                data.push({
                    label: i.fund,
                    [h.label]: i.percentage
                })
            }
        })
    })

    return {
        data: data.sort((a, b) => a.label > b.label ? 1 : -1),
        rows: rows.sort((a, b) => a.label > b.label ? 1 : -1)
    }
}