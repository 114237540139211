import {useMemo, useState} from "react";
import {FormikProps} from "formik";
import * as Yup from 'yup';
// Local imports
import {InputTypes} from "../../../../types/InputTypes";
import {addValues} from "../../../../utils/mathUtil";
import {checkDateBefore, formatDate} from "../../../../utils/DateUtils";
import {fCurrency, fPercent} from "../../../../utils/formatNumber";
//MUI
import {
    Box, Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import {Close as CloseIcon} from '@mui/icons-material';
// Components
import {DataDisplay, EditableTable, EditableTableColumn, EditableTableRow, SideDrawer} from '../../../../components';
// Store
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {findWIPLoan, retrieveAllFunds, retrieveNewDealsData} from "../../../../store/capitalBudget/selectors";
import {MCPFund, NewDeal} from "../../../../types/capitalBudgetTypes";
import {DealInclusion, MCPRatings} from "../../../../types/capitalBudgetEnums";
import {updateNewDeal} from "../../../../store/capitalBudget/capitalBudgetSlice";
import {calculateCapitalBudget} from "../../../../store/capitalBudget/capitalBudgetFunctions";

const _ = require('lodash');

// ----------------------------------------------------------------------
// Column definitions
let WIPColumns: EditableTableColumn[] = [
    {
        id: 'include',
        label: 'Included',
        edit: {
            fieldType: InputTypes.SELECTION,
            values: [
                {label: 'Budget', value: DealInclusion.BUDGET},
                {label: 'WIP', value: DealInclusion.WIP},
                {label: 'Unallocated', value: DealInclusion.UNASSIGNED},
            ],
            displayNone: false
        },
        formatter: (value: string) => {
            if (value === DealInclusion.UNASSIGNED) {
                return 'Not Allocated';
            } else {
                return value;
            }
        }
    },
    {
        id: 'expectedClose',
        label: 'Expected Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right'
        },
        formatter: (value: string) => {
            if (value === null) return 'N/A'
            else return formatDate(new Date(value), 'dd-MM-yyyy')
        }
    },
    {
        id: 'amendedCloseDate',
        label: 'Amended Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right'
        },
        edit: {
            fieldType: InputTypes.DATE
        },
        formatter: (value: string) => {
            if (value === null) return 'N/A'
            else return formatDate(new Date(value), 'dd-MM-yyyy')
        }
    },
    {
        id: 'adjustedClose',
        label: 'Adjusted Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right'
        },
        formatter: (value: string) => {
            if (value == null) return 'N/A'
            else return formatDate(new Date(value), 'dd-MM-yyyy')
        }
    },
    {
        id: 'name',
        label: 'Asset Name',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 20,
            // bgcolor: 'white'

        },
        sx: {
            position: 'sticky',
            left: 0,
            zIndex: 8,
            bgcolor: 'white'
        }
    },
];

const detailedColumns: EditableTableColumn[] = [{
    id: 'owner',
    label: 'Relationship Owner',
    align: 'left',
},
    {
        id: 'industry',
        label: 'Industry',
        align: 'left',
    },
    {
        id: 'currency',
        label: 'Base Currency',
        align: 'left',
    },
    {
        id: 'ranking',
        label: 'Ranking',
        align: 'left',
    },
    {
        id: 'rating',
        label: 'Rating',
        align: 'left',
        formatter: (value) => value?.toUpperCase() || null
    },
    {
        id: 'tenor',
        label: 'Tenor (yrs)',
        align: 'right',
        formatter: (value) => {
            return (Math.round(value * 100) / 100).toString()
        },
    },
    {
        id: 'upfront',
        label: 'Upfront Fees',
        align: 'right',
        formatter: fPercent,
    },
    {
        id: 'margin',
        label: 'Margin (%)',
        align: 'right',
        formatter: fPercent,
    },
    {
        id: 'lineFee',
        label: 'Line Fee (%)',
        align: 'right',
        formatter: fPercent,
    },
]

const commitmentColumns: Array<EditableTableColumn> = [
    {
        id: 'commitment',
        label: 'Commitment',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px'
        },
        formatter: (value) => {
            if (value === null) return 'N/A'
            return fCurrency(value)
        }
    },
    {
        id: 'commitmentOffset',
        label: 'Commitment Offset',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        edit: {
            fieldType: InputTypes.CURRENCY,
            numFormatProps: {
                allowNegative: true,
                decimalScale: 2,
                fixedDecimalScale: true
            },
        },
        sx: {
            minWidth: '110px'
        },
        formatter: (value) => {
            if (value === null) return 'N/A'
            return fCurrency(value)
        }
    },
    {
        id: 'adjustedCommitment',
        label: 'Adjusted Commitment',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px'
        },
        formatter: (value) => {
            if (value === null) return 'N/A'
            return fCurrency(value)
        }
    },
    {
        id: 'drawnClose',
        label: '% Drawn at Close',
        align: 'right',
        total: false,
        headSX: {
            borderRight: '1',
            borderColor: 'common.white',
            fixedDecimalScale: true
        },
        sx: {
            borderRight: 2,
            minWidth: '110px'
        },
        formatter: (value) => {
            if (value === null) return 'N/A'
            return fPercent(value)
        }
    }
];

// Displays and allows editing of WIP Transactions
export default function WIPTransactions() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.capitalBudget.loading);
    const funds = useAppSelector(state => retrieveAllFunds(state, true));

    const transactions = useAppSelector(state => retrieveNewDealsData(state));

    const [selected, setSelected] = useState<EditableTableRow | null>(null);

    const [include, setInclude] = useState<DealInclusion | 'all'>('all');
    const [inPeriod, setInPeriod] = useState<boolean>(false);
    const reportDate = useAppSelector(state => state.capitalBudget.thirdPartyData?.dateOfDataSet || null);

    const [detailed, setDetailed] = useState<boolean>(false)

    const onSetInclude = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value === 'all') setInclude('all');
        else setInclude(value as DealInclusion)
    }

    const loan = useAppSelector(state => findWIPLoan(state, selected));

    const columns: EditableTableColumn[] = [
        ...WIPColumns,
        ...(detailed ? detailedColumns : []),
        ...commitmentColumns,
        ...funds.map((fund: MCPFund, f) => {
            return {
                id: `${fund.label}`,
                label: fund.label,
                align: 'right',
                total: true,
                edit: {
                    fieldType: InputTypes.CURRENCY,
                    numFormatProps: {
                        allowNegative: false,
                        decimalScale: 2,
                        fixedDecimalScale: true
                    },
                    disabled: (f === 0)
                },
                sx: {
                    minWidth: '110px'
                },
                formatter: (value) => fCurrency(value)
            } as EditableTableColumn
        })
    ]

    const handleEditRow = (row: EditableTableRow) => {
        let updatedRow = _.cloneDeep(row);
        funds.forEach((fund: MCPFund) => {
            updatedRow.allocation[fund.label] = updatedRow[fund.label];
            delete updatedRow[fund.label];
        })
        // Format Date Before Saving
        if (updatedRow.amendedCloseDate) updatedRow.amendedCloseDate = formatDate(updatedRow.amendedCloseDate, 'yyyy-MM-dd');
        // Handle null or not commitmentOffset
        if (!updatedRow.commitmentOffset) updatedRow.commitmentOffset = null;
        dispatch(updateNewDeal(updatedRow as NewDeal))
    }

    // ----------------------------------------------------------------------
    // Validation Schema
    const TransactionSchema = Yup.object().shape({
        commitment: Yup.number()
            .min(0)
            .nullable(),
        adjustedCommitment: Yup.number()
            .min(0)
            .nullable(),
        DASLF: Yup.number()
            .test(
                'DASLF check', 'Allocation amount lower than threshold.',
                (value, context) => {
                    const commitment = context.parent.adjustedCommitment;
                    if (value) {
                        return !(value !== 0 && commitment > 250000 && value < 250000);
                    }
                    return true
                }
            )
    });
// ----------------------------------------------------------------------
    // Memoization for filtered loan
    const filtered = useMemo(() => {

        return transactions.filter(t => {
            if ((inPeriod && reportDate && checkDateBefore(t.adjustedClose, reportDate))) {
                // if ((inPeriod && reportDate && checkDateBefore(t.expectedClose, reportDate)) || !t.expectedClose) {
                return false
            }
            switch (include) {
                case DealInclusion.WIP:
                    return (t.include === DealInclusion.WIP);
                case DealInclusion.BUDGET:
                    return (t.include === DealInclusion.BUDGET);
                case DealInclusion.UNASSIGNED:
                    return (t.include === DealInclusion.UNASSIGNED);
                default:
                    return true
            }
        })
    }, [transactions, inPeriod, reportDate, include])

    return (
        <>
            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                <EditableTable
                    title={'WIP Transactions'}

                    columns={columns}
                    data={filtered}

                    rowSelect={(row) => setSelected(row)}
                    selectedRow={(selected) ? selected : null}

                    defaultSort='adjustedClose'
                    defaultOrder='asc'
                    altSort='name'

                    loading={(loading || (transactions.length === 0))}

                    expand

                    customFilter={
                        <>
                            <Button variant={'contained'} disableElevation onClick={() => {
                                dispatch(calculateCapitalBudget())
                            }} sx={{mr: 1}}>
                                Recalculate
                            </Button>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={detailed}
                                        onChange={() => setDetailed(!detailed)}
                                        name={'wip-detailedView'}
                                        color='info'
                                    />
                                }
                                label={'Detailed View'}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={inPeriod}
                                        onChange={() => setInPeriod(!inPeriod)}
                                        name={'wip-inperiod'}
                                        color='info'
                                    />
                                }
                                label={'Exclude Pre Report Loans'}
                            />
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography>Show Loans:</Typography>
                                <Select
                                    id="wip_select"
                                    value={include}
                                    label='Show Loans'
                                    variant='standard'
                                    onChange={onSetInclude}
                                    sx={{
                                        pl: 1,
                                        color: 'common.white'
                                    }}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    <MenuItem value={DealInclusion.WIP}>WIP</MenuItem>
                                    <MenuItem value={DealInclusion.BUDGET}>Budget</MenuItem>
                                    <MenuItem value={DealInclusion.UNASSIGNED}>Not Allocated</MenuItem>
                                </Select>
                            </Box>
                        </>
                    }

                    search

                    totalRow

                    handleRowEdit={handleEditRow}
                    validationSchema={TransactionSchema}
                    formRowValueFunction={(values: any, props: FormikProps<any>) => {
                        // Calculates and auto-allocates remainder to DASLF
                        let overflow = values.adjustedCommitment;

                        // Update adjusted commitment if offset has changed
                        if (values.commitmentOffset !== null) {
                            const newAdjustedCommitment = addValues(values.commitment, values.commitmentOffset);
                            // Check if offset wll displace whole deal amount
                            if (newAdjustedCommitment > 0) {
                                if (values.adjustedCommitment !== newAdjustedCommitment) {
                                    props.setFieldValue('adjustedCommitment', newAdjustedCommitment)
                                }
                                overflow = newAdjustedCommitment;
                            } else {
                                if (!props.errors.commitmentOffset) {
                                    props.setFieldError('commitmentOffset', 'Offset exceeds maximum')
                                }
                            }
                        }

                        if (overflow === null) {
                            funds.some((fund: MCPFund) => {
                                if (values[fund.id] > 0) {
                                    if (!props.errors[fund.id]) props.setFieldError(fund.label, 'Cannot allocation fund with null commitment.')
                                    return true;
                                }
                                return false;
                            })
                        }
                        funds.forEach((fund: MCPFund) => {
                            if (fund.label !== 'DASLF') {
                                overflow = addValues(overflow, -values[fund.label]);
                            }
                        })
                        if (overflow < 0) {
                            if (!props.errors.commitment) {
                                props.setFieldError('commitment', 'Sum of fund values is greater than commitment')
                            }
                        } else {
                            if (values.DASLF !== overflow) {
                                props.setFieldValue('DASLF', overflow)
                            }
                        }
                    }}
                />
                {selected && loan &&
                    <SideDrawer
                        title='Details'
                        open={!!selected}
                        onClose={() => setSelected(null)}
                    >
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <IconButton size='small' onClick={() => setSelected(null)}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        <Box sx={{px: 5, pt: 1, mt: -4}}>
                            <DataDisplay
                                dataValues={[
                                    {label: 'Owner', data: loan.owner.name},
                                    {
                                        label: 'Origination Direction',
                                        data: (loan.llc_bi_product_package && loan.llc_bi_product_package.primary_origination_director) ? loan.llc_bi_product_package.primary_origination_director.name : null
                                    },
                                    {
                                        label: 'Deal Financial Close',
                                        data: (loan.llc_bi_product_package) ? formatDate(new Date(loan.llc_bi_product_package.project_financial_close_date), 'dd-MM-yyyy') : null
                                    },
                                    {label: 'Asset Name', data: loan.name},
                                    {label: 'Tranche Type', data: loan.llc_bi_product},
                                    {label: 'Ranking', data: loan.ranking_debt_seniority},
                                    {
                                        label: 'Commitment',
                                        data: (loan.llc_bi_amount) ? fCurrency(loan.llc_bi_amount) : null
                                    },
                                    {
                                        label: 'Expected Drawdown at Close',
                                        data: (loan.expected_drawdown_at_close) ? fCurrency(loan.expected_drawdown_at_close) : null
                                    },
                                    {
                                        label: 'Tenor',
                                        data: (loan.remaining_tenor) ? loan.remaining_tenor.toString() : null
                                    },
                                    {
                                        label: 'Rating',
                                        data: (loan.metrics_rating) ? MCPRatings[loan.metrics_rating] : null
                                    },
                                    {label: 'Industry Segment', data: loan.llc_bi_product_package?.industry},
                                    {label: 'Approval Stage', data: loan.llc_bi_stage},
                                    {label: 'Deal Status', data: loan.llc_bi_product_package?.llc_bi_status},
                                    {
                                        label: 'Fees', subData: [...loan.llc_bi_fees.map(fee => {
                                            return [
                                                {label: 'Type', data: fee.llc_bi_fee_type},
                                                {label: 'Percentage', data: fPercent(fee.llc_bi_percentage / 100)},
                                                {label: 'Amount', data: fCurrency(fee.llc_bi_amount)},
                                                {label: 'Calculation', data: fee.llc_bi_calculation_type},
                                            ]
                                        })]
                                    },
                                    {
                                        label: 'Pricing', subData: [...loan.llc_bi_pricing_streams.map(stream => {
                                            return [
                                                {
                                                    label: 'Date Effective',
                                                    data: formatDate(new Date(stream.llc_bi_effective_date), 'dd-MM-yyyy')
                                                },
                                                {label: 'Term Units', data: stream.llc_bi_term_unit},
                                                {
                                                    label: 'Term Length',
                                                    data: (stream.llc_bi_term_length) ? stream.llc_bi_term_length.toString() : null
                                                },
                                                {
                                                    label: '',
                                                    alwaysExpand: true,
                                                    subData: [...stream.llc_bi_pricing_rate_components.map(component => {
                                                        return [
                                                            {
                                                                label: 'Interest Rate Type',
                                                                data: component.llc_bi_interest_rate_type
                                                            },
                                                            {
                                                                label: 'All in Rate',
                                                                data: (component.llc_bi_all_in_rate) ? fPercent(component.llc_bi_all_in_rate / 100) : null
                                                            },
                                                            {
                                                                label: 'Rate',
                                                                data: (component.llc_bi_rate) ? fPercent(component.llc_bi_rate / 100) : null
                                                            },
                                                            {
                                                                label: 'Spread',
                                                                data: (component.llc_bi_spread) ? fPercent(component.llc_bi_spread / 100) : null
                                                            },
                                                            {label: 'Index', data: component.llc_bi_index},
                                                            {label: 'Margin Type', data: component.margin_type},
                                                        ]
                                                    })]
                                                },
                                            ]
                                        })]
                                    },
                                    {
                                        label: 'Domicile',
                                        data: (loan.llc_bi_product_package?.state_of_exposure === 'Offshore') ? loan.llc_bi_product_package?.offshore_location : 'Australia'
                                    },
                                    {label: 'Base Currency', data: loan.currency_iso_code},
                                    {label: 'Multi Currency', data: loan.multi_currency_option},
                                ]}
                            />
                        </Box>
                    </SideDrawer>
                }
            </Grid>
        </>

    )
}