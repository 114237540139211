import {CalculationLoanType, CapitalBudgetPeriodResults} from "../../../types/capitalBudgetTypes";
import {addValues} from "../../../utils/mathUtil";
import {LoanTags} from "../../../types/capitalBudgetEnums";
import {convertRatingToValue} from "../../../utils/CapitalBudgetUtils";

// Values to sum when assets grouped.
const summedFields = [
    'value',
    'undrawn'
];

export interface PeriodSummaryResults extends CapitalBudgetPeriodResults {
    [x: string]: any
}

// Processes a Period to calculate additional fields and portfolio information
export function summarisePeriod(results: PeriodSummaryResults): PeriodSummaryResults {
    let realestate = 0;
    let totalActive = 0;
    let senior = 0;
    let subordinated = 0;
    let equity = 0;
    let other = 0;
    let otherRanking: { [x: string]: number } = {};
    let investmentGrade = 0;
    let subInvestmentGrade = 0;
    let unrated = 0;
    let domicile = 0;
    let aud = 0;


    // GROUP BY ASSET ID
    results.assets = [...results.loans.reduce((newList: any, loan: any) => {
        if (!loan.tags.includes(LoanTags.ACTIVE)) return newList;
        totalActive = addValues(totalActive, loan.value)

        // Ranking Diversification
        switch (loan.ranking) {
            case 'Senior':
                senior = addValues(senior, loan.value);
                break;
            case 'Subordinated':
                subordinated = addValues(subordinated, loan.value);
                break;
            case 'Equity':
                equity = addValues(equity, loan.value);
                break;
            case 'Other':
                other = addValues(other, loan.value);
                break;

            default:
                if (Object.hasOwn(otherRanking, loan.ranking)) {
                    otherRanking[loan.ranking] = addValues(otherRanking[loan.ranking], loan.value);
                } else {
                    otherRanking[loan.ranking] = loan.value;
                }
        }

        // Investment Grade
        const numericRating = convertRatingToValue(loan.rating)
        if (numericRating && numericRating <= 12) {
            investmentGrade = addValues(investmentGrade, loan.value);
        } else {
            subInvestmentGrade = addValues(subInvestmentGrade, loan.value);
        }

        // Real estate exposure
        if (loan.industryGroup === 'Real Estate') {
            realestate = addValues(realestate, loan.value)
        }

        // Loan origination
        if (loan.domicile === 'Australia') {
            domicile = addValues(domicile, loan.value);
        }
        if (loan.baseCurrency === 'AUD') {
            aud = addValues(aud, loan.value);
        }

        const key = loan.client;

        // Record client Split via fund
        loan.funds = {
            [loan.fund]: loan.value
        };

        // Record Running Total of summable fields
        let itemBalances: any = {};
        summedFields.forEach((field: string) => {
            itemBalances[field] = 0;
        })

        // Check and retrieve field if already in output array
        let item = newList.get(key);
        if (item) {
            item.funds[loan.fund] = addValues(item.funds[loan.fund] || 0, loan.value);
        } else {
            item = Object.assign({}, loan, itemBalances)
        }

        // consolidate summed fields
        summedFields.forEach(field => {
            item[field] = addValues(item[field], loan[field]);
        })

        // Remove unecessary fields
        delete item.fund
        delete item.trancheId

        return newList.set(key, item);
    }, new Map()).values()]

    results.assets = results.assets.sort((a: CalculationLoanType, b: CalculationLoanType) => {
        if (a.value > b.value) return -1
        else if (b.value > a.value) return 1
        else return (b.name > a.name) ? 1 : -1
    })

    // Industry Diversification Breakdown
    // GROUP BY CLIENT ID
    results.industries = [...results.loans.reduce((newList: any, loan: any) => {
        if (!loan.tags.includes(LoanTags.ACTIVE)) return newList;

        const key = loan.industry;

        let itemObject = {
            industry: loan.industry,
            value: loan.value,
            percentage: loan.value / results.aum
        }

        let item = newList.get(key);

        if (item) {
            item.value = addValues(item.value, loan.value)
            item.percentage = item.value / results.aum
        } else {
            item = itemObject
        }

        return newList.set(key, item)
    }, new Map()).values()]

    if (results.startingAvailableCap > 0) {
        results.industries.push({
            industry: 'Cash',
            value: results.startingAvailableCap,
            percentage: results.startingAvailableCap/results.aum
        })
    }

    const topTen = results.assets.slice(0, 10).reduce((a: number, b: CalculationLoanType) => {
        return addValues(a, b.value);
    }, 0)

    if (results.startingAvailableCap > 0) {
        senior = addValues(senior, results.startingAvailableCap);
        investmentGrade = addValues(investmentGrade, results.startingAvailableCap);
        domicile = addValues(domicile, results.startingAvailableCap);
        aud = addValues(aud, results.startingAvailableCap)
    }

    for (const rank in otherRanking) {
        otherRanking[rank] = otherRanking[rank]/results.aum
    }

    results = {
        ...results,

        cashPer: (results.startingAvailableCap > 0) ? results.startingAvailableCap / results.aum : 0,

        senior: senior / results.aum,
        subordinated: subordinated / results.aum,
        equity: equity / results.aum,
        other: other / results.aum,
        otherRanking,

        investmentGrade: investmentGrade / results.aum,
        subInvestmentGrade: subInvestmentGrade / results.aum,
        unrated: unrated / results.aum,

        avgSize: totalActive / results.assets.length,
        largestExposure: results.assets[0].value,
        largestExposurePer: results.assets[0].value / totalActive,
        topTenExposure: topTen,
        topTenExposurePer: topTen / totalActive,

        realEstateExp: realestate / results.aum,

        domicile: domicile / results.aum,
        aud: aud / results.aum,
    }

    return results;
}