import { useEffect } from 'react';
import { Location, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import useNotifier from '../hooks/useNotifier';
// Layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MinimalDashboardLayout from "../layouts/Minimal";
// Pages
import {
    ConfirmNewUser,
    ForgotPassword,
    Login,
    Logout, Main,
    Page404 as NotFound,
    Profile,
    ResetPassword,
    Users,
} from '../pages';
import CapitalBudget from '../pages/CapitalBudget';
import FundAssetRegister from '../pages/AssetFundRegister';
import Info from '../pages/Info';
import SCurveArm from "../pages/SCurveArm";
import ValuationModel from "../pages/ValuationModel";
// Store and User State Functions
import { useAppDispatch, useAppSelector } from '../store/store';
import { getSession, reauthenticateUser } from '../store/user/userFunctions';
import { getUserRole, isAuthenticated } from '../store/user/userSelectors';
import { userRoleTypes } from '../store/user/userSlice';
import PowerBIPages from "../pages/PowerBI";

// ----------------------------------------------------------------------

/**
 * Handles the Routing for the application
 * 
 * Protect routes  from unauthorised access
 * If user has logged in reauthenticates users and redirects to page
 * 
 */

export default function Router() {
    // check if user is authenticated
    const authenticated = useAppSelector(state => isAuthenticated(state))
    const role = useAppSelector(state => getUserRole(state));

    useNotifier();

    return (
        <Routes>
            <Route path='/logout' element={<Logout authenticated={authenticated} />} />

            {/* AUTHENTICATED ROUTES */}
            <Route path='/dashboard' element={<RequireAuth authenticated={authenticated}><DashboardLayout /></RequireAuth>}>
                <Route path='/dashboard' element={<Navigate to="home" />} />
                <Route path='/dashboard/home' element={<Main />} />
                <Route path='/dashboard/asset-fund-register/*' element={<FundAssetRegister />} />
                <Route path='/dashboard/capital-budget/*' element={<CapitalBudget />} />
                <Route path='/dashboard/s-curve_arm/*' element={<SCurveArm />} />
                <Route path='/dashboard/valuation-model/*' element={<ValuationModel />} />
                <Route path='/dashboard/power-bi/*' element={<PowerBIPages />} />
                <Route path='/dashboard/profile' element={<Profile />} />
                <Route path='/dashboard' element={<RestrictedRoute userRole={role} allowed={[userRoleTypes.ADMIN]}><><Outlet/></></RestrictedRoute>}>
                    <Route path='/dashboard/users' element={<Users />} />
                </Route>
                {/* PATH NOT FOUND */}
                <Route path='/dashboard/404' element={<NotFound />} />
                <Route path='*' element={<NotFound />} />
            </Route>

            <Route path='/info' element={<RequireAuth authenticated={authenticated}><MinimalDashboardLayout /></RequireAuth>}>
                <Route path='/info/*' element={<Info />} />
            </Route>

            {/* Routes unauthorised/authorised sections with a layout and wrapper to determine authorisation process */}
            {/* UNAUTHENTICATED ROUTES */}
            < Route path='/' element={<AuthenticatedCheck authenticated={authenticated}><LogoOnlyLayout /></AuthenticatedCheck>}>
                <Route path='/' element={<Navigate to="login" />} /> {/*  */}
                <Route path='login' element={<Login />} />
                <Route path='forgotPassword' element={<ForgotPassword />} />
                <Route path='resetPassword' element={<ResetPassword />} />
                <Route path='confirmEmail' element={<ConfirmNewUser />} />
                <Route path='dashboard/*' element={<Navigate to="/login" />} />
                <Route path='404' element={<NotFound />} />
            </Route>

            {/* PATH NOT FOUND */}
            <Route path='*' element={<Navigate to="/404" />} />
        </Routes >
    )
}

// Typing of traversable location
interface routingLocation extends Location {
    state: {
        from?: {
            pathname: string
        }
    }
}

// Wrapper for unauthenticated routes
function AuthenticatedCheck({ authenticated, children }: { authenticated: boolean, children: JSX.Element }) {
    let location = useLocation() as routingLocation;
    const dispatch = useAppDispatch();

    // Used to reroute to page before authentication
    let from = location.state?.from?.pathname || '/dashboard'

    // If there is a user session reauthenticate user
    useEffect(() => {
        if (!authenticated && getSession()) {
            dispatch(reauthenticateUser());
        }
    }, [authenticated, dispatch]);

    // If user in state reroute to page
    if (authenticated) {
        return <Navigate to={from} />;
    }

    // Default route to unauthenticated page
    return children;
}

// Wrapper for authenticated routes 
function RequireAuth({ authenticated, children }: { authenticated: boolean, children: JSX.Element }) {
    let location = useLocation();

    // If no user in state
    if (!authenticated) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    // Default route to authenticated page
    return children;
}

// Wrapper for restricting user routes
function RestrictedRoute({ userRole, allowed, children }: { userRole: userRoleTypes | null, allowed: Array<userRoleTypes>, children: JSX.Element }) {

    if (!userRole || !allowed.includes(userRole)) {
        return <Navigate to='/dashboard/404' />;
    }

    return children;
}