import {useEffect} from "react";
import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
// MUI
import {Box, Button, CircularProgress, Divider, Grid, Typography} from "@mui/material";
// Local Imports
import {InputTypes} from "../../../../types/InputTypes";
import usePrevious from "../../../../hooks/usePrevious";
// Components
import {FormInput} from "../../../../components";
// Store
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {saveAsScenario, saveScenario} from "../../../../store/scenarios/scenarioFunctions";

// ----------------------------------------------------------------------
// Validation Schema
const saveSchema = Yup.object().shape({
    description: Yup.string().required('Desc is required'),
});
// ----------------------------------------------------------------------

// Save Scenario
export default function SaveScenario({onClose, saveAs}: { onClose: () => void, saveAs: boolean }) {
    const dispatch = useAppDispatch();

    const scenario = useAppSelector(state => state.scenarios.scenario);
    const dataSetDate = useAppSelector(state => state.capitalBudget.thirdPartyData?.dateOfDataSet || null);
    const saving = useAppSelector(state => state.scenarios.saving);

    const prevSaveState = usePrevious(saving);

    useEffect(() => {
        if (!!prevSaveState && !saving) {
            onClose()
        }
    }, [prevSaveState, saving, onClose])

    return (
        <>
            {scenario &&
                <Formik
                    initialValues={{
                        description: (!saveAs) ? scenario.description : '',
                        thirdPartyDataSetDate: dataSetDate,
                    }}
                    onSubmit={(values) => {
                        if (saveAs) {
                            dispatch(saveAsScenario({description: values.description}));
                        } else {
                            dispatch(saveScenario({description: values.description}));
                        }
                    }}
                    validationSchema={saveSchema}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            handleSubmit,
                        } = props

                        return (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <Grid container sx={{p: 2}}>
                                        <FormInput
                                            id='description'
                                            label='Description'
                                            fieldType={InputTypes.STRING}
                                            layout={{xs: 12, md: 12, lg: 12}}
                                        />
                                        <FormInput
                                            id={`thirdPartyDataSetDate`}
                                            label='Data as of'
                                            fieldType={InputTypes.DATE}
                                            layout={{xs: 12, md: 12, lg: 12}}
                                            disabled={true}
                                        />
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                {saveAs ?
                                                    <Typography align='center' variant='subtitle2'>
                                                        Save As will create a new scenario without overwriting the current scenario.
                                                    </Typography>
                                                    :
                                                    <Typography align='center' variant='subtitle2'>
                                                        Saving the scenario will overwrite the previous state.
                                                    </Typography>
                                                }

                                            </Box>
                                        </Grid>
                                        <Grid item container direction='row'>
                                            <Grid item sx={{width: '50%', p: 2}}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    onClick={onClose}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item sx={{width: '50%', p: 2}}>
                                                {saving ?
                                                    <CircularProgress/>
                                                    :
                                                    <Button
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        {saveAs ?
                                                            'Save As'
                                                            :
                                                            'Save'
                                                        }
                                                    </Button>
                                                }

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        )
                    }}
                </Formik>
            }
        </>
    )
}