import React, {useState} from 'react';
import _ from 'lodash';
// LOCAL
import {ScheduledAdjustment} from "../../../../types/valuationModelTypes";
import {AdjustmentTypeFields} from "../../../../types/valuationModelEnums";
// COMPONENTS
import TransferTemplateReader from "./TransferTemplateReader";
import {actionRow, DataTable, defaultFormat, valueCurrency, valueDate} from "../../../../components";
// @MUI
import {Grid, IconButton} from "@mui/material";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Close as CloseIcon} from "@mui/icons-material";
// STORE
import {useAppDispatch} from "../../../../store/store";
import {bulkScheduleAdjustments} from "../../../../store/valuationModel/valuationModelSlice";


const TransferColumns: Array<GridColDef> = [
    {
        ...defaultFormat,
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    {
        ...defaultFormat,
        field: 'trancheId',
        headerName: 'Tranche Id',
        width: 85
    },
    {
        ...defaultFormat,
        field: 'transactionType',
        headerName: 'Transaction Type',
        flex: 1,
        valueFormatter: (params) => AdjustmentTypeFields.find(a => a.value === params.value)?.label || params.value
    },
    {
        ...defaultFormat,
        field: 'fund',
        headerName: 'Fund',
        width: 80
    },
    {
        ...valueCurrency,
        field: 'amount',
        headerName: 'Amount',
        width: 100
    },
    {
        ...valueDate,
        field: 'startDate',
        headerName: 'Transfer Date',
        width: 100
    },
]

/**
 * Handles upload of CSV and creation of transfers
 * @constructor
 */
const TransactionUpload = () => {
    const dispatch = useAppDispatch();

    const [transactions, setTransactions] = useState<Array<ScheduledAdjustment>>([]);

    const handleRemoveTransaction = (transactionId: string) => {
        const newTransactions = _.cloneDeep(transactions);
        const transactionIndex = newTransactions.findIndex(t => t.id === transactionId);
        if (transactionIndex !== -1) {
            newTransactions.splice(transactionIndex, 1)
        }
        setTransactions(newTransactions)
    }

    const handleCreateTransferTransactions = () => {
        if (transactions.length > 0) {
            dispatch(bulkScheduleAdjustments(transactions));
            setTransactions([]);
        }
    }

    return (
        <>
            <Grid container direction="column" justifyContent='flex-start' sx={{height: '100%'}}>
                <Grid item>
                    <TransferTemplateReader setTransactions={setTransactions} save={handleCreateTransferTransactions}/>
                </Grid>
                <Grid item sx={{flex: 1}}>
                    <TransactionDisplay transactions={transactions} handleRemove={handleRemoveTransaction} />
                </Grid>
            </Grid>
        </>
    )
}

export default TransactionUpload;


const TransactionDisplay = ({transactions, handleRemove}: {transactions: Array<ScheduledAdjustment>, handleRemove: (id: string) => void}) => {
    return (
        <DataTable
            title={'Future Transfers'}
            columns={[
                ...TransferColumns,
                {
                    ...actionRow,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <>
                                <IconButton
                                    sx={{color: 'primary.main'}}
                                    style={{padding: '0px', outline: 'none'}}
                                    onClick={() => handleRemove(params.row.id)}
                                    size="small"
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </>
                        )
                    }
                }
            ]}
            data={transactions}
            loading={false}
            showExport={false}
        />
    )
}