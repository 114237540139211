// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {PortfolioChanges} from "../../../types/capitalBudgetTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {checkDateSame} from "../../../utils/DateUtils";

// Reducers Related to Capital Budget - Portfolio
const portfolioReducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create new Capital Provider
    addPortfolioLoanChange: (state, action: PayloadAction<PortfolioChanges>) => {
        if (state.scenarioData) {
            const index = state.scenarioData.portfolioChanges.findIndex(p => p.trancheId === action.payload.trancheId && p.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.scenarioData.portfolioChanges[index].status !== SaveStatus.NEW) {
                    let changed = false;
                    const existing = state.scenarioData.portfolioChanges[index];
                    if (!checkDateSame(existing.amendedMaturity, action.payload.amendedMaturity)) changed = true;
                    if (existing.amendment !== action.payload.amendment) changed = true;
                    if (changed) {
                        action.payload.previous = (!existing.previous) ? existing : existing.previous;
                        action.payload.status = SaveStatus.EDITED;
                        state.scenarioData.portfolioChanges[index] = action.payload;
                    }
                } else if (state.scenarioData.portfolioChanges[index].status === SaveStatus.NEW) {
                    action.payload.status = SaveStatus.NEW;
                    state.scenarioData.portfolioChanges[index] = action.payload;
                }
            } else {
                action.payload.status = SaveStatus.NEW;
                state.scenarioData.portfolioChanges = [...state.scenarioData.portfolioChanges, action.payload]
            }
        }
    },
    removePortfolioLoanChange: (state, action: PayloadAction<PortfolioChanges>) => {
        if (state.scenarioData) {
            const index = state.scenarioData.portfolioChanges.findIndex(p => p.trancheId === action.payload.trancheId && p.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.scenarioData.portfolioChanges[index].status !== SaveStatus.NEW) {
                    action.payload.status = SaveStatus.REMOVED;
                    state.scenarioData.portfolioChanges[index] = action.payload;
                } else {
                    const portfolioChanges = state.scenarioData.portfolioChanges;
                    portfolioChanges.splice(index, 1);
                    state.scenarioData.portfolioChanges = portfolioChanges;
                }
            }
        }
    }
}

export default portfolioReducers;