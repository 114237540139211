import {memo} from "react";
// MUI Components
import {TableRow, TableCell, IconButton, Typography} from "@mui/material";
import {Delete as DeleteIcon, KeyboardArrowRight as KeyboardArrowRightIcon} from "@mui/icons-material";
// Components
import {EditableTableColumn, EditableTableRow} from ".";
import {determineStatusColor} from "../../utils/colorGenUtils";

// ----------------------------------------------------------------------
// Props

interface UnEditTableRowProps {
    row: EditableTableRow,
    columns: Array<EditableTableColumn>,
    setEditRow: (row: EditableTableRow) => void, // set Row for edit
    selectedRow?: number | string | null, // id of row selected
    handleRowSelection: (row: EditableTableRow) => void, // callback for selecting row
    handleRemove?: (id: number | string) => void, // callback for handling remove
}

// ----------------------------------------------------------------------

function UnEditTableRow(props: UnEditTableRowProps) {
    const {
        row,
        columns,
        setEditRow,
        handleRowSelection,
        handleRemove
    } = props;

    let cbColor: string | undefined = determineStatusColor(row.status)

    return (
        <TableRow
            sx={{
                "&:hover": {
                    // CUSTOM TO CAPITAL BUDGET
                    ...(cbColor) ? {bgcolor: `${cbColor}.light`} : {bgcolor: 'grey.300'}
                },
                height: 30,
                userSelect: 'text'
            }}
        >
            {/* EXPAND ICONS */}
            {!!handleRowSelection &&
                <TableCell
                    sx={{
                        "&:hover": {
                            // CUSTOM TO CAPITAL BUDGET
                            ...(cbColor) ? {bgcolor: `${cbColor}.light`} : {bgcolor: 'grey.300'}
                        },
                        borderColor: 'primary.main',
                        p: 0,
                        ...(cbColor) ? {bgcolor: `${cbColor}.lighter`} : {}
                    }}
                    align='center'
                >
                    <IconButton
                        style={{padding: '0px', outline: 'none'}}
                        onClick={() => handleRowSelection(row)}
                        size="small">
                        <KeyboardArrowRightIcon/>
                    </IconButton>
                </TableCell>
            }
            {/* LOOP DATA CELLS */}
            {columns.map((column, c) => (
                <TableCell
                    id={`${row.id}-${column.id}`}
                    key={column.id + c}
                    align={column.align ? column.align : 'center'}
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        "&:hover": {
                            // CUSTOM TO CAPITAL BUDGET
                            ...(cbColor) ? {bgcolor: `${cbColor}.light`} : {bgcolor: 'grey.300'}
                        },
                        flex: 1,
                        p: 1,
                        borderColor: 'primary.main',
                        // CUSTOM TO CAPITAL BUDGET
                        ...row.sx,
                        ...column.sx,
                        ...(cbColor) ? {bgcolor: `${cbColor}.lighter`} : {},
                    }}
                    onClick={() => setEditRow(row)}
                >
                    <Typography>
                        {column.formatter ?
                            column.formatter(row[column.id])
                            :
                            row[column.id]
                        }
                    </Typography>
                </TableCell>
            ))}
            {handleRemove &&
                <TableCell
                    sx={{
                        ...row.sx,
                        borderColor: 'primary.main',
                        px: 1,
                        // CUSTOM TO CAPITAL BUDGET
                        ...(cbColor) ? {bgcolor: `${cbColor}.lighter`} : {}
                    }}
                >
                    <IconButton
                        sx={{color: 'primary.main'}}
                        style={{padding: '0px', outline: 'none'}}
                        onClick={() => handleRemove(row.id)}
                        size="small">
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>
            }
        </TableRow>
    )
}

export default memo(UnEditTableRow);