import {RootState} from "../../store";
import {MCPFund, SelldownRepayment} from "../../../types/capitalBudgetTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {createSelector} from "@reduxjs/toolkit";

/**
 * Retrieves axcess loans in format for selldown or Early Repayments
 * @param state
 */
export const selldownRepaymentSelector = createSelector(
    (state: RootState) => state.capitalBudget.thirdPartyData?.axcess.portfolio,
    (state: RootState) => state.capitalBudget.scenarioData?.selldownRepayments,
    (axcess, selldownRepayments) => {
        if (!axcess || !selldownRepayments) return [];

        const transactions: Array<any> = [];

        selldownRepayments.forEach(trans => {
            // DO NOT ADD REMOVED
            if (trans.status !== SaveStatus.REMOVED) {
                // ATTACHES TRANCHE INFORMATION TO SELLDOWN
                const index = axcess.findIndex(l => (l.tranche_id === trans.trancheId && l.fund === trans.fund));
                if (index !== -1) {
                    transactions.push({
                        ...axcess[index],
                        ...trans
                    })
                }
            }
        })

        return transactions;
    }

)

// Retrieve all Selldowns for Fund
export const retrieveAllSelldownRepaymentsByFund = (state: RootState, fund: MCPFund | null) => {
    let selldownRepayments = state.capitalBudget?.scenarioData?.selldownRepayments || [];
    let loans = state.capitalBudget.thirdPartyData?.axcess.portfolio || [];

    if (fund) {
        selldownRepayments = selldownRepayments.filter(s => s.fundId === fund.id)
    }


    const confirmedSelldownRepayments: Array<SelldownRepayment> = []

    selldownRepayments.forEach(s => {
        const loan = loans.find(l => l.tranche_id === s.trancheId);
        if (loan) {
            confirmedSelldownRepayments.push({
                ...s,
                loanMaturity: loan.maturity,
                drawnCommitmentPer: loan.drawn / loan.commitment
            })
        }
    })

    return confirmedSelldownRepayments;
}

// // Retrieves all Loans in Tranche
// export const retrieveLoansAllocatedByTranche = (state: RootState, trancheId: number | null): Array<Loan> => {
//     const axcess = state.capitalBudget.thirdPartyData?.axcess;
//
//     if (!axcess || !trancheId) return [];
//
//     return axcess.filter(loan => loan.tranche_id === trancheId);
// }



