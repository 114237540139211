import { Amplify } from 'aws-amplify';

// Routes
import Router from './Routes/routes';
// Theme
import './App.css';

import ThemeProvider from './theme';
// MUI
import { Clear as ClearIcon } from "@mui/icons-material";
import { Box, IconButton } from '@mui/material';
//Components
import { SnackbarProvider } from 'notistack';
import { useAppDispatch } from './store/store';
import { closeNotification } from "./store/notifications/notificationSlice";

// AWS AMPLIFY Configuration
const config = {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        // region: process.env.REACT_APP_AWS_REGION,
        region: 'ap-southeast-2',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_POOL_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: "MCPAPI",
                endpoint: process.env.REACT_APP_AWS_MCP_API
            },
        ]
    }
}
try {
    Amplify.configure(config);
} catch (e) {
    console.log(e)
}

// -----------------------------------------------------------------------------------

export default function App() {
    const dispatch = useAppDispatch();

    console.log(process.env.REACT_APP_ENV)
    console.log('BUILD:', process.env.REACT_APP_PIPELINE_ID)

    return (
        <ThemeProvider>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                action={(key) => (
                    <IconButton sx={{color: 'common.white'}} onClick={() => dispatch(closeNotification(key as string))}>
                        <ClearIcon/>
                    </IconButton>
                )}
            >
                <Box sx={{ minWidth: 800}}>
                    <Router />
                </Box>
            </SnackbarProvider>
        </ThemeProvider>
    );
}