import {useState} from 'react';
// MUI
import {Grid, Container, Typography} from '@mui/material';
// components
import {HorizontalTable, HorizontalTableColumn, Page} from '../../components';
import {SelectionBar} from './SelectionBar';
// Store
import {useAppSelector} from '../../store/store'
// Data
import {capitalBudgetSelector} from "../../store/capitalBudget/selectors";
// import {addValues} from "../../utils/mathUtil";
// import {SummaryGraphs} from "../../sections/dashboard/capitalBudget/summary";
// import {CapitalBudgetPeriod} from "../../types/capitalBudgetTypes";

// Capital Budget Summary App Page
export default function CapitalBudgetApp() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const capitalBudget = useAppSelector(state => state.capitalBudget?.capitalBudget || null)
    const {data, rows} = capitalBudgetSelector(capitalBudget);
    const [column, selectCol] = useState<HorizontalTableColumn | null>(null);

    // if (column) {
    //     let test = 0;
    //     column.industry.forEach((i: any) => {
    //         test = addValues(test, i.percentage)
    //     })
    // }

    // console.log(data, rows)

    return (
        <Page title="Metrics - Capital Budget">
            <Container maxWidth={false} sx={{maxWidth: '100%'}}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                        Capital Budget
                    </Typography>
                    <SelectionBar/>
                </Grid>
                <Grid container sx={{height: 1, width: '100%'}}>
                    <Grid item xs={12} md={12} lg={12} sx={{bgColor: 'red', pb: 1}}>
                        <HorizontalTable
                            rows={rows}
                            data={(data) ? data : []}
                            loading={dataLoading}

                            // selectedRow={row}
                            selectedCol={column}
                            // rowSelect={selectRow}
                            colSelect={selectCol}

                            selectableCell
                        />
                    </Grid>
                    {/*<Grid item xs={12} md={12} lg={12}>*/}
                    {/*    {column &&*/}
                    {/*        <>*/}
                    {/*            <Typography variant="h4" sx={{mb: 2, color: 'secondary.main', pt: 4, pb: 0}}>*/}
                    {/*                {column.label}*/}
                    {/*            </Typography>*/}
                    {/*            <SummaryGraphs columnData={column as CapitalBudgetPeriod}/>*/}
                    {/*        </>*/}
                    {/*    }*/}
                    {/*</Grid>*/}
                </Grid>
            </Container>
        </Page>
    )
}