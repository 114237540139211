import {useState} from "react";
// MUI
import {Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {
    ChevronRight as ExpandIcon,
    ExpandMore as CloseIcon
} from "@mui/icons-material"
// Local Import
import {formatDate} from "../../../../utils/DateUtils";
import {fCurrency, fPercent} from "../../../../utils/formatNumber";
// Components
import {SideDrawer} from "../../../../components";
// Store
import {useAppSelector} from "../../../../store/store";
import {EditAccountBalances} from "./index";
import {FundBalance} from "../../../../types/capitalBudgetTypes";


export default function AccountBalanceTotals() {
    const accBalances: any = useAppSelector(state => state.capitalBudget.scenarioData?.accountBalances || null)
    const loading = useAppSelector(state => state.capitalBudget.loading);
    const date = useAppSelector(state => state.scenarios.scenario?.reportAsOf || null);

    const balanceId = useAppSelector(state => state.scenarios.scenario?.accountBalanceId);
    const balancesDate = useAppSelector(state => state.capitalBudget.thirdPartyData?.accountBalance?.date || null);


    const [edit, setEdit] = useState<boolean>(false);

    return (
        <>
            <Table>
                <TableBody
                    sx={{
                    }}
                >
                    <TableRow>
                        <TableCell
                            align='right'
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                                borderTopRadius: 10,
                                border: 'none',
                                p: 1,
                                borderTopRightRadius: 10,
                                borderTopLeftRadius: 10,

                            }}
                            colSpan={5}
                        >
                            {!loading && !balanceId &&
                                <Button
                                    onClick={() => setEdit(!edit)}
                                    variant='outlined'
                                    color='info'
                                    size='small'
                                >
                                    Set Edit
                                </Button>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            sx={{
                                bgcolor: 'primary.main',
                                p: 0
                            }}
                            rowSpan={2}
                        />
                        <TableCell
                            align='center'
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                            rowSpan={2}
                        >
                            Fund
                        </TableCell>
                        <TableCell
                            align='center'
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                            rowSpan={2}
                        >
                            Fund Type
                        </TableCell>
                        <TableCell
                            align='right'
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                                p: 1,
                                pb: 0,
                                borderBottom: 0
                            }}
                        >
                            Cash Balance
                        </TableCell>
                        <TableCell
                            align='right'
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                                p: 1,
                                pb: 0,
                                borderBottom: 0
                            }}
                        >
                            Available Capital
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align='right'
                            sx={{
                                m: 0,
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                                p: 1,
                                pt: 0
                            }}
                        >
                            (as of {balancesDate ? formatDate(new Date(balancesDate)) : date ? formatDate(new Date(date)) : '-'})
                        </TableCell>
                        <TableCell
                            align='right'
                            sx={{
                                m: 0,
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                                p: 1,
                                pt: 0
                            }}
                        >
                            (as of {balancesDate ? formatDate(new Date(balancesDate)) : date ? formatDate(new Date(date)) : '-'})
                        </TableCell>
                    </TableRow>
                    {loading || !accBalances ?
                        <TableRow
                            sx={{
                                bgcolor: 'common.white'
                            }}
                        >
                            <TableCell colSpan={5} align='center'>
                                <CircularProgress/>
                            </TableCell>
                        </TableRow>
                        :
                        accBalances.funds.map((fund: FundBalance) => <FundRow fund={fund} key={fund.id}/>)
                    }
                    <TableRow>
                        <TableCell
                            sx={{
                                bgcolor: 'primary.main',
                                borderBottomLeftRadius: 10,
                                p: 0
                            }}
                        />
                        <TableCell
                            align='center'
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            Total
                        </TableCell>
                        <TableCell
                            align='right'
                            colSpan={2}
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                                p: 1,
                            }}
                        >
                            {accBalances && accBalances.cash ? fCurrency(accBalances.cash) : '-'}
                        </TableCell>
                        <TableCell
                            align='right'
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 'bold',
                                borderBottomRightRadius: 10,
                                p: 1,
                                pr: 5
                            }}
                        >
                            {accBalances && accBalances.capital ? fCurrency(accBalances.capital) : '-'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {!loading &&
                <SideDrawer
                    title='Account Balances Update'
                    open={edit}
                    onClose={() => setEdit(false)}
                >
                    <EditAccountBalances
                        balances={accBalances}
                        onClose={() => setEdit(false)}
                    />
                </SideDrawer>
            }
        </>
    )
}

const FundRow = ({fund}: { fund: FundBalance}) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <TableRow
            >
                <TableCell
                    sx={{
                        bgcolor: 'common.white',
                        p: 0,
                        width: 24,
                        ...(open ? {borderBottom: 0} : {})
                    }}
                >
                    {fund.allocation &&
                        <IconButton onClick={() => setOpen(!open)}>
                            {open ? <CloseIcon/> : <ExpandIcon/>}
                        </IconButton>
                    }
                </TableCell>
                <TableCell
                    align='left'
                    sx={{
                        bgcolor: 'common.white',
                        p: 1,
                        ...(open ? {borderBottom: 0} : {})
                    }}
                >
                    {fund.label}
                </TableCell>
                <TableCell
                    align='left'
                    sx={{
                        bgcolor: 'common.white',
                        p: 1,
                        pl: 5,
                        ...(open ? {borderBottom: 0} : {})
                    }}
                >
                    {fund.type}
                </TableCell>
                <TableCell
                    align='right'
                    sx={{
                        bgcolor: 'common.white',
                        p: 1,
                        ...(open ? {borderBottom: 0} : {})
                    }}
                >
                    {fund.cash ? fCurrency(fund.cash) : '-'}
                </TableCell>
                <TableCell
                    align='right'
                    sx={{
                        bgcolor: 'common.white',
                        p: 1,
                        pr: 3,
                        ...(open ? {borderBottom: 0} : {})
                    }}
                >
                    {fund.capital ? fCurrency(fund.capital) : '-'}
                </TableCell>
            </TableRow>
            {fund.allocation && open &&
                <>
                    <TableRow
                        sx={{
                            borderTop: 0
                        }}
                    >
                        <TableCell
                            sx={{
                                bgcolor: 'common.white'
                            }}
                            colSpan={2}
                        />
                        <TableCell
                            align='right'
                            sx={{
                                bgcolor: 'common.white',
                                color: 'primary.main',
                                fontWeight: 'bold',
                                verticalAlign: 'top'
                            }}
                        >
                            Allocations:
                        </TableCell>
                        <TableCell
                            sx={{
                                bgcolor: 'common.white',
                                pr: '50%'
                            }}
                        >
                            <Table size='small'>
                                <TableBody>
                                    {fund.allocation.map(a => (
                                        <TableRow key={a.id}>
                                            <TableCell
                                                sx={{
                                                    border: 0
                                                }}
                                            >
                                                {a.fund}:
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                sx={{
                                                    border: 0
                                                }}
                                            >
                                                {fPercent(a.allocation)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                        <TableCell
                            sx={{
                                bgcolor: 'common.white'
                            }}
                        />
                    </TableRow>
                </>

            }
        </>
    )
}