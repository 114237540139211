import {ReactNode, useEffect} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import usePrevious from "../hooks/usePrevious";

interface AlertDialogProps {
    title: string,
    info?: string,
    children?: ReactNode,
    button?: string,
    size?: 'xs' | 'sm' | 'md',
    open: boolean,
    handleClose: () => void,
    handleConfirm?: () => void,
    loading?: boolean
}

export default function AlertDialog(props: AlertDialogProps) {
    const {title, info, children, button, size , open, handleClose, handleConfirm, loading} = props;

    const previousLoading = usePrevious(loading);

    useEffect(() => {
        if (!!previousLoading && !loading) {
            handleClose()
        }
    }, [previousLoading, loading, handleClose])


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={size || 'sm'}
            fullWidth={(!!size)}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
                {info &&
                    <DialogContentText>
                        {info}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                {handleConfirm &&
                    <>
                        {loading ?
                            <Box sx={{height: 50, width: 80}}>
                                <CircularProgress/>
                            </Box>
                            :
                            <Button variant='contained' onClick={handleConfirm}>{button ? button : 'Confirm'}</Button>
                        }
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}