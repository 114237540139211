import {CalculationLoanType, CapitalBudgetPeriodResults, MCPFund} from "../../../../types/capitalBudgetTypes";
import {DealInclusion, InvestmentType, LoanTags, PeriodType} from "../../../../types/capitalBudgetEnums";
import {Period} from "../../../../types/GeneralTypes";
import {
    checkDateSameOrBefore,
    checkInPeriod,
    daysBetweenDates,
    findlastDayOfMonth,
    findNextDayOfTheWeek
} from "../../../../utils/DateUtils";
import {addArrayValues, addValues} from "../../../../utils/mathUtil";
import {convertRatingToValue} from "../../../../utils/CapitalBudgetUtils";


// Handles each newly allocated Loan in a Period.
export function handleNewLoanForPeriod(newLoan: CalculationLoanType, results: CapitalBudgetPeriodResults, fund: MCPFund | null, budgetDate: number | Date, period: Period, periodType: PeriodType, periodMultiplier: number, ctcWeekBuffer: number, periodTypeMulti: number) {
    const loan: CalculationLoanType = {
        ...newLoan,
        tags: []
    }
    // NEW COMMITMENTS - loan starts within period
    if (checkInPeriod(loan.startDate, period)) {
        loan.tags.push(LoanTags.NEW_LOAN);
        results.newCommitments = addValues(results.newCommitments, -loan.value);

        if (loan.include === DealInclusion.BUDGET) {
            results.newLoansValue = addValues(results.newLoansValue, loan.value);
        } else if (loan.include === DealInclusion.WIP) {
            results.wip = addValues(results.wip, loan.value)
        }

        // First Drawdown of new loans
        loan.tags.push(LoanTags.FIRST_DRAW);
        results.firstDrawdown = addArrayValues([results.firstDrawdown, -loan.value, loan.undrawn]);
    }

    // CRE CTC STRAIGHT LINE DRAWDOWN - FOR:
    // - Loan Starts before a periods end date,
    // - Period Start Date is before loan end date
    // - That are not of type: 'Revolving', 'Capex', 'Equity', and
    // - Undrawn or partially drawn
    if (checkDateSameOrBefore(loan.startDate, period.lastDate) && checkDateSameOrBefore(period.startDate, loan.endDate)) {
        if (!['Revolving', 'Capex', 'Equity'].includes(loan.trancheType)) {
            // IDENTIFY LOAN PERIODS
            // FINDS FIRST PERIOD
            const firstPeriod = (periodType === 'week') ? findNextDayOfTheWeek(new Date(loan.startDate), 7) : findlastDayOfMonth(new Date(loan.startDate));
            const startingPeriod = (periodType === 'week') ?
                Math.floor((daysBetweenDates(budgetDate, firstPeriod) / 7) + 1)
                :
                Math.floor((daysBetweenDates(budgetDate, firstPeriod) / 365) * periodTypeMulti + 1);
            const lastPeriod = (periodType === 'week') ? findNextDayOfTheWeek(new Date(loan.endDate), 7) : findlastDayOfMonth(new Date(loan.endDate));
            const endingPeriod = (periodType === 'week') ?
                Math.floor((daysBetweenDates(budgetDate, lastPeriod) / 7) - ctcWeekBuffer)
                :
                Math.floor((daysBetweenDates(budgetDate, lastPeriod) / 365) * periodTypeMulti - 1);

            const numberOfPeriods = endingPeriod - startingPeriod;
            const periodCTC = loan.undrawn / numberOfPeriods;

            if (startingPeriod <= periodMultiplier && periodMultiplier < endingPeriod) {
                loan.creCtcDrawdown = periodCTC;
                loan.updatedUndrawn = addValues(loan.undrawn, -periodCTC * (periodMultiplier + 1 - startingPeriod));
                if (loan.investmentType === InvestmentType.REAL_ESTATE) {
                    loan.tags.push(LoanTags.CRE_CTC);
                    loan.tags.push(LoanTags.CRE_CTC_NEW);
                    results.periodCTCDrawdownNew = addValues(results.periodCTCDrawdownNew, -periodCTC);
                } else {
                    loan.tags.push(LoanTags.CORP_CTC_NEW);
                    results.periodCorpCTCDrawdownNew = addValues(results.periodCorpCTCDrawdownNew, -periodCTC);
                }
            }
        }
    }

    if (checkDateSameOrBefore(loan.startDate, period.lastDate)) {
        loan.tags.push(LoanTags.ACTIVE);

        results.ium = addValues(results.ium, loan.value);

        // WAV TENOR
        if (loan.tenor > 0) {
            // CALCULATES TENOR BASED ON PERIOD END DATE AND LOAN MATURITY
            const updatedTenor = (daysBetweenDates(new Date(period.lastDate), new Date(loan.endDate)) / 365);

            loan.updatedTenor = (updatedTenor > 0) ? updatedTenor : 0;

            if (!['Equity', 'Other'].includes(loan.ranking)) {
                if (updatedTenor > 0) {
                    const weightedTenor = updatedTenor * loan.value;
                    results.wavTenor = addValues(results.wavTenor, weightedTenor);
                }
            }
        }

        // WAV INTEREST RATE DURATION
        if (!!loan.interestType) {
            results.wavInterestTotal = addValues(results.wavInterestTotal, loan.value);
            const weightedInterestDuration = (loan.interestType === 'Fixed') ? (loan.updatedTenor * loan.value) : 0.1
            results.wavInterest = addValues(results.wavInterest, weightedInterestDuration);
        }

        // WAV RATING
        const ratingValue = convertRatingToValue(loan.rating)
        if (ratingValue) {
            results.wavRatingDenom = addValues(results.wavRatingDenom, loan.value);
            const weightRating = ratingValue * loan.value;
            results.wavRatingCalc = addValues(results.wavRatingCalc, weightRating);
        }
        // DO NOT INCLUDE EQUITY
        if (!['Equity', 'Other'].includes(loan.ranking)  || (['Equity', 'Other'].includes(loan.ranking) && loan.margin > 0)) {
            const drawnValue = (loan.updatedUndrawn) ? loan.value - loan.updatedUndrawn : loan.drawn;
            results.wavYieldTotal = addValues(results.wavYieldTotal, drawnValue);

            // Calculate Margin - Loan.margin + if applicable facility fee rate + if base rate floor and rate < base rate floor THEN base rate floor - base rate
            const margin = addArrayValues([loan.margin, ((loan.facilityFee === 'Facility Limit') ? loan.facilityFeeRate : 0), ((!loan.baseRateFloor && loan.baseRateFloor > loan.baseRate) ? loan.baseRateFloor - loan.baseRate : 0)]);
            results.wavMargin = addValues(results.wavMargin, margin * drawnValue);

            // IF FIXED RATE LOAN Yield does not include base rate
            results.wavYield = addValues(results.wavYield, drawnValue * addValues(margin, ((loan.pricingType !== 'FIXED' ? loan.baseRate : 0))))
        }
    }

    if (checkInPeriod(loan.endDate, period)) {
        loan.tags.push(LoanTags.REPAYMENT);
        results.expectedRepayments = addValues(results.expectedRepayments, loan.value);
        results.expectedRepaymentsCash = addValues(results.expectedRepaymentsCash, loan.drawn);
    }

    if (loan.tags.length > 0) results.loans.push(loan)
}