import {Fragment, useState} from "react";
// MUI
import {
    Box, Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material"
import {
    KeyboardArrowDown as DownIcon,
    KeyboardArrowRight as RightIcon,
    Close as CloseIcon,
    Edit as EditIcon
} from "@mui/icons-material";
// Components
import {HorizontalTableColumn, HorizontalTableProps, HorizontalTableRow} from ".";
import CustomToolbar from "./CustomToolbar";
import {determineStatusColor} from "../../utils/colorGenUtils";
import {CellType} from "../../types/InputTypes";


/**
 * Horizontal Table
 * Utilised for data flowing in a horizontally
 * Allows for selectable columns, rows and cells
 *
 *
 * The table takes in data in a key format:
 - Array of rows:
 * id: String used to target data for each column, can be left null if the row is a subHeader row,
 * label: String used as text for row header, and
 * subHeader: Boolean, explicitly determine row is informational and will not show data

 - Array of data:
 * label: String used as column header,
 * ...strings that match the row ids to connect data.
 *
 */
export default function HorizontalTable(props: HorizontalTableProps) {
    const {
        title,
        rows,
        data,
        loading,
        selectedRow,
        selectedCol,
        rowSelect,
        colSelect,

        selectableCell = false,
        editAction,
        removeAction,
        lastRowAction = false,
        create,
        doubleClickSelect = false,
        headerButton,
    } = props;

    const handleRowSelection = (row: HorizontalTableRow) => {
        if (rowSelect) {
            if (row.id === selectedRow?.id) rowSelect(null)
            else rowSelect(row);
        }
    }

    const handleColSelection = (col: HorizontalTableColumn) => {
        if (colSelect) {
            if (col.label === selectedCol?.label) colSelect(null);
            else colSelect(col);
        }
    }

    const handleCellSelect = (row: HorizontalTableRow, col: HorizontalTableColumn) => {
        if (selectableCell && colSelect && rowSelect) {
            if (col.label === selectedCol?.label && row.id === selectedRow?.id) {
                rowSelect(null);
                colSelect(null);
            } else {
                rowSelect(row);
                colSelect(col);
            }
        }
    }

    return (
        <Box
            sx={{
                height: '100%',
                padding: 1,
                bgcolor: 'secondary.main',
                borderRadius: '5px'
            }}
        >
            {((!!title) || (!!create)) &&
                <CustomToolbar
                    title={title}
                    create={create}
                />
            }
            <TableContainer
                component={Box}
                sx={{
                    width: '100%',
                    height: ((!!title) || (!!create)) ? 'calc(100% - 48px)' : '100%',
                    // flexGrow: 1,
                    overflow: 'none',
                    bgcolor: 'common.white'
                }}
            >
                <Table
                    size='small'
                    stickyHeader
                    sx={{
                        width: '100%',
                        height: ((!!title) || (!!create)) ? 'calc(100% - 48px)' : '100%',
                        overflowX: 'none',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {/* EMPTY STARTING CELL */}
                            <TableCell
                                sx={{
                                    minHeight: '5px',
                                    position: "sticky",
                                    top: 0,
                                    left: 0,
                                    bgcolor: 'secondary.main',
                                    borderColor: 'secondary.main',
                                    zIndex: 3,
                                }}
                                align={'center'}
                            >
                                {!loading && headerButton &&
                                    <Button variant='contained' disableElevation onClick={headerButton.action}>
                                        {headerButton.label}
                                    </Button>
                                }

                            </TableCell>
                            {/* Loop over Columns  */}
                            {data.map((data, d) => (
                                <TableCell
                                    key={data.label + d}
                                    align='center'
                                    onClick={() => handleColSelection(data)}
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        minWidth: 125,
                                        bgcolor: 'secondary.main',
                                        color: 'common.white',
                                        border: '1px',
                                        px: 0,
                                        zIndex: 1,
                                        pr: '10px',
                                        ...data.headSX,
                                        // Check if selected column
                                        ...(data.label !== selectedCol?.label ?
                                                {// NOT SELECTED
                                                    "&:hover": {
                                                        bgcolor: 'secondary.light',
                                                        cursor: "pointer"
                                                    }
                                                }
                                                :
                                                {// SELECTED
                                                    "&:hover": {
                                                        bgcolor: 'secondary.main',
                                                        cursor: "pointer"
                                                    },
                                                    bgcolor: 'secondary.light',
                                                }
                                        )
                                    }}
                                >
                                    {(data.tooltip) ?
                                        <Tooltip title={data.tooltip} placement='bottom'>
                                            <div>
                                                {data.label} {(data.labelTwo) ? <><br/> {data.labelTwo}</> : ''}
                                            </div>
                                        </Tooltip>
                                        :
                                        <>
                                            {data.label}
                                        </>
                                    }
                                </TableCell>
                            ))
                            }
                        </TableRow>
                    </TableHead>
                    {loading ?
                        // LOADING
                        <TableBody sx={{height: '100%'}}>
                            <TableRow sx={{height: '100%'}}>
                                <TableCell align='center' colSpan={data.length + 1}>
                                    <Box sx={{h: 50}}>
                                        <CircularProgress sx={{maxHeight: '90%'}}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody
                            sx={{
                                overflowY: 'scroll'
                            }}
                        >
                            {rows.map((row, i) => (
                                <Fragment key={row.label + i}>
                                    {(row.id === null) && row.subHeading ?
                                        <TableRow key={row.label + i}>
                                            <>
                                                <TableCell
                                                    sx={{
                                                        position: 'sticky',
                                                        left: 0,
                                                        color: 'common.white',
                                                        bgcolor: 'primary.main',
                                                        // minWidth: '200px',
                                                        borderTop: 1,
                                                        borderBottom: 1,
                                                        borderColor: 'primary.main',
                                                        zIndex: 2,
                                                        ...row.headSX,
                                                    }}
                                                >
                                                    {row.label}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        bgcolor: 'primary.main',
                                                        borderTop: 1,
                                                        borderBottom: 1,
                                                        borderColor: 'primary.main',
                                                        ...row.sx,
                                                    }}
                                                    colSpan={rows.length - 1}
                                                />
                                            </>
                                        </TableRow>
                                        :
                                        <HTableRow
                                            row={row}
                                            data={data}
                                            handleRowSelection={handleRowSelection}
                                            handleCellSelect={handleCellSelect}
                                            editAction={editAction}
                                            removeAction={removeAction}
                                            selectedRow={selectedRow}
                                            selectedCol={selectedCol}
                                            selectableCell={selectableCell}
                                            lastRow={(i === rows.length - 1)}
                                            lastRowAction={lastRowAction}
                                            doubleClickSelect={doubleClickSelect}
                                        />
                                    }
                                </Fragment>
                            ))}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
        </Box>
    )
}

interface HTableRowProps {
    row: HorizontalTableRow,
    data: Array<HorizontalTableColumn>,

    handleRowSelection: (row: HorizontalTableRow) => void,
    handleCellSelect: (row: HorizontalTableRow, col: HorizontalTableColumn) => void,
    editAction?: (row: HorizontalTableRow) => void,
    removeAction?: (row: HorizontalTableRow) => void,
    selectedRow?: HorizontalTableRow | null,
    selectedCol?: HorizontalTableColumn | null,

    selectableCell?: boolean,
    lastRow: boolean,
    lastRowAction?: boolean,
    doubleClickSelect: boolean
}

// Handles Each Single Row
function HTableRow(props: HTableRowProps) {
    const {
        row,
        data,
        handleRowSelection,
        handleCellSelect,
        editAction,
        removeAction,
        selectedRow,
        selectedCol,
        selectableCell,
        lastRow,
        lastRowAction,
        doubleClickSelect = false
    } = props;

    const [open, setOpen] = useState<boolean>(false);

    let cbColor: string | undefined = determineStatusColor(row.status)

    return (
        <>
            <TableRow>
                {/* Row Heading */}
                <TableCell
                    onClick={() => handleRowSelection(row)}
                    sx={{
                        position: 'sticky',
                        left: 0,
                        color: 'common.white',
                        bgcolor: 'secondary.main',
                        // minWidth: '200px',
                        padding: '5px',
                        ...(row.id !== selectedRow?.id ?
                                { //ROW UNSELECTED
                                    "&:hover": {
                                        ...(cbColor) ? {bgcolor: `${cbColor}.light`} : {bgcolor: 'secondary.light'},
                                        cursor: "pointer"
                                    },
                                }
                                :
                                { //ROW SELECTED
                                    "&:hover": {
                                        bgcolor: 'secondary.main',
                                        cursor: "pointer"
                                    },
                                    ...(cbColor) ? {bgcolor: `${cbColor}.light`} : {bgcolor: 'secondary.light'}
                                }
                        ),
                        zIndex: 2,
                        ...row.headSX,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignContent: 'center',
                            }}
                        >
                            {(row.subRows) &&
                                // Drop down expand row
                                <Box>
                                    {open ?
                                        <Tooltip title='Close'>
                                            <IconButton size='small' sx={{p: 0, color: 'common.white'}}
                                                        onClick={() => setOpen(false)}>
                                                <DownIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title='Expand'>
                                            <IconButton size='small' sx={{p: 0, color: 'common.white'}}
                                                        onClick={() => setOpen(true)}>
                                                <RightIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    }

                                </Box>
                            }
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}>
                                {row.label}
                            </Box>
                        </Box>
                        {/* Header column additional properties*/}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                            {editAction && (!lastRow || lastRowAction) &&
                                <div>
                                    <Tooltip title='Edit Row'>
                                        <IconButton size='small' sx={{p: 0, color: 'common.white'}}
                                                    onClick={() => editAction(row)}>
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                            {removeAction && (!lastRow || lastRowAction) &&
                                <div>
                                    <Tooltip title='Remove Row'>
                                        <IconButton size='small' sx={{p: 0, color: 'common.white'}}
                                                    onClick={() => removeAction(row)}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                        </Box>
                    </Box>
                </TableCell>

                {(!row.id) ?
                    <></>
                    :
                    // Cell Data
                    data.map((data, j) => (
                            <TableCell
                                key={data.label + j}
                                align='right'
                                onClick={() => {
                                    if (!doubleClickSelect) {
                                        handleCellSelect(row, data)
                                    }
                                }}
                                onDoubleClick={() => {
                                    if (doubleClickSelect) {
                                        handleCellSelect(row, data)
                                    }
                                }}
                                sx={{
                                    ...data.sx,
                                    ...row.sx,
                                    borderColor: 'primary.main',
                                    p: '0',
                                    pr: '10px',
                                    ...(cbColor) ? {bgcolor: `${cbColor}.lighter`} : {},

                                    // IF CELL IS A NUMBER TYPE AND NEGATIVE
                                    ...(row.type === CellType.CURRENCY && row.id && (data[row.id] < 0) ?
                                            {
                                                color: 'red'
                                            } : {}
                                    ),
                                    // IF CELL IS SELECTABLE
                                    ...(selectableCell ?
                                            {
                                                "&:hover": {
                                                    cursor: "pointer"
                                                }
                                            } : {}
                                    ),
                                    // IF COLUMN AND ROW IS SELECTED
                                    ...(((data.label === selectedCol?.label) && (row.id === selectedRow?.id)) ?
                                            {
                                                bgcolor: 'secondary.main',
                                                color: 'common.white'
                                            }
                                            :
                                            // IF ONLY COLUMN OR ROW IS SELECTED
                                            ((data.label === selectedCol?.label) || (row.id === selectedRow?.id)) ?
                                                {
                                                    bgcolor: 'secondary.light',
                                                    color: 'common.white'
                                                }
                                                :
                                                {}
                                    ),
                                }}
                            >
                                {(row.id && data[row.id] !== null) ?
                                    // USES FORMATTER IF NECESSARY
                                    ((row.formatter) ?
                                            row.formatter(data[row.id])
                                            :
                                            <>
                                                {data[row.id] as string}
                                            </>
                                    )
                                    :
                                    'N/A'}
                            </TableCell>
                        )
                    )
                }
            </TableRow>
            {open &&
                <>
                    {row.subRows && row.subRows?.map((subRow, i) => (
                        <HTableRow
                            row={{
                                headSX: {
                                    bgcolor: 'primary.light'
                                },
                                sx: {
                                    bgcolor: 'grey.400'
                                },
                                ...subRow,
                            }}
                            data={data}
                            handleRowSelection={handleRowSelection}
                            handleCellSelect={handleCellSelect}
                            removeAction={removeAction}
                            selectedRow={selectedRow}
                            selectedCol={selectedCol}
                            selectableCell={selectableCell}
                            lastRow={lastRow}
                            lastRowAction={lastRowAction}
                            doubleClickSelect={doubleClickSelect}
                            key={subRow.label + i}
                        />
                    ))}
                </>
            }
        </>
    )
}