import {createAsyncThunk} from "@reduxjs/toolkit";
import {addNotification} from "../notifications/notificationSlice";
import {apiGetRequest, multipleAsyncGetRequest} from "../apiUtils";
import {AFRAxcessLoanCompare} from "../../types/AssetFundRegisterTypes";
import {Assumption} from "../../types/valuationModelTypes";
import { formatDate, getLastBusinessDayPreviousMonth} from "../../utils/DateUtils";


// Retrieve Initial Asset Register Data
export const retrieveInitialAFRData = createAsyncThunk('afr/initialData', async (_, thunkAPI) => {
    try {
        const _axcessDataSet = await apiGetRequest('external-data/axcess')


        // Convert the axcess sets to a list of dates ordered desc removing duplicates
        const dataSets: Array<number> = _axcessDataSet.reduce((sets: Array<number>, currentSet: {
            date: string
        },) => {
            const setDate = new Date(currentSet.date).getTime();

            const day = new Date(setDate).getDay()

            if (day !== 6 && day !== 0) {
                if (!sets.some(s => s === setDate)) {
                    sets.push(setDate);
                }
            }

            return sets
        }, []).sort((a: number, b: number) => b - a)

        if (dataSets.length < 1) {
            const message = 'No Fund Asset Register Data available.'
            thunkAPI.dispatch(addNotification(message, 'error'));
            return thunkAPI.rejectWithValue(message);
        }

        const date = dataSets[0]
        const previousDate = dataSets.length === 1 ? dataSets[0] : dataSets[1];

        const {portfolio, funds, fx} = await retrieveAFRData(previousDate, date);

        return {
            date,
            previousDate,
            dataSets,
            portfolio,
            funds,
            fx
        }

    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred retrieving Data for Asset Fund Register.';
        }
        console.log(error)
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Retrieves Dates for changes dates
export const changeAFRDates = createAsyncThunk('afr/dateChange', async (dates: {previousDate: number, date: number}) => {
    const {previousDate, date} = dates;

    const {portfolio, funds, fx} = await retrieveAFRData(previousDate, date);

    return {
        date,
        previousDate,
        portfolio,
        funds,
        fx
    }
})

// Retrieves AFR data for set dates.
async function retrieveAFRData(previousDate: number, date: number) {
    try {
        const fDate = formatDate(date, 'yyyy-MM-dd');
        const fxDate = formatDate(getLastBusinessDayPreviousMonth(fDate), 'yyyy-MM-dd');
        const fPreviousDate = formatDate(previousDate, 'yyyy-MM-dd');
        const fxPreviousDate = formatDate(getLastBusinessDayPreviousMonth(fPreviousDate), 'yyyy-MM-dd');


        // Retrieve comparison portfolio and assumptions
        let {axcessData, assumptions, previousFx, currentFx} = await multipleAsyncGetRequest({
            axcessData: `external-data/axcess/compare?previousDate=${fPreviousDate}&date=${fDate}`,
            assumptions: `valuation-model/assumptions?previousDate=${fPreviousDate}&valuationDate=${fDate}`,
            previousFx: `external-data/fx?date=${fxPreviousDate}`,
            currentFx: `external-data/fx?date=${fxDate}`,

        })

        const portfolio = axcessData.portfolio as Array<AFRAxcessLoanCompare>;

        let funds: Array<string> = [];

        portfolio.forEach(p => {
            // Attach Assumptions
            const assumption = assumptions.find((a: Assumption) => a.trancheId === p.tranche_id);
            // If assumption found
            if (assumption) {
                p.assumption = assumption.type;
            }

            const current = p.funds.map(f => f.fund);
            const before = p.funds_before.map(f => f.fund);

            p.allFunds = [...new Set([...current, ...before])];

            funds = [...new Set([...p.allFunds, ...funds])]
        })

        return {
            portfolio,
            fx: {
                previous: previousFx.rates,
                current: currentFx.rates
            },
            funds: funds.sort()
        }
    } catch (e) {
        throw e;
    }
}