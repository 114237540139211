import _ from "lodash";
import {RootState} from "../../store";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {createSelector} from "@reduxjs/toolkit";

// Retrieve All Other Transactions and filter if fund is selected
export const retrieveOtherTransactions = createSelector(
    (state: RootState) => _.cloneDeep(state.capitalBudget.scenarioData?.otherTransactions || []),
    (state: RootState) => state.capitalBudget.misc.fund,
    (_state: RootState, raw?: boolean) => raw,
    (otherTransactions, fund, raw = false) => {
        otherTransactions.forEach(ot => {
            // IF RAW THEN CHECK IF EDITED AND RETRIEVE ORIGINAL
            if (raw && ot.status === SaveStatus.EDITED) {
                ot = ot.previous || ot;
            }
            ot.date = new Date(ot.date).getTime();
        })

        otherTransactions = otherTransactions.filter(ot => ((!raw && ot.status !== SaveStatus.REMOVED) || (raw && ot.status !== SaveStatus.NEW)));

        if (fund) {
            otherTransactions = otherTransactions.filter(ot => ot.fund === fund.label);
        }

        return otherTransactions;
    }
)