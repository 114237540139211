import {useEffect, useMemo, useState} from "react";
import {formatDate} from "../../utils/DateUtils";
// @mui
import {Container, Typography, Grid, FormGroup, FormControlLabel, Checkbox, Divider} from '@mui/material';
// components
import {DataTable, Page, SideDrawer} from '../../components';
import AFRNavBar from "./AFRNavBar";
// Redux Store
import {useAppSelector} from "../../store/store";
import {afrPortfolioSelector, AFRPortfolioTableColumns, highlightingDataGridSx} from "../../store/afr/afrSelector";
import {AssumptionType} from "../../types/valuationModelEnums";
import TrancheDetails from "../../sections/dashboard/afr/TrancheDetails";
import {addValues} from "../../utils/mathUtil";
import {fCurrency} from "../../utils/formatNumber";

// ----------------------------------------------------------------------

const initialTotals = {
    drawn: 0,
    undrawn: 0,
    commitment: 0,
    audUndrawn: 0,
    audDrawn: 0,
    audCommitment: 0,
}

export default function Summary() {
    const portfolio = useAppSelector(state => afrPortfolioSelector(state));
    const loading = useAppSelector(state => state.afr.loading)
    const date = useAppSelector(state => state.afr.date)
    const previousDate = useAppSelector(state => state.afr.previousDate)

    const [selectedRow, selectRow] = useState<number | null>(null);
    const [selectHighlighted, setHighlighted] = useState(false);
    const [selectMatured, setMatured] = useState(false);
    const [totals, setTotal] = useState(initialTotals);

    const filtered = useMemo(() => {
        return portfolio.filter(p => {
            // If matured filter is set remove any past maturity
            if (selectMatured && p.assumption === AssumptionType.PAST_MATURITY) return false;
            // If highlight filter remove those that have no assumptions || remove || new || changes;
            return !(selectHighlighted && (!p.assumption && !p.removed && !p.new && !Object.keys(p.change).length));
        })
    }, [portfolio, selectMatured, selectHighlighted])

    // Calculate totals
    useEffect(() => {
        const updatedTotals = {
            drawn: 0,
            undrawn: 0,
            commitment: 0,
            audUndrawn: 0,
            audDrawn: 0,
            audCommitment: 0,
        };

        filtered.forEach(t => {
            updatedTotals.drawn = addValues(updatedTotals.drawn, t.drawn);
            updatedTotals.undrawn = addValues(updatedTotals.undrawn, t.undrawn);
            updatedTotals.commitment = addValues(updatedTotals.commitment, t.commitment);
            updatedTotals.audUndrawn = addValues(updatedTotals.audUndrawn, t.audUndrawn);
            updatedTotals.audDrawn = addValues(updatedTotals.audDrawn, t.audDrawn);
            updatedTotals.audCommitment = addValues(updatedTotals.audCommitment, t.audCommitment);
        })

        setTotal(updatedTotals)
    }, [filtered])

    return (
        <Page title="Dashboard">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                        <Grid item md={5}>
                            <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                                Asset Fund
                                Register {!loading && date && previousDate && ` - ${formatDate(previousDate, 'dd-MMM-yyyy')} vs. ${formatDate(date, 'dd-MMM-yyyy')}`}
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <AFRNavBar/>
                        </Grid>
                    </Grid>
                    <Grid item container sx={{width: '100%', flex: 1}} direction='column'>
                        <Grid item container padding={2} sx={{textAlign: 'center'}}>
                            <Grid item sm={4}>
                                <Typography variant='h6'>Drawn</Typography>
                                <Typography variant='h4'>{fCurrency(totals.drawn)}</Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography variant='h6'>Undrawn</Typography>
                                <Typography variant='h4'>{fCurrency(totals.undrawn)}</Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography variant='h6'>Commitment</Typography>
                                <Typography variant='h4'>{fCurrency(totals.commitment)}</Typography>
                            </Grid>
                        </Grid>
                        {totals.commitment !== totals.audCommitment &&
                            <>
                                <Divider/>
                                <Grid item container padding={2} sx={{textAlign: 'center'}}>
                                    <Grid item sm={4}>
                                        <Typography variant='h6'>Drawn (AUD)</Typography>
                                        <Typography variant='h4'>{fCurrency(totals.audDrawn)}</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography variant='h6'>Undrawn (AUD)</Typography>
                                        <Typography variant='h4'>{fCurrency(totals.audUndrawn)}</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography variant='h6'>Commitment (AUD)</Typography>
                                        <Typography variant='h4'>{fCurrency(totals.audCommitment)}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid item sx={{flexGrow: 1}}>

                            <DataTable
                                title={'Tranches'}
                                columns={AFRPortfolioTableColumns}
                                data={filtered}
                                loading={loading}

                                selectedRow={selectedRow}
                                selectRow={(row) => selectRow(row as number)}

                                sort={{field: 'tranche_id', sort: 'asc'}}
                                search
                                showColumns
                                showExport={false}
                                showFilter

                                additionalDataGridSx={highlightingDataGridSx}
                                customFilterComponent={
                                    <FormGroup row={true}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectHighlighted}
                                                    sx={{
                                                        color: "common.white",
                                                        '&.Mui-checked': {
                                                            color: "common.white"
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Show Only Highlighted"
                                            onChange={() => setHighlighted(!selectHighlighted)}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={false}
                                                    sx={{
                                                        color: "common.white",
                                                        '&.Mui-checked': {
                                                            color: "common.white"
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Hide Past Maturity"
                                            onChange={() => setMatured(!selectMatured)}
                                        />
                                    </FormGroup>
                                }
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Container>
            {(selectedRow) &&
                <SideDrawer
                    title='Tranche Details'
                    open={!!selectedRow}
                    onClose={() => selectRow(null)}
                    size='x-large'
                >
                    <TrancheDetails trancheId={selectedRow}/>
                </SideDrawer>
            }
        </Page>
    );
}
