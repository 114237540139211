import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import accountBalancesReducer from "./accountBalancesReducer";
import capitalReducers from './capitalReducers';
import otherTransactionReducers from "./otherTransactionReducers";
import newDealReducers from "./newDealReducers";
import portfolioReducers from "./portfolioReducers";
import reallocationReducers from "./reallocationReducers";
import selldownRepaymentReducer from "./selldownRepaymentReducer";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {MCPFund} from "../../../types/capitalBudgetTypes";

// Groups together separated capital Budget reducers
const reducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    setFund: (state, action: PayloadAction<null | MCPFund>) => {
        if (state.scenarioData) {
            if (!action.payload) state.misc.fund = null;
            else {
                const index = state.scenarioData.funds.findIndex(fund => action.payload?.id === fund.id)
                if (index !== -1) {
                    state.misc.fund = action.payload;
                }
            }
        }
    },
    setWeeksAndMonths: (state, action: PayloadAction<{weeks: number, months: number }>) => {
        state.misc.weeks = action.payload.weeks;
        state.misc.months = action.payload.months;
        // state.misc.periods = generatePeriods(state.misc.weeks, state.misc.months)
    },
    setWeeks: (state, action: PayloadAction<number>) => {
        state.misc.weeks = action.payload;
        // state.misc.periods = generatePeriods(state.misc.weeks, state.misc.months)
    },
    setMonths: (state, action: PayloadAction<number>) => {
        state.misc.months = action.payload;
        // state.misc.periods = generatePeriods(state.misc.weeks, state.misc.months)
    },
    ...accountBalancesReducer,
    ...capitalReducers,
    ...otherTransactionReducers,
    ...newDealReducers,
    ...portfolioReducers,
    ...reallocationReducers,
    ...selldownRepaymentReducer
}
export default reducers;