import {RootState} from "../../store";
// Components
import {EditableTableRow} from "../../../components";
import {MCPFund, NewDeal, WIPLoan} from "../../../types/capitalBudgetTypes";
import {DealInclusion, FundType, SaveStatus} from "../../../types/capitalBudgetEnums";
import {addMonthsToDate} from "../../../utils/DateUtils";
import {
    extractFacilityFee,
    extractnCinoBase,
    extractnCinoSpread,
    groupDebtSeniority
} from "../../../utils/CapitalBudgetUtils";
import {createSelector} from "@reduxjs/toolkit";
import {addValues} from "../../../utils/mathUtil";

// Retrieves all new Deals
export const retrieveNewDealsData = createSelector(
    (state: RootState) => state.capitalBudget.scenarioData?.newDeals,
    (state: RootState) => state.capitalBudget.thirdPartyData?.ncino.data,
    (deals, wip) => {
        const transactions: Array<EditableTableRow> = [];

        if (!deals || !wip) return transactions;

        deals.forEach((deal) => {
            const loanRef = wip.find(l => l.id === deal.wipLoanId);

            if (loanRef) {
                let allocation: { [x: string]: number } = {};

                Object.keys(deal.allocation).forEach(a => {
                    allocation[a] = deal.allocation[a];
                })

                const expectedClose = (deal.amendedCloseDate) ? deal.amendedCloseDate : (deal.expectedClose) ? deal.expectedClose : null
                let expectedCommitment = 0;
                // Extrapolate adjustedCommitment
                if (deal.commitment) {
                    expectedCommitment = addValues(expectedCommitment, deal.commitment);
                }
                if (deal.commitmentOffset) {
                    expectedCommitment = addValues(expectedCommitment, deal.commitmentOffset);
                }

                let lineFee: null | number = null;
                let establishmentFee: null | number = null;

                loanRef.llc_bi_fees.forEach(f => {
                    if (f.llc_bi_fee_type === 'Line Fee') {
                        lineFee = f.llc_bi_percentage/100;
                    }
                    if (f.llc_bi_fee_type === 'Establishment Fee') {
                        establishmentFee = f.llc_bi_percentage/100;
                    }
                })

                transactions.push({
                    ...deal,
                    ...allocation,
                    adjustedClose: expectedClose ? new Date(expectedClose).getTime() : null,
                    adjustedCommitment: expectedCommitment,
                    owner: loanRef.llc_bi_product_package.primary_origination_director.name,
                    industry: loanRef.llc_bi_product_package?.industry,
                    ranking: groupDebtSeniority(loanRef.ranking_debt_seniority),
                    rating: loanRef.metrics_rating,
                    tenor: (loanRef.llc_bi_term_months || 0) / 12,
                    upfront: establishmentFee,
                    margin: extractnCinoSpread(loanRef),
                    lineFee: lineFee,
                    drawnClose: (deal.commitment) ? loanRef.expected_drawdown_at_close/deal.commitment : 0,
                    currency: loanRef.currency_iso_code
                })
            }

        })

        return transactions.filter(transaction => transaction.status !== SaveStatus.REMOVED);
    }
)

// Retrieve WIPLoan by ID
export const findWIPLoan = (state: RootState, row: EditableTableRow | null): null | WIPLoan => {
    if (state.capitalBudget.thirdPartyData && row) {
        const loan = state.capitalBudget.thirdPartyData.ncino.data.find((l: WIPLoan) => l.id === row.wipLoanId);
        return loan || null;
    } else {
        return null;
    }
}

export const retrieveWIPLoansForCalc = (state: RootState, fund: MCPFund | null, raw: boolean = false) => {
    const deals = state.capitalBudget.scenarioData?.newDeals;
    const wipLoans = state.capitalBudget.thirdPartyData?.ncino.data;

    const transactions: Array<any> = [];
    if (!deals || !wipLoans) return transactions;

    deals.forEach((d) => {
        // IF RAW THEN CHECK IF EDITED AND RETRIEVE ORIGINAL
        let deal: NewDeal;
        if (raw && d.status === SaveStatus.EDITED) {
            deal = d.previous || d;
        } else {
            deal = d;
        }

        if ((fund && (deal.allocation[fund.label] !== 0 || fund.type === FundType.FEEDER)) || !fund) {
            if (deal.include !== DealInclusion.UNASSIGNED) {
                const wip = wipLoans.find(wipLoan => wipLoan.id === deal.wipLoanId)
                if (wip) {
                    const tenor = (wip.llc_bi_term_months || 0) / 12;
                    const closeDate = (deal.amendedCloseDate) ? deal.amendedCloseDate : wip.llc_bi_close_date;
                    // const undrawn = (wip.expected_drawdown_at_close !== null && wip.llc_bi_amount) ? wip.llc_bi_amount - wip.expected_drawdown_at_close : 0;
                    const drawnPercentage = (wip.llc_bi_amount) ? (wip.expected_drawdown_at_close / wip.llc_bi_amount) : 1;
                    const facilityFee = extractFacilityFee(wip);

                    const transaction = {
                        id: deal.id,
                        asset: wip.ncino_id,
                        name: wip.name,
                        baseRate: extractnCinoBase(wip),
                        margin: extractnCinoSpread(wip),
                        facilityFee: facilityFee ? "Facility Limit" : null,
                        facilityFeeRate: facilityFee,
                        tenor: tenor,
                        rating: wip.metrics_rating,
                        ranking: groupDebtSeniority(wip.ranking_debt_seniority),
                        investmentType: wip.llc_bi_cra_type_code,
                        interestType: (wip.llc_bi_pricing_streams[0]?.llc_bi_pricing_rate_components[0]?.llc_bi_interest_rate_type || null),
                        industry: wip.llc_bi_product_package?.industry,
                        industryGroup: wip.llc_bi_product_package?.industry,
                        industrySegment: wip.llc_bi_product_package?.industry,
                        trancheType: wip.llc_bi_product,
                        pricingType: (wip.llc_bi_pricing_streams[0]?.llc_bi_pricing_rate_components[0]?.llc_bi_interest_rate_type || null),
                        domicile: (wip.llc_bi_product_package?.state_of_exposure === 'Offshore') ? wip.llc_bi_product_package?.offshore_location : 'Australia',
                        baseCurrency: wip.currency_iso_code,
                        multiCurrency: wip.multi_currency_option,
                        startDate: (deal.amendedCloseDate) ? deal.amendedCloseDate : wip.llc_bi_close_date,
                        endDate: addMonthsToDate(closeDate, tenor * 12),
                        include: deal.include
                    }

                    if (fund) {
                        if (deal.allocation[fund.label] !== 0) {
                            const value = deal.allocation[fund.label]
                            transactions.push({
                                ...transaction,
                                fund: fund.label,
                                value,
                                drawn: value * drawnPercentage,
                                drawnPercentage,
                                undrawn: value * (1 - drawnPercentage)
                            })
                        } else if (fund.type === FundType.FEEDER && fund.holdings && fund.holdings.length > 0) {
                            fund.holdings.forEach(h => {
                                if (deal.allocation[h.fund] !== 0) {
                                    const value = deal.allocation[h.fund] * h.percentage;
                                    transactions.push({
                                        ...transaction,
                                        fund: fund.label,
                                        value,
                                        drawn: value * drawnPercentage,
                                        drawnPercentage,
                                        undrawn: value * (1 - drawnPercentage)
                                    })
                                }
                            })
                        }
                    } else {
                        Object.keys(deal.allocation).forEach(all => {
                            const value = deal.allocation[all];
                            if (deal.allocation[all] !== 0) {
                                transactions.push({
                                    ...transaction,
                                    fund: all.toUpperCase(),
                                    value,
                                    drawnPercentage,
                                    drawn: value * drawnPercentage,
                                    undrawn: value * (1 - drawnPercentage)
                                })
                            }
                        })
                    }
                }
            }
        }
    })

    return transactions;

}

// Retrieve Portfolio Changes and filters for fund and fund commitment value if fund parameter provided
export const retrievePortfolioChanges = (state: RootState, fund: MCPFund | null) => {
    if (!state.capitalBudget.scenarioData?.portfolioChanges) return []

    // Retrieve Portfolio changes
    let changes = state.capitalBudget.scenarioData.portfolioChanges;

    // If not specific to a Fund return all changes
    if (!fund) return changes;
}