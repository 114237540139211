import React, {ReactElement} from "react";
import {Link as RouterLink} from "react-router-dom";
// Components
import {Page} from "../../components";
// @mui
import {ArrowForward as ArrowIcon} from "@mui/icons-material";
import {Button, Container, Grid, Stack, Typography} from "@mui/material";

export interface ReportType {
    category: string,
    title: string,
    reportId: string
}

// Display Power BI Report
export function Report({category, title, reportId}: ReportType) {
    return (
        <Page title={`Metrics - ${category} - ${title}`}>
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1}}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                        {title}
                    </Typography>
                </Grid>
                <Grid container sx={{height: "90%", width: '100%'}}>
                    <Grid item xs={12} md={12} lg={12} sx={{bgColor: 'red', pb: 1}}>
                        <iframe title="EY - Portfolio Team" width="100%" height="100%"
                                src={`https://app.powerbi.com/reportEmbed?reportId=${reportId}&appId=${process.env.REACT_APP_BI_TENANT_ID}&autoAuth=true&ctid=${process.env.REACT_APP_BI_TENANT_ID}`}
                                frameBorder="0" allowFullScreen={true}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export interface ReportSelectionType {
    title: string,
    icon: ReactElement,
    routeTo: string
}

// Display for individual Selectable Reports
export const ReportSelection = ({title, icon, routeTo}: ReportSelectionType) => {
    return (
        <Button
            size='large'
            variant="outlined"
            sx={{
                height: 50,
                display: 'flex',
                justifyContent: 'space-between',
            }}
            to={routeTo}
            component={RouterLink}
        >
            <Stack direction="row"
                   sx={{
                       display: 'flex',
                       justifyContent: 'space-between',
                       alignContents: 'center',
                       alignItems: 'center'
                   }}
                   spacing={2}
            >
                {React.cloneElement(icon, {sx: {fontSize: 30}})}
                <Typography variant='h6'>
                    {title}
                </Typography>
            </Stack>
            <ArrowIcon sx={{fontSize: 20}}/>
        </Button>
    )
}