// MUI
import { Grid, Container, Typography } from '@mui/material';
import { useEffect } from 'react';
// components
import { Page } from '../components';
// sections
import UserList from '../sections/auth/UserList';
// Store
import { useAppDispatch } from '../store/store';
import { getUsers } from '../store/user/userFunctions';

// Users Page
export default function Users() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUsers())
    }, [dispatch])

    return (
        <Page title="Users">
            <Container maxWidth={false} sx={{ maxWidth: '100%', height: 1 }}>
                <Grid container spacing={2} direction='column' sx={{ height: 1 }}>
                    <Typography variant="h4" sx={{ mb: 5, color: 'primary.main' }}>
                        Users
                    </Typography>
                    <Grid item container sx={{ width: '100%', flex: 1 }}>
                        <UserList />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}