// Components
import {Page} from "../../components";
import {ReportSelection} from "./BIComponents";
// @mui
import {Container, Stack, Typography} from "@mui/material";
import {
    Calculate as CalculateIcon,
    LibraryBooksTwoTone as BookIcon,
    List as ListIcon,
    MoneyOff as MoneyOffIcon,
    People as PeopleIcon,
} from "@mui/icons-material";

export default function Reports() {

    return (
        <Page title="MiPad - Power BI Reports">
            <Container maxWidth={false} sx={{maxWidth: '100%'}}>
                <Typography variant="h3" paragraph align="left">
                    Reports
                </Typography>
                <Stack direction="column" spacing={2}>
                    <ReportSelection
                        title="Portfolio"
                        icon={<BookIcon/>}
                        routeTo="/dashboard/power-bi/portfolio"
                    />
                    <ReportSelection
                        title="Finance"
                        icon={<CalculateIcon/>}
                        routeTo="/dashboard/power-bi/financial"
                    />
                    <ReportSelection
                        title="Investor"
                        icon={<PeopleIcon/>}
                        routeTo="/dashboard/power-bi/investor"
                    />
                    <ReportSelection
                        title="Loan Admin"
                        icon={<MoneyOffIcon/>}
                        routeTo="/dashboard/power-bi/loan-admin"
                    />
                    <ReportSelection
                        title="IT"
                        icon={<ListIcon />}
                        routeTo="/dashboard/power-bi/it"
                    />
                    <ReportSelection
                        title="Auxiliary"
                        icon={<ListIcon />}
                        routeTo="/dashboard/power-bi/auxiliary"
                    />
                </Stack>
            </Container>
        </Page>
    )
}