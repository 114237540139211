import {ChangeEvent, MouseEvent, useEffect, useMemo, memo, useState} from 'react'
// Local Import
import {EditableTableProps, EditableTableRow} from ".";
import {Order} from '../../types/GeneralTypes';
import {escapeRegExp, getComparator} from '../../utils/generalUtils';
import {addValues} from '../../utils/mathUtil';
// MUI
import {
    Box,
    CircularProgress,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material"
// Components
import CustomToolbar from "./CustomToolbar";
import EditableTableHead from './EditableTableHead';

import _ from 'lodash';
import EditableTableBody from "./EditableTableBody";

/**
 * Editable Table
 * Utilised for data flowing vertically and allowing Formik style editing.
 * Allows for selectable rows
 *
 *
 * The table takes in data in a key format:
 - Array of Columns:
 * id: The string used to connect the data to each column
 * label: Label used at the top of the header,

 - Array of data:
 * id: this is the unique identifier for each row.
 * label: String used as column header,
 * ...strings that match the row ids to connect data.
 *
 */
function EditableTable(props: EditableTableProps) {
    const {
        title,
        columns,
        data,
        loading,

        defaultSort = 'id',
        defaultOrder = 'asc',
        altSort,

        selectedRow,
        rowSelect = () => '',

        handleRemove,

        customFilter,

        totalRow = false,
        search = false,

        handleRowEdit,
        validationSchema,

        formRowValueFunction,
        create,

        expand
    } = props;

    const [rows, setRows] = useState<Array<EditableTableRow>>([])
    // Handles State of search string in Custom Table Search Bar
    const [searchText, setSearchText] = useState('');
    // Handles Sort Parameters
    const [order, setOrder] = useState<Order>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultSort);
    // Select for edit
    const [editRow, setEditRow] = useState<string | number | null>(null);
    // Totals 
    const [totals, setTotals] = useState<{ [x: string]: number }>({})
    // EXPANDED TABLE
    const [expanded, setExpand] = useState<boolean>(false);

    // Copies data values to rows state as a mutable source
    useEffect(() => {
        let tempRows = _.cloneDeep(data);

        setRows(tempRows);
    }, [setRows, data, columns])

    // Calculates totals row
    useEffect(() => {
        if (totalRow) {
            const allTotalFields: { [x: string]: number } = {}
            columns.forEach(c => {
                if (c.total) {

                    let colTotal = 0;
                    rows.forEach(r => {
                        colTotal = addValues(colTotal, _.get(r, c.id));
                    })

                    allTotalFields[c.id] = colTotal;
                }
            })

            setTotals(allTotalFields);
        }

    }, [columns, rows, totalRow])
    // Location of total row start
    const colIndex = columns.findIndex(c => c.total);

    // Handles Row Selection
    const handleRowSelection = (row: EditableTableRow) => {
        if (row === selectedRow) rowSelect(null);
        else rowSelect(row);
    }

    // Handles Search of Strings in table
    useEffect(() => {
        const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
        const filteredRows = data.filter((row: any) => {
            return columns.some((field: any) => {
                const value = _.get(row, field.id)
                if (!!value) {
                    return searchRegex.test(_.get(row, field.id).toString());
                }
                return false
            });
        });
        setRows(filteredRows);
    }, [searchText, data, columns])


    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
    };

    // Handles Sorting requests
    const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    // Handle select for Edit
    const handleEditRequest = (row: EditableTableRow): void => {
        if (row.id === editRow) setEditRow(null);
        else setEditRow(row.id)
    }

    const sorted = useMemo(() => {
        return rows.slice().sort(getComparator(order, orderBy, altSort));
    }, [rows, order, orderBy, altSort]);

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    bgcolor: 'secondary.main',
                    borderRadius: '5px',
                    width: '100%',
                    height: 'inherit',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <CustomToolbar
                    title={title}
                    search={search}
                    value={searchText}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
                    clearSearch={() => requestSearch('')}
                    create={create}
                    customFilter={customFilter}
                    expanded={expanded}
                    setExpand={(e) => setExpand(e)}
                    expand={expand}
                />
                <TableContainer
                    component={Box}
                    sx={{
                        width: '100%',
                        height: 'inherit',
                        bgcolor: 'common.white',
                    }}
                >
                    <Table
                        size='small'
                        stickyHeader
                        sx={{
                            height: 'inherit',
                            width: '100%',
                            '& .MuiTableCell-root': {
                                fontSize: 12.5
                            }
                        }}
                    >
                        {/* HEADER ROW */}
                        <EditableTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                            columns={columns}
                            selectable={(!!rowSelect)}
                            removeOpt={(!!handleRemove)}
                        />
                        {/* LOADING DATA */}
                        {loading ?
                            <TableBody sx={{flex: 1}}>
                                <TableRow sx={{height: '100%'}}>
                                    <TableCell
                                        colSpan={columns.length + 1}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: 50
                                            }}
                                        >
                                            <CircularProgress/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            // DATA ROWS
                            <>
                                {!expanded &&
                                    <EditableTableBody
                                        sorted={sorted}
                                        columns={columns}
                                        editRow={editRow}
                                        handleRemove={handleRemove}
                                        handleRowSelection={handleRowSelection}
                                        handleEditRequest={handleEditRequest}
                                        selectedRow={selectedRow}
                                        handleRowEdit={handleRowEdit}
                                        validationSchema={validationSchema}
                                        formRowValueFunction={formRowValueFunction}
                                        loading={loading}
                                        totalRow={totalRow}
                                        colIndex={colIndex}
                                        rowSelect={rowSelect}
                                        totals={totals}
                                    />
                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Box>
            <Dialog open={expanded} onClose={() => setExpand(false)} fullWidth={true} maxWidth={false} fullScreen sx={{zIndex: 1202}} >
                    <Box
                        sx={{
                            p: 1,
                            bgcolor: 'secondary.main',
                            borderRadius: '5px',
                            width: '100%',
                            height: 'inherit',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <CustomToolbar
                            title={title}
                            search={search}
                            value={searchText}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
                            clearSearch={() => requestSearch('')}
                            create={create}
                            customFilter={customFilter}
                            expanded={expanded}
                            setExpand={(e) => setExpand(e)}
                            expand={expand}
                        />
                        <TableContainer
                            component={Box}
                            sx={{
                                width: '100%',
                                height: 'inherit',
                                bgcolor: 'common.white',
                            }}
                        >
                            <Table
                                size='small'
                                stickyHeader
                                sx={{
                                    height: 'inherit',
                                    width: '100%',
                                    '& .MuiTableCell-root': {
                                        fontSize: 12.5
                                    }
                                }}
                            >
                                {/* HEADER ROW */}
                                <EditableTableHead
                                    onRequestSort={handleRequestSort}
                                    order={order}
                                    orderBy={orderBy}
                                    columns={columns}
                                    selectable={(!!rowSelect)}
                                    removeOpt={(!!handleRemove)}
                                />
                                {/* LOADING DATA */}
                                {loading ?
                                    <TableBody sx={{flex: 1}}>
                                        <TableRow sx={{height: '100%'}}>
                                            <TableCell
                                                colSpan={columns.length + 1}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: 50
                                                    }}
                                                >
                                                    <CircularProgress/>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    // DATA ROWS
                                    <>
                                        {expanded &&
                                            <EditableTableBody
                                                sorted={sorted}
                                                columns={columns}
                                                editRow={editRow}
                                                handleRemove={handleRemove}
                                                handleRowSelection={handleRowSelection}
                                                handleEditRequest={handleEditRequest}
                                                selectedRow={selectedRow}
                                                handleRowEdit={handleRowEdit}
                                                validationSchema={validationSchema}
                                                formRowValueFunction={formRowValueFunction}
                                                loading={loading}
                                                totalRow={totalRow}
                                                colIndex={colIndex}
                                                rowSelect={rowSelect}
                                                totals={totals}
                                            />
                                        }
                                    </>
                                }
                            </Table>
                        </TableContainer>
                    </Box>
            </Dialog>
        </>
    )
}

export default memo(EditableTable);