import React, {ReactElement} from "react";
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Button, Typography, Container, Stack} from '@mui/material';
import {
    AccountBalanceOutlined as AccountBalanceIcon,
    Assessment as AssessmentIcon,
    ArrowForward as ArrowIcon,
    AttachMoneyTwoTone as AttachMoneyIcon,
    LibraryBooksTwoTone as BookIcon,
    SsidChartOutlined as ChartIcon,
} from "@mui/icons-material";
// components
import {Page} from '../components';

// ----------------------------------------------------------------------

// Main Page
export default function Main() {

    return (
        <Page title="MiPad - Home">
            <Container maxWidth="sm">
                {/*<ContentStyle sx={{  alignItems: 'center', pt: 0}}>*/}
                <Typography variant="h3" paragraph align="center">
                    Welcome!
                </Typography>
                <Stack direction="column" spacing={2}>
                    <AppNavigationSelection
                        title="Asset Fund Register"
                        icon={<BookIcon/>}
                        routeTo="/dashboard/asset-fund-register/summary"
                    />
                    <AppNavigationSelection
                        title="Capital Budget"
                        icon={<AccountBalanceIcon/>}
                        routeTo="/dashboard/capital-budget/summary"
                    />
                    <AppNavigationSelection
                        title="S Curve - ARM"
                        icon={<ChartIcon/>}
                        routeTo="/dashboard/s-curve_arm/summary"
                    />
                    <AppNavigationSelection
                        title="Valuation Model"
                        icon={<AttachMoneyIcon/>}
                        routeTo="/dashboard/valuation-model/valuationSummary"
                    />
                    <AppNavigationSelection
                        title="Power BI Reports"
                        icon={<AssessmentIcon/>}
                        routeTo="/dashboard/power-bi/reports"
                    />
                </Stack>
            </Container>
        </Page>
    );
}

const AppNavigationSelection = ({title, icon, routeTo}: { title: string, icon: ReactElement, routeTo: string }) => {
    return (
        <Button
            size='large'
            variant="outlined"
            sx={{
                height: 100,
                display: 'flex',
                justifyContent: 'space-between',
            }}
            to={routeTo}
            component={RouterLink}
        >
            <Stack direction="row"
                   sx={{
                       display: 'flex',
                       justifyContent: 'space-between',
                       alignContents: 'center',
                       alignItems: 'center'
                   }}
                   spacing={4}
            >
                {React.cloneElement(icon, {sx: {fontSize: 50}})}
                <Typography variant='h4'>
                    {title}
                </Typography>
            </Stack>
            <ArrowIcon sx={{fontSize: 40}}/>
        </Button>
    )
}