import { useEffect, useState } from 'react';
// Local imports
import { dateSortComparator, formatDate } from '../../utils/DateUtils';
// @mui
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Button } from '@mui/material';
// Components
import { AlertDialog, DataTable, SideDrawer } from "../../components";
// Sections
import { CreateUserForm } from './createUser';
// Store
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getUsers } from "../../store/user/userSelectors";
import { resetUpdate, User } from '../../store/user/userSlice';
import { enableDisableUser } from '../../store/user/userFunctions';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, hide: true },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
        field: 'birthdate',
        headerName: 'Birth date',
        valueFormatter: (param) => {
            return formatDate(new Date(param.value), 'dd-MM-yyyy')
        },
        sortComparator: dateSortComparator,
        flex: 1
    },
    { field: 'role', headerName: 'Role', flex: 1 },
    {
        field: 'status',
        headerName: 'Status',
        valueFormatter: (param) => {
            return (param) ? 'Active' : 'Disabled'
        },
        flex: 1,
    },
]

export default function FormExample() {
    const users = useAppSelector(state => getUsers(state));
    const loading = useAppSelector(state => state.user.loading);
    const updated = useAppSelector(state => state.user.userUpdated);

    const dispatch = useAppDispatch();

    const [update, setUpdate] = useState<null | 'open' | User>(null);
    const [enable, setEnable] = useState<User | null>(null);

    useEffect(() => {
        if (updated && !!update) {
            setUpdate(null);
            dispatch(resetUpdate())
        }
    }, [update, setUpdate, updated, dispatch])

    return (
        <>
            <DataTable
                title="Users"
                columns={[
                    ...columns,
                    {
                        field: 'activate',
                        headerName: '',
                        width: 150,
                        align: 'center',
                        renderCell: (params: GridCellParams) =>
                            <Button
                                sx={{ m: 1 }}
                                variant='contained'
                                onClick={() => setEnable(params.row)}>
                                {(params.row.status) ? 'Deactivate' : 'Enable'} User
                            </Button>
                    },
                    // {
                    //     field: ' ',
                    //     headerName: '',
                    //     align: 'center',
                    //     renderCell: (params: GridCellParams) =>
                    //         <Button
                    //             sx={{ m: 1 }}
                    //             variant='contained'
                    //             onClick={() => setUpdate(params.row)}>
                    //             Select
                    //         </Button>
                    // }
                ]}
                data={users}
                loading={users.length === 0 || loading}
                showExport={false}
                hideFooter
                create={() => setUpdate('open')}
            />
            <SideDrawer
                title={'Create User'}
                open={!!(update)}
                onClose={() => setUpdate(null)}
            >
                <CreateUserForm updateValues={(update && update !== 'open') ? {
                    email: update.email,
                    givenName: update.given_name,
                    familyName: update.family_name,
                    birthdate: update.birthdate,
                    role: update.role
                } : undefined} />
            </SideDrawer>
            {enable &&
                <AlertDialog
                    title={`${enable.status ? 'Disable' : 'Enabled'} User`}
                    info={`Are you sure you want to ${enable.status ? 'Disable' : 'Enabled'} this User?`}
                    open={!!enable}
                    handleClose={() => setEnable(null)}
                    handleConfirm={() => {
                        if (!!enable) dispatch(enableDisableUser({email: enable.email, status: !enable.status}))
                        setEnable(null);
                    }}

                />
            }

        </>
    )
}