import _ from "lodash";
import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";

// Retrieve External data
export const retrieveSortedExternalDataSets = createSelector(
    (state: RootState) => state.externalData.accountBalance || [],
    (state: RootState) => state.externalData.axcess || [],
    (state: RootState) => state.externalData.ncino || [],
    (state: RootState) => state.externalData.holding || [],
    (state: RootState) => state.externalData.loading,
    (accountBalance, axcess, ncino, holding, loading) => {
        return {
            accountBalance: _.cloneDeep(accountBalance).filter(a => !!a.date).sort((a, b) => (a.date > b.date) ? -1 : 1),
            axcess: _.cloneDeep(axcess).filter(a => !!a.date).sort((a, b) => (a.date > b.date) ? -1 : 1),
            ncino: _.cloneDeep(ncino).filter(a => !!a.date).sort((a, b) => (a.date > b.date) ? -1 : 1),
            holding: _.cloneDeep(holding).filter(a => !!a.date).sort((a, b) => (a.date > b.date) ? -1 : 1),
            loading
        }
    }
)