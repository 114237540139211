import {useAppDispatch, useAppSelector} from '../../store/store';
import {dateSortComparator, formatDate} from '../../utils/DateUtils';
// @mui
import {Grid, Container, Typography, Button} from '@mui/material';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
// components
import {AlertDialog, DataTable, SideDrawer, Page} from '../../components';
import {Scenario} from "../../types/capitalBudgetTypes";
import {setScenarioById} from "../../store/scenarios/scenerioSlice";
import {useState} from "react";
import {setActiveScenario} from "../../store/scenarios/scenarioFunctions";
import {CreateScenario} from "../../sections/dashboard/capitalBudget/scenarios";

// ----------------------------------------------------------------------
//  Column Definitions
const columns: GridColDef[] = [
    {field: 'id', headerName: 'ID', width: 100, hide: true},
    {
        field: 'createdDate',
        headerName: 'Date Created',
        width: 150,
        type: 'date',
        valueFormatter: (param) => formatDate(new Date(param.value), 'dd-MM-yyyy hh:mm a'),
        sortComparator: dateSortComparator
    },
    {
        field: 'description',
        headerName: 'Description',
        minWidth: 250,
        editable: true,
        flex: 2
    },
    {
        field: 'reportAsOf',
        headerName: 'Report as of',
        width: 120,
        type: 'date',
        valueFormatter: (param) => {
            return formatDate(new Date(param.value))
        },
        sortComparator: dateSortComparator
    },
    {
        field: 'creator',
        headerName: 'Created By',
        width: 150,
        valueFormatter: (param) => {
            return `${param.value.firstName} ${param.value.lastName}`
        },
    },
    {
        field: 'modifiedDate',
        headerName: 'Date Modified',
        width: 150,
        valueFormatter: (param) => {
            if (!param.value) {
                return 'N/A'
            }
            return formatDate(new Date(param.value), 'dd-MM-yyyy hh:mm a')
        },
    },
    {
        field: 'modifier',
        headerName: 'Modified By',
        width: 150,
        valueFormatter: (param) => {
            if (!param.value) {
                return 'N/A'
            }
            return `${param.value.firstName} ${param.value.lastName}`
        },
    },
    {
        field: 'active',
        headerName: 'Active',
        width: 100,
        valueFormatter: (param) => {
            return (param.value) ? 'True' : 'False'
        },
    },
    {
        field: 'activeBy',
        headerName: 'Active By',
        width: 150,
        valueFormatter: (param) => {
            if (!param.value) {
                return 'N/A'
            }
            return `${param.value.firstName} ${param.value.lastName}`
        },
    },
];

// ----------------------------------------------------------------------

// Capital Budget Scenario App Page
export default function Scenarios() {
    const dispatch = useAppDispatch();

    const scenarioLoading = useAppSelector(state => state.scenarios.loading)
    const scenarios = useAppSelector(state => state.scenarios.scenarios)
    const currentScenario = useAppSelector(state => state.scenarios.scenario)

    const [switchScenario, setSwitch] = useState<number | null>(null)
    const [createScenario, setCreate] = useState<boolean>(false)

    const handleSelectScenario = (scenario: Scenario) => {
        setSwitch(scenario.id);
    }

    return (
        <Page title="Metrics - Capital Budget - Scenarios">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                        Scenarios
                    </Typography>
                    <Grid item container sx={{width: '100%', flex: 1, pb: 1}}>
                        <DataTable
                            title={'Scenarios'}
                            columns={
                                [
                                    ...columns,
                                    {
                                        field: 'action',
                                        headerName: '',
                                        width: 200,
                                        renderCell: (params: GridCellParams) =>
                                            <>
                                                <Button
                                                    sx={{m: 1}}
                                                    variant='contained'
                                                    onClick={() => handleSelectScenario(params.row)}
                                                    disabled={(currentScenario?.id === params.row.id)}
                                                >
                                                    Select
                                                </Button>
                                                <Button
                                                    sx={{m: 1}}
                                                    variant='contained'
                                                    onClick={() => dispatch(setActiveScenario(params.row.id))}
                                                    disabled={(params.row.active)}
                                                >
                                                    Set Active
                                                </Button>
                                            </>
                                    }
                                ]
                            }
                            data={(scenarios) ? scenarios : []}
                            loading={scenarioLoading}
                            showExport={false}
                            hideFooter
                            search
                            sort={{field: 'reportAsOf', sort: 'desc'}}

                            selectedRow={currentScenario ? currentScenario?.id : null}

                            create={() => setCreate(!createScenario)}
                        />
                    </Grid>
                </Grid>
            </Container>
            <SideDrawer
                title={'Create New Scenario'}
                open={createScenario}
                onClose={() => setCreate(false)}
            >
                <CreateScenario onClose={() => setCreate(false)} />
            </SideDrawer>
            <AlertDialog
                title='Confirm Scenario Switch'
                info='Warning! Any Unsaved Changes will be lost when switching scenarios.'

                open={!!switchScenario}
                handleClose={() => setSwitch(null)}
                handleConfirm={() => {
                    if (switchScenario) dispatch(setScenarioById(switchScenario))
                    setSwitch(null)
                }}
            />
        </Page>
    )
}