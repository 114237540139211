import {CalculationLoanType, CapitalBudgetPeriodResults, MCPFund} from "../../../../types/capitalBudgetTypes";
import {
    checkDateBefore,
    checkDateSameOrBefore,
    checkInPeriod,
    daysBetweenDates,
    findlastDayOfMonth,
    findNextDayOfTheWeek
} from "../../../../utils/DateUtils";
import {AmendmentType, FundType, InvestmentType, LoanTags, PeriodType} from "../../../../types/capitalBudgetEnums";
import {addArrayValues, addValues} from "../../../../utils/mathUtil";
import {convertRatingToValue} from "../../../../utils/CapitalBudgetUtils";
import {Period} from "../../../../types/GeneralTypes";

// Handles each Axcess Loan in a Period.
export function handlePortfolioForPeriod(portfolioLoan: CalculationLoanType, results: CapitalBudgetPeriodResults, fund: MCPFund | null, budgetDate: number | Date, period: Period, periodType: PeriodType, periodMultiplier: number, ctcWeekBuffer: number, periodTypeMulti: number) {
    let loan: CalculationLoanType = {
        ...portfolioLoan,
        tags: []
    }
    if (!period.base) {
        if (!fund || (fund && ((fund.label === loan.fund) || (fund.type === FundType.FEEDER && fund.holdings.map(h => h.fund).includes(loan.fund))))) {
            // if (!fund || (fund && fund.label === loan.fund)) {
            // Loan Amendments (Early Repayments and Extensions
            if (loan.amendedMaturity && checkInPeriod(loan.amendedMaturity, period)) {
                switch (loan.amendment) {
                    case AmendmentType.EARLY_REPAYMENT:
                        loan.tags.push(LoanTags.EARLY_REPAYMENT)
                        results.earlyRepayment = addValues(results.earlyRepayment, loan.value);
                        results.earlyRepaymentCash = addValues(results.earlyRepaymentCash, loan.drawn);
                        break;
                    case AmendmentType.EXTENSION:
                        loan.tags.push(LoanTags.EXTENSION)
                        results.extension = addValues(results.extension, loan.value);
                        results.extensionCash = addValues(results.extensionCash, loan.drawn);
                        break;
                    default:
                        break;
                }
            }

            // Contractual Repayments
            if (checkInPeriod(loan.endDate, period)) {
                loan.tags.push(LoanTags.REPAYMENT);

                // Extension Offset
                if (loan.extensionOffset) {
                    loan.tags.push(LoanTags.OFFSET_EXTENSION);
                    results.extensionOffset = addValues(results.extensionOffset, -loan.value);
                    results.extensionOffsetCash = addValues(results.extensionOffsetCash, -loan.drawn);
                }
                // Early Repayment Offset
                if (loan.earlyRepOffset) {
                    loan.tags.push(LoanTags.OFFSET_EARLY);
                    results.earlyRepOffset = addValues(results.earlyRepOffset, -loan.value);
                    results.earlyRepOffsetCash = addValues(results.earlyRepOffsetCash, -loan.drawn);
                }

                results.expectedRepayments = addValues(results.expectedRepayments, loan.value);
                results.expectedRepaymentsCash = addValues(results.expectedRepaymentsCash, loan.drawn);
            }
        }
    }

    if (!period.base && loan.transfersOut && checkDateSameOrBefore(loan.transfersOut.transferDate, period.lastDate)) {
        const loans: Array<CalculationLoanType> = handleLoanSplit(loan, fund);
        loans.forEach(l => {
            handleActiveLoan(l, results, fund, budgetDate, period, periodType, periodMultiplier, ctcWeekBuffer, periodTypeMulti);
        })
    } else {
        if (!fund || (fund && ((fund.label === loan.fund) || (fund.type === FundType.FEEDER && fund.holdings.map(h => h.fund).includes(loan.fund))))) {
            // if (!fund || (fund && fund.label === loan.fund)) {
            handleActiveLoan(loan, results, fund, budgetDate, period, periodType, periodMultiplier, ctcWeekBuffer, periodTypeMulti);
        }
    }
}

function handleLoanSplit(loan: CalculationLoanType, fund: MCPFund | null) {
    const splits: Array<CalculationLoanType> = [];

    if (loan.transfersOut) {
        const originalDrawnP = loan.drawn / loan.value;
        loan.transfersOut.transfers.forEach((transfer) => {
            if (transfer.amount > 0) {
                const splitPer = transfer.amount / loan.value;
                splits.push({
                    ...loan,
                    fund: transfer.fund,
                    drawn: loan.drawn * splitPer,
                    undrawn: loan.undrawn * splitPer,
                    value: loan.value * splitPer,
                    tags: [...loan.tags, LoanTags.TRANSFER],
                    originalDrawnP: originalDrawnP,
                })
            }
        })
        const newSplit = loan.transfersOut.newAllocation / loan.value;
        splits.push({
            ...loan,
            drawn: loan.drawn * newSplit,
            undrawn: loan.undrawn * newSplit,
            value: loan.value * newSplit,
            tags: [...loan.tags, LoanTags.TRANSFER],
            originalDrawnP: originalDrawnP
        })
    }

    if (fund) {
        return splits.filter(s => s.fund === fund.label);
    } else {
        return splits;
    }
}

function handleActiveLoan(loan: CalculationLoanType, results: CapitalBudgetPeriodResults, fund: MCPFund | null, budgetDate: number | Date, period: Period, periodType: PeriodType, periodMultiplier: number, ctcWeekBuffer: number, periodTypeMulti: number) {
    const adjustedEndDate = (!period.base && loan.amendedMaturity) ? loan.amendedMaturity : loan.endDate;
    // AUM - if loan starts before period end date and does not end before period start date
    if (checkDateSameOrBefore(loan.startDate, period.lastDate) && !checkDateBefore(adjustedEndDate, period.startDate)) {
        loan.tags.push(LoanTags.ACTIVE);
        results.ium = addValues(results.ium, loan.value);

        // WAV TENOR
        if (loan.tenor > 0) {
            // const updatedTenor = (loan.tenor * periodTypeMulti) - periodMultiplier;
            const updatedTenor = (daysBetweenDates(new Date(period.lastDate), new Date(adjustedEndDate)) / 365);

            loan.updatedTenor = (updatedTenor > 0) ? updatedTenor : 0;

            if (!['Equity', 'Other'].includes(loan.ranking)) {
                if (updatedTenor > 0) {
                    const weightedTenor = updatedTenor * loan.value;
                    results.wavTenor = addValues(results.wavTenor, weightedTenor);
                }
            }
        } else {
            loan.updatedTenor = 0;
        }

        // WAV INTEREST RATE DURATION
        if (loan.interestType !== 'Other') {
            results.wavInterestTotal = addValues(results.wavInterestTotal, loan.value);
            const weightedInterestDuration = (loan.interestType === 'Fixed Rate') ? (loan.updatedTenor * loan.value) : (0.1 * loan.value)
            results.wavInterest = addValues(results.wavInterest, weightedInterestDuration);
        }

        // WAV RATING
        const ratingValue = convertRatingToValue(loan.rating)
        if (ratingValue) {
            results.wavRatingDenom = addValues(results.wavRatingDenom, loan.value);
            const weightRating = ratingValue * loan.value;
            results.wavRatingCalc = addValues(results.wavRatingCalc, weightRating);
        }

        // CRE CTC STRAIGHT LINE DRAWDOWN - FOR:
        // - 'REAL_ESTATE' Loans,
        // - That are not of type: 'Revolving', 'Capex', 'Equity', and
        // - Undrawn or partially drawn
        if (!period.base) {
            if (loan.investmentType === InvestmentType.REAL_ESTATE && !['Revolving', 'Capex', 'Equity'].includes(loan.trancheType) && loan.drawnPercentage >= 0 && loan.drawnPercentage < 1) {
                // NUMBER OF PERIODS IN LOAN
                const endOfPeriod = (periodType === PeriodType.WEEK) ? findNextDayOfTheWeek(new Date(adjustedEndDate), 7) : findlastDayOfMonth(new Date(adjustedEndDate));
                const numberOfPeriods = (periodType === 'week') ?
                    Math.floor((daysBetweenDates(budgetDate, endOfPeriod) / 7) - ctcWeekBuffer)
                    :
                    Math.floor((daysBetweenDates(budgetDate, endOfPeriod) / 365) * periodTypeMulti - 1) // 1 MONTH BUFFER

                loan.tags.push(LoanTags.CRE_CTC);
                // Handling of undrawn axcess loans in the first period
                if (loan.drawnPercentage === 0) {
                    loan.tags.push(LoanTags.CRE_CTC_NON_ACTIVE);
                    if (periodMultiplier === 0) {
                        if (checkDateBefore(budgetDate, adjustedEndDate)) {
                            const periodDrawdown = loan.undrawn / 2;
                            loan.updatedUndrawn = periodDrawdown;
                            loan.creCtcDrawdown = periodDrawdown;
                            results.periodCTCDrawdownUnactive = addValues(results.periodCTCDrawdownUnactive, -periodDrawdown);
                        } else {
                            loan.creCtcDrawdown = loan.value
                            results.periodCTCDrawdownUnactive = addValues(results.periodCTCDrawdownUnactive, -loan.undrawn);
                            loan.updatedUndrawn = 0;
                        }
                    } else {
                        if ((numberOfPeriods - periodMultiplier) >= 1) {
                            const periodCTC = (loan.undrawn / 2) / (numberOfPeriods - 1);
                            loan.creCtcDrawdown = periodCTC;
                            // Remaining undrawn loan amount
                            loan.updatedUndrawn = addValues((loan.undrawn / 2), -(periodCTC * (periodMultiplier)));
                            // Add to running sum of CRE CTC
                            results.periodCTCDrawdownUnactive = addValues(results.periodCTCDrawdownUnactive, -periodCTC);
                        }
                    }
                    // Handling of existing partially drawn loans in Axcess
                } else {
                    loan.tags.push(LoanTags.CRE_CTC_ACTIVE);
                    if (periodMultiplier === 0 && ((numberOfPeriods - periodMultiplier) < 1)) {
                        results.periodCTCDrawdown = addValues(results.periodCTCDrawdown, -loan.undrawn);
                        loan.creCtcDrawdown = loan.undrawn;
                        loan.updatedUndrawn = 0;
                    } else if (periodMultiplier >= 0 && ((numberOfPeriods - periodMultiplier) >= 1)) {
                        // Calculate CTC Per Period
                        const periodCTC = loan.undrawn / numberOfPeriods;
                        loan.creCtcDrawdown = periodCTC;
                        // Remaining undrawn loan amount
                        loan.updatedUndrawn = addValues(loan.undrawn, -periodCTC * (periodMultiplier + 1));
                        // Add to running sum of CRE CTC
                        results.periodCTCDrawdown = addValues(results.periodCTCDrawdown, -periodCTC);
                    }
                }
            }
        }

        // WAV YIELD
        // DO NOT INCLUDE EQUITY
        if (!['Equity', 'Other'].includes(loan.ranking) || (['Equity', 'Other'].includes(loan.ranking) && loan.margin > 0)) {
            let drawnValue = loan.drawn
            if (!period.base) {
                drawnValue = (loan.updatedUndrawn) ? loan.value - loan.updatedUndrawn : loan.drawn;
            }
            results.wavYieldTotal = addValues(results.wavYieldTotal, drawnValue);

            // Calculate Margin - Loan.margin + if applicable facility fee rate + if base rate floor and rate < base rate floor THEN base rate floor - base rate
            const margin = addArrayValues([loan.margin, ((loan.facilityFee === 'Facility Limit') ? loan.facilityFeeRate : 0), ((!loan.baseRateFloor && loan.baseRateFloor > loan.baseRate) ? loan.baseRateFloor - loan.baseRate : 0)]);
            results.wavMargin = addValues(results.wavMargin, margin * drawnValue);

            // IF FIXED RATE LOAN Yield does not include base rate
            results.wavYield = addValues(results.wavYield, drawnValue * addValues(margin, ((loan.pricingType !== 'FIXED' ? loan.baseRate : 0))))
        } else {

        }
    }

    if (!period.base) {

        // Handle Loan Selldowns
        if (loan.selldowns && loan.selldowns.length > 0) {
            loan.updatedValue = loan.value || 0; // CHECK USE OF THIS VALUE
            loan.selldowns.forEach(s => {
                // IF THE SELLDOWN IS HAPPENING IN PERIOD
                if (checkInPeriod(s.date, period)) {
                    loan.tags.push(LoanTags.SELLDOWN);
                    // REDUCE LOAN AMOUNT AND APPLY SELLDOWN
                    results.selldowns = addValues(results.selldowns, s.amount);
                    loan.updatedValue = addValues(loan.updatedValue, -s.amount);
                    const cashSelldown = s.amount * (loan.drawn / loan.value);
                    results.selldownsCash = addValues(results.selldownsCash, cashSelldown);

                }
            })
        }

        // Offsets at new Maturity
        if (checkInPeriod(adjustedEndDate, period)) {
            if (loan.selldowns && loan.selldowns.length > 0) {
                // Selldown offset
                loan.tags.push(LoanTags.OFFSET_SELLDOWN);
                loan.selldowns.forEach(s => {
                    results.selldownOffset = addValues(results.selldownOffset, -s.amount);
                    const cashOffset = s.amount * (1 - (loan.undrawn / loan.value));
                    results.selldownOffsetCash = addValues(results.selldownOffsetCash, -cashOffset);

                })
            }

            if (loan.transfersOut) {
                // CHECK FOR TRANSFER OUT SELLDOWN
                if (checkInPeriod(adjustedEndDate, period)) {
                    const transfer = loan.transfersOut
                    if (transfer.fromFund === loan.fund) {
                        // TRANSFER OUT
                        loan.tags.push(LoanTags.OFFSET_TRANSFER_OUT);
                        const value = addValues(transfer.originalAllocation, -transfer.newAllocation);
                        results.transfersOutOffset = addValues(results.transfersOutOffset, -value);
                        const cashOutValue = value * (loan?.originalDrawnP || 0);
                        results.transfersOutOffsetCash = addValues(results.transfersOutOffsetCash, -cashOutValue);
                    } else {
                        // TRANSFER IN
                        const reallocate = transfer.transfers.find(r => r.fund === loan.fund);
                        if (reallocate) {
                            loan.tags.push(LoanTags.OFFSET_TRANSFER_IN);
                            const value = reallocate.amount
                            results.transfersInOffset = addValues(results.transfersInOffset, value);
                            const cashOutValue = value * (loan?.originalDrawnP || 0);
                            results.transfersInOffsetCash = addValues(results.transfersInOffsetCash, cashOutValue);
                        }
                    }
                }
            }
        }

        // Check loans transfers
        if (loan.transfersOut) {
            const transfer = loan.transfersOut
            // CHECK TRANSFER DATE OF LOAN
            if (checkInPeriod(transfer.transferDate, period)) {
                if (transfer.fromFund === loan.fund) {
                    // TRANSFER OUT
                    loan.tags.push(LoanTags.TRANSFER_OUT);
                    const value = addValues(transfer.originalAllocation, -transfer.newAllocation);
                    results.transfersOut = addValues(results.transfersOut, value);
                    const cashOutValue = value * (loan?.originalDrawnP || 0);

                    results.transfersOutCash = addValues(results.transfersOutCash, cashOutValue);
                } else {
                    // TRANSFER IN
                    const reallocate = transfer.transfers.find(r => r.fund === loan.fund);
                    if (reallocate) {
                        loan.tags.push(LoanTags.TRANSFER_IN);
                        const value = reallocate.amount
                        results.transfersIn = addValues(results.transfersIn, -value);
                        const cashOutValue = value * (loan?.originalDrawnP || 0);
                        results.transfersInCash = addValues(results.transfersInCash, -cashOutValue);
                    }
                }
            }
        }
    }

    results.loans.push(loan);
}