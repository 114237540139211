import {useEffect} from "react";
import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
// MUI
import {Button, CircularProgress, Divider, Grid, Typography} from "@mui/material";
// Local Imports
import {InputTypes} from "../../../../types/InputTypes";
// Components
import {FormInput} from "../../../../components";
// Store
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {retrieveExternalDataLists} from "../../../../store/externalData/externalDataFunction";
import {formatDate} from "../../../../utils/DateUtils";
import {retrieveSortedExternalDataSets} from "../../../../store/externalData/externalDataSelector";
import {createNewScenario} from "../../../../store/scenarios/scenarioFunctions";
import {NewScenario} from "../../../../types/capitalBudgetTypes";
import usePrevious from "../../../../hooks/usePrevious";

// ----------------------------------------------------------------------
// Validation Schema
const saveSchema = Yup.object().shape({
    description: Yup.string().required('Desc is required.'),
    accountBalance: Yup.string().min(2, 'Account Balance Data Set is required.').required('Account Balance Data Set is required.'),
    axcess: Yup.string().min(2, 'Axcess Data Set is required.').required('Axcess Data Set is required.'),
    ncino: Yup.string().min(2, 'nCino Data Set is required.').required('nCino Data Set is required.'),
    holding: Yup.string().min(2, 'Holding Data Set is required.').required('Holding Data Set is required.'),
    reportAsOf: Yup.date().required('Report Date is invalid/required.')
});
// ----------------------------------------------------------------------

// Save Scenario
export default function CreateScenario({onClose}: { onClose: () => void }) {
    const dispatch = useAppDispatch();

    const saving = useAppSelector(state => state.scenarios.saving)
    const {loading, accountBalance, axcess, ncino, holding} = useAppSelector(state => retrieveSortedExternalDataSets(state));

    const prevSaveState = usePrevious(saving);

    useEffect(() => {
        if (!!prevSaveState && !saving) {
            onClose()
        }
    }, [prevSaveState, saving, onClose])

    const currentAccountBalance = useAppSelector(state => state.capitalBudget.thirdPartyData?.accountBalance?.id);
    const currentAxcess = useAppSelector(state => state.capitalBudget.thirdPartyData?.axcess.id);
    const currentnCino = useAppSelector(state => state.capitalBudget.thirdPartyData?.ncino.id);
    const currentHolding = useAppSelector(state => state.capitalBudget.thirdPartyData?.holding.id);

    const currentScenario = useAppSelector(state => state.scenarios.scenario);

    useEffect(() => {
        dispatch(retrieveExternalDataLists())
    }, [dispatch])

    if (!currentScenario) return <></>

    return (
        <>
            {currentAxcess && currentnCino && currentHolding && !saving ?
                <Formik
                    initialValues={{
                        description: '',
                        accountBalance: currentAccountBalance || null,
                        axcess: currentAxcess,
                        ncino: currentnCino,
                        holding: currentHolding,
                        reportAsOf: new Date(currentScenario.reportAsOf),
                        balanceClear: false,
                        capitalClear: false,
                        otherTransactionsClear: false,
                        clearAxcessChange: false,
                        clearNcinoChanges: false,
                    }}
                    onSubmit={(values) => {
                        dispatch(createNewScenario(values as NewScenario))
                    }}
                    validationSchema={saveSchema}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            handleSubmit,
                            values,
                            setFieldValue,
                        } = props

                        const accountBalanceDate = (values.accountBalance === 'new') ? new Date().toDateString() : accountBalance.find(n => n.id === values.accountBalance)?.date || 0;
                        const ncinoDate = (values.ncino === 'new') ? new Date().toDateString() : ncino.find(n => n.id === values.ncino)?.date || 0;
                        const axcessDate = axcess.find(n => n.id === values.axcess)?.date || 0;
                        const holdingDate =  holding.find(n => n.id === values.holding)?.date || 0;

                        const newReportDate = [ncinoDate, axcessDate, holdingDate, ...(accountBalanceDate) ? [accountBalanceDate] : []].sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1))[0]

                        if (new Date(values.reportAsOf).toDateString() !== new Date(newReportDate).toDateString()) {
                            setFieldValue('reportAsOf', new Date(newReportDate));
                        }

                        return (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <Grid container sx={{p: 2}}>
                                        <FormInput
                                            id='description'
                                            label='Description'
                                            fieldType={InputTypes.STRING}
                                            layout={{xs: 12, md: 12, lg: 12}}
                                        />
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography color='primary.main' variant='h6'>
                                                Data Reload:
                                            </Typography>
                                        </Grid>
                                        {loading || axcess.length <= 0 || !values.axcess ?
                                            <Grid item xs={12} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                p: 1
                                            }}>
                                                <CircularProgress/>
                                            </Grid>
                                            :
                                            <>
                                                <FormInput
                                                    id='axcess'
                                                    label='Axcess Data Set'
                                                    fieldType={InputTypes.SELECTION}
                                                    values={[
                                                        ...axcess.map((a) => {
                                                            return {
                                                                // label: `${formatDate(new Date(a.dateOfData), 'dd-MM-yyyy hh:mm a')}${a.automated ? ' (Auto Retrieved)' : ''}${(a.id === currentAxcess) ? ' -  Current Data Set' : ''}`,
                                                                label: (a.automated ? `${formatDate(new Date(a.date), 'dd-MM-yyyy')} EOD` : `${formatDate(new Date(a.date), 'dd-MM-yyyy')} at ${formatDate(new Date(a.dateOfData), 'dd-MM-yyyy hh:mm a')}`) + (a.id === currentAxcess ? ' -  Current Data Set' : ''),
                                                                value: a.id
                                                            }
                                                        })
                                                    ]}
                                                    limit
                                                    layout={{xs: 12, md: 12, lg: 12}}
                                                />
                                            </>
                                        }
                                        {loading || ncino.length <= 0 || !values.ncino ?
                                            <Grid item xs={12} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                p: 1
                                            }}>
                                                <CircularProgress/>
                                            </Grid>
                                            :
                                            <>
                                                <FormInput
                                                    id='ncino'
                                                    label='nCino Data Set'
                                                    fieldType={InputTypes.SELECTION}
                                                    values={[
                                                        {label: 'Retrieve New Data Set', value: 'new'},
                                                        ...ncino.map((n) => {
                                                            return {
                                                                label: (n.automated ? `${formatDate(new Date(n.date), 'dd-MM-yyyy')} EOD` : `${formatDate(new Date(n.date), 'dd-MM-yyyy')} at ${formatDate(new Date(n.dateOfData), 'dd-MM-yyyy hh:mm a')}`)  + (n.id === currentnCino ? ' -  Current Data Set' : ''),
                                                                value: n.id
                                                            }
                                                        })
                                                    ]}
                                                    layout={{xs: 12, md: 12, lg: 12}}
                                                    limit
                                                />
                                            </>
                                        }
                                        {loading || holding.length <= 0 || !values.holding ?
                                            <Grid item xs={12} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                p: 1
                                            }}>
                                                <CircularProgress/>
                                            </Grid>
                                            :
                                            <>
                                                <FormInput
                                                    id='holding'
                                                    label='Portfolio Holding Data Set'
                                                    fieldType={InputTypes.SELECTION}
                                                    values={[
                                                        ...holding.map((h) => {
                                                            return {
                                                                label: `${formatDate(new Date(h.date), 'dd-MM-yyyy')}${h.automated ? ' (Auto Retrieved)' : ''}${(h.id === currentHolding) ? ' -  Current Data Set' : ''}`,
                                                                value: h.id
                                                            }
                                                        })
                                                    ]}
                                                    layout={{xs: 12, md: 12, lg: 12}}
                                                    limit
                                                />
                                            </>
                                        }
                                        {loading || accountBalance.length <= 0  ?
                                            <Grid item xs={12} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                p: 1
                                            }}>
                                                <CircularProgress/>
                                            </Grid>
                                            :
                                            <>
                                                <FormInput
                                                    id='accountBalance'
                                                    label='Account Balance Data Set'
                                                    fieldType={InputTypes.SELECTION}
                                                    values={[
                                                        ...accountBalance.map((a) => {
                                                            return {
                                                                label: `${formatDate(new Date(a.date), 'dd-MM-yyyy')}${a.automated ? ' (Auto Retrieved)' : ''}${(a.id === currentHolding) ? ' -  Current Data Set' : ''} at ${formatDate(new Date(a.dateOfData), 'dd-MM-yyyy hh:mm a')}`,
                                                                value: a.id
                                                            }
                                                        })
                                                    ]}
                                                    layout={{xs: 12, md: 12, lg: 12}}
                                                    limit
                                                />
                                            </>
                                        }
                                        <FormInput
                                            id='reportAsOf'
                                            label='Report As Of'
                                            fieldType={InputTypes.DATE}
                                            layout={{xs: 12, md: 12, lg: 12}}
                                            disabled
                                        />
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography color='primary.main' variant='h6'>
                                                Other:
                                            </Typography>
                                        </Grid>
                                        <FormInput
                                            id='balanceClear'
                                            label='Clear Fund Account Balances'
                                            fieldType={InputTypes.CHECKBOX}
                                        />
                                        <FormInput
                                            id='capitalClear'
                                            label='Clear Capital Changes'
                                            fieldType={InputTypes.CHECKBOX}
                                        />
                                        <FormInput
                                            id='otherTransactionsClear'
                                            label='Clear Other Transactional Changes'
                                            fieldType={InputTypes.CHECKBOX}
                                        />
                                        <FormInput
                                            id='clearAxcessChange'
                                            label='Clear Axcess Related Forecasts'
                                            fieldType={InputTypes.CHECKBOX}
                                        />
                                        <FormInput
                                            id='clearNcinoChanges'
                                            label='Clear nCino Related Forecasts'
                                            fieldType={InputTypes.CHECKBOX}
                                        />
                                        <Grid item container direction='row'>
                                            <Grid item sx={{width: '50%', p: 2}}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    onClick={onClose}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item sx={{width: '50%', p: 2}}>
                                                {saving ?
                                                    <CircularProgress/>
                                                    :
                                                    <Button
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        Create
                                                    </Button>
                                                }

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        )
                    }}
                </Formik>
                :
                <CircularProgress/>
            }
        </>
    )
}