import { ChangeEvent, memo } from 'react';
// MUI
import { AppBar, Button, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { Clear as ClearIcon, Create as CreateIcon, Search as SearchIcon } from '@mui/icons-material';

// ----------------------------------------------------------------------
// Props

type CustomToolbarProps = {
    title?: string,
    search: boolean, // allow search field
    value: string // Search string value
    onChange: (e: ChangeEvent<HTMLInputElement>) => void, // Handles search string value change
    clearSearch: () => void, // Function clearing search field
    create?: () => void // Function handling create button
}

// ----------------------------------------------------------------------

export default memo(function CustomToolbar(props: CustomToolbarProps) {
    const {
        title,
        search = true,
        value,
        onChange,
        clearSearch,
        create
    } = props;

    return (
        <Toolbar
            sx={{
                minHeight: 40
            }}
            variant='dense'
        >
            <AppBar
                color='secondary'
                position='absolute'
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 2,
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        color: 'white',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant='h6'>
                        {title}
                    </Typography>
                    {create &&
                        <Button
                            onClick={create}
                            sx={{ pl: 2 }}
                            color='inherit'
                            startIcon={<CreateIcon />}
                        >
                            Create New
                        </Button>
                    }
                </Grid>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {search &&
                        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <TextField
                                variant="standard"
                                value={value}
                                onChange={onChange}
                                placeholder="Search…"
                                InputProps={{
                                    startAdornment: <SearchIcon fontSize="small" />,
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: value ? 'visible' : 'hidden' }}
                                            onClick={clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                                sx={{
                                    bgcolor: 'common.white',
                                    color: 'common.white',
                                    pl: 1,
                                    pr: 1,
                                    pb: 0.5,
                                    borderRadius: '5px'
                                }}
                            />
                        </div>
                    }
                </div>
            </AppBar>
        </Toolbar>
    )
})