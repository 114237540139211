import { useState } from 'react';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import { HorizontalTable, HorizontalTableRow, HorizontalTableColumn, Page } from '../../components';
import { SelectionBar } from './SelectionBar';
// Sections
import { AllocationAmendments } from '../../sections/dashboard/capitalBudget/interfundTransfers';
// Store
import { useAppSelector } from '../../store/store';
import {portfolioStatisticsSelector} from "../../store/capitalBudget/selectors";

// Capital Budget App Page
export default function InterfundTransfers() {
    const loading = useAppSelector(state => state.capitalBudget.loading);
    const {data, rows} = useAppSelector(state => portfolioStatisticsSelector(state));

    const [row, selectRow] = useState<HorizontalTableRow | null>(null);
    const [column, selectCol] = useState<HorizontalTableColumn | null>(null);

    return (
        <Page title="Metrics - Capital Budget - Interfund Transfers">
            <Container maxWidth={false} sx={{ maxWidth: '100%', height: 1 }}>
                <Grid container spacing={2} direction='column' sx={{ height: 1 }}>
                    <Grid container spacing={1} direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{ mb: 3, color: 'primary.main' }}>
                            Interfund Transfers
                        </Typography>
                        <SelectionBar />
                    </Grid>
                    <Grid item sx={{ width: '100%' }}>
                        <HorizontalTable
                            rows={rows}
                            data={(data) ? data : []}
                            loading={loading}

                            selectedRow={row}
                            selectedCol={column}
                            rowSelect={selectRow}
                            colSelect={selectCol}

                            selectableCell
                        />
                    </Grid>
                    <Grid sx={{ height: 2, bgcolor: 'primary.main', m: 1, width: '100%' }} />
                    <Grid item sx={{ flex: 1, overflowY: 'hidden', width: '100%' }}>
                        <AllocationAmendments />
                    </Grid>
                </Grid>
                {/* </Grid> */}
            </Container>
        </Page>
    )
}