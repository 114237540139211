import {Fragment} from "react";
import {Formik, FormikProps} from "formik";
import * as Yup from 'yup';
import {addValues} from "../../../../utils/mathUtil";
import {ObjectSchema} from "yup";
// Local Import
import {FormInput} from "../../../../components";
import {InputTypes} from "../../../../types/InputTypes";
// MUI
import {Button, Divider, Grid} from "@mui/material";
// Store
import {useAppDispatch} from "../../../../store/store";
import {updateBalancesReducers} from "../../../../store/capitalBudget/capitalBudgetSlice";
import {FundBalance} from "../../../../types/capitalBudgetTypes";


// Allows for the editing of Account Balances Total cash amount
export default function EditAccountBalances({balances, onClose}: {
    balances: { funds: Array<FundBalance>, total: number },
    onClose: () => void
}) {
    const dispatch = useAppDispatch();

    const valObject: { [key: string]: ObjectSchema<any>} = {};

    balances.funds.forEach((fund: FundBalance) => {
        valObject[fund.label] = Yup.object({
            cash: Yup.number().required(),
            total: Yup.number().required()
        })
    })

    const BalancesSchema = Yup.object().shape({
        total: Yup.number().required(),
        ...valObject
    })

    let initialValues: any = {
        cash: 0,
        total: 0
    }

    balances.funds.forEach((fund: FundBalance) => {
        initialValues[fund.label] = {
            cash: fund.cash,
            total: fund.capital
        };
    })

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={BalancesSchema}
                onSubmit={(values: any) => {
                    dispatch(updateBalancesReducers(values));
                    onClose();
                }}>
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        setFieldValue,
                        values
                    } = props;

                    let cash = 0;
                    let total = 0;

                    balances.funds.forEach((fund: FundBalance) => {
                        cash = addValues(cash, values[fund.label].cash)
                        total = addValues(total, values[fund.label].total)
                    })

                    if (cash !== values.cash) {
                        setFieldValue('cash', cash)
                    }

                    if (total !== values.total) {
                        setFieldValue('total', total)
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    {balances.funds.map((fund: FundBalance) =>
                                        <Fragment key={fund.label}>
                                            <FormInput
                                                id={`${fund.label}.cash`}
                                                label={`${fund.label} (Cash)`}
                                                fieldType={InputTypes.CURRENCY}
                                                layout={{xs: 6, md: 6, lg: 6}}
                                                size='small'
                                            />
                                            <FormInput
                                                id={`${fund.label}.total`}
                                                label={`${fund.label} (Capital)`}
                                                fieldType={InputTypes.CURRENCY}
                                                layout={{xs: 6, md: 6, lg: 6}}
                                                size='small'
                                            />
                                        </Fragment>
                                    )}
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <FormInput
                                        id='cash'
                                        label='Cash Total'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        size='small'
                                        disabled
                                    />
                                    <FormInput
                                        id='total'
                                        label='Capital'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        size='small'
                                        disabled
                                    />
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Confirm Balances
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}