// Packages
import {createAsyncThunk} from "@reduxjs/toolkit";
// Local Imports
import {multipleAsyncGetRequest} from "../apiUtils";
import {addValues} from "../../utils/mathUtil";
import {generatePeriods} from "../../utils/DateUtils";
import {checkWIPLoans, formatHoldings} from "./calculationUtils";
// Types
import {DealInclusion, SaveStatus} from "../../types/capitalBudgetEnums";
import {
    AccountBalance,
    CalculationLoanType,
    CapitalTransaction,
    FundBalance,
    MCPFund,
    OtherTransaction,
    Scenario,
    ScenarioDataSet,
} from "../../types/capitalBudgetTypes";
// Store
import {RootState} from "../store";
import {retrievePortfolioLoansForCalc, retrieveWIPLoansForCalc} from "./selectors";
import {retrieveCapitalTransactions} from "./selectors/capitalSelectors";
import {retrieveOtherTransactions} from "./selectors/otherTransactionSelector";
import {addNotification} from "../notifications/notificationSlice";
import calculateBudget from "./calculationUtils/calculateBudget";

// Retrieve Capital Budget Data
export const getCapitalBudgetData = createAsyncThunk('capitalBudget/getData', async (scenario: Scenario, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;

        const scenarioSaving = state.scenarios.saving;

        if (!scenarioSaving) {
            // RETRIEVE DATA
            const capitalBudgetData: any = await multipleAsyncGetRequest({
                capital: `scenarios/${scenario.id}?data=capital`,
                otherTransactions: `scenarios/${scenario.id}?data=otherTransactions`,
                newDeals: `scenarios/${scenario.id}?data=newDeal`,
                portfolioChanges: `scenarios/${scenario.id}?data=portfolioChanges`,
                selldownRepayments: `scenarios/${scenario.id}?data=selldownRepayment`,
                transfers: `scenarios/${scenario.id}?data=interfundTransfer`,
                funds: `scenarios/${scenario.id}?data=funds`
            });

            let thirdPartyData = await multipleAsyncGetRequest({
                ncino: `external-data/ncino/${scenario.ncinoId}`,
                axcess: `external-data/axcess/${scenario.axcessId}`,
                holding: `external-data/holding/${scenario.holdingId}`,
                ...(scenario.accountBalanceId) ? {accountBalance: `external-data/account-balance/${scenario.accountBalanceId}`} : null
            });

            const {ncino, axcess, holding, accountBalance = null} = thirdPartyData;

            // Format Holdings Data
            const formattedHoldings = formatHoldings(holding.data, capitalBudgetData.funds)
            holding.data = formattedHoldings.fundFilteredHolders;


            capitalBudgetData.funds = formattedHoldings.funds;

            capitalBudgetData.newDeals = checkWIPLoans(scenario.id, ncino.data, capitalBudgetData.newDeals, capitalBudgetData.funds);

            const dataSetDate = [axcess.date, ncino.date, holding.date, ...(accountBalance ? [accountBalance.date] : [])].sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1))[0]

            // Assign Fund Balances if available by query or manual entry
            let fundBalances

            let capital = 0;
            let cash = 0;

            if (accountBalance) {
                fundBalances = capitalBudgetData.funds.map((fund: FundBalance) => {
                    const balance = accountBalance.balances.find((b: AccountBalance) => b.fund === fund.label);

                    fund.capital = (balance) ? balance.capitalAvailability : 0;
                    fund.cash = (balance) ? balance.cash : 0;

                    capital = addValues(capital, fund.capital);
                    cash = addValues(cash, fund.cash);

                    return fund;
                })
            } else {
                fundBalances = capitalBudgetData.funds.map((fund: any) => {
                    // CASH previously did not exist total is the capital available balance
                    fund.capital = fund.total;
                    delete fund.total

                    capital = addValues(capital, fund.capital);
                    cash = addValues(cash, fund.cash)

                    return fund
                })
            }

            capitalBudgetData.accountBalances = {
                funds: fundBalances,
                cash,
                capital
            }

            return {
                scenarioData: capitalBudgetData,
                thirdPartyData: {
                    accountBalance,
                    axcess,
                    ncino,
                    holding,
                    dateOfDataSet: new Date(dataSetDate).getTime()
                }
            }
        } else {
            return {
                thirdPartyData: null,
                scenarioData: null,
            }
        }
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred retrieving scenario';
        }
        console.log(error)
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Clear all Capital Budget Data
export const clearCapitalBudgetScenario = createAsyncThunk('capitalBudget/clearScenario', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (state.capitalBudget.scenarioData && state.capitalBudget.thirdPartyData) {
        const scenarioData: ScenarioDataSet = {
            accountBalances: state.capitalBudget.scenarioData.accountBalances,
            capital: [],
            otherTransactions: [],
            funds: state.capitalBudget.scenarioData.funds,
            newDeals: [],
            portfolioChanges: [],
            selldownRepayments: [],
            transfers: []
        }
        let fundsAllocation: any = {}
        scenarioData.funds.forEach((fund: MCPFund) => {
            fundsAllocation[fund.id] = 0;
        })

        state.capitalBudget.thirdPartyData.ncino.data.forEach((wipLoan) => {
            scenarioData.newDeals.push({
                id: wipLoan.id,
                wipLoanId: wipLoan.id,
                ncinoId: wipLoan.ncino_id,
                include: DealInclusion.UNASSIGNED,
                allocation: {
                    ...fundsAllocation,
                    // AUTOMATIC FULL ALLOCATION TO DASLF
                    DASLF: (wipLoan.llc_bi_amount) ? wipLoan.llc_bi_amount : 0
                },
                lastModified: wipLoan.last_modified,
                status: SaveStatus.NEW,
                amendedCloseDate: null,
                expectedClose: wipLoan.llc_bi_close_date,
                name: wipLoan.name,
                commitment: wipLoan.llc_bi_amount,
                commitmentOffset: null
            })
        })
        await thunkAPI.dispatch(calculateCapitalBudget())

        thunkAPI.dispatch(addNotification('Scenario Data reset.', 'success'))


        return scenarioData
    } else {
        thunkAPI.dispatch(addNotification('Data not available or loaded to calculate.', 'error'))
        return thunkAPI.rejectWithValue('Data not available or loaded to calculate.')
    }
})

// Calculate Capital Budget
export const calculateCapitalBudget = createAsyncThunk('capitalBudget/calculate', async (_, thunkAPI) => {
    const time = new Date().getTime();
    const state = thunkAPI.getState() as RootState;
    if (!state.capitalBudget.thirdPartyData || !state.capitalBudget.scenarioData) {
        thunkAPI.dispatch(addNotification('Data not available or loaded to calculate.', 'error'))
        return {}
    }

    // Retrieval of data for calculations
    const fund = state.capitalBudget.misc.fund;
    const portfolios: Array<CalculationLoanType> = retrievePortfolioLoansForCalc(state, fund);
    const newLoans: Array<CalculationLoanType> = retrieveWIPLoansForCalc(state, fund);
    const capital: Array<CapitalTransaction> = retrieveCapitalTransactions(state);
    const otherTransactions: Array<OtherTransaction> = retrieveOtherTransactions(state);
    const budgetDate: number | Date = state.capitalBudget.thirdPartyData.dateOfDataSet;

    if (!state.scenarios.scenario) return {weeks: [], months: []}

    let periods = generatePeriods(state.capitalBudget.misc.weeks, state.capitalBudget.misc.months, state.scenarios.scenario.reportAsOf);

    const {
        base,
        weeks,
        months
    } = calculateBudget(state.capitalBudget.scenarioData, periods, fund, portfolios, newLoans, capital, otherTransactions, budgetDate);

    if (process.env.REACT_APP_ENV === 'LOCAL_DEV') {
        console.log('Calc time:', new Date().getTime() - time)
    }
    return {base, weeks, months}
})

// Calculate Capital Budget
export const calculateBaseCapitalBudget = createAsyncThunk('capitalBudget/calculateRaw', async (_, thunkAPI) => {
    const time = new Date().getTime();
    const state = thunkAPI.getState() as RootState;
    if (!state.capitalBudget.thirdPartyData || !state.capitalBudget.scenarioData) {
        thunkAPI.dispatch(addNotification('Data not available or loaded to calculate.', 'error'))
        return {}
    }

    // Retrieval of data for calculations
    const fund = state.capitalBudget.misc.fund;
    const portfolios: Array<CalculationLoanType> = retrievePortfolioLoansForCalc(state, fund, true);
    const newLoans: Array<CalculationLoanType> = retrieveWIPLoansForCalc(state, fund, true);
    const capital: Array<CapitalTransaction> = retrieveCapitalTransactions(state, true);
    const otherTransactions: Array<OtherTransaction> = retrieveOtherTransactions(state, true);
    const budgetDate: number | Date = state.capitalBudget.thirdPartyData.dateOfDataSet;

    if (!state.scenarios.scenario) return {weeks: [], months: []}

    let periods = generatePeriods(state.capitalBudget.misc.weeks, state.capitalBudget.misc.months, state.scenarios.scenario.reportAsOf);

    const {
        base,
        weeks,
        months
    } = calculateBudget(state.capitalBudget.scenarioData, periods, fund, portfolios, newLoans, capital, otherTransactions, budgetDate);

    if (process.env.REACT_APP_ENV === 'LOCAL_DEV') {
        console.log('Calc time:', new Date().getTime() - time)
    }
    return {base, weeks, months}
})