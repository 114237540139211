import {RootState} from "../../store";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {createSelector} from "@reduxjs/toolkit";
import {Adjustment} from "../../../types/valuationModelTypes";

// Select Adjustment Fees for Tranche, If fund is provided also filter for funds.
export const selectAdjustmentFeesForTranche = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.adjustments,
    (_state: RootState, trancheId: number, fund?: string) => ({trancheId, fund}),
    (adjustments, {trancheId, fund}): Array<Adjustment> => {
        const trancheAdjustments = adjustments[trancheId];

        if (trancheAdjustments) {
            if (fund) {
                return trancheAdjustments[fund].filter(a => a.status !== SaveStatus.REMOVED);
            } else {
                let allAdjustments = []
                for(const f in trancheAdjustments) {
                    allAdjustments.push(...trancheAdjustments[f])
                }
                return allAdjustments.filter(a => a.status !== SaveStatus.REMOVED);
            }
        } else {
            return []
        }
    }
)

// export const selectAdjustmentFeeTypesForTranche = createSelector(
//     (state: RootState) => state.valuationModel.valuationModelData.adjustments,
//     (_state: RootState, trancheId: number, fund?: string) => ({trancheId, fund}),
//     (adjustments, {trancheId}): Array<AdjustmentType> => {
//         const trancheAdjustments = adjustments[trancheId];
//
//         if (trancheAdjustments) {
//             let filteredAdjustmentTypes: Array<AdjustmentType> = [];
//             for(const f in trancheAdjustments) {
//                 trancheAdjustments[f].forEach(a => {
//                     if (a.status !== SaveStatus.REMOVED) {
//                         filteredAdjustmentTypes.push(a.transactionType);
//                     }
//                 })
//             }
//             return filteredAdjustmentTypes;
//         } else {
//             return []
//         }
//     }
// )


// Retrieve all scheduled Adjustments
export const getScheduledAdjustments = createSelector(
    (state: RootState) => (state.valuationModel.valuationModelData.manualAccrual),
    (_state: RootState, trancheId?: number) => trancheId,
    (manualAccrual, trancheId) => {
        const manualAccruals = manualAccrual.filter(accrual => accrual.status !== SaveStatus.REMOVED)
        if (trancheId) {
            return manualAccruals.filter(accruals => accruals.trancheId === trancheId);
        }
        return manualAccruals
    }
)