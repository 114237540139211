import { useState } from 'react';
// MUI
import { Grid, Container, Typography } from '@mui/material';
// components
import {HorizontalTable, HorizontalTableColumn, HorizontalTableRow, Page} from '../../components';
import {BudgetForecastGraphs} from "../../sections/dashboard/capitalBudget/budgetForecast";
import { SelectionBar } from './SelectionBar';
// Store
import { useAppSelector } from '../../store/store'
import {budgetForecastSelector} from "../../store/capitalBudget/selectors";


// Capital Budget Summary App Page
export default function BudgetForecast() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const {data, rows} = useAppSelector(state => budgetForecastSelector(state));
    const [column, selectCol] = useState<HorizontalTableColumn | null>(null);
    const [row, selectRow] = useState<HorizontalTableRow | null>(null);

    return (
        <Page title="Metrics - Budget Forecast">
            <Container maxWidth={false} sx={{ maxWidth: '100%' }}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Typography variant="h4" sx={{ mb: 3, color: 'primary.main' }}>
                        Budget Forecast
                    </Typography>
                    <SelectionBar />
                </Grid>
                <Grid container sx={{ height: 0.95, width: '100%' }}>
                    <Grid item xs={12} md={12} lg={12}>
                        <HorizontalTable
                            rows={rows}
                            data={(data) ? data : []}
                            loading={dataLoading}

                            selectedCol={column}
                            selectedRow={row}

                            colSelect={selectCol}
                            rowSelect={selectRow}

                            selectableCell
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <BudgetForecastGraphs />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}