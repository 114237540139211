import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {SelldownRepayment} from "../../../types/capitalBudgetTypes";
import {checkObjectChanged} from "../../../utils/generalUtils";

// Reducer Related to Capital Budget - Selldown and Partial Repayments (Interfund Transfers)
const selldownRepaymentReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Add selldown repayment
    addSellRepTransaction: {
        prepare(newTransaction: any) {
            return {
                payload: {
                    ...newTransaction,
                    id: nanoid()
                }
            }
        },
        reducer(state, action: PayloadAction<any>) {
            const newTran = action.payload;
            if (state.scenarioData) {
                const fund = state.scenarioData.funds.find(f => f.label === newTran.fund);
                const index = state.scenarioData.selldownRepayments.findIndex(t => t.trancheId === newTran.trancheId && t.fund === newTran.fund && t.date === newTran.date);
                if (index === -1 && fund) {
                    newTran.fundId = fund.id;
                    newTran.status = SaveStatus.NEW;
                    state.scenarioData.selldownRepayments = [...state.scenarioData.selldownRepayments, newTran];
                }
            }
        },
    },
    // Update Selldown Repayment
    updateSelldownRepayment: (state, action: PayloadAction<SelldownRepayment>) => {
        if (state.scenarioData) {
            const index = state.scenarioData.selldownRepayments.findIndex(d => d.id === action.payload.id);
            if (index !== -1) {
                if (action.payload.status === SaveStatus.EDITED) {
                    let changed = false;
                    let existing = state.scenarioData.selldownRepayments[index];
                    if (existing.date !== action.payload.date) changed = true;
                    changed = (checkObjectChanged(existing, action.payload, ['amount']) ? true : changed);
                    if (changed) {
                        action.payload.previous = (!existing.previous) ? existing : existing.previous;
                        state.scenarioData.selldownRepayments[index] = action.payload;
                    }
                }
            }
        }
    },
    // Remove Selldown Repayment
    removeSelldownRepayment: (state, action: PayloadAction<string>) => {
        if (state.scenarioData) {
            const index = state.scenarioData.selldownRepayments.findIndex(d => d.id === action.payload);
            if (index !== -1) {
                if (state.scenarioData.selldownRepayments[index].status !== SaveStatus.NEW) {
                    state.scenarioData.selldownRepayments[index].status = SaveStatus.REMOVED;
                } else {
                    const transactions = state.scenarioData.selldownRepayments;
                    transactions.splice(index, 1);
                    state.scenarioData.selldownRepayments = transactions;
                }
            }
        }
    }
}

export default selldownRepaymentReducer;