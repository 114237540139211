// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
// Local Imports
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {addValues} from "../../../utils/mathUtil";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import _ from "lodash";

// Reducers Related to Capital Budget - Capital
const accountBalancesReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Update Account BalancesS
    updateBalancesReducers: (state, action) => {
        // Check the values = total
        if (state.scenarioData && state.scenarioData.accountBalances) {

            let cash = 0;
            let total = 0;

            state.scenarioData.accountBalances.funds.forEach(fund => {
                cash = addValues(cash, action.payload[`${fund.label}`].cash)
                total = addValues(total, action.payload[`${fund.label}`].total)
            })

            state.scenarioData.accountBalances.cash = cash;
            state.scenarioData.accountBalances.capital = total;

            state.scenarioData.accountBalances.status = SaveStatus.EDITED

            const updatedFunds = _.cloneDeep(state.scenarioData.accountBalances.funds);

           updatedFunds.forEach((fund, f) => {
               updatedFunds[f].cash = action.payload[`${fund.label}`].cash;
               updatedFunds[f].capital = action.payload[`${fund.label}`].total;
           })

            state.scenarioData.accountBalances.funds = updatedFunds;

        }
    },
}

export default accountBalancesReducer;