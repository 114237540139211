// @mui
import {Container, Typography, Button, Box, styled} from '@mui/material';
import {
    ConstructionOutlined as UnderConstructionIcon
} from "@mui/icons-material";
// components
import { Page } from '../../components';
import {Link as RouterLink} from "react-router-dom";

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0)
}));


// ----------------------------------------------------------------------

export default function Summary() {
    return (
        <Page title="Dashboard" >
            <Container maxWidth={false}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    S-Curve - ARM
                </Typography>

                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" paragraph>
                        Under Construction...
                    </Typography>

                    <UnderConstructionIcon sx={{fontSize: 200, color: 'warning.main'}}/>

                    <Box
                        component="img"
                        src="/static/logo_blue.png"
                        sx={{ mx: 'auto', my: { xs: 5, sm: 5 } }}
                    />

                    <Button to="/" size="large" variant="contained" component={RouterLink}>
                        Go to Home
                    </Button>
                </ContentStyle>

            </Container>
        </Page>
    );
}
