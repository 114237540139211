import {convertRatingToValue} from "./CapitalBudgetUtils";
import {Adjustment, AxcessLoanCompare, Curve, RulesSet, sCurveOverride, Valuation} from "../types/valuationModelTypes";
import {checkDateBefore, checkDateBetween, checkDateSame, daysBetweenDates} from "./DateUtils";
import {addDays} from "date-fns";
import {addArrayValues, addValues} from "./mathUtil";
import {AdjustmentType} from "../types/valuationModelEnums";
import {SaveStatus} from "../types/capitalBudgetEnums";
import {nanoid} from "@reduxjs/toolkit";
import {determineDefaultSCurve} from "./valuationUtils";

/**
 * Determines a Fair value based on curve tenor and rating
 * @param curve
 * @param tenor
 * @param rating
 */
export function determineFairValueBps(curve: Curve, tenor: number, rating: string): number | null {
    let mcpNumericRating = convertRatingToValue(rating);

    if (!mcpNumericRating) return null;

    let fairValue;

    try {
        let ratingValues = curve.curve[`${mcpNumericRating}`];
        if (!ratingValues) { // Set to either the upper or lower rating if the rating does not exist in the matrix
            // Find highest and lowest rating
            let highestRating = parseInt(Object.keys(curve.curve)[0]);
            let lowestRating = parseInt(Object.keys(curve.curve)[0]);
            for (const key in curve.curve) {
                if (parseInt(key) > lowestRating) {
                    lowestRating = parseInt(key);
                }
                if (parseInt(key) < highestRating) {
                    highestRating = parseInt(key);
                }
            }
            if (mcpNumericRating < highestRating) {
                mcpNumericRating = highestRating;
            } else if (mcpNumericRating > lowestRating) {
                mcpNumericRating = lowestRating;
            } else {
                return null;
            }
            ratingValues = curve.curve[`${mcpNumericRating}`];
        }

        if (tenor > 1) {
            // Retrieve the upper and base value used to calculate then used to determine final amount
            const baseValue = ratingValues[Math.floor(tenor) - 1];
            // Handle if base value cannot be found (tenor exceeds matrix max). Defaults to max value in matrix
            if (!baseValue) {
                return ratingValues[ratingValues.length - 1];
            }
            const upperValue = ratingValues[Math.ceil(tenor) - 1];
            // Return the floor if there is no upper. I.e. tenor exceeds the matrix max
            if (!upperValue) {
                return baseValue;
            }

            // Calculates the partial value, amount between rounded tenor
            const partial = Math.round((tenor - Math.floor(tenor)) * 100);
            const partialValue = ((upperValue - baseValue) / 100) * partial;

            fairValue = baseValue + partialValue;
        } else {
            // Defaults to 1 year tenor fair value amount if less than 1 year
            fairValue = ratingValues[0];
        }
    } catch (e) {
        return null;
    }

    return fairValue;
}

// Recall existing published valuation model
export function recallValMod(tranche: AxcessLoanCompare, valuation: Valuation, adjustments: Array<Adjustment>, bbsw: number, fairValueBps: number): Valuation {
    // If write down exists, zero out the valuation
    if (adjustments.some(a => a.transactionType === AdjustmentType.WRITE_DOWN)) {
        return {
            ...valuation,
            closingDiscount: 0,
            appliedPl: 0,
            carry: 0,
            fairValue: 0,
            cappedFairValue: 0,
            yieldToMaturity: 0,
            dailyLossGain: 0,
            adjustedOpeningBalance: 0,
            netRepaymentBasedPL: 0,
            openingDiscountValuationDate: 0,
            previousAccruals: 0,
            postRepaymentAdjustedDiscount: 0
        }
    }

    // Calculate previous day accruals, if there is more than 1 day since previous date and there is an existing Accrual Adjustment
    const appliedPLExists = adjustments.some(a => a.transactionType === AdjustmentType.PL_ACCRUAL);
    let valuationDays = daysBetweenDates(new Date(valuation.previousDate), new Date(valuation.valuationDate)) - 1; // Subtract 1 as we don't include valuation date
    let previousAccruedPL = 0;
    if (valuationDays > 0 && appliedPLExists) {
        // If maturity falls over the off period, need to calculate the correct number of valuation days
        if (checkDateBetween(tranche.maturity, valuation.previousDate, valuation.valuationDate) && !checkDateSame(tranche.maturity, valuation.valuationDate)) {
            valuationDays = daysBetweenDates(new Date(valuation.previousDate), new Date(tranche.maturity)) - 1; // Subtract 1 as we don't include maturity date
        }
        // If there is a  change in maturity today, we should use the previous maturity for previous accruals
        if (tranche.change.maturity) {
            previousAccruedPL = calculatePreviousDaysAccruedPL(valuationDays, valuation.previousDate, new Date(tranche.change.maturity), valuation.openingDiscount, 1, false);
        } else {
            // Calculate if maturity has not changed today
            previousAccruedPL = calculatePreviousDaysAccruedPL(valuationDays, valuation.previousDate, tranche.maturity, valuation.openingDiscount, 1, false);
        }
    }

    // If NEW_ASSET adjustment exists, assign amount as opening discount. Or, if there is previous day accruals, add this value
    const totalUpfrontFees = adjustments.reduce((total, fee) => {
        if (fee.transactionType === AdjustmentType.NEW_ASSET && fee.status !== SaveStatus.REMOVED) {
            total = addValues(total, fee.amount);
        }
        return total;
    }, 0);
    let openingDiscount = totalUpfrontFees || valuation.openingDiscount;
    const openingDiscountValuationDate = (totalUpfrontFees) ? totalUpfrontFees : addValues(openingDiscount, previousAccruedPL);

    // Calculate Adjusted Opening Balance
    const adjustedOpeningBalance = calculateAdjustedOpeningBalance(openingDiscount, adjustments);
    const adjustedOpeningBalancePlusPrevAccruals = addValues(previousAccruedPL, adjustedOpeningBalance);

    // Recall Net Repayment Based PL
    let netRepaymentBasedPL = 0;
    const adjustment = adjustments.find(a => a.transactionType === AdjustmentType.NET_REPAYMENT_PL);
    if (adjustment) {
        netRepaymentBasedPL = adjustment.amount;
    }

    // Get Post Repayment Based Adjusted Discount
    const postRepaymentAdjustedDiscount = addArrayValues([adjustedOpeningBalancePlusPrevAccruals, netRepaymentBasedPL]);

    // This value should be 0 unless type is not Equity or Other
    let dailyLossGain = 0;
    if (!['Equity', 'Other'].includes(tranche.tranche_type)) {
        // Calculate Daily Loss or Gain (ITM or OTM)
        dailyLossGain = calculateITMorOTM(valuation.commitment, valuation.carry, valuation.cappedFairValue);
    }

    return {
        ...valuation,
        adjustedOpeningBalance: adjustedOpeningBalance,
        netRepaymentBasedPL: netRepaymentBasedPL,
        dailyLossGain: dailyLossGain,
        openingDiscountValuationDate: openingDiscountValuationDate,
        previousAccruals: previousAccruedPL,
        postRepaymentAdjustedDiscount: postRepaymentAdjustedDiscount,
        fairValueBps: fairValueBps
    }
}

// Main function for calculating valuation model
export function calculateValMod(tranche: AxcessLoanCompare, valuation: Valuation, adjustments: Array<Adjustment>, bbsw: number, fairValueBps: number, rules: RulesSet, initialRun?: boolean): {valuation: Valuation, adjustments: Array<Adjustment>}  {
    // If write down exists, zero out the valuation
    if (adjustments.some(a => a.transactionType === AdjustmentType.WRITE_DOWN && a.status !== SaveStatus.REMOVED)) {
        const filteredAdjustments: Array<Adjustment> = [];
        adjustments.forEach(a => {
            if (a.transactionType === AdjustmentType.WRITE_DOWN && a.status !== SaveStatus.REMOVED) {
                filteredAdjustments.push(a);
            } else if (a.status !== SaveStatus.NEW) {
                a.status = SaveStatus.REMOVED;
                filteredAdjustments.push(a);
            }
        })
        return {
            valuation: {
                ...valuation,
                closingDiscount: 0,
                appliedPl: 0,
                carry: 0,
                fairValue: 0,
                cappedFairValue: 0,
                yieldToMaturity: 0,
                dailyLossGain: 0,
                adjustedOpeningBalance: 0,
                netRepaymentBasedPL: 0,
                openingDiscountValuationDate: 0,
                previousAccruals: 0,
                postRepaymentAdjustedDiscount: 0,
                status: (valuation.status !== SaveStatus.NEW) ? SaveStatus.EDITED : SaveStatus.NEW
            },
            adjustments: filteredAdjustments
        }
    }

    // Check for Accrual Override
    const accrualOverride = rules.accrualOverrides.some(r => (r.trancheId === tranche.tranche_id && r.fund === valuation.fund));

    // Calculate previous day accruals before valuation date (if days between are > 1)
    let valuationDays = daysBetweenDates(new Date(valuation.previousDate), new Date(valuation.valuationDate)) - 1; // Subtract 1 as we don't include valuation date
    let previousAccruedPL = 0;
    if (valuationDays > 0) {
        // If maturity falls over the off period, need to calculate the correct number of valuation days
        if (checkDateBetween(tranche.maturity, valuation.previousDate, valuation.valuationDate) && !checkDateSame(tranche.maturity, valuation.valuationDate)) {
            valuationDays = daysBetweenDates(new Date(valuation.previousDate), new Date(tranche.maturity)) - 1; // Subtract 1 as we don't include maturity date
        }
        // If there is a  change in maturity today, we should use the previous maturity for previous accruals
        if (tranche.change.maturity) {
            previousAccruedPL = calculatePreviousDaysAccruedPL(valuationDays, valuation.previousDate, new Date(tranche.change.maturity), valuation.openingDiscount, 1, accrualOverride);
        } else {
            // Calculate if maturity has not changed today
            previousAccruedPL = calculatePreviousDaysAccruedPL(valuationDays, valuation.previousDate, tranche.maturity, valuation.openingDiscount, 1, accrualOverride);
        }
    }

    // If NEW_ASSET adjustment exists, assign amount as opening discount. Or, if there is previous day accruals, add this value
    const totalUpfrontFees = adjustments.reduce((total, fee) => {
        if (fee.transactionType === AdjustmentType.NEW_ASSET && fee.status !== SaveStatus.REMOVED) {
            total = addValues(total, fee.amount);
        }
        return total;
    }, 0);
    let openingDiscount = totalUpfrontFees || valuation.openingDiscount;
    const openingDiscountValuationDate = (totalUpfrontFees) ? totalUpfrontFees : addValues(openingDiscount, previousAccruedPL);

    // Automatic adjustments for asset sale has been suspended as this is usually a cornerstone case and sometimes the rate will differ.
    //
    // Auto generate a new ASSET_SALE adjustment if there is a fund transfer assumed
    // const manualAssetSaleIndex = adjustments.findIndex(a => a.transactionType === AdjustmentType.ASSET_SALE && a.status !== SaveStatus.REMOVED && a.isManual);
    // const autoAssetSaleIndex = adjustments.findIndex(a => a.transactionType === AdjustmentType.ASSET_SALE && a.status !== SaveStatus.REMOVED && !a.isManual);
    // if (manualAssetSaleIndex === -1 && autoAssetSaleIndex === -1 && initialRun) {
    //     if (valuation.previousCommitment > valuation.commitment && Math.abs(openingDiscountValuationDate) > 0) {
    //         if (tranche.assumption === AssumptionType.FUND_TRANSFER) {
    //             const commitmentDiffPct = addValues(valuation.previousCommitment, -valuation.commitment)/valuation.previousCommitment
    //             const feesPaid = commitmentDiffPct * openingDiscountValuationDate * -1;
    //             adjustments.push({
    //                 id: nanoid(),
    //                 trancheId: tranche.tranche_id,
    //                 valuationDate: valuation.valuationDate,
    //                 fund: valuation.fund,
    //                 transactionType: AdjustmentType.ASSET_SALE,
    //                 amount: feesPaid,
    //                 isManual: false,
    //                 comment: 'Auto generated due to commitment decrease and assumption FUND_TRANSFER.',
    //                 status: SaveStatus.NEW
    //             })
    //         }
    //     }
    // } else { // Update auto ASSET_SALE or remove if manual exists
    //     if (manualAssetSaleIndex > -1 && autoAssetSaleIndex > -1) {
    //         if (adjustments[autoAssetSaleIndex].status !== SaveStatus.NEW) {
    //             adjustments[autoAssetSaleIndex].status = SaveStatus.REMOVED;
    //         } else {
    //             adjustments.splice(autoAssetSaleIndex, 1);
    //         }
    //     } else if (autoAssetSaleIndex > -1 && manualAssetSaleIndex === -1) {
    //         const commitmentDiffPct = addValues(valuation.previousCommitment, -valuation.commitment)/valuation.previousCommitment
    //         adjustments[autoAssetSaleIndex].amount = commitmentDiffPct * openingDiscountValuationDate;
    //     }
    // }

    // Calculate Adjusted Opening Balance
    const adjustedOpeningBalance = calculateAdjustedOpeningBalance(openingDiscount, adjustments);
    const adjustedOpeningBalancePlusPrevAccruals = addValues(previousAccruedPL, adjustedOpeningBalance);

    // Check if there is an Applied PL Override
    const appliedPLOverride = adjustments.find(a => a.transactionType === AdjustmentType.APPLIED_PL_OVERRIDE && a.status !== SaveStatus.REMOVED);

    let postRepaymentAdjustedDiscount = adjustedOpeningBalancePlusPrevAccruals;
    let netRepaymentBasedPL = 0;
    let modifiedAdjustments = adjustments;
    let appliedPL;
    // If there is an override to Applied PL, set
    if (appliedPLOverride) {
        appliedPL = appliedPLOverride.amount;
        let updated: Array<Adjustment> = [];
        modifiedAdjustments.forEach(a => {
            if (a.transactionType !== AdjustmentType.PL_ACCRUAL && a.transactionType !== AdjustmentType.NET_REPAYMENT_PL) {
                updated.push(a); // Filter adjustments that coexist with Applied PL Override
            } else {
                if (a.status !== SaveStatus.NEW) { // Tag any stored adjustments for removal
                    a.status = SaveStatus.REMOVED;
                    updated.push(a);
                }
            }
        })
        modifiedAdjustments = updated;
    } else {
        // Proceed to calculate Net Repayment Based Profit and Loss if there is no override adjustment
        // Auto adjustments are created here
        const ret = calculateNetRepaymentBasedPL(tranche, valuation, openingDiscountValuationDate, adjustments, openingDiscount, initialRun);
        netRepaymentBasedPL = ret.netRepaymentBasedPL;
        modifiedAdjustments = ret.modifiedAdjustments;
        postRepaymentAdjustedDiscount = addArrayValues([adjustedOpeningBalancePlusPrevAccruals, netRepaymentBasedPL]);

        // Calculate Accrued Profit and Loss
        let accruedPL = addValues(calculateAccruedPL(valuation.valuationDate, tranche.maturity, postRepaymentAdjustedDiscount, 1, accrualOverride), previousAccruedPL);

        // Create or update Accrued PL adjustment
        const accrualAdjustmentIndex = modifiedAdjustments.findIndex(a => a.transactionType === AdjustmentType.PL_ACCRUAL && a.status !== SaveStatus.REMOVED);
        if (accrualAdjustmentIndex > -1) {
            if (accrualOverride || accruedPL === 0) { // Remove the accrual if an override is created or if calculated accrual is now 0
                if (modifiedAdjustments[accrualAdjustmentIndex].status === SaveStatus.NEW) {
                    modifiedAdjustments.splice(accrualAdjustmentIndex, 1);
                } else {
                    modifiedAdjustments[accrualAdjustmentIndex].status = SaveStatus.REMOVED;
                }
            } else { // Update the existing accrual or remove if accrual amount is now 0
                if (modifiedAdjustments[accrualAdjustmentIndex].amount !== accruedPL) {
                    modifiedAdjustments[accrualAdjustmentIndex].amount = accruedPL;
                    if (modifiedAdjustments[accrualAdjustmentIndex].status !== SaveStatus.NEW) {
                        modifiedAdjustments[accrualAdjustmentIndex].status = SaveStatus.EDITED;
                    }
                }
            }
        } else if (!accrualOverride && accruedPL !== 0) { // Create a new accrual if there is no override and there is a figure for Accrued PL
            modifiedAdjustments.push({
                id: nanoid(),
                trancheId: tranche.tranche_id,
                valuationDate: valuation.valuationDate,
                fund: valuation.fund,
                transactionType: AdjustmentType.PL_ACCRUAL,
                amount: accruedPL,
                isManual: false,
                comment: 'PL Accrual automatically generated - no override detected.',
                status: SaveStatus.NEW
            })
        }

        // Calculate Applied Profit and Loss
        appliedPL = calculateAppliedPL(adjustedOpeningBalance, netRepaymentBasedPL, accruedPL, 1);
    }

    // Calculate Closing Discount
    const closingDiscount = calculateClosingDiscount(adjustedOpeningBalance, appliedPL);

    // Calculate Carry
    const carry = calculateCarry(valuation.commitment, closingDiscount);

    // Calculate Coupon
    const couponRate = calculateCouponRate(tranche.drawn_margin, tranche.facility_fee_type, tranche.facility_fee_rate_p, tranche.base_rate_floor || 0, bbsw);
    const coupon = (couponRate * 100) / 4;

    // Calculate YTM
    const ytm = calculatedYTM(valuation.valuationDate, tranche.maturity, carry, coupon);

    // The following values should be 0 unless the loan is not Equity or Other
    let fairValue = 0;
    let cappedFairValue = 0
    let dailyLossGain = 0;
    if (!['Equity', 'Other'].includes(tranche.tranche_type)) {
        // Check for Call Protected rules and calculate Fair Value
        const callProtected = rules.callProtected.some(r => (r.trancheId === tranche.tranche_id));
        fairValue = calculateFairValue(valuation.valuationDate, tranche.maturity, fairValueBps, coupon);

        cappedFairValue = fairValue;
        // If FV greater than 100 and not called protected, restrict FV to 100
        if (fairValue > 100 && !callProtected) {
            cappedFairValue = 100;
        }

        // Calculate Daily Loss or Gain (ITM or OTM)
        dailyLossGain = calculateITMorOTM(valuation.commitment, carry, cappedFairValue);
    }

    // Edit valuation object and return
    valuation.closingDiscount = closingDiscount;
    valuation.appliedPl = appliedPL;
    valuation.carry = carry;
    valuation.fairValue = fairValue;
    valuation.cappedFairValue = cappedFairValue;
    valuation.yieldToMaturity = ytm;
    valuation.adjustedOpeningBalance = adjustedOpeningBalance;
    valuation.netRepaymentBasedPL = netRepaymentBasedPL;
    valuation.openingDiscountValuationDate = openingDiscountValuationDate;
    valuation.dailyLossGain = dailyLossGain;
    valuation.previousAccruals = previousAccruedPL;
    valuation.postRepaymentAdjustedDiscount = postRepaymentAdjustedDiscount;
    valuation.fairValueBps = fairValueBps;
    if (valuation.status !== SaveStatus.NEW) {
        valuation.status = SaveStatus.EDITED;
    }

    // Return modified valuation and adjustments
    return {valuation: valuation, adjustments: modifiedAdjustments};
}

// Add or deduct fees from Opening Discount
export function calculateAdjustedOpeningBalance(openingDiscount: number, adjustments: Array<Adjustment>): number {
    let adjustedOpeningBalance = openingDiscount;
    adjustments.forEach(fee => {
        if (fee.status !== SaveStatus.REMOVED) {
            if (fee.transactionType === AdjustmentType.ASSET_PURCHASE || fee.transactionType === AdjustmentType.ASSET_SALE) {
                adjustedOpeningBalance = addValues(adjustedOpeningBalance, fee.amount);
            } else if (fee.transactionType === AdjustmentType.FEE_RECEIVED) {
                adjustedOpeningBalance = addValues(adjustedOpeningBalance, -Math.abs(fee.amount));
            }
        }
    })
    return adjustedOpeningBalance;
}

// Calculate loan (Price) Fair Value
function calculateFairValue(_valuationDate: Date | number, _maturity: Date | number, fairValueBps: number, coupon: number): number {
    // Determine coupon period - model uses quarterly
    const maturity = new Date(_maturity);
    const valuationDate = new Date(_valuationDate);

    // CHECK IF LOAN IS MATURED, IF LOAN IS NOT MATURED USED MATURITY DATE ELSE USE VALUATION DATE + 1
    const newMaturity = (checkDateBefore(maturity, (addDays(valuationDate, 1)))) ? addDays(valuationDate, 1) : maturity;
    const days = daysBetweenDates(valuationDate, newMaturity);

    // CALCULATE NUMBER OF QUARTERS REMAINING
    // const months = days / 30.417;
    // const quarters = months / 3;
    const n = days / 365 * 4

    // Adjust discount rate
    const discountRate = (fairValueBps / 10000) / 4;

    // Calculate Fair Value using bond valuation formula
    // PRICE = (COUPON x (1 - (1 + r)^(-n))/ r) + (PAR VALUE / (1 + r)^n)

    const line1 = addValues(1, discountRate); // (1 + r)
    const line2 = Math.pow(line1, -n); // (1 + r)^(-n)
    const line3 = addValues(1, -line2); // (1 - (1 + r)^(-n))
    const line4 = line3 / discountRate; // (1 - (1 + r)^(-n))/ r)
    const line5 = line4 * coupon; // (COUPON x (1 - (1 + r)^(-n))/ r)
    const line6 = Math.pow(line1, n); // (1 + r)^n)
    const line7 = 100 / line6; // (PAR VALUE / (1 + r)^n)
    return addValues(line5, line7); // (COUPON x (1 - (1 + r)^(-n))/ r) + (PAR VALUE / (1 + r)^n)
}

// Calculate the Carry Value
function calculateCarry(commitment: number | null, closingDiscount: number): number {
    let carry = 0;
    let carryDollarAmount = 0;
    if (commitment) {
        carryDollarAmount = addValues(commitment, closingDiscount);
        carry = carryDollarAmount / commitment;
    }
    return carry * 100;
}

// Calculate the Yield to Maturity
export function calculatedYTM(_valuationDate: Date | number, _maturity: Date | number, carry: number, coupon: number): number {
    // Determine coupon period - model uses quarterly
    const maturity = new Date(_maturity);
    const valuationDate = new Date(_valuationDate);
    if (checkDateSame(maturity, valuationDate) || checkDateBefore(maturity, valuationDate)) {
        return 0; // No YTM if today is maturity or if past maturity
    }

    const days = daysBetweenDates(valuationDate, maturity);

    const n = days / 365 * 4; //TODO May need to introduce Actual/Actual

    // Run YTM calculation
    // YTM = ( C + ((FV - PV) / n) ) / ( (FV + PV) / 2 )
    // WHERE:
    //     - C = Coupon Rate
    //     - FV = Face Value
    //     - PV = Present Value
    //     - n  = number of compounding periods
    const line1 = addValues(100, -carry) / n; // ((FV - PV) / n) )
    const line2 = addValues(coupon, line1); // ( C + ((FV - PV) / n) )
    const line3 = addValues(100, carry) / 2; // ( (FV + PV) / 2 )
    const line4 = line2 / line3; // ( C + ((FV - PV) / n) ) / ( (FV + PV) / 2 )
    return line4 * 4 * 10000; // Convert back to annual
}

// Calculate Net Repayment Based Profit and Loss
// This function handles adjustments: PARTIAL_REPAYMENT, EARLY_REPAYMENT, ASSET_SALE, PAID_MATURED
function calculateNetRepaymentBasedPL(tranche: AxcessLoanCompare, valuation: Valuation, adjustedOpeningBalance: number, adjustments: Array<Adjustment>, openingDiscount: number, initialRun?: boolean): { netRepaymentBasedPL: number, modifiedAdjustments: Array<Adjustment> } {
    try {
        const commitment = valuation.commitment || 0;
        const previousCommitment = valuation.previousCommitment || 0;
        const modifiedAdjustments: Array<Adjustment> = adjustments;
        let netRepaymentBasedPL = 0

        if (previousCommitment > commitment) {
            const commitmentDiff = addValues(commitment, -previousCommitment);
            const commitmentDiffPct = commitmentDiff / previousCommitment;

            // Filter off adjustments
            let manualFeeReceivedAssetSale = 0;
            let autoFeeReceivedAssetSale = 0;
            let autoNetRepaymentBasedPL = 0;

            adjustments.forEach(a => {
                switch (a.transactionType) {
                    case AdjustmentType.ASSET_SALE:
                        if (a.isManual) {
                            manualFeeReceivedAssetSale = addValues(manualFeeReceivedAssetSale, a.amount);
                        } else {
                            autoFeeReceivedAssetSale = addValues(autoFeeReceivedAssetSale, a.amount);
                        }
                        break;
                    case AdjustmentType.NET_REPAYMENT_PL:
                        autoNetRepaymentBasedPL = a.amount;
                        break;
                }
            })

            const lessTradeFees = (manualFeeReceivedAssetSale > 0) ? manualFeeReceivedAssetSale : autoFeeReceivedAssetSale;
            netRepaymentBasedPL = adjustedOpeningBalance * commitmentDiffPct;
            netRepaymentBasedPL = addValues(netRepaymentBasedPL, -lessTradeFees);
            netRepaymentBasedPL = Math.round(netRepaymentBasedPL * 100) / 100;
            if (Math.abs(autoNetRepaymentBasedPL) > 0) { // Update auto generated Net Repayment Based PL Adjustment (if it exists and the values are different)
                if (netRepaymentBasedPL !== autoNetRepaymentBasedPL) {
                    const index = modifiedAdjustments.findIndex(a => a.transactionType === AdjustmentType.NET_REPAYMENT_PL && !a.isManual);
                    if (netRepaymentBasedPL === 0) {
                        modifiedAdjustments.splice(index, 1); // If PL is 0, remove the auto adjustment
                    } else {
                        modifiedAdjustments[index].amount = netRepaymentBasedPL;
                        if (modifiedAdjustments[index].status !== SaveStatus.NEW) {
                            modifiedAdjustments[index].status = SaveStatus.EDITED;
                        }
                    }
                }
            } else { // Auto generate Net Repayment Based PL Adjustment if appropriate assumption exists
                if (tranche.assumption && Math.abs(netRepaymentBasedPL) > 0 && initialRun) {
                    modifiedAdjustments.push({
                        id: nanoid(),
                        trancheId: tranche.tranche_id,
                        valuationDate: valuation.valuationDate,
                        fund: valuation.fund,
                        transactionType: AdjustmentType.NET_REPAYMENT_PL,
                        amount: netRepaymentBasedPL,
                        isManual: false,
                        comment: 'Auto Generated due to commitment decrease and assumption ' + tranche.assumption + '.',
                        status: SaveStatus.NEW
                    })
                }
            }

        }
        return {
            netRepaymentBasedPL: netRepaymentBasedPL,
            modifiedAdjustments
        };
    } catch (e) {
        console.log(e);
        return {
            netRepaymentBasedPL: 0,
            modifiedAdjustments: []
        };
    }
}

function calculatePreviousDaysAccruedPL(valuationDays: number, _previousDate: Date | number, _maturity: Date | number, openingDiscount: number, threshold: number, accrualOverride: boolean): number {
    if (accrualOverride || openingDiscount === 0) return 0;

    // Set up number of days
    const maturity = new Date(_maturity);
    const previousDate = new Date(_previousDate);
    let days: number;

    days = daysBetweenDates(addDays(previousDate, 1) , maturity);
    if (days < 0) { // In some cases, this could get calculated past maturity. In which case, we would not calculate accrual
        return 0;
    }
    const dailyPL = (openingDiscount) / days;

    // Add accruals for days over the weekend or days to maturity if asset matures over the weekend
    const accruedPl = dailyPL * valuationDays

    if (Math.abs(openingDiscount) < threshold) {
        return -openingDiscount;
    } else {
        return -accruedPl;
    }
}

// Calculate Accrued Profit and loss since previous model
function calculateAccruedPL(_valuationDate: Date | number, _maturity: Date | number, postRepaymentAdjustedDiscount: number, threshold: number, accrualOverride: boolean): number {
    try {
        if (accrualOverride) return 0;

        // Set up number of days
        const maturity = new Date(_maturity);
        const valuationDate = new Date(_valuationDate);
        const days = daysBetweenDates(valuationDate, maturity);
        if (days < 0) { // In some cases, this could get calculated past maturity. In which case, we would not calculate accrual
            return 0;
        }

        let accruedPl: number;
        accruedPl = postRepaymentAdjustedDiscount / days;

        if (Math.abs(postRepaymentAdjustedDiscount) < threshold) {
            return -postRepaymentAdjustedDiscount;
        } else {
            return -accruedPl;
        }
    } catch (e) {
        console.log(e);
        return 0;
    }
}

// Calculate Closing Discount
function calculateClosingDiscount(adjustedOpeningBalance: number, appliedPL: number): number {
    const closingDiscount = addValues(adjustedOpeningBalance, appliedPL);
    return Math.round(closingDiscount * 100) / 100;
}

// Calculate Applied Profit and Loss
function calculateAppliedPL(adjustedOpeningBalance: number, netRepaymentBasedPL: number, accruedPL: number, threshold: number): number {
    try {
        let appliedPl = netRepaymentBasedPL;
        if (Math.abs(adjustedOpeningBalance) < threshold) {
            appliedPl = addValues(appliedPl, -adjustedOpeningBalance);
        } else {
            if (accruedPL < 0.01 && accruedPL > 0) {
                appliedPl = addValues(appliedPl, 0.01);
            } else if (accruedPL > -0.01 && accruedPL < 0) {
                appliedPl = addValues(appliedPl, -0.01);
            } else {
                appliedPl = addValues(appliedPl, accruedPL);
            }
        }
        return appliedPl;
    } catch (e) {
        console.log(e);
        return 0;
    }
}

// Calculate Coupon Rate
export function calculateCouponRate(drawnMargin: number, feeType: string, facilityFee: number, floor: number, bbsw: number) {
    // START WITH MARGIN
    let sumMarginFacility = drawnMargin;
    // IF FACILITY LIMIT
    if (feeType === 'Facility Limit') {
        sumMarginFacility = addValues(sumMarginFacility, facilityFee);
    }
    // IF FLOOR BASE RATE
    if (!!floor || floor === 0) {
        // IF FLOOR BASE RATE IS GREATER THAN BBSW ADD TO MARGIN (Including if BBSW is negative)
        sumMarginFacility = addValues(sumMarginFacility, (floor  > bbsw) ? addValues(floor , -bbsw) : 0)
    }
    // return (sumMarginFacility * 100) / 4; // Coupon needs to be for quarterly coupon payments
    return sumMarginFacility;
}

// Calculate whether an asset is ITM or OTM
export function calculateITMorOTM(_commitment: number | null, carry: number, fairValue: number) {
    let commitment = 0;
    if (_commitment) { // In case commitment is null
        commitment = _commitment;
    }
    if (carry > fairValue) { // Out of the money
        const lossPct = addValues(fairValue, -carry)/100;
        const loss = commitment * lossPct;
        return Math.round(loss);
    } else if (fairValue > carry) { // In the money
        const gainPct = addValues(carry, -fairValue)/100;
        const gain = commitment * -gainPct;
        return Math.round(gain);
    } else { // On the money
        return 0;
    }
}

/**
 * Derives Fair Value BPS from tranche information and if the tranche has an override
 * @param curves
 * @param overrides
 * @param tranche
 */
export function determineScurveFairValueBps(curves: Array<Curve>, overrides: Array<sCurveOverride>, tranche: AxcessLoanCompare) {
    const sCurveOverride = overrides.find(c => c.trancheId === tranche.tranche_id);
    let curve: Curve | null;
    if (!!sCurveOverride) {
        curve = curves.find(c => c.curveType === sCurveOverride.curveType) || null;
    } else {
        curve = determineDefaultSCurve(curves, tranche.investment_type);
    }
    return (curve) ? determineFairValueBps(curve, tranche.remaining_tenor_yrs, tranche.rating_mcp) : 0;
}