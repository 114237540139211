// @mui
import {Container, Grid, Typography} from '@mui/material';
// components
import {DataTable, Page} from '../../components';
// Store
import {useAppSelector} from "../../store/store";
import {getValuationModelLoading} from "../../store/valuationModel/selectors/generalSelectors";
// Local imports
import {ExceptionsColumns, exceptionsSelector} from "../../store/valuationModel/selectors/exceptionsSelector";

export default function Exceptions() {
    const exceptions = useAppSelector(state => exceptionsSelector(state));
    const loading = useAppSelector(state => getValuationModelLoading(state));


    return (
        <Page title="Metrics - Valuation Model - Summary">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Exceptions
                        </Typography>
                    </Grid>
                    <Grid item container sx={{width: '100%', flex: 1}} direction='column'>
                        <Grid item sx={{flex: 1, pb: 1}}>
                            <DataTable
                                title={'Exceptions'}
                                columns={ExceptionsColumns}
                                data={exceptions}
                                loading={loading}

                                sort={{field: 'tranche_id', sort: 'asc'}}
                                search
                                showColumns
                                showFilter
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}