import _ from "lodash";
import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {AFRAxcessLoanCompare} from "../../types/AssetFundRegisterTypes";
import {SxProps} from "@mui/material";
import {GridCellParams, GridColDef, GridColTypeDef} from "@mui/x-data-grid";
import {fCurrency} from "../../utils/formatNumber";
import {formatAssumptionLabel} from "../../utils/valuationUtils";
import clsx from "clsx";
import {AssumptionType} from "../../types/valuationModelEnums";
import {formatDate} from "../../utils/DateUtils";
import {addValues} from "../../utils/mathUtil";
import {AxcessLoanFund} from "../../types/valuationModelTypes";


interface AFRTrancheData extends AFRAxcessLoanCompare {
    drawn: number,
    undrawn: number
    audCommitment: number,
    audDrawn: number,
    audUndrawn: number
}

// Retrieve AFR Portfolio
export const afrPortfolioSelector = createSelector(
    (state: RootState) => _.cloneDeep(state.afr.portfolio || []) as Array<AFRTrancheData>,
    (state: RootState) => state.afr.fx.current || [],
    (state: RootState) => state.afr.fund,
    (portfolio, fx, fund) => {
        if (fund !== 'ALL') {
            const filterPortfolio: Array<AFRTrancheData> = [];

            portfolio.forEach(t => {
                if (t.allFunds.some(f => f === fund)) {

                    const fundData = t.funds.find(f => f.fund === fund);

                    // FX CONVERSION
                    const aud = {
                        audCommitment: fundData?.commitment || 0,
                        audUndrawn: fundData?.undrawn || 0,
                        audDrawn: fundData?.drawn || 0,
                    }
                    if (t.base_currency !== 'AUD') {
                        const rate = fx.find(r => r.currency === t.base_currency);

                        if (rate) {
                            aud.audCommitment = aud.audCommitment / rate.rate;
                            aud.audUndrawn = aud.audUndrawn / rate.rate;
                            aud.audDrawn = aud.audDrawn / rate.rate;
                        }
                    }

                    filterPortfolio.push({
                        ...t,
                        id: t.tranche_id,
                        commitment: fundData?.commitment || 0,
                        undrawn: fundData?.undrawn || 0,
                        drawn: fundData?.drawn || 0,
                        ...aud
                    });
                }
            })

            portfolio = filterPortfolio;
        } else {
            portfolio.forEach(t => {

                const undrawn = t.funds.reduce((total, fund) => addValues(total, fund.undrawn || 0), 0);
                const drawn = t.funds.reduce((total, fund) => addValues(total, fund.drawn || 0), 0);

                // FX CONVERSION
                const aud = {
                    audCommitment: t.commitment || 0,
                    audUndrawn: undrawn || 0,
                    audDrawn: drawn || 0,
                }
                if (t.base_currency !== 'AUD') {
                    const rate = fx.find(r => r.currency === t.base_currency);

                    if (rate) {
                        aud.audCommitment = aud.audCommitment / rate.rate;
                        aud.audUndrawn = aud.audUndrawn / rate.rate;
                        aud.audDrawn = aud.audDrawn / rate.rate;
                    }
                }

                t.id = t.tranche_id;
                t.undrawn = undrawn;
                t.drawn = drawn;
                t.audCommitment = aud.audCommitment;
                t.audUndrawn = aud.audUndrawn;
                t.audDrawn = aud.audDrawn;
            })
        }

        return portfolio;
    }
)

const highlightFormatting: GridColTypeDef = {
    cellClassName: (params: GridCellParams) => {
        return clsx(' highlight', {
            new: (params.row.new),
            updated: (Object.keys(params.row.change).length > 0),
            assumptionGeneric: (!!params.row.assumption && (params.row.assumption !== AssumptionType.MATURED && params.row.assumption !== AssumptionType.PAST_MATURITY)),
            assumptionMatured: (!params.row.removed && !!params.row.assumption && (params.row.assumption === AssumptionType.MATURED || params.row.assumption === AssumptionType.PAST_MATURITY)),
            removed: (params.row.removed)
        })
    }
}

export const highlightingDataGridSx: SxProps = {
    '& .highlight.removed': {
        bgcolor: 'error.light'
    },
    '& .highlight.new': {
        bgcolor: 'success.light'
    },
    '& .highlight.updated': {
        bgcolor: 'warning.light'
    },
    '& .highlight.assumptionGeneric': {
        bgcolor: 'warning.main'
    },
    '& .highlight.assumptionMatured': {
        bgcolor: 'info.lighter'
    }
}

export const AFRPortfolioTableColumns: Array<GridColDef> = [
    {
        field: 'tranche_id',
        headerName: 'Tranche ID',
        minWidth: 100,
        ...highlightFormatting
    },
    {
        field: 'client_id',
        headerName: 'Client ID',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'asset_id',
        headerName: 'Asset ID',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'asset',
        headerName: 'Asset',
        flex: 2,
        minWidth: 250,
        ...highlightFormatting
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        flex: 2,
        minWidth: 250,
        ...highlightFormatting
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        flex: 1,
        minWidth: 150,
        ...highlightFormatting
    },
    {
        field: 'tranche_type',
        headerName: 'Tranche Type',
        flex: 1,
        minWidth: 100,
        ...highlightFormatting,
        hide: true
    },
    {
        field: 'apra_business_type',
        headerName: 'APRA Business Type',
        flex: 1,
        minWidth: 150,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'apra_facility_type',
        headerName: 'APRA Facility Type',
        flex: 1,
        minWidth: 200,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'apra_industry',
        headerName: 'APRA industry',
        flex: 1,
        minWidth: 250,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'apra_lending_category',
        headerName: 'APRA Lending Category',
        flex: 1,
        minWidth: 250,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'apra_purpose',
        headerName: 'APRA Purpose',
        flex: 1,
        minWidth: 250,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'base_currency',
        headerName: 'Base Currency',
        flex: 2,
        minWidth: 110,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'interest_type',
        headerName: 'Interest Type',
        flex: 2,
        minWidth: 150,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'investment_type',
        headerName: 'Investment Type',
        flex: 1,
        minWidth: 200,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'margin_type',
        headerName: 'Margin Type',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'pricing_type',
        headerName: 'Pricing Type',
        flex: 2,
        minWidth: 150,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'rating_mcp',
        headerName: 'MCP Rating',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'remaining_tenor_yrs',
        headerName: 'Tenor',
        flex: 2,
        minWidth: 100,
        type: 'number',
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'security',
        headerName: 'Security',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'seniority_rank',
        headerName: 'Seniority',
        flex: 2,
        minWidth: 150,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'sp_industry',
        headerName: 'S&P Industry',
        flex: 1,
        minWidth: 250,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'sp_industry_group',
        headerName: 'S&P Industry Group',
        flex: 1,
        minWidth: 250,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'sp_sector',
        headerName: 'S&P Sector',
        flex: 1,
        minWidth: 200,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'sp_sub_industry',
        headerName: 'S&P Sub Industry',
        flex: 1,
        minWidth: 250,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'state',
        headerName: 'State',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting,
        hide: true,
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        type: 'date',
        align: 'right',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting
    },
    {
        field: 'drawn',
        headerName: 'Drawn',
        type: 'number',
        flex: 2,
        minWidth: 150,
        align: 'right',
        ...highlightFormatting,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => (params.row.removed) ? 0 : params.row.drawn
    },
    {
        field: 'undrawn',
        headerName: 'Undrawn',
        type: 'number',
        flex: 2,
        minWidth: 150,
        align: 'right',
        ...highlightFormatting,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => (params.row.removed) ? 0 : params.row.undrawn
    },
    {
        field: 'commitment',
        headerName: 'Commitment',
        type: 'number',
        flex: 2,
        minWidth: 150,
        align: 'right',
        ...highlightFormatting,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => (params.row.removed) ? 0 : params.row.commitment
    },
    {
        field: 'audDrawn',
        headerName: 'Drawn (AUD)',
        type: 'number',
        flex: 2,
        minWidth: 150,
        align: 'right',
        ...highlightFormatting,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => (params.row.removed) ? 0 : params.row.audDrawn
    },
    {
        field: 'audUndrawn',
        headerName: 'Undrawn (AUD)',
        type: 'number',
        flex: 2,
        minWidth: 150,
        align: 'right',
        ...highlightFormatting,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => (params.row.removed) ? 0 : params.row.audUndrawn
    },
    {
        field: 'audCommitment',
        headerName: 'Commitment (AUD)',
        type: 'number',
        flex: 2,
        minWidth: 150,
        align: 'right',
        ...highlightFormatting,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => (params.row.removed) ? 0 : params.row.audCommitment
    },
    {
        field: 'assumption',
        headerName: 'Assumption',
        flex: 1,
        minWidth: 200,
        ...highlightFormatting,
        valueGetter: (params) => {
            if (params.value) {
                return formatAssumptionLabel(params.value)
            } else if (params.row.removed) {
                return "Removed"
            } else if (params.row.new) {
                return "New"
            } else {
                return "-"
            }
        }
    },
    {
        field: 'removed',
        headerName: 'Closed',
        flex: 2,
        minWidth: 100,
        ...highlightFormatting,
        valueFormatter: (params) => (params.value) ? "Closed" : ""
    },
]


interface FXAFRAxcessLoanCompare extends AFRAxcessLoanCompare {
    audCommitment: number | null,
    audFunds: Array<AxcessLoanFund>,
    audFundsBefore: Array<AxcessLoanFund>,
}

// Retrieve Tranche By ID;
export const getTranche = createSelector(
    (state: RootState) => _.cloneDeep(state.afr.portfolio || []),
    (state: RootState) => state.afr.fx.previous || [],
    (state: RootState) => state.afr.fx.current || [],
    (state: RootState, trancheId: number) => trancheId,
    (portfolio, previousFx, currentFx, trancheId) => {
        const tranche = portfolio.find(tranche => tranche.tranche_id === trancheId) as FXAFRAxcessLoanCompare || null;

        if (!tranche) return null

        if (tranche.base_currency !== 'AUD') {
            const currentRate = currentFx.find(r => r.currency === tranche.base_currency)?.rate || 0;
            const previousRate = previousFx.find(r => r.currency === tranche.base_currency)?.rate || 0;

            tranche.audCommitment = tranche.commitment/currentRate || NaN
            tranche.audFunds = tranche.funds.map(f => {
                return {
                    ...f,
                    undrawn: f.undrawn/currentRate,
                    drawn: f.drawn/currentRate,
                    commitment: f.commitment/currentRate,
                }
            });
            tranche.audFundsBefore = tranche.funds_before.map(f => {
                return {
                    ...f,
                    undrawn: f.undrawn/previousRate,
                    drawn: f.drawn/previousRate,
                    commitment: f.commitment/previousRate,
                }
            });
        } else {
            tranche.audCommitment = null;
        }

        return tranche
    }
)

// Select Dates as Strings
export const selectDateSetsString = createSelector(
    (state: RootState) => state.afr.dataSets || [],
    (dataSets) => {
        return dataSets.map(set => formatDate(set, 'yyyy-MM-dd'))
    }
)