import {cloneDeep} from "lodash";
import {
    CalculationLoanType,
    CapitalBudgetPeriodResults,
    CapitalTransaction,
    MCPFund,
    OtherTransaction,
} from "../../../../types/capitalBudgetTypes";
import {Period} from "../../../../types/GeneralTypes";
import {handlePortfolioForPeriod} from "./handlePortfolioForPeriod";
import {
    CapitalAction,
    CapitalTypes,
    OtherTransactionTypes,
    PeriodType
} from "../../../../types/capitalBudgetEnums";
import {handleNewLoanForPeriod} from "./handleNewLoanForPeriod";
import {checkInPeriod} from "../../../../utils/DateUtils";
import {addArrayValues, addValues} from "../../../../utils/mathUtil";
import {convertValueToRating} from "../../../../utils/CapitalBudgetUtils";

const resultsDefault = {
    ium: 0, // Investments Under Management
    aum: 0, // Assets Under Management
    startingAvailableCap: 0, // Starting Capital balance
    closingAvailableCap: 0, // Closing Capital Balance
    startingAvailableCash: 0, // Starting Capital balance
    closingAvailableCash: 0, // Closing Capital Balance
    contractualCapital: 0,

    newCommitments: 0, // Allocated New Assets
    newLoansValue: 0, // Budget allocated New Assets
    wip: 0, // WIP allocated New Assets

    expectedRepayments: 0, // Contractual Loan repayments

    extensionOffset: 0, // Offset for loans extended
    earlyRepOffset: 0, // Offset for loans repaid early
    selldownOffset: 0, //  Offset for loans sold down or paritally repaid
    // interfundOffset: 0, // Offset for amounts transferred
    transfersInOffset: 0, // Offset for amounts transferred In
    transfersOutOffset: 0, // Offset for amounts transferred Out
    extension: 0, // Repayments at new Maturity
    extensionAlt: 0, // Repayments at new Maturity
    earlyRepayment: 0, // Repayments at new Maturity
    earlyRepaymentAlt: 0, // Repayments at new Maturity
    selldowns: 0, // Amounts Sold down or repaid partially
    // interfundTransfers: 0, // Amounts Transferred out
    transfersIn: 0, // Amounts Transferred In
    transfersOut: 0, // Amounts Transferred Out

    // Cash based calcs
    expectedRepaymentsCash: 0,
    extensionOffsetCash: 0,
    earlyRepOffsetCash: 0,
    extensionCash: 0,
    extensionAltCash: 0,
    earlyRepaymentCash: 0,
    earlyRepaymentAltCash: 0,
    selldownsCash: 0,
    transfersInCash: 0,
    transfersOutCash: 0,
    transfersInOffsetCash: 0,
    transfersOutOffsetCash: 0,

    subscriptions: 0,
    redemptions: 0,
    redemptionsOffset: 0,
    commitments: 0,
    cancellations: 0,

    // CTC and Drawdown Calculations
    firstDrawdown: 0, // New loans expected first drawdown
    periodCTCDrawdown: 0,
    periodCTCDrawdownUnactive: 0,
    periodCTCDrawdownNew: 0,
    totalCTCDrawdown: 0,
    periodCorpCTCDrawdownNew: 0,

    // Additional transactions (Other/Manual Transactions)
    debtDraw: 0,
    cashDistribution: 0,
    revolverDrawdown: 0,
    adjustment: 0,

    // WAV calculations
    wavInterestTotal: 0,
    wavInterest: 0,
    wavYieldTotal: 0,
    wavYield: 0,
    wavMargin: 0,
    wavTenor: 0,
    wavTenorTotal: 0,
    wavRatingCalc: 0,
    wavRatingDenom: 0,
    wavRating: null,

    loans: [],
    capitalTransactions: [],
    otherTransactions: []
}

/**
 * Calculates position of period and determines relevant summary fields
 * Fund - if specific fund is selected
 * Period - Week/Month details of period, incl. start date, end date ...etc
 * Period Type - 'week' or 'month' used for time based calculations. (working with weeks in a year vs months)
 * AvailableCap - Starting Capital balance for the period.
 * AvailableCash - Starting cash balance for the period.
 * redemptionOffset - offset redemptions by 1 period.
 * Period Multiplier - Used in the calculation of the Weighted Average Tenor, formula removes periods passed for periods not current.
 * Portfolios - Array of exisitng portfolios
 * New Loans - Array of WIP loans
 * Portfolio Changes - Array of loan amendments
 * Selldown repayments - Array of Asset selldowns or partial early repayments
 * Capital - Array of Incoming and outgoing capital.;
 * Other Transactions - Array of other manual transactions
 */

function calculatePeriod(fund: MCPFund | null, period: Period, periodType: PeriodType, availableCap: number, availableCash: number, redemptionOffset: number, periodMultiplier: number, budgetDate: number | Date, portfolios: Array<CalculationLoanType>, newLoans: Array<CalculationLoanType>, capital: Array<CapitalTransaction>, otherTransactions: Array<OtherTransaction>): CapitalBudgetPeriodResults {
    let results: CapitalBudgetPeriodResults = {...period, ...cloneDeep(resultsDefault)};

    results.redemptionsOffset = redemptionOffset;

    const periodTypeMulti = (periodType === 'week') ? 52 : 12;
    const ctcWeekBuffer = 10; // Buffer for CTC Drawdown.
    // CHECK NECESSITY OF TRANSFERS
    portfolios.forEach(p => {
        handlePortfolioForPeriod(p, results, fund, budgetDate, period, periodType, periodMultiplier, ctcWeekBuffer, periodTypeMulti);
    })

    if (!period.base) {
        newLoans.forEach(l => {
            handleNewLoanForPeriod(l, results, fund, budgetDate, period, periodType, periodMultiplier, ctcWeekBuffer, periodTypeMulti)
        })

        // Handle Subs and Reds
        capital.forEach(c => {
            if (checkInPeriod(c.date, period)) {
                results.capitalTransactions.push(c);
                // INVESTOR
                if (c.investorType === CapitalTypes.INVESTOR) {
                    if (c.transactionType === CapitalAction.SUBSCRIPTION) results.subscriptions = addValues(results.subscriptions, c.amount);
                    if (c.transactionType === CapitalAction.REDEMPTION) results.redemptions = addValues(results.redemptions, c.amount);
                    // LENDER
                } else {
                    if (c.transactionType === CapitalAction.COMMITMENT) results.commitments = addValues(results.commitments, c.amount);
                    if (c.transactionType === CapitalAction.CANCELLATION) results.cancellations = addValues(results.cancellations, c.amount);
                }
            }
        })

        otherTransactions.forEach(ot => {
            if (checkInPeriod(ot.date, period)) {
                results.otherTransactions.push(ot);
                switch (ot.transactionType) {
                    case OtherTransactionTypes.ADJUSTMENT:
                        if (ot.capital) {
                            results.adjustment = addValues(results.adjustment, ot.amount);
                        }
                        break;
                    case OtherTransactionTypes.CASH_DISTRIBUTION:
                        results.cashDistribution = addValues(results.cashDistribution, ot.amount);
                        break;

                    case OtherTransactionTypes.DEBT_DRAW:
                        results.debtDraw = addValues(results.debtDraw, ot.amount);
                        break;

                    case OtherTransactionTypes.REVOLVER_DRAWDOWN:
                        results.revolverDrawdown = addValues(results.revolverDrawdown, ot.amount);
                        break;

                    default:
                        break;
                }
            }
        })
    }

    // FINAL CALCULATIONS
    results.startingAvailableCap = availableCap;
    results.startingAvailableCash = availableCash;

    // AUM total is the total assets under management and available capital.
    if (results.startingAvailableCap > 0) {
        results.wavRatingCalc = addValues(results.wavRatingCalc, (6 * results.startingAvailableCap));
        results.wavRatingDenom = addValues(results.wavRatingDenom, results.startingAvailableCap);
        results.aum = addValues(results.ium, results.startingAvailableCap);
    } else {
        results.aum = results.ium
    }

    results.closingAvailableCap = addArrayValues([
        availableCap, // STARTING CAP
        results.newCommitments, // NEW LOAN COMMITMENTS
        results.expectedRepayments, // LOAN REPAYMENTS
        results.extensionOffset, // LOAN EXTENSIONS OFFSET
        results.earlyRepOffset, // EARLY REPAYMENTS OFFSET
        results.selldownOffset, // SELLDOWN OFFSET
        results.earlyRepayment, // EARLY LOAN REPAYMENTS
        results.extension, // LOAN EXTENSION
        results.selldowns, // ASSET SELLDOWNS
        results.transfersIn, // INTERFUND TRANSFERS (IN)
        results.transfersOut, // INTERFUND TRANSFERS (OUT)
        results.transfersInOffset, // INTERFUND OFFSET AMOUNTS (IN)
        results.transfersOutOffset, // INTERFUND OFFSET AMOUNTS (OUT)
        results.subscriptions, // INVESTOR SUBSCRIPTION
        results.redemptions, // INVESTOR REDEMPTIONS
    ]);

    results.closingAvailableCash = addArrayValues([
        availableCash, // STARTING CAP
        results.expectedRepaymentsCash, // LOAN REPAYMENTS
        results.extensionOffsetCash, // LOAN EXTENSIONS OFFSET
        results.earlyRepOffsetCash, // EARLY REPAYMENTS OFFSET
        results.selldownOffsetCash, // SELLDOWN OFFSET
        results.earlyRepaymentCash, // EARLY LOAN REPAYMENTS
        results.extensionCash, // LOAN EXTENSION
        results.selldownsCash, // ASSET SELLDOWNS
        results.transfersInCash, // INTERFUND TRANSFERS (IN)
        results.transfersOutCash, // INTERFUND TRANSFERS (OUT)
        results.transfersInOffsetCash, // INTERFUND OFFSET AMOUNTS (IN)
        results.transfersOutOffsetCash, // INTERFUND OFFSET AMOUNTS (OUT)
        results.subscriptions, // INVESTOR SUBSCRIPTION
        redemptionOffset, // INVESTOR REDEMPTIONS OFFSET
        results.debtDraw,
        results.firstDrawdown,
        results.periodCTCDrawdown,
        results.periodCTCDrawdownUnactive,
        results.periodCTCDrawdownNew,
        results.periodCorpCTCDrawdownNew,
        results.cashDistribution,
        results.revolverDrawdown
    ]);


    results = {
        ...results,
        totalCTCDrawdown: addArrayValues([results.periodCTCDrawdown, results.periodCTCDrawdownUnactive, results.periodCTCDrawdownNew]),

        wavInterest: Math.round((results.wavInterest / results.wavInterestTotal) * 100) / 100,
        wavTenor: Math.round((results.wavTenor / results.wavRatingDenom) * 100) / 100,
        wavRatingValue: Math.round((results.wavRatingCalc / results.wavRatingDenom) * 100) / 100,
        wavRating: convertValueToRating(results.wavRatingCalc / results.wavRatingDenom),
        wavMargin: Math.round((results.wavMargin / results.wavYieldTotal) * 10000) / 10000,
        wavYield: Math.round((results.wavYield / results.wavYieldTotal) * 10000) / 10000,

        contractualCapital: addValues(results.startingAvailableCap, results.expectedRepayments),
    }

    return results
}

export default calculatePeriod;