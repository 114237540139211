import {Navigate, Route, Routes} from "react-router-dom";
import {useEffect} from "react";
// Pages
import Page404 from "../Page404";
import ValuationWorksheet from "./ValuationWorksheet";
import BulkUpload from "./BulkUpload";
// Store
import {useAppDispatch, useAppSelector} from "../../store/store";
import {getCurrentVersion, getVersionLoadingState} from "../../store/version/versionSelector";
import {versionsInitialLoad} from "../../store/version/versionFunctions";
import {retrieveValuationModel} from "../../store/valuationModel/valuationModelFunctions";
import ValuationExport from "./ValuationExport";
import AdjustmentsExport from "./AdjustmentsExport";
import ValuationSummary from "./ValuationSummary";
import ThirdPartyDataExport from "./ThirdPartyDataExport";
import Exceptions from "./Exceptions";
import usePrevious from "../../hooks/usePrevious";

const ValuationModelPages = () => {
    const dispatch = useAppDispatch();

    const version = useAppSelector(state => getCurrentVersion(state));
    const prevVersion = usePrevious(version);
    const loading = useAppSelector(state => getVersionLoadingState(state));
    const user = useAppSelector(state => state.user.user);

    if (process.env.REACT_APP_ENV !== 'LOCAL_DEV' && user) {
        window.onbeforeunload = () => {
            return ''
        }
    }

    // Initial Load
    useEffect(() => {
        if (!version && !loading) {
            // run initial load for version
            dispatch(versionsInitialLoad())
        } else if (!prevVersion && version && !loading) {
            dispatch(retrieveValuationModel())
        }
    }, [prevVersion, version, loading, dispatch])

    // Reload Valuation model when version changes
    useEffect(() => {
        if (!!prevVersion && version) {
            dispatch(retrieveValuationModel())
        }
    }, [prevVersion, version, dispatch])

    return (
        <Routes>
            <Route path='/' element={<Navigate to='valuationSummary'/>}/>

            <Route path='/valuationSummary' element={<ValuationSummary/>}/>
            <Route path='/valuationWorksheet' element={<ValuationWorksheet/>}/>
            <Route path='/bulkUpload' element={<BulkUpload/>}/>
            <Route path='/valuationExport' element={<ValuationExport/>}/>
            <Route path='/adjustmentsExport' element={<AdjustmentsExport/>}/>
            <Route path='/exceptions' element={<Exceptions/>}/>
            <Route path='/dataExport' element={<ThirdPartyDataExport/>}/>

            <Route path='/*' element={<Page404/>}/>

        </Routes>
    )
}

export default ValuationModelPages;