import {useState} from 'react';
import _ from "lodash";
import {formatDate} from "../../../../utils/DateUtils";
import {fCurrency} from "../../../../utils/formatNumber";
// @mui
// components
import {retrieveOtherTransactions} from "../../../../store/capitalBudget/selectors/otherTransactionSelector";
import {
    AlertDialog,
    SideDrawer,
    VerticalTable,
    VerticalTableColumn
} from '../../../../components';
// Store
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {CellType} from "../../../../types/InputTypes";
import {
    addOtherTransaction,
    editOtherTransaction,
    removeOtherTransaction
} from "../../../../store/capitalBudget/capitalBudgetSlice";
import {NewManualTransaction} from "./index";

let TransactionColumns: Array<VerticalTableColumn> = [
    {
        id: 'date',
        label: 'Date',
        sortable: true,
        formatter: (value: number) => formatDate(new Date(value), 'dd-MM-yyyy'),
        align: 'left',
        sx: {width: 120}
    },
    {
        id: 'transactionType',
        label: 'Transaction Type',
        sortable: true,
        align: 'left',
        sx: {width: 200}
    },
    {
        id: 'fund',
        label: 'Fund',
        sortable: true,
        align: 'left',
        // sx: {width: 75}

    },
    {
        id: 'amount',
        label: 'Amount',
        sortable: true,
        align: 'right',
        formatter: (value: number) => fCurrency(value),
        total: true,
        type: CellType.CURRENCY,
    },
    {
        id: 'notes',
        label: 'Notes',
        sortable: true,
        align: 'left',
        sx: {minWidth: 300, flexGrow: 1}
    },
    {
        id: 'cash',
        label: 'Cash',
        align: 'center',
        formatter: (value) => (value ? 'Yes' : 'No')
    },
    {
        id: 'capital',
        label: 'Capital',
        align: 'center',
        formatter: (value) => (value ? 'Yes' : 'No')
    },
]

// Capital Budget - Other Transactions Component
export default function OtherTransactions() {
    const dispatch = useAppDispatch();

    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const otherTransactions = useAppSelector(state => retrieveOtherTransactions(state))
    const scenarioId = useAppSelector(state => state.scenarios.scenario?.id);

    // CREATE
    const [form, setFormOpen] = useState<any | null>(null);
    const [open, setOpen] = useState<any | null>(null);

    // Sends an editable version of transaction to update form
    const handleEditTransaction = (row: number) => {
        const tempTransaction: any = _.cloneDeep(otherTransactions.find(e => e.id === row));

        if (tempTransaction) {
            tempTransaction.fund = tempTransaction.fundId;
            tempTransaction.date = new Date(tempTransaction.date);
            if (!tempTransaction.notes) tempTransaction.notes = '';
        }

        setFormOpen(tempTransaction)
    }

    return (
        <>
            <VerticalTable
                title={'Other Transactions'}
                loading={dataLoading}

                columns={TransactionColumns}
                data={otherTransactions}

                defaultOrder={'desc'}
                defaultSort={'date'}
                secondarySort={'transactionType'}
                secondaryOrder={'asc'}

                create={() => setFormOpen(true)}

                handleEdit={(rowId) => handleEditTransaction(rowId)}
                handleRemove={(rowId) => setOpen(rowId)}

                highlightStatus

                totalRow
            />
            <SideDrawer
                title={'Manual Transactions'}
                open={(!!form)}
                onClose={() => setFormOpen(null)}
                size={'large'}
            >
                <NewManualTransaction
                    editValues={form}
                    onClose={() => setFormOpen(null)}
                    submitTransaction={(values) => {
                        if (scenarioId) {
                            values.scenarioId = scenarioId;
                            dispatch(addOtherTransaction(values));
                            setFormOpen(null)
                        }
                    }}
                    editTransaction={(values) => {
                        dispatch(editOtherTransaction(values));
                        setFormOpen(null)
                    }}
                />
            </SideDrawer>
            <AlertDialog
                title='Delete Transaction'
                info="Are you sure you want to remove this?"
                open={!!open}
                handleClose={() => {
                    setOpen(null)
                }}
                handleConfirm={() => {
                    dispatch(removeOtherTransaction(open))
                    setOpen(null)
                }}
            />
        </>
    )
}