import {WIPLoan} from "../types/capitalBudgetTypes";

const MCPRatingIndex = {
    'AA+': 4,
    'AA': 5,
    'AA-': 6,
    'A+': 7,
    'A': 8,
    'A-': 9,
    'BBB+': 10,
    'BBB': 11,
    'BBB-': 12,
    'BB+': 13,
    'BB': 14,
    'BB-': 15,
    'B+': 16,
    'B': 17,
    'B-': 18,
    'CCC+': 19,
    'CCC': 20,
    'D': 21,
    'NR': null
}

export function convertRatingToValue(rating: string): number | null {
    let ratingValue;
    try {
        //@ts-ignore
        ratingValue = (MCPRatingIndex[`${rating.toUpperCase()}`])

        return ratingValue || 0
    } catch (err) {
        return 0
    }
}

export function convertValueToRating(rating: number): string | null {
    try {
        //@ts-ignore
        const ratingValue = Object.keys(MCPRatingIndex).find((key: string) => MCPRatingIndex[`${key}`] === Math.floor(rating));
        return ratingValue || null
    } catch (err) {
        return null
    }
}

export function groupDebtSeniority(seniority: string) {
    if (!seniority) return 'No Seniority'
    try {
        switch (seniority.toLowerCase()) {
            case 'senior':
            case 'super senior':
            case 'stretch senior':
                return 'Senior';

            case 'subordinated':
            case 'mezzanine':
                return 'Subordinated';

            case 'equity':
            case 'hybrid':
            case 'preferred equity':
            case 'preferred':
            case 'shareholder loan':
                return 'Equity';

            case 'other':
                return 'Other';

            default:
                return seniority

        }
    } catch (e) {
        return 'No Seniority'
    }
}

export function extractnCinoBase(loan: WIPLoan): null | number {
    const productPackage = loan.llc_bi_pricing_streams[0];

    if (productPackage) {
        const rateComponents = productPackage.llc_bi_pricing_rate_components[0];
        if (rateComponents) {
            return rateComponents.llc_bi_rate / 100
        }
    }
    return null
}

export function extractnCinoSpread(loan: WIPLoan): null | number {
    const productPackage = loan.llc_bi_pricing_streams[0];

    if (productPackage) {
        const rateComponents = productPackage.llc_bi_pricing_rate_components[0];
        if (rateComponents) {
            return rateComponents.llc_bi_spread / 100
        }
    }
    return null
}

export function extractFacilityFee(loan: WIPLoan): null | number {
    const fees = loan.llc_bi_fees;

    if (fees) {
        const lineFee = fees.find(f => f.llc_bi_fee_type === 'Line Fee')
        if (lineFee) {
            return lineFee.llc_bi_percentage / 100;
        }
    }

    return null
}