// MUI
import {Container, Grid, Typography} from "@mui/material";
// Components
import {HorizontalTable, Page} from "../../components";
import {SelectionBar} from "./SelectionBar";
// Sections
import {AccountBalanceTotals, OtherTransactions} from "../../sections/dashboard/capitalBudget/accountBalances";
// Reducer
import {useAppSelector} from "../../store/store";
import {retrieveFundPortfolioHoldings} from "../../store/capitalBudget/selectors/fundSelectors";

// Capital Budget - Account Balances Page
export default function AccountBalances() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const {data, rows} = useAppSelector(state => retrieveFundPortfolioHoldings(state));

    return (
        <>
            <Page title="Metrics - Capital Budget - Capital">
                <Container maxWidth={false} sx={{maxWidth: '100%'}}>
                    <Grid container spacing={1} direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Account Balances
                        </Typography>
                        <SelectionBar/>
                    </Grid>
                    <Grid item container sx={{width: '100%'}} >
                        <Grid item lg={12} xl={5} sx={{pb: 1}}>
                            <AccountBalanceTotals/>
                        </Grid>
                        <Grid item container direction='column' lg={12} xl={7} sx={{pb: 1, h: 1}}>
                            <Grid sx={{pl: 1, flexGrow: 1, pb: 1}}>
                                <OtherTransactions />
                            </Grid>
                            <Grid sx={{pl: 1}}>
                                <HorizontalTable
                                    rows={rows}
                                    data={data}
                                    loading={dataLoading}
                                    title={'Portfolio Holdings'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}