import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {apiGetRequest, apiPostRequest, apiPutRequest} from "../apiUtils";
import {addNotification} from "../notifications/notificationSlice";
import {NewScenario, Scenario, ScenarioDataSet} from "../../types/capitalBudgetTypes";

// Retrieve Scenarios
export const retrieveScenarios = createAsyncThunk('scenario/retrieveScenarios', async (_, thunkAPI) => {
    try {
        return await apiGetRequest('scenarios');
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred retrieving scenarios';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Set Active Scenario
export const setActiveScenario = createAsyncThunk('scenario/setActiveScenario', async (scenarioId: string, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        const user = state.user.user;

        if (user) {
            await apiPutRequest(`scenarios/${scenarioId}?setActive=true`, {
                user: {
                    user: user.name,
                    username: user.username
                }
            });
            thunkAPI.dispatch(retrieveScenarios())
            return;
        } else {
            new Error('User not found.')
        }
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred updating scenario';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Save Scenario
export const saveScenario = createAsyncThunk('scenario/saveScenario', async (data: { description: string }, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        const currentScenario = state.scenarios.scenario;
        const scenarioData = state.capitalBudget.scenarioData;
        const user = state.user.user;

        if (currentScenario && user && scenarioData) {

            const scenario = {
                ...currentScenario,
                description: data.description,
                modifier: {
                    user: user.name,
                    username: user.username
                },
                modifiedDate: new Date().getTime(),
                // SCENARIOS DATA
                accountBalances: scenarioData.accountBalances.funds,
                capital: scenarioData.capital,
                otherTransactions: scenarioData.otherTransactions,
                newDeals: scenarioData.newDeals,
                portfolioChanges: scenarioData.portfolioChanges,
                transfers: scenarioData.transfers,
                selldownRepayments: scenarioData.selldownRepayments
            }

            await apiPutRequest(`scenarios/${scenario.id}`, {scenario})
            thunkAPI.dispatch(addNotification('Scenario Successfully Saved.', 'success'))
            thunkAPI.dispatch(retrieveScenarios())
            return;
        } else {
            new Error('Scenario not found.')
        }
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred saving scenario';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Save As Scenario
export const saveAsScenario = createAsyncThunk('scenario/saveAsScenario', async (data: { description: string }, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        const currentScenario = state.scenarios.scenario;
        const scenarioData = state.capitalBudget.scenarioData;

        if (currentScenario && scenarioData) {

            let scenario: any = {
                ...currentScenario,
                description: data.description,
                balancesClear: false,
                capitalClear: false,
                otherTransactionsClear: false,
                clearAxcessChanges: false,
                clearNcinoChanges: false,
                referenceScenario: currentScenario.id,
                scenarioData: {
                    ...scenarioData,
                    accountBalances: scenarioData.accountBalances.funds,
                }

            }
            const newScenario = await apiPostRequest(`scenarios`, {scenario});

            thunkAPI.dispatch(addNotification('Scenario Successfully Saved.', 'success'))
            await thunkAPI.dispatch(retrieveScenarios())

            return newScenario.scenarioId;
        } else {
            new Error('Scenario not found.')
        }
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred saving scenario';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})


// Creates a new scenario;
export const createNewScenario = createAsyncThunk('scenario/createNew', async (newScenario: NewScenario, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;

        const currScenarioData = state.capitalBudget.scenarioData as ScenarioDataSet;
        const currentScenario = state.scenarios.scenario as Scenario;

        if (!currScenarioData) Error('Scenario Data not found!');

        // Form new Scenario
        const scenario = {
            description: newScenario.description,
            // Retrieve new data sets if necessary
            accountBalanceId: newScenario.accountBalance,
            axcessId: newScenario.axcess,
            ncinoId: (newScenario.ncino === 'new') ? (await apiPostRequest('external-data/ncino', {})).ncinoId : newScenario.ncino,
            holdingId: newScenario.holding,
            balancesClear: newScenario.balanceClear,
            capitalClear: newScenario.capitalClear,
            otherTransactionsClear: newScenario.otherTransactionsClear,
            clearAxcessChanges: newScenario.clearAxcessChange,
            clearNcinoChanges: newScenario.clearNcinoChanges,
            referenceScenario: currentScenario.id,
            scenarioData: {
                ...currScenarioData,
                accountBalances: currScenarioData.accountBalances.funds,
            }
        }

        const scenarioId = await apiPostRequest(`scenarios`, {scenario});
        thunkAPI.dispatch(addNotification('Scenario Successfully Saved.', 'success'));
        await thunkAPI.dispatch(retrieveScenarios());

        return scenarioId;
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred creating scenario';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})