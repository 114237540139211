import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createUser, forgotPassword, loginUser, reauthenticateUser, forgotPasswordSubmit, signOut, verifyUser, changePassword, getUsers, updateUserAttributes, enableDisableUser } from './userFunctions';

// -----------------------------------------------------------------------------------

export interface User {
    id: string,
    name: string,
    username: string,
    email: string,
    given_name: string,
    family_name: string,
    birthdate: string,
    role: userRoleTypes,
    status: boolean
}

export enum userRoleTypes {
    ADMIN = 'admin',
    USER = 'user'
}

export interface UserState {
    user: null | User,
    users: Array<User>,
    passwordChanged: boolean,
    userUpdated: boolean,
    createUser: {
        loading: boolean,
        user: User | null
    }
    verification: null | 'SUCCESS' | 'FAILED',
    loading: boolean
}

// INITIAL STATE
const initialState: UserState = {
    user: null,
    users: [],
    passwordChanged: false,
    userUpdated: false,
    createUser: {
        loading: false,
        user: null
    },
    verification: null,
    loading: false
}

// REDUCER
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetChanged(state) {
            state.passwordChanged = false;
            state.verification = null
        },
        resetUpdate(state) {
            state.userUpdated = false;
        }
    },
    extraReducers(builder) {
        builder
            // USER LOGIN
            .addCase(loginUser.pending, (state) => {
                state.loading = true
            })
            .addCase(loginUser.fulfilled, (state, action: PayloadAction<User>) => {
                state.user = action.payload;
                state.loading = false
            })
            .addCase(loginUser.rejected, (state) => {
                state.loading = false
            })

            // USER REAUTHENTICATE USER
            .addCase(reauthenticateUser.pending, (state) => {
                state.loading = true
            })
            .addCase(reauthenticateUser.fulfilled, (state, action: PayloadAction<User>) => {
                state.user = action.payload;
                state.loading = false
            })
            .addCase(reauthenticateUser.rejected, (state) => {
                state.loading = false
            })

            // USER SIGN OUT
            .addCase(signOut.fulfilled, (state) => {
                state.user = null;
                state.loading = false
            })

            // USER FORGOT PASSWORD
            .addCase(forgotPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(forgotPassword.rejected, (state) => {
                state.loading = false;
            })

            // USER SET PASSWORD
            .addCase(forgotPasswordSubmit.pending, (state) => {
                state.loading = true;
            })
            .addCase(forgotPasswordSubmit.fulfilled, (state) => {
                state.loading = false;
                state.passwordChanged = true;
            })
            .addCase(forgotPasswordSubmit.rejected, (state) => {
                state.loading = false;
            })

            // CREATE USER
            .addCase(createUser.pending, (state) => {
                state.createUser.loading = true;
            })
            .addCase(createUser.fulfilled, (state, action: PayloadAction<any>) => {               
                state.createUser.loading = false;
                state.createUser.user = action.payload;
                // state.passwordChanged = true;
            })
            .addCase(createUser.rejected, (state) => {
                state.createUser.loading = false;
            })

            // VERIFY USER
            .addCase(verifyUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyUser.fulfilled, (state) => {
                state.verification = 'SUCCESS';
                state.loading = false;
            })
            .addCase(verifyUser.rejected, (state) => {
                state.verification = 'FAILED';
                state.loading = false;
            })

            // USER CHANGE PASSWORD
            // VERIFY USER
            .addCase(changePassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.passwordChanged = true;
                state.loading = false;
            })
            .addCase(changePassword.rejected, (state) => {
                state.loading = false;
            })
            
            // GET USERS
            .addCase(getUsers.pending, (state) => {
                state.users = [];
                state.loading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users = action.payload;
                state.loading = false;
            })
            .addCase(getUsers.rejected, (state) => {
                state.loading = false;
            })

            // UPDATE USER ATTRIBUTES
            .addCase(updateUserAttributes.pending, (state) => {
                state.createUser.loading = true;
            })
            .addCase(updateUserAttributes.fulfilled, (state) => {
                state.userUpdated = true;
                state.createUser.loading = false;
            })
            .addCase(updateUserAttributes.rejected, (state) => {
                state.createUser.loading = false;
            })

            // ENABLE OR DISABLE USER
            .addCase(enableDisableUser.pending, (state) => {
                state.createUser.loading = true;
            })
            .addCase(enableDisableUser.fulfilled, (state) => {
                state.createUser.loading = false;
            })
            .addCase(enableDisableUser.rejected, (state) => {
                state.createUser.loading = false;
            })
    }
})

export const { resetChanged, resetUpdate } = userSlice.actions

export default userSlice.reducer;