import { Theme } from "@mui/system"

export default function DataGrid(theme: Theme) {
    return {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-main': {
                        backgroundColor: theme.palette.common.white,
                        height: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: theme.palette.secondary.main,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    },
                    '& .MuiDataGrid-columnsContainer': {
                        height: '40px'
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                        color: theme.palette.common.white,
                        padding: 0,
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        overflowX: 'visible'
                    },
                    '& .MuiDataGrid-footerContainer': {
                        backgroundColor: theme.palette.primary.main,
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        height: 30,
                        minHeight: 15
                    },

                    '& .MuiTablePagination-root': {
                        color: theme.palette.common.white,
                        padding: 0,
                    },
                    // '& .MuiSvgIcon-root': {
                    //     color: theme.palette.common.white,
                    // },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-sortIcon': {
                        color: theme.palette.common.white,
                    },
                    '& .MuiDataGrid-filterIcon': {
                        color: theme.palette.common.white,
                    },
                }
            }
        },
        MuiDataGridCell: {
            root: {
                "&:hover": {
                    cursor: "pointer"
                }
            }
        }
    }
}