import {Formik, FormikProps} from "formik";
import _ from "lodash";
import * as Yup from "yup";
import {AxcessLoanCompare, AxcessLoanFund} from "../../../../../../../types/valuationModelTypes";
import {fCurrency, fPercent} from "../../../../../../../utils/formatNumber";
// MUI
import {Box, Button, Grid, Typography} from "@mui/material";
// Store
import {useAppDispatch, useAppSelector} from "../../../../../../../store/store";
import {getValuationFunds} from "../../../../../../../store/valuationModel/selectors/generalSelectors";
import {FormInput} from "../../../../../../../components";
import {InputTypes} from "../../../../../../../types/InputTypes";
import {AdjustmentType, AdjustmentTypeFields} from "../../../../../../../types/valuationModelEnums";
import {
    addSingleAdjustment,
    addSingleScheduledAdjustment
} from "../../../../../../../store/valuationModel/valuationModelSlice";
import {getValuationDate} from "../../../../../../../store/version/versionSelector";
import {determineAdjustmentDiscountPremium} from "./index";


const initialValues = {
    transactionType: '',
    scheduled: false,
    startDate: null,
    endDate: null,
    fund: '',
    amount: 0,
    comment: '',
}

const SingleFeeSchema = Yup.object().shape({
    transactionType: Yup.string().required('Adjustment/Fee Type is required'),
    fund: Yup.string().required('Fund is required'),
    amount: Yup.number(),
    comment: Yup.string().required('Comment is required')
})

const SingleFeeForm = ({trancheId, tranche, setOpen}: {
    trancheId: number,
    tranche: AxcessLoanCompare,
    setOpen: (open: boolean) => void
}) => {
    const dispatch = useAppDispatch();

    const funds = useAppSelector(state => getValuationFunds(state));
    const valuationDate = useAppSelector(state => getValuationDate(state))

    const selectFunds = _.cloneDeep([...tranche.funds, ...(tranche.funds_before) ? tranche.funds_before : []])
        .sort((a, b) => (a.fund > b.fund) ? 1 : -1)
        .reduce((fundsArray: Array<AxcessLoanFund>, fund) => {
            if (fundsArray.findIndex(f => f.fund === fund.fund) === -1) {
                if (funds.includes(fund.fund)) fundsArray.push(fund)
            }

            return fundsArray;
        }, []);

    return (
        <Box sx={{width: '100%'}}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    if (values.scheduled) {
                        dispatch(addSingleScheduledAdjustment({
                            scheduledAdjustment: {
                                ...values,
                                trancheId,
                                valuationDate,
                            },
                            valuationDate
                        }))
                    } else {
                        dispatch(addSingleAdjustment({
                            ...values,
                            trancheId,
                            valuationDate,
                        }))
                    }
                    setOpen(false)
                }}
                validationSchema={SingleFeeSchema}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values
                    } = props;

                    let fund: AxcessLoanFund | undefined;

                    if (values.fund) {
                        fund = tranche.funds.find(f => f.fund === values.fund);
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid item container direction='row'>
                                    <FormInput
                                        id='transactionType'
                                        label='Adjustment/Fee Type'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={AdjustmentTypeFields.filter(a => a.value !== AdjustmentType.PL_ACCRUAL && a.value !== AdjustmentType.NET_REPAYMENT_PL)}

                                        size='small'
                                    />
                                    {values.transactionType !== AdjustmentType.WRITE_DOWN &&
                                        <>
                                            <FormInput
                                                id='scheduled'
                                                label='Schedule for Future'
                                                fieldType={InputTypes.CHECKBOX}
                                                layout={{xs: 6, md: 6, lg: 6}}

                                                size='small'
                                            />
                                            {values.scheduled &&
                                                <>
                                                    <FormInput
                                                        id='startDate'
                                                        label='Start Date'
                                                        fieldType={InputTypes.DATE}
                                                        layout={{xs: 6, md: 6, lg: 6}}
                                                        minDate={valuationDate ? new Date(new Date(valuationDate).setHours(0, 0, 0, 0)) : new Date()}

                                                        size='small'
                                                    />
                                                    <FormInput
                                                        id='endDate'
                                                        label='End Date'
                                                        fieldType={InputTypes.DATE}
                                                        layout={{xs: 6, md: 6, lg: 6}}
                                                        minDate={new Date()}

                                                        size='small'
                                                    />
                                                </>
                                            }
                                        </>

                                    }
                                    <FormInput
                                        id='fund'
                                        label='Fund'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={selectFunds.map((fund) => {
                                            return {label: fund.fund, value: fund.fund}
                                        })}

                                        size='small'
                                    />
                                    <Grid item container md={6} sx={{p: 1}}>
                                        {(fund?.commitment) &&
                                            <>
                                                <Typography variant="h5">
                                                    {values.fund} commitment: {fCurrency(fund.commitment)}
                                                </Typography>
                                            </>
                                        }
                                    </Grid>
                                    <FormInput
                                        id='amount'
                                        label='Amount'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 5, md: 5, lg: 5}}
                                        size='small'
                                    />
                                    <Grid item container md={1} sx={{p: 1}} justifyContent='center' alignItems='center'>
                                        <Typography>
                                            {determineAdjustmentDiscountPremium(values.transactionType, values.amount)}
                                        </Typography>
                                    </Grid>
                                    <Grid item container md={6} justifyContent='center' alignItems='center'>
                                        {(fund?.commitment) &&
                                            <Typography variant="h4">
                                                {fPercent(values.amount / fund.commitment)}
                                            </Typography>
                                        }
                                    </Grid>

                                    <FormInput
                                        id='comment'
                                        label='Comments'
                                        fieldType={InputTypes.TEXT_BOX}
                                        layout={{xs: 12, md: 12, lg: 12}}

                                        size='small'
                                    />
                                    <Grid item container justifyContent='center' alignItems='center' sx={{p: 0.5}}>
                                        <Button variant='contained' type='submit'>
                                            Confirm
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </Box>
    )
}

export default SingleFeeForm;