// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import { Page } from '../../components';
// sections
import { ChangePassword } from '../../sections/auth/changePassword';

// ----------------------------------------------------------------------

// User Profile Page
export default function Profile() {
  // const theme = useTheme();

  return (
    <Page title="Create User">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5, color: 'primary.main' }}>
          Profile
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <ChangePassword />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}