import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
import {fCurrency} from '../../../../utils/formatNumber';
// Local Imports
import {InputTypes} from '../../../../types/InputTypes';
// MUI
import {Button, Grid, Typography} from '@mui/material';
// Components
import {FormInput} from '../../../../components';
// Store
import {useAppDispatch, useAppSelector} from '../../../../store/store';
import {
    retrieveAllAxcessLoans,
    retrieveLoansAllocatedByTranche
} from "../../../../store/capitalBudget/selectors";
import {addSellRepTransaction} from "../../../../store/capitalBudget/capitalBudgetSlice";
import {checkDateSame} from "../../../../utils/DateUtils";
import {Loan} from "../../../../types/capitalBudgetTypes";

// ----------------------------------------------------------------------
// Initial Form State
const defaultTransactionValues = {
    portfolio: null,
    fund: '',
    amount: 0,
    date: null
}
// ----------------------------------------------------------------------

// Create Amendment Transaction
export default function SelldownRepaymentForm({onClose}: { onClose: () => void }) {
    const dispatch = useAppDispatch();

    const loans: Array<Loan> = useAppSelector(state => state.capitalBudget.thirdPartyData?.axcess.portfolio || []);
    const axcess = useAppSelector(state => retrieveAllAxcessLoans(state))
    const selldownRepayments = useAppSelector(state => state.capitalBudget.scenarioData?.selldownRepayments || [])

    return (
        <>
            <Formik
                initialValues={defaultTransactionValues}
                validationSchema={Yup.object().shape({
                    transfer: Yup.object()
                        .test({
                            name: 'overlappedTransfer',
                            exclusive: false,
                            params: {},
                            message: 'This transaction already exists. Please refer to the Selldowns and Partial Repayments Table.',
                            test: function () {
                                const {portfolio, fund, date} = this.parent;
                                if (portfolio && fund) {
                                    const index = selldownRepayments.findIndex(t => t.trancheId === portfolio.tranche_id && t.fund === fund && checkDateSame(t.date, date));
                                    if (index !== -1) return false;
                                }
                                return true
                            }
                        }),
                    date: Yup.date()
                        .typeError('Valid Date Required')
                        // .min(new Date(), 'Date must be after today.')
                        .required('Date is required'),
                    amount: Yup.string().min(0).test({
                        name: 'min',
                        exclusive: false,
                        params: {},
                        message: 'Reallocation must not exceed loan commitment amount.',
                        test: function (value) {
                            const {portfolio, fund} = this.parent;
                            const loan = loans.find(l => (l.tranche_id === portfolio.tranche_id && l.fund === fund))
                            return !!value && !!portfolio && !!loan && parseFloat(value) <= loan.commitment;
                        }
                    }).required('Amount Required.'),
                    fund: Yup.string().required('Required.').required('Required.')
                })}
                onSubmit={(values: any) => {
                    const transaction = {
                        axcessLoanId: values.portfolio.id,
                        trancheId: values.portfolio.tranche_id,
                        fund: values.fund,
                        amount: values.amount,
                        date: new Date(values.date).toDateString(),
                    }
                    dispatch(addSellRepTransaction(transaction));
                    onClose();
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values,
                    } = props;
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='portfolio'
                                        label='Portfolio'
                                        fieldType={InputTypes.SEARCH}
                                        values={axcess}
                                        labelFunc={(option) => `${option.tranche_id} - ${option.borrower}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <TransactionDetails trancheId={values.portfolio?.tranche_id || null}
                                                        formikProps={props}/>
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Add Transfer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}

type TransactionDetailsProps = {
    trancheId: number | null,
    formikProps: FormikProps<any>
}

const TransactionDetails = (props: TransactionDetailsProps) => {
    const {
        trancheId,
        formikProps
    } = props;

    const {
        errors,
        values
    } = formikProps;

    const loans = useAppSelector(state => retrieveLoansAllocatedByTranche(state, trancheId));

    const loan = loans.find(l => l.fund === values.fund);

    return (
        <>
            <FormInput
                id='fund'
                label='Fund'
                fieldType={InputTypes.SELECTION}
                layout={{xs: 12, md: 12, lg: 12}}

                values={loans.map(loan => {
                    return {label: loan.fund, value: loan.fund}
                })}
                disabled={!trancheId}
            />
            {errors.transfer &&
                <Grid container item xs={12}>
                    <Typography sx={{color: 'error.main'}} align='center'>
                        {errors.transfer as string}
                    </Typography>
                </Grid>
            }
            {loan &&
                <Grid container item xs={12}>
                    <Grid item xs={12} sx={{px: 2, py: 1}}>
                        <Typography variant='h4' sx={{color: 'primary.main'}}><b>Loan:</b></Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Start Date:</b></Typography><Typography variant='body1' sx={{pl: 5, fontSize: 16}}>{new Date(loan.start_date).toDateString()}</Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Maturity Date:</b></Typography><Typography variant='body1' sx={{pl: 5, fontSize: 16}}>{new Date(loan.maturity).toDateString()}</Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Current Allocation:</b></Typography><Typography variant='body1' sx={{pl: 5, fontSize: 16}}>{fCurrency(loan.commitment)}</Typography>
                    </Grid>
                </Grid>
            }
            <FormInput
                id='date'
                label='Transfer Date'
                fieldType={InputTypes.DATE}
                layout={{xs: 12, md: 12, lg: 12}}
                minDate={(!!loan) ? new Date(loan.start_date) : new Date()}
                maxDate={(!!loan) ? new Date(loan.maturity) : undefined}
                disabled={!values.fund}
            />
            <FormInput
                id='amount'
                label='Transfer Amount'
                fieldType={InputTypes.CURRENCY}
                layout={{xs: 12, md: 12, lg: 12}}

                disabled={!values.fund}
            />
        </>
    )
}