import { ReactNode, useMemo } from 'react';
// Material UI
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// -------------------------------------------------------------------------------

export default function ThemeProvider({ children }: { children: ReactNode}) {
    const themeOptions: any = useMemo(
      () => ({
        palette,
        shape: { borderRadius: 8 },
        typography,
        shadows,
        customShadows,
      }),
      []
    );
  
    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);
  
    return (
      <StyledEngineProvider injectFirst>
          {/* @ts-ignore */}
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </StyledEngineProvider>
    );
  }
  