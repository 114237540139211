import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import afrReducer from './afr/afrSlice';
import capitalBudgetReducer from './capitalBudget/capitalBudgetSlice';
import externalDataReducer from './externalData/externalDataSlice';
import generalReducer from './general/generalSlice';
import notificationReducer from './notifications/notificationSlice';
import scenarioReducer from './scenarios/scenerioSlice';
import userReducer from './user/userSlice';
import valuationModelReducer from "./valuationModel/valuationModelSlice";
import versionReducer from "./version/versionSlice";

export const store = configureStore({
    reducer: {
        afr: afrReducer,
        capitalBudget: capitalBudgetReducer,
        externalData: externalDataReducer,
        general: generalReducer,
        notifications: notificationReducer,
        scenarios: scenarioReducer,
        user: userReducer,
        valuationModel: valuationModelReducer,
        version: versionReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector