import {Scenario} from "../../types/capitalBudgetTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    createNewScenario,
    retrieveScenarios,
    saveAsScenario,
    saveScenario,
    setActiveScenario
} from "./scenarioFunctions";

export interface ScenarioState {
    scenarios: Array<Scenario>,
    scenario: Scenario | null,
    loading: boolean,
    saving: boolean,
}

const initialState: ScenarioState = {
    scenarios: [],
    scenario: null,
    loading: false,
    saving: false
}

// REDUCER
export const scenarioSlice = createSlice({
    name: 'scenario',
    initialState,
    reducers: {
        setScenarioById: (state, action: PayloadAction<number>) => {
            const index = state.scenarios.findIndex(s => s.id === action.payload);
            if (index !== -1) {
                state.scenario = state.scenarios[index];
            }
        },
    },
    extraReducers(builder) {
        builder
            // RETRIEVE SCENARIOS
            .addCase(retrieveScenarios.pending, (state) => {
                state.loading = true;
                state.scenarios = [];
            })
            .addCase(retrieveScenarios.fulfilled, (state, action) => {
                state.loading = false;
                state.scenarios = action.payload;
                if (state.scenario && state.scenario.id) {
                    const index = state.scenarios.findIndex(s => state.scenario?.id === s.id);
                    if (index !== -1) state.scenario = state.scenarios[index];
                } else {
                    const index = state.scenarios.findIndex(s => !!s.active);
                    if (index !== -1) state.scenario = state.scenarios[index];
                    else state.scenario = action.payload[0];
                }
            })
            .addCase(retrieveScenarios.rejected, (state) => {
                state.loading = false;
            })

            // SET ACTIVE
            .addCase(setActiveScenario.pending, (state) => {
                state.saving = true;
            })
            .addCase(setActiveScenario.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(setActiveScenario.rejected, (state) => {
                state.saving = false;
            })

            // SAVE SCENARIOS
            .addCase(saveScenario.pending, (state) => {
                state.saving = true;
            })
            .addCase(saveScenario.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(saveScenario.rejected, (state) => {
                state.saving = false;
            })

            // SAVE AS SCENARIOS
            .addCase(saveAsScenario.pending, (state) => {
                state.saving = true;
            })
            .addCase(saveAsScenario.fulfilled, (state, action) => {
                const index = state.scenarios.findIndex(s => action.payload === s.id);
                if (index !== -1) state.scenario = state.scenarios[index];
                state.saving = false;
            })
            .addCase(saveAsScenario.rejected, (state) => {
                state.saving = false;
            })

            // CREATE SCENARIO
            .addCase(createNewScenario.pending, (state) => {
                state.saving = true;
            })
            .addCase(createNewScenario.fulfilled, (state, action) => {
                const index = state.scenarios.findIndex(s => action.payload.scenarioId === s.id);
                if (index !== -1) state.scenario = state.scenarios[index];
                state.saving = false;
            })
            .addCase(createNewScenario.rejected, (state) => {
                state.saving = false;
            })
    }
})

export const {setScenarioById} = scenarioSlice.actions;

export default scenarioSlice.reducer;