import {Route, Routes} from "react-router-dom";
import AssetAllocationHelper from "./AssetAllocationHelper";

const InfoPages = () => {

    return (
        <Routes>
            <Route path='/assetAllocationComparison' element={<AssetAllocationHelper/>}/>
            {/*<Route path='/sourcesUses' element={<SourcesUsesDrillDown/>}/>*/}
        </Routes>
    )
}

export default InfoPages;