import {useEffect, useState} from "react";
// Local Imports
import {
    calculateBaseCapitalBudget,
} from "../../../../store/capitalBudget/capitalBudgetFunctions";
// Store
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {Button, Grid} from "@mui/material";

const domain = process.env.REACT_APP_AWS_MCP_LOCAL_URL || '';
const sender = new BroadcastChannel('asset_allocation_parent')
const receiver = new BroadcastChannel('asset_allocation_child')

// Button handling comparison between capital budget data
export default function ComparisonHandler() {
    const dispatch = useAppDispatch();

    // STATUS OF MESSAGES BROADCAST TO LIMIT SINGLE BROADCASTS AT A TIME
    const [messageSent, setMessageSent] = useState<boolean>(false);

    const [openWindow, setWindow] = useState<boolean>(false);

    const base = useAppSelector(state => state.capitalBudget.base);
    const updatedBudget = useAppSelector(state => state.capitalBudget?.capitalBudget || null);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const millions = useAppSelector(state => state.general.millions);
    const misc = useAppSelector(state => state.capitalBudget.misc);

    // SENDS LOADING INDICATOR FOR WHEN BUDGET IS CALCULATING
    useEffect(() => {
        if (openWindow && calculating) {
            sender.postMessage({type: 'LOADER'});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculating, base.calculating])

    // DETECT WHEN THE BUDGET OR BASE BUDGET HAS BEEN UPDATED AND SEND AN UPDATE ONCE THE DATA IS FINISHED CALCULATING
    useEffect(() => {
        if (openWindow && !messageSent && updatedBudget && base.baseBudget) {
            // SLEEP ALLOWS FOR THE OVERCOMING OF REACTS BATCH MECHANISM, THIS WILL PUSH OUT THE UPDATE TO THE NEXT RUN LOOP SO THE LOADING MESSAGE SENDS INDEPENDENTLY OF THE OTHER.
            setTimeout(() => {
                sender.postMessage({type: 'UPDATE', data: {header: `${(misc.fund) ? misc.fund.label : ''}`, base: base.baseBudget, updated: updatedBudget}})
            }, 10)
            setMessageSent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedBudget, base.baseBudget])

    // IF CAPITAL BUDGET IS CALCULATING RECALCULATE THE BASE BUDGET
    useEffect(() => {
        if (openWindow && calculating && !base.calculating) {
            dispatch(calculateBaseCapitalBudget())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [misc.fund, misc.weeks, misc.months])

    // IF MILLIONS STATE FOR ROUNDING IS CHANGED SEND UPDATE MESSAGE
    useEffect(() => {
        if (openWindow && !messageSent) {
            sender.postMessage({type: 'LOADER'});
            // SLEEP ALLOWS FOR THE OVERCOMING OF REACTS BATCH MECHANISM, THIS WILL PUSH OUT THE UPDATE TO THE NEXT RUN LOOP SO THE LOADING MESSAGE SENDS INDEPENDENTLY OF THE OTHER.
            setTimeout(() => {
                sender.postMessage({type: 'UPDATE_MILLIONS', data: millions})
            }, 10)
            setMessageSent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [millions])


    const handleWindowOpenClose = () => {
        if (openWindow) {
            sender.postMessage({type: 'CLOSE'});
        }
        setWindow(!openWindow);
    }

    // HANDLE CLOSE WINDOW
    receiver.onmessage = (e) => {
        const message = e.data;

        switch (message.type) {
            // WHEN WINDOW IS CONFIRMED OPEN SEND THE INITIAL DATA.
            case 'OPENED':
                sender.postMessage({type: 'UPDATE', data: {header: `${(misc.fund) ? misc.fund.label : ''}`, base: base.baseBudget, updated: updatedBudget}})
                setMessageSent(true);
                break;

            // CHANGE STATE OF APPLICATION WHEN THE STATE IS UPDATED
            case 'CLOSE':
                setWindow(false);
                break;

            // CONFIRM MESSAGE HAS BEEN RECEIVED BY WINDOW
            case 'RECEIVED':
                setMessageSent(false);
                break;

            default:
                break;
        }
    }

    // OPEN AND CALCULATE;
    useEffect(() => {
        if (openWindow) {
            dispatch(calculateBaseCapitalBudget())
            window.open(domain + '/info/assetAllocationComparison', 'asset_allocation', 'menubar=no,toolbar=no,location=no');
        }
    }, [openWindow, dispatch]);

    return <>
        <Grid item container xs={12} justifyContent='flex-end' sx={{pb: 1}}>
            <Button color='primary' variant='contained'
                    onClick={handleWindowOpenClose}>{openWindow ? 'Close' : 'Open'} Comparison</Button>
        </Grid>
    </>
}