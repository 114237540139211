import {useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
// Pages
import AccountBalances from "./AccountBalances";
import Capital from "./Capital"
import CapitalPosition from "./CapitalPosition";
import InterfundTransfers from "./InterfundTransfers";
import NewDeals from "./NewDeals";
import Page404 from "../Page404";
import Portfolio from "./Portfolio";
import Scenarios from "./Scenarios";
import SourcesUses from "./SourcesUses";
import SelldownRepayment from "./SelldownRepayment";
import Summary from "./Summary";
import BudgetForecast from "./BudgetForecast";
// Store;
import {useAppDispatch, useAppSelector} from "../../store/store";
import {retrieveScenarios} from "../../store/scenarios/scenarioFunctions";
import {calculateCapitalBudget, getCapitalBudgetData} from "../../store/capitalBudget/capitalBudgetFunctions";
import usePrevious from "../../hooks/usePrevious";

const CapitalBudgetPages = () => {
    const dispatch = useAppDispatch();
    const scenario = useAppSelector(state => state.scenarios.scenario);
    const scenarios = useAppSelector(state => state.scenarios.scenarios);
    const checkCalcStatus = useAppSelector(state => state.capitalBudget.scenarioData && state.capitalBudget.thirdPartyData && !state.capitalBudget.calculating)
    const calculating = useAppSelector(state => state.capitalBudget.calculating)
    const user = useAppSelector(state => state.user.user);

    const error = useAppSelector(state => state.capitalBudget.error)

    const prevScenario = usePrevious(scenario);
    const prevCalcStatus = usePrevious(calculating)

    if (process.env.REACT_APP_ENV !== 'LOCAL_DEV' && user) {
        window.onbeforeunload = () => {
            return ''
        }
    }

    // Retrieves Scenarios
    useEffect(() => {
        if (scenarios.length === 0) {
            dispatch(retrieveScenarios());
        }
    }, [dispatch, scenarios.length])

    // Retrieve Scenario Data
    useEffect(() => {
        if (scenario && !error && (scenario !== prevScenario)) {
            dispatch(getCapitalBudgetData(scenario))
        } else if (!!scenario && checkCalcStatus && !prevCalcStatus) {
            dispatch(calculateCapitalBudget())
        }
    }, [scenario, error, prevScenario, dispatch, checkCalcStatus, prevCalcStatus])

    return (
        <Routes>
            <Route path='/' element={<Navigate to='summary'/>}/>
            <Route path='/accountBalances' element={<AccountBalances/>}/>
            <Route path='/capital' element={<Capital/>}/>
            <Route path='/capitalPosition' element={<CapitalPosition/>}/>
            <Route path='/interfundTransfer' element={<InterfundTransfers/>}/>
            <Route path='/newDeals' element={<NewDeals/>}/>
            <Route path='/portfolio' element={<Portfolio/>}/>
            <Route path='/scenarios' element={<Scenarios/>}/>
            <Route path='/selldownRepayments' element={<SelldownRepayment/>}/>
            <Route path='/sourcesUses' element={<SourcesUses/>}/>
            <Route path='/summary' element={<Summary/>}/>
            <Route path='/budgetForecast' element={<BudgetForecast/>}/>

            <Route path='/*' element={<Page404/>}/>

        </Routes>
    )
}

export default CapitalBudgetPages;