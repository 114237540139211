import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
// Local imports
import {
    OtherTransactionsTypeInputLabels,
    OtherTransactionTypes
} from "../../../../types/capitalBudgetEnums";
import {MCPFund} from "../../../../types/capitalBudgetTypes";
import {FormInput} from "../../../../components";
import {InputTypes} from "../../../../types/InputTypes";
// MUI
import {Button, Divider, Grid} from "@mui/material";
// Store
import {retrieveFundsSelectFormat} from "../../../../store/capitalBudget/selectors";
import {useAppSelector} from "../../../../store/store";

interface NewTransactionProps {
    editValues: any | null,
    onClose: () => void,
    submitTransaction: (values: any) => void,
    editTransaction: (values: any) => void
}

// New Capital Transaction
export default function NewManualTransaction({
                                                 editValues,
                                                 onClose,
                                                 submitTransaction,
                                                 editTransaction
                                             }: NewTransactionProps) {

    const funds = useAppSelector(state => retrieveFundsSelectFormat(state));
    const fund = useAppSelector(state => state.capitalBudget?.misc.fund)

    const TransactionSchema = Yup.object().shape({
        transactionType: Yup.mixed<OtherTransactionTypes>().oneOf(Object.values(OtherTransactionTypes)).required('Transaction Type is required'),
        date: Yup.date().typeError('Valid date required.').required('Transaction date is required'),
        fund: Yup.mixed<MCPFund>().nullable(),
        amount: Yup.number().required('Amount is required'),
        notes: Yup.string(),
    });

    const transactionValues = {
        transactionType: '',
        date: '',
        fund: (fund) ? fund.id : '',
        amount: '',
        notes: '',
        capital: true,
        cash: true
    };

    return (
        <>
            <Formik
                initialValues={editValues?.id ? {...editValues, fund: (editValues.fund || '')} : transactionValues}
                validationSchema={TransactionSchema}
                onSubmit={(values: any) => {
                    const newValues = {...values};

                    if (newValues.fund) {
                        newValues.fundId = newValues.fund;
                        const fund = funds.find(f => f.value === newValues.fundId);
                        if (fund) {
                            newValues.fund = fund.label;
                            newValues.date = new Date(newValues.date).toDateString();
                        } else {
                            newValues.fund = null;
                            newValues.fundId = null;
                        }
                    } else {
                        newValues.fund = null;
                        newValues.fundId = null;
                    }

                    if (editValues?.id) {
                        editTransaction(newValues)
                    } else {
                        submitTransaction(newValues);
                    }

                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                    } = props;
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='transactionType'
                                        label='Transaction Type'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}

                                        values={OtherTransactionsTypeInputLabels.map(ot => {
                                            return {label: ot.label, value: ot.id}
                                        })}
                                    />
                                    <FormInput
                                        id='date'
                                        label='Date'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='fund'
                                        label='Fund'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={funds}
                                    />
                                    <FormInput
                                        id='amount'
                                        label='Amount'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        numFormatProps={{decimalScale: 2}}
                                    />
                                    <FormInput
                                        id='notes'
                                        label='Additional Notes'
                                        fieldType={InputTypes.TEXT_BOX}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <Grid item xs={12} sx={{p:2}}>
                                        Include In:
                                    </Grid>
                                    <FormInput
                                        id='capital'
                                        label='Capital'
                                        fieldType={InputTypes.CHECKBOX}
                                        layout={{xs: 3, md: 3, lg: 3}}
                                    />
                                    <FormInput
                                        id='cash'
                                        label='Cash'
                                        fieldType={InputTypes.CHECKBOX}
                                        layout={{xs: 3, md: 3, lg: 3}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item container direction='row'>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {`${(editValues?.id) ? 'Update' : 'Create'} Transaction`}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}