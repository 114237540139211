import {createAsyncThunk} from "@reduxjs/toolkit";
import {multipleAsyncGetRequest} from "../apiUtils";
import {addNotification} from "../notifications/notificationSlice";


// Retrieves list of ncino and axcess data sets
export const retrieveExternalDataLists = createAsyncThunk('external-data', async(_, thunkAPI) => {
    try {
        let {
            accountBalance,
            axcess,
            ncino,
            holding
        } = await multipleAsyncGetRequest({
            accountBalance: 'external-data/account-balance',
            axcess: 'external-data/axcess',
            ncino: 'external-data/ncino',
            holding: 'external-data/holding'
        })

        return {
            accountBalance,
            axcess,
            ncino,
            holding
        }
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred retrieving scenarios';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})