import { Outlet } from 'react-router-dom';
// MUI
import { styled } from "@mui/material/styles";
import { Grid } from '@mui/material';
import MinimalNavbar from "./MinimalNavbar";

// ----------------------------------------------------------------------

const APP_BAR = 64;

const RootStyle = styled('div')({
    display: 'flex',
    maxHeight: '100%',
    overflow: 'none'
});

const MainStyle = styled('div')(({ theme }) => ({
    overflow: 'none',
    height: '100vh',
    width: `100%`,
    paddingTop: APP_BAR,
    backgroundColor: theme.palette.grey[200],
}));


export default function MinimalDashboardLayout() {

    return (
        <RootStyle>
            <MinimalNavbar />
            <MainStyle>
                <Grid
                    sx={{
                        pt: 5,
                        px: 2,
                        overflow: 'auto',
                        height: '100%',
                        overflowX: 'visible'
                    }}
                >
                    <Outlet />
                </Grid>
            </MainStyle>

        </RootStyle>
    )
}