import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {FundTransfers} from "../../../types/capitalBudgetTypes";
import {addValues} from "../../../utils/mathUtil";

// Reducer Related to Capital Budget - Reallocation (Interfund Transfers)
const reallocationReducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Add fund transfer
    addTransfer: {
        prepare(newTransfer: any) {
            return {
                payload: {
                    ...newTransfer,
                    id: nanoid()
                }
            }
        },
        reducer(state, action: PayloadAction<any>) {
            const newTransfer = action.payload;
            if (state.scenarioData) {
                const fund = state.scenarioData.funds.find(f => f.label === newTransfer.fromFund);
                const index = state.scenarioData.transfers.findIndex(t => t.trancheId === newTransfer.trancheId && t.fromFund === newTransfer.fromFund && t.status !== SaveStatus.REMOVED);
                if (index === -1 && fund) {
                    newTransfer.fromFundId = fund.id;
                    newTransfer.status = SaveStatus.NEW;
                    state.scenarioData.transfers = [...state.scenarioData.transfers, newTransfer];
                }
            }
        }
    },
    // Update transfer
    updateTransfer: (state, action: PayloadAction<FundTransfers>) => {
        if (state.scenarioData) {
            const index = state.scenarioData.transfers.findIndex(d => d.id === action.payload.id && d.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;
                const existing = state.scenarioData.transfers[index];

                if (action.payload.status === SaveStatus.EDITED) {

                    if (existing.newAllocation !== action.payload.newAllocation) changed = true;
                    action.payload.transfers.forEach(t => {
                        const transfer = existing.transfers.find(ot => ot.fund === t.fund);
                        if (!!transfer && t.amount !== transfer.amount) changed = true;
                    })
                }
                if (action.payload.status === SaveStatus.NEW || changed) {
                    let total = action.payload.newAllocation;
                    action.payload.transfers.forEach(transfer => {
                        total = addValues(total, transfer.amount);
                    })
                    if (action.payload.originalAllocation === total) {
                        if (action.payload.status === SaveStatus.EDITED) {
                            action.payload.previous = (!existing.previous) ? existing : existing.previous;
                        }
                        state.scenarioData.transfers[index] = action.payload;
                    }
                }
            }
        }
    },
    // Remove Transfer
    removeTransfer: (state, action: PayloadAction<string>) => {
        if (state.scenarioData) {
            const index = state.scenarioData.transfers.findIndex(d => d.id === action.payload && d.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.scenarioData.transfers[index].status !== SaveStatus.NEW) {
                    state.scenarioData.transfers[index].status = SaveStatus.REMOVED;
                } else {
                    const transfers = state.scenarioData.transfers;
                    transfers.splice(index, 1);
                    state.scenarioData.transfers = transfers;
                }
            }
        }
    }
}

export default reallocationReducers;