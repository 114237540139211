import * as Yup from 'yup';
import { Formik, FormikProps } from "formik";
// MUI
import { Grid, Typography, CircularProgress, Button } from "@mui/material";
// Local imports
import { InputTypes } from "../../../types/InputTypes";
import { formatDate } from '../../../utils/DateUtils';
// Components
import { FormInput } from "../../../components";
// Store
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { createUser, updateUserAttributes } from "../../../store/user/userFunctions";

// Validation Schema
const UserSchema = Yup.object().shape({
    givenName: Yup.string().required('Given Name is required'),
    familyName: Yup.string().required('Family Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    birthdate: Yup.date().typeError('Valid date required.').required('Birth date is required'),
    role: Yup.string().required('User Role is required.')
});

const initialValues = {
    givenName: '',
    familyName: '',
    email: '',
    birthdate: null,
    role: 'user'
}


// Creates User Form
export default function CreateUserForm({ updateValues }: { updateValues?: any }) {
    const dispatch = useAppDispatch()
    const loading = useAppSelector(state => state.user.createUser.loading);
    const user = useAppSelector(state => state.user.createUser.user);

    return (
        <>
            <Formik
                initialValues={(updateValues) ? updateValues : initialValues}
                validationSchema={UserSchema}
                onSubmit={async (values: any) => {
                    if (!!updateValues) {
                        dispatch(updateUserAttributes({
                            givenName: values.givenName,
                            familyName: values.familyName,
                            email: values.email,
                            birthdate: formatDate(values.birthdate),
                            role: values.role
                        }));
                    } else {
                        dispatch(createUser({
                            givenName: values.givenName,
                            familyName: values.familyName,
                            email: values.email,
                            birthdate: formatDate(values.birthdate),
                            role: values.role
                        }))
                    }
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit} id='login'>
                                <Grid container >
                                    <FormInput
                                        id='givenName'
                                        label='Given Name'
                                        fieldType={InputTypes.STRING}
                                        layout={{ xs: 6, md: 6, lg: 6 }}
                                    />
                                    <FormInput
                                        id='familyName'
                                        label='Family Name'
                                        fieldType={InputTypes.STRING}
                                        layout={{ xs: 6, md: 6, lg: 6 }}
                                    />
                                    <FormInput
                                        id='email'
                                        label='Email'
                                        fieldType={InputTypes.STRING}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                        disabled={!!updateValues}
                                    />
                                    <FormInput
                                        id='birthdate'
                                        label='Date of Birth'
                                        fieldType={InputTypes.DATE}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                    />
                                    <FormInput
                                        id='role'
                                        label='Role'
                                        fieldType={InputTypes.SELECTION}
                                        values={[
                                            { label: 'Admin', value: 'admin' },
                                            { label: 'User', value: 'user' },
                                        ]}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                    />
                                    {loading ?
                                        <Grid container justifyContent='center' direction='row'>
                                            <CircularProgress />
                                        </Grid>
                                        :
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            sx={{ mx: 1, my: 2 }}
                                        >
                                            {updateValues ?
                                                <>Update User</>
                                                :
                                                <>Create User</>
                                            }
                                        </Button>
                                    }
                                    <Grid container justifyContent='flex-start' direction='row'>
                                        {!!user &&
                                            <Typography>Account Created for {user.email}.</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}