import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// MUI
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
// Components
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const APP_BAR = 64;
const DRAWER_WIDTH = 220;
const DRAWER_WIDTH_CLOSED = 65;

const RootStyle = styled('div')({
	display: 'flex',
	maxHeight: '100%',
	overflow: 'none'
});

const MainStyle = styled('div')(({ theme }) => ({
	overflow: 'none',
	height: '100vh',
	width: `calc(100% - ${DRAWER_WIDTH}px)`,
	paddingTop: APP_BAR,
	backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
	const [open, setOpen] = useState<boolean>(true);

	return (
		<RootStyle>
			<DashboardNavbar onOpenSidebar={() => setOpen(!open)} />
			<DashboardSidebar open={open} />
			<MainStyle
				sx={{
					width: `calc(100% - ${(open) ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED}px)`,
				}}
			>
				<Grid
					sx={{
						pt: 5,
						px: 2,
						overflow: 'auto',
						height: '100%',
						overflowX: 'visible'
					}}
				>
					<Outlet />
				</Grid>
			</MainStyle>
		</RootStyle>
	);
}
