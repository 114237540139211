import {Navigate, Route, Routes} from "react-router-dom";
// Pages
import Page404 from "../Page404";
import Summary from "./Summary";
const SCurveArmPages = () => {

    return (
        <Routes>
            <Route path='/' element={<Navigate to='summary'/>}/>


            <Route path='/summary' element={<Summary />} />

            <Route path='/*' element={<Page404/>}/>

        </Routes>
    )
}

export default SCurveArmPages;