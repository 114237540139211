import React, {ReactElement} from "react";
// MUI
import {
    Box, Grid, SxProps,
    Table, TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {useAppSelector} from "../../../store/store";
import {getTranche} from "../../../store/afr/afrSelector";
import {
    Block as BlockIcon,
    FiberNew as NewIcon,
    HelpCenter as NotFoundIcon,
    NewReleases as WarningIcon
} from "@mui/icons-material";
import {AFRAxcessLoanCompare} from "../../../types/AssetFundRegisterTypes";
import {formatDate} from "../../../utils/DateUtils";
import {fCurrency, fPercent} from "../../../utils/formatNumber";
import _ from "lodash";
import {addValues} from "../../../utils/mathUtil";
import {InfoTable} from "../../../components";
import {AxcessLoanFund} from "../../../types/valuationModelTypes";

const TrancheDetails = ({trancheId}: { trancheId: number }) => {
    const tranche = useAppSelector(state => getTranche(state, trancheId));

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {!tranche ?
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <NotFoundIcon
                        sx={{
                            color: 'grey.400',
                            fontSize: 200,
                        }}
                    />
                    <Typography
                        sx={{
                            color: 'grey.400',
                        }}
                        variant='h3'
                    >
                        Tranche Not Found.
                    </Typography>
                </Box>
                :
                <>
                    <TrancheBanner tranche={tranche}/>
                    <TrancheInformation tranche={tranche}/>
                    {tranche.audCommitment &&
                        <Typography variant='h6' sx={{px: 4}}>{tranche.base_currency}</Typography>
                    }
                    <FundTable allFunds={tranche.allFunds} funds={tranche.funds} funds_before={tranche.funds_before}/>
                    {
                        tranche.audCommitment &&
                        <>
                            <Typography variant='h6' sx={{px: 4}}>AUD</Typography>
                            <FundTable allFunds={tranche.allFunds} funds={tranche.audFunds} funds_before={tranche.audFundsBefore}/>
                        </>
                    }
                    <TrancheProperties tranche={tranche}/>
                </>
            }
        </Box>
    )
}

const TrancheBanner = ({tranche}: { tranche: AFRAxcessLoanCompare }) => {
    return (
        <>
            {(tranche.new) &&
                <Banner icon={<NewIcon/>} text='New Loan.' color='success.light'/>
            }
            {(tranche.removed) &&
                <Banner icon={<BlockIcon/>} text='Loan has been closed.' color='error.light'/>
            }
            {(Object.keys(tranche.change).length > 0) &&
                <Banner icon={<WarningIcon/>} text='Loan has been modified.' color='warning.light'/>
            }
        </>
    )
}

const Banner = ({icon, text, color}: { icon: ReactElement, text: string, color: string }) => {
    return (
        <Box
            sx={{
                p: 2,
                mb: 1,
                borderRadius: 1,
                bgcolor: color,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {React.cloneElement(icon, {sx: {color: 'grey.700'}})}
            <Typography
                variant='h6'
                sx={{
                    pl: 1,
                    color: 'grey.700',
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}

// Basic Tranche Information
const TrancheInformation = ({tranche}: { tranche: AFRAxcessLoanCompare }) => {

    const information = [
        {label: 'Tranche ID', info: tranche.tranche_id},
        {label: 'Asset ID', info: tranche.asset_id},
        {label: 'Client ID', info: tranche.client_id},
        {label: 'Tranche Name', info: tranche.tranche},
        {label: 'Borrower', info: tranche.borrower},
        {label: 'Asset Name', info: tranche.asset},
        {
            label: 'Start Date',
            info: formatDate(tranche.start_date, 'dd-MMM-yyyy'),
        },
        {
            label: 'Maturity',
            info: formatDate(tranche.maturity, 'dd-MMM-yyyy'),
            previous: (tranche.change.maturity) ? formatDate(tranche.change.maturity, 'dd-MMM-yyyy') : null
        },
        {
            label: 'Commitment',
            info: fCurrency(tranche.commitment),
            previous: (tranche.change.commitment) ? fCurrency(tranche.change.commitment as number) : null
        },
    ]

    return (
        <Box
            sx={{
                pl: 10,
                p: 2
            }}
        >
            <Grid container spacing={2} justifyContent='center'>
                <Grid item md={12} alignItems='center'>
                    <Box
                        sx={{
                            p: 2
                        }}
                    >
                        <Table>
                            <TableBody>
                                {information.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell
                                            sx={{
                                                color: 'primary.main',
                                                fontSize: 15,
                                                p: 0,
                                                border: 'none',
                                                width: 130
                                            }}
                                        >
                                            <b>{row.label}:</b>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: 15,
                                                flex: 1,
                                                p: 0,
                                                border: 'none'
                                            }}
                                        >
                                            {row.previous &&
                                                <><s>{row.previous}</s>    </>
                                            }
                                            {row.info}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}

function updateTotals(totals: { drawn: number, undrawn: number, commitment: number }, fund: AxcessLoanFund) {
    return {
        drawn: addValues(totals.drawn, fund.drawn),
        undrawn: addValues(totals.undrawn, fund.undrawn),
        commitment: addValues(totals.commitment, fund.commitment),
    }
}

// Tranche Fund Split
const FundTable = ({allFunds, funds, funds_before}: { allFunds: Array<string>, funds: Array<AxcessLoanFund>, funds_before: Array<AxcessLoanFund> }) => {
    let data: Array<{
        label: string,
        previous: string | { drawn: string, undrawn: string, commitment: string },
        current: string | { drawn: string, undrawn: string, commitment: string },
        sx?: SxProps
    }> = [
        {label: 'Tranche Fund Split', previous: 'Previous', current: 'Current', sx: {borderBottom: 1}}
    ]

    let totalCurrent = {
        drawn: 0,
        undrawn: 0,
        commitment: 0,
    };
    let totalPrevious = {
        drawn: 0,
        undrawn: 0,
        commitment: 0,
    };

    let different = false;

    _.cloneDeep(allFunds).sort().forEach(fund => {
        const previous = funds_before.find(f => f.fund === fund);
        const current = funds.find(f => f.fund === fund);

        if (!previous && current) {
            different = true;

            data.push({
                label: fund,
                current: {
                    drawn: fCurrency(current.drawn),
                    undrawn: fCurrency(current.undrawn),
                    commitment: fCurrency(current.commitment),
                },
                previous: {
                    drawn: fCurrency(0),
                    undrawn: fCurrency(0),
                    commitment: fCurrency(0),
                }
            })
            totalCurrent = updateTotals(totalCurrent, current);
        } else if (!current && previous) {
            different = true;

            data.push({
                label: fund,
                current: {
                    drawn: fCurrency(0),
                    undrawn: fCurrency(0),
                    commitment: fCurrency(0),
                },
                previous: {
                    drawn: fCurrency(previous.drawn),
                    undrawn: fCurrency(previous.undrawn),
                    commitment: fCurrency(previous.commitment),
                }
            })
            totalPrevious = updateTotals(totalPrevious, previous);
        } else if (previous && current) {
            if (previous.commitment !== current.commitment) different = true;

            data.push({
                label: fund,
                current: {
                    drawn: fCurrency(current.drawn),
                    undrawn: fCurrency(current.undrawn),
                    commitment: fCurrency(current.commitment),
                },
                previous: {
                    drawn: fCurrency(previous.drawn),
                    undrawn: fCurrency(previous.undrawn),
                    commitment: fCurrency(previous.commitment),
                }
            })
            totalCurrent = updateTotals(totalCurrent, current);
            totalPrevious = updateTotals(totalPrevious, previous);
        }
    })

    data.push({
        label: 'Total Commitment',
        current: {
            drawn: fCurrency(totalCurrent.drawn),
            undrawn: fCurrency(totalCurrent.undrawn),
            commitment: fCurrency(totalCurrent.commitment),
        },
        previous: {
            drawn: fCurrency(totalPrevious.drawn),
            undrawn: fCurrency(totalPrevious.undrawn),
            commitment: fCurrency(totalPrevious.commitment),
        },
        sx: {borderTop: 1}
    })

    return (
        <Box
            sx={{
                mx: 2,
                p: 2
            }}
        >
            <Table>
                <TableBody>
                    {
                        data.map((row, d) => (
                                <TableRow key={d}>
                                    <TableCell sx={{p: '2px', border: 'none', ...row.sx}}>
                                        <b>{row.label}:</b>
                                    </TableCell>
                                    {different &&
                                        <>
                                            {typeof (row.previous) === 'string' ?
                                                <>
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    >
                                                        <b>{row.previous}</b>
                                                    </TableCell>
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    >
                                                        <b>Drawn</b>
                                                    </TableCell>
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    >
                                                        <b>Undrawn</b>
                                                    </TableCell>
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    >
                                                        <b>Commitment</b>
                                                    </TableCell>
                                                </>
                                                :
                                                <>
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    />
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    >
                                                        {row.previous.drawn}
                                                    </TableCell>
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    >
                                                        {row.previous.undrawn}
                                                    </TableCell>
                                                    <TableCell
                                                        align='right'
                                                        sx={{p: '2px', border: 'none', bgcolor: 'grey.300', ...row.sx}}
                                                    >
                                                        {row.previous.commitment}
                                                    </TableCell>
                                                </>
                                            }
                                        </>
                                    }
                                    {typeof (row.current) === 'string' ?
                                        <>
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            >
                                                <b>{row.current}</b>
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            >
                                                <b>Drawn</b>
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            >
                                                <b>Undrawn</b>
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            >
                                                <b>Commitment</b>
                                            </TableCell>
                                        </>
                                        :
                                        <>
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            />
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            >
                                                {row.current.drawn}
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            >
                                                {row.current.undrawn}
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                sx={{p: '2px', border: 'none', ...row.sx}}
                                            >
                                                {row.current.commitment}
                                            </TableCell>
                                        </>
                                    }
                                </TableRow>
                            )
                        )
                    }
                </TableBody>
            </Table>
        </Box>
    )
}

// In-Depth Tranche Properties
const TrancheProperties = ({tranche}: { tranche: AFRAxcessLoanCompare }) => {

    const data: Array<{ label: string, value: string, sx?: SxProps }> = [
        {label: 'APRA Business Type', value: tranche.apra_business_type},
        {label: 'APRA Facility Type', value: tranche.apra_facility_type},
        {label: 'APRA Industry', value: tranche.apra_industry},
        {label: 'APRA Lending Category', value: tranche.apra_lending_category},
        {label: 'APRA Purpose', value: tranche.apra_purpose},
        {label: 'Currency', value: tranche.base_currency},
        {label: 'Base Rate Floor', value: tranche.base_rate_floor ? fPercent(tranche.base_rate_floor) : '-'},
        {label: 'Drawn Margin', value: fPercent(tranche.drawn_margin)},
        {label: 'Facility Fee Rate', value: fPercent(tranche.facility_fee_rate_p)},
        {label: 'Facility Fee Type', value: tranche.facility_fee_type},
        {label: 'Interest Type', value: tranche.interest_type},
        {label: 'Investment Type', value: tranche.investment_type},
        {label: 'Margin Type', value: tranche.margin_type},
        {label: 'Pricing Type', value: tranche.pricing_type},
        {label: 'MCP Rating', value: tranche.rating_mcp},
        {label: 'Tenor', value: tranche.remaining_tenor_yrs.toString()},
        {label: 'Security', value: (tranche.security) ? 'YES' : 'NO'},
        {label: 'Debt Seniority', value: tranche.seniority_rank},
        {label: 'S&P Industry', value: tranche.sp_industry},
        {label: 'S&P Industry Group', value: tranche.sp_industry_group},
        {label: 'S&P Sector', value: tranche.sp_sector},
        {label: 'S&P Sub Industry', value: tranche.sp_sub_industry},
        {label: 'State', value: tranche.state},
        {label: 'Tranche Type', value: tranche.tranche_type},
    ]

    return (
        <Box
            sx={{
                mx: 2,
                p: 2,
                width: '50%'
            }}
        >
            <Typography variant="h6">
                Tranche Details
            </Typography>
            <InfoTable data={data}/>
        </Box>
    )
}

export default TrancheDetails;