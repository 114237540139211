import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// MUI
import { styled } from '@mui/material/styles';
import { Card, CircularProgress, Container, Grid, Typography } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { Logo, Page } from '../../components';
// Store
import { useAppDispatch, useAppSelector } from '../../store/store';
import {ConfirmUserForm} from "../../sections/auth/confirmUser";

// ----------------------------------------------------------------------
// Styles

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

// Redirected Page from email to confirm users email account
export default function ConfirmNewUser() {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const loading = useAppSelector(state => state.user.loading);
    const verification = useAppSelector(state => state.user.verification);

    // Checks if users has been verified and clears params
    useEffect(() => {
        if (verification === 'SUCCESS') {
            setSearchParams('')
        }   
    }, [setSearchParams, verification])

    // Retrieves verification items from query params
    const code = searchParams.get('code');
    const email = searchParams.get('email');

    // sends verification check
    useEffect(() => {
        if (!loading && !verification && code && email) {
        }
    }, [code, dispatch, email, loading, verification])


    const mdUp = useResponsive('up', 'md');

    return (
        <Page title="Confirm Account">
            <RootStyle>
                <HeaderStyle>
                    <Logo white={false} />
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome
                        </Typography>
                    </SectionStyle>
                )}


                <Container maxWidth="sm" >
                    <ContentStyle>
                        {!loading && verification === null &&
                            <ConfirmUserForm />
                        }
                        {((verification !== 'SUCCESS') && (!code || !email)) &&
                            <Grid container justifyContent='center' direction='row'>
                                <Typography variant="h5" sx={{ color: 'text.secondary', my: 2 }}>Could not verify User. Please contact help.</Typography>
                            </Grid>
                        }
                        {loading &&
                            <Grid container justifyContent='center' direction='row'>
                                <CircularProgress size={300} />
                            </Grid>
                        }
                        {!loading && verification === 'SUCCESS' &&
                            < Grid container justifyContent='center' direction='row'>
                                <Typography variant='h3' sx={{ color: 'text.secondary', my: 2 }}>User verified.</Typography>

                                <Typography sx={{ color: 'text.secondary', my: 2 }}>Reset password instructions have been sent you your email. Please follow the directions as necessary.</Typography>

                            </Grid>
                        }
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page >
    );
}
