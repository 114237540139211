import _ from "lodash";
import {RootState} from "../../store";
import {
    CalculationLoanType,
    FundTransfers,
    Loan,
    MCPFund,
    PortfolioChanges,
    SelldownRepayment
} from "../../../types/capitalBudgetTypes";
import {AmendmentType, FundType, SaveStatus} from "../../../types/capitalBudgetEnums";
import {daysBetweenDates} from "../../../utils/DateUtils";
import {addValues} from "../../../utils/mathUtil";
import {groupDebtSeniority} from "../../../utils/CapitalBudgetUtils";
import {createSelector} from "@reduxjs/toolkit";

const summedFields = [
    'accrued_fees',
    'accrued_interest',
    'commitment',
    'drawn',
    'total_accruals',
    'undrawn'
]

// Retrieves all loans grouped by Tranche
export const portfolioLoansAll = createSelector(
    (state: RootState) => state.capitalBudget.thirdPartyData?.axcess.portfolio,
    (state: RootState) => state.capitalBudget.misc.fund,
    (state: RootState) => state.capitalBudget.scenarioData?.portfolioChanges || [],
    (portfolio, fund, amendments) => {
        let loans = portfolio as Array<Loan>;

        if (!loans) return [];

        if (fund) {
            loans = loans.reduce((r: any, o: any) => {
                if (fund.label === o.fund) {
                    r.push(o);
                } else {
                    if (fund.type === FundType.FEEDER) {
                        const holding = fund.holdings.find(h => h.fund === o.fund)
                        if (holding) {
                            const loan = _.cloneDeep(o)
                            const holdingPer = holding.percentage;
                            summedFields.forEach(field => {
                                loan[field] = loan[field] * holdingPer;
                            })
                            r.push(loan)
                        }
                    }
                }
                return r;
            }, [])
        }

        // Group all loans by Tranche ID and sum necessary fields.
        const portfolioLoans = [...loans.reduce((r: any, o: any) => {
            const key = o.tranche_id;

            let itemObject: any = {};

            summedFields.forEach((field: string) => {
                itemObject[field] = 0;
            })

            const item = r.get(key) || Object.assign({}, o, itemObject);

            summedFields.forEach(field => {
                item[field] = addValues(item[field], o[field]);
            })

            return r.set(key, item);
        }, new Map()).values()]

        amendments.forEach(amendment => {
            if (amendment.status !== SaveStatus.REMOVED) {
                for (let t = 0; t < portfolioLoans.length; t++) {
                    const tranche = portfolioLoans[t];
                    if (tranche.tranche_id === amendment.trancheId) {
                        portfolioLoans[t] = {
                            ...tranche,
                            ...amendment,
                        }
                        break;
                    }
                }
            }
        })

        return portfolioLoans;
    }
)

// Retrieves Portfolio of loans and portfolio changes for those loans.
// Formats the loan so that it is standardized with processing.
// The additional Fund parameter allows for filtering of loans assigned to a fund.
export const retrievePortfolioLoansForCalc = (state: RootState, fund: MCPFund | null, base: boolean = false): Array<CalculationLoanType> => {
    let loans = state.capitalBudget.thirdPartyData?.axcess.portfolio;
    const budgetDate = state.capitalBudget.thirdPartyData?.dateOfDataSet;

    const portfolioChanges = state.capitalBudget.scenarioData?.portfolioChanges;
    const selldownRepayments = state.capitalBudget.scenarioData?.selldownRepayments;
    const transfers = state.capitalBudget.scenarioData?.transfers;

    let transactions: Array<any> = [];

    if (!loans || !portfolioChanges || !selldownRepayments || !transfers || !budgetDate) return [];

    loans.forEach(loan => {
        // FUND CT OVERRIDE FOR FST
        let loanFund = loan.fund;
        if (fund && fund.label !== 'FST') {
            if (loanFund === 'FST') {
                loanFund = 'CT'
            }
        }

        transactions.push({
            trancheId: loan.tranche_id,
            client: loan.client_id,
            asset: loan.asset_id,
            fund: loanFund,
            name: loan.borrower,
            value: loan.commitment,
            undrawn: +loan.undrawn,
            drawn: +loan.drawn,
            drawnPercentage: loan.drawn_p,
            baseRate: loan.base_rate,
            margin: loan.drawn_margin,
            facilityFee: loan.facility_fee_type,
            facilityFeeRate: loan.facility_fee_rate_p,
            baseRateFloor: loan.base_rate_floor,
            tenor: loan.remaining_tenor_yrs,
            rating: loan.rating_mcp ? loan.rating_mcp.toUpperCase() : null,
            ranking: groupDebtSeniority(loan.seniority_rank),
            interestType: loan.pricing_type,
            investmentType: loan.investment_type,
            industry: loan.sp_industry,
            industryGroup: loan.sp_industry_group,
            industrySegment: loan.sp_sub_industry,
            tranche: loan.tranche,
            trancheType: loan.tranche_type,
            domicile: (loan.state === 'Offshore') ? 'Offshore' : 'Australia',
            baseCurrency: loan.base_currency,
            startDate: loan.start_date,
            endDate: loan.maturity,
            pricingType: loan.pricing_type,
            selldowns: [],
            transfersOut: null,
            extensionOffset: false,
            earlyRepOffset: false
        })
    })


    // Attached loan amendments
    portfolioChanges.forEach(pc => {
        // Do not include removed selldowns otherwise if raw, restore removed and do not include new
        if ((!base && pc.status !== SaveStatus.REMOVED) || (base && pc.status !== SaveStatus.NEW)) {
            // IF RAW THEN CHECK IF EDITED AND RETRIEVE ORIGINAL
            let portfolioChange: PortfolioChanges;
            if (base && pc.status === SaveStatus.EDITED) {
                portfolioChange = pc.previous || pc;
            } else {
                portfolioChange = pc;
            }

            transactions.forEach((transaction) => {
                // Apply offset to all funds in loan
                if (transaction.trancheId === portfolioChange.trancheId) {
                    if (portfolioChange.amendment === AmendmentType.EXTENSION) transaction.extensionOffset = true;
                    if (portfolioChange.amendment === AmendmentType.EARLY_REPAYMENT) transaction.earlyRepOffset = true;
                    transaction.tenor = (Math.round((daysBetweenDates(budgetDate, new Date(portfolioChange.amendedMaturity)) / 365) * 10)) / 10;
                    transaction.amendedMaturity = portfolioChange.amendedMaturity;
                    transaction.amendment = portfolioChange.amendment;
                }
            })
        }
    })

    // Attach selldowns to loan
    selldownRepayments.forEach(s => {
        // Do not include removed selldowns otherwise if base, restore removed and do not include new
        if ((!base && s.status !== SaveStatus.REMOVED) || (base && s.status !== SaveStatus.NEW)) {
            // IF RAW THEN CHECK IF EDITED AND RETRIEVE ORIGINAL
            let selldown: SelldownRepayment;
            if (base && s.status === SaveStatus.EDITED) {
                selldown = s.previous || s;
            } else {
                selldown = s;
            }
            const transaction = transactions.find(t => (t.trancheId === selldown.trancheId && t.fund === selldown.fund));
            if (transaction) {
                transaction.selldowns.push(selldown);

            }
        }
    })
    // Attach transfers out of loans
    transfers.forEach(t => {
        // Do not include removed transfers otherwise if base, restore removed and do not include new
        if ((!base && t.status !== SaveStatus.REMOVED) || (base && t.status !== SaveStatus.NEW)) {
            // IF RAW THEN CHECK IF EDITED AND RETRIEVE ORIGINAL
            let transfer: FundTransfers;
            if (base && t.status === SaveStatus.EDITED) {
                transfer = t.previous || t;
            } else {
                transfer = t;
            }
            const transaction = transactions.find(loan => (loan.trancheId === transfer.trancheId && loan.fund === transfer.fromFund));
            if (transaction) {
                transaction.transfersOut = transfer
            }
        }
    })

    if (fund) {
        if (fund.type === FundType.FEEDER) {
            const filtered: Array<CalculationLoanType> = [];
            _.cloneDeep(transactions).forEach(transaction => {
                if (transaction.fund === fund.label) {
                    filtered.push(transaction);
                } else {
                    let holding = fund.holdings.find(h => h.fund === transaction.fund);
                    if (holding) {
                        try {
                            const holdingPer = holding.percentage;
                            transaction.value = transaction.value * holdingPer;
                            transaction.drawn = transaction.drawn * holdingPer;
                            transaction.undrawn = transaction.undrawn * holdingPer;
                            if (transaction.transfersOut) {
                                transaction.transfersOut = {
                                    ...transaction.transfersOut,
                                    originalAllocation: transaction.transfersOut.originalAllocation * holdingPer,
                                    newAllocation: transaction.transfersOut.newAllocation * holdingPer,
                                    transfers: transaction.transfersOut.transfers.map((t: {
                                        amount: number,
                                        fundId: number,
                                        fund: string
                                    }) => {
                                        return {
                                            ...t,
                                            amount: t.amount * holdingPer
                                        }
                                    })
                                }
                            }
                            transaction.selldowns = transaction.selldowns.map((s: SelldownRepayment) => {
                                return {
                                    ...s,
                                    amount: s.amount * holdingPer,
                                }
                            })
                            filtered.push(transaction);
                        } catch (e) {
                            console.log(e)
                        }
                    }
                }
            })

            transactions = filtered;
        } else {
            transactions = transactions.filter(t => {
                if (t.fund === fund.label) return true;
                else if (t.transfersOut && t.transfersOut.transfers.map((transfer: any) => transfer.fund).includes(fund.label)) return true;
                return false;
            })
        }
    }

    return transactions;
}