import {RootState} from "../../store";
import {CalculationFundTransfer, FundTransfers} from "../../../types/capitalBudgetTypes";
import {addValues} from "../../../utils/mathUtil";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {createSelector} from "@reduxjs/toolkit";

/**
 * Retrieves axcess loans in format for interfund transfers
 * @param state
 */
export const transferSelector = createSelector(
    (state: RootState) => state.capitalBudget.thirdPartyData?.axcess.portfolio,
    (state: RootState) => state.capitalBudget.scenarioData?.funds,
    (state: RootState) => state.capitalBudget.scenarioData?.transfers,
    (axcess, funds, transfers) => {
        if (!axcess || !funds || !transfers) return [];

        const reallocations: Array<any> = [];

        let baseAllocations: { [x: string]: number } = {};

        funds.forEach(f => baseAllocations[`${f.label}`] = 0)

        transfers.forEach(transfer => {
            if (transfer.status !== SaveStatus.REMOVED) {
                const index = axcess.findIndex(l => (l.tranche_id === transfer.trancheId && l.fund === transfer.fromFund));
                if (index !== -1) {
                    const loan = axcess[index];
                    const reallocation: any = {
                        ...loan,
                        ...transfer,
                        ...baseAllocations,
                    }
                    let remaining = loan.commitment;
                    transfer.transfers.forEach(ft => {
                        remaining = addValues(remaining, -ft.amount);
                        reallocation[`${ft.fund}`] = ft.amount
                    })
                    reallocation[`${transfer.fromFund}`] = remaining;
                    reallocations.push(reallocation)
                }
            }
        })

        return reallocations;
    }
)

export const _transferSelector = (state: RootState): Array<any> => {
    const axcess = state.capitalBudget.thirdPartyData?.axcess.portfolio
    const funds = state.capitalBudget.scenarioData?.funds;
    const transfers = state.capitalBudget.scenarioData?.transfers;

    if (!axcess || !funds || !transfers) return [];

    const reallocations: Array<any> = [];

    let baseAllocations: { [x: string]: number } = {};

    funds.forEach(f => baseAllocations[`${f.label}`] = 0)

    transfers.forEach(transfer => {
        if (transfer.status !== SaveStatus.REMOVED) {
            const index = axcess.findIndex(l => (l.tranche_id === transfer.trancheId && l.fund === transfer.fromFund));
            if (index !== -1) {
                const loan = axcess[index];
                const reallocation: any = {
                    ...loan,
                    ...transfer,
                    ...baseAllocations,
                }
                let remaining = loan.commitment;
                transfer.transfers.forEach(ft => {
                    remaining = addValues(remaining, -ft.amount);
                    reallocation[`${ft.fund}`] = ft.amount
                })
                reallocation[`${transfer.fromFund}`] = remaining;
                reallocations.push(reallocation)
            }
        }
    })

    return reallocations;
}

// Retrieves all Axcess loans Grouped by TRANCHE ID
export const retrieveAllAxcessLoans = createSelector(
    (state: RootState) => state.capitalBudget.thirdPartyData?.axcess.portfolio,
    (axcess) => {
        if (!axcess) return [];

        return [...axcess.reduce((r: any, o: any) => {
            const key = o.tranche_id;

            let itemObject: any = {};

            const item = r.get(key) || Object.assign({}, o, itemObject);

            return r.set(key, item);
        }, new Map()).values()]
    }
)

// Retrieves all Loans in Tranche
export const retrieveLoansAllocatedByTranche = createSelector(
    (state: RootState) => state.capitalBudget.thirdPartyData?.axcess.portfolio,
    (_state: RootState, trancheId: number | null) => trancheId,
    (axcess, trancheId) => {
        if (!axcess || !trancheId) return [];

        return axcess.filter(loan => loan.tranche_id === trancheId);
    }
)

// Retrieve Loan Reallocations by Fund
export const retrieveReallocationByFund = (state: RootState): Array<CalculationFundTransfer> => {
    const reallocation = state.capitalBudget.scenarioData?.transfers || [];
    const fund = state.capitalBudget.misc.fund;
    const loans = state.capitalBudget.thirdPartyData?.axcess.portfolio;

    if (!fund || !loans) return [];

    const filteredReallocation: Array<CalculationFundTransfer> = [];
    // Filter reallocation to those only relevant to fund
    reallocation.forEach(r => {
        let reallocation: FundTransfers | null = null;
        if (r.fromFundId === fund.id) {
            reallocation = r;
        } else {
            r.transfers.forEach(t => {
                if (t.fundId === fund.id) {
                    reallocation = {
                        ...r,
                        transfers: [t]
                    }
                }
            })
        }
        // Find and attached loan maturity to reallocation
        if (reallocation !== null) {
            const loan = loans.find(l => l.tranche_id === reallocation?.trancheId);
            if (loan) {
                filteredReallocation.push({
                    ...reallocation,
                    loanMaturity: loan.maturity,
                    drawnCommitmentPer: addValues(loan.commitment, -loan.undrawn)/loan.commitment,
                })
            }
        }
    })

    // console.log(filteredReallocation)

    return filteredReallocation;
}

