import {RootState} from "../../store";
import {Curve, TrancheMiscData} from "../../../types/valuationModelTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {getCurves} from "./thirdPartyDataSelector";
import {determineDefaultSCurve} from "../../../utils/valuationUtils";
import {createSelector} from "@reduxjs/toolkit";
import {determineFairValueBps} from "../../../utils/ValuationModelCalcs";


// Retrieve all active Call Protected
export const getCallProtected = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.callProtected,
    (callProtected) => callProtected.filter(cp => cp.status !== SaveStatus.REMOVED)
)

// Retrieve all curve overrides
export const getCurveOverrides = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.sCurveOverride,
    (sCurveOverride) => sCurveOverride.filter(curve => curve.status !== SaveStatus.REMOVED)
)

// Retrieve all accrual Overrides;
// Retrieve all curve overrides
export const getAccrualOverrides = createSelector(
    (state: RootState) => state.valuationModel.valuationModelData.accruedOverrides.filter(o => o.status !== SaveStatus.REMOVED),
    (_state: RootState, trancheId?: number) => trancheId,
    (overrides, trancheId) => {
        if (trancheId) {
            return overrides.filter(override => override.trancheId === trancheId);
        } else {
            return overrides
        }
    }
)

export const getTrancheMiscAttr = createSelector(
    (state: RootState) => state.valuationModel.thirdPartyData.axcess?.portfolio || [],
    getCallProtected,
    getCurveOverrides,
    getCurves,
    (state: RootState, trancheId?: number) => getAccrualOverrides(state, trancheId),
    (state: RootState) => state.valuationModel.valuationModelData.funds,
    (state: RootState, trancheId?: number) => trancheId,
    (portfolio, callProtected, curveOverrides, curves, accrualOverrides, funds, trancheId) => {
        const miscData: any = {
            trancheId: trancheId,
            callProtected: false,
            sCurve: null,
            accrualOverride: []
        }

        const tranche = portfolio.find(p => p.tranche_id === trancheId);
        if (!tranche) return miscData;

        // Check if Call protected
        if (!!callProtected.find(cp => cp.trancheId === trancheId)) {
            miscData.callProtected = true;
        }

        // Get Curve and check for override
        const sCurveOverride = curveOverrides.find(curve => curve.trancheId === trancheId);
        let scurve: Curve | null;
        const defaultCurve = determineDefaultSCurve(curves, tranche.investment_type);
        if (!sCurveOverride) {
            scurve = defaultCurve;
        } else {
            scurve = curves.find(c => c.curveType === sCurveOverride.curveType) || null;
        }
        // Determine FairValue
        if (scurve) {
            const fairValue = determineFairValueBps(scurve, tranche.remaining_tenor_yrs, tranche.rating_mcp);
            if (fairValue) {
                miscData.sCurve = {
                    id: scurve.id,
                    curveType: scurve.curveType,
                    fairValue: fairValue,
                    override: !!sCurveOverride,
                    default: defaultCurve?.curveType
                }
            }
        }

        const overrides = accrualOverrides.map(override => override.fund);
        const valuationFunds = funds.map(fund => fund.name);

        miscData.accrualOverride = tranche.funds.filter(fund => valuationFunds.includes(fund.fund)).map((fund) => {
            return {
                fund: fund.fund,
                active: overrides.includes(fund.fund)
            }
        }).sort((a, b) => (a.fund > b.fund) ? 1 : -1)

        return miscData as TrancheMiscData;
    }
)

// export const _getTrancheMiscAttr = (state: RootState, trancheId: number) => {
//     const miscData: any = {
//         trancheId: trancheId,
//         callProtected: false,
//         sCurve: null,
//         accrualOverride: []
//     }
//
//     const tranche = (state.valuationModel.thirdPartyData.axcess?.portfolio || []).find(p => p.tranche_id === trancheId);
//
//     if (!tranche) return miscData;
//
//     // Check if Call protected
//     const callProtected = getCallProtected(state);
//     if (!!callProtected.find(cp => cp.trancheId === trancheId)) {
//         miscData.callProtected = true;
//     }
//
//     // Get Curve and check for override
//     const sCurveOverride = getCurveOverrides(state).find(curve => curve.trancheId === trancheId);
//     let scurve: Curve | null;
//     const defaultCurve = determineDefaultSCurve(getCurves(state), tranche.investment_type);
//     if (!sCurveOverride) {
//         scurve = defaultCurve;
//     } else {
//         scurve = getCurves(state).find(c => c.curveType === sCurveOverride.curveType) || null;
//     }
//     // Determine FairValue
//     if (scurve) {
//         const fairValue = determineFairValue(scurve, tranche.remaining_tenor_yrs, tranche.rating_mcp);
//         if (fairValue) {
//             miscData.sCurve = {
//                 id: scurve.id,
//                 curveType: scurve.curveType,
//                 fairValue: fairValue,
//                 override: !!sCurveOverride,
//                 default: defaultCurve?.curveType
//             }
//         }
//     }
//
//     // Get accrual Overrides for funds
//     const overrides = getAccrualOverrides(state, trancheId).map(override => override.fund);
//     const valuationFunds = state.valuationModel.valuationModelData.funds.map(fund => fund.name);
//
//     miscData.accrualOverride = tranche.funds.filter(fund => valuationFunds.includes(fund.fund)).map((fund) => {
//         return {
//             fund: fund.fund,
//             active: overrides.includes(fund.fund)
//         }
//     }).sort((a, b) => (a.fund > b.fund) ? 1 : -1)
//
//     return miscData as TrancheMiscData;
// }
