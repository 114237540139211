import {NavLink as RouterLink, matchPath, useLocation} from 'react-router-dom';
// MUI
import {styled} from '@mui/material/styles';
import {Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Tooltip, Divider} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
    Logout as LogoutIcon,
} from '@mui/icons-material';
// Store
import {useAppSelector} from '../../store/store';
import {userRoleTypes} from '../../store/user/userSlice';
import {getUser} from '../../store/user/userSelectors';

/**s
 * Formats SideBar navigation
 */
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({theme}) => ({
    ...theme.typography.body2,
    height: 36,
    position: 'relative',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 12,
    height: 12,
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

// ----------------------------------------------------------------------

export interface Item {
    title: string,
    path: string,
    icon: string | JSX.Element,
    children?: Item[],
    restrict?: Array<userRoleTypes>
}

// Color mapping for Nav Item nesting, currently only 2 layers
const childItemPalette = [
    {
        parent: {
            active: 'secondary.main',
            default: 'primary.main',
            text: 'common.white'
        },
        child: {
            active: 'secondary.light',
            activeText: 'common.white'
        }
    },
    {
        parent: {
            active: 'secondary.light',
            default: 'common.white',
            text: 'primary.main'
        },
        child: {
            active: 'primary.lighter',
            activeText: 'primary.main'
        }
    }
]

// Handles each navigational menu item in the sidebar, handling collapsible menu items
function NavItem({item, active, depth = 0}: { item: Item, active: Function, depth?: number }) {
    const isActiveRoot = active(item.path);

    const user = useAppSelector(state => getUser(state))

    const {title, path, icon, children} = item;

    const activeParentStyle = {
        fontWeight: 'fontWeightMedium',
        bgcolor: childItemPalette[depth].parent.active,
        color: 'common.white'
    }

    const activeChildStyle = {
        color: childItemPalette[depth].child.activeText,
        fontWeight: 'fontWeightMedium',
        bgcolor: childItemPalette[depth].child.active,
    }

    if (children) {
        return (
            <>
                {/* @ts-ignore */}
                <ListItemStyle
                    component={RouterLink}
                    to={path}
                    sx={{
                        color: childItemPalette[depth].parent.text,
                        bgcolor: childItemPalette[depth].parent.default,
                        ...(isActiveRoot && activeParentStyle),
                        '&:hover': activeParentStyle,
                    }}
                >
                    <Tooltip title={title} placement='right'>
                        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    </Tooltip>
                    <ListItemText disableTypography primary={title} sx={{fontSize: '12px'}}/>
                    {isActiveRoot ?
                        <KeyboardArrowDownIcon sx={{width: 12, height: 12}}/>
                        :
                        <KeyboardArrowRightIcon sx={{width: 12, height: 12}}/>
                    }
                </ListItemStyle>

                <Collapse in={isActiveRoot} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((item: Item) => {
                            if (item.restrict && user) {
                                // console.log(item.restrict.find(role => role === user.role))
                            }
                            if (!item.restrict || (user && item.restrict.find(role => role === user.role))) {

                            }
                            const isActiveSub = active(item.path);

                            if (item.children) {
                                return (
                                    <NavItem
                                        item={item}
                                        active={active}
                                        key={item.title}
                                        depth={depth + 1}
                                    />
                                )

                            } else {
                                return (
                                    // @ts-ignore
                                    <ListItemStyle
                                        key={item.title}
                                        component={RouterLink}
                                        to={item.path}
                                        sx={{
                                            bgcolor: 'common.white',
                                            color: 'primary.main',
                                            '&:hover': activeChildStyle,
                                            ...(isActiveSub && activeChildStyle),
                                        }}
                                    >
                                        <Tooltip title={`${title} - ${item.title}`} placement='right'>
                                            <ListItemIconStyle>{item.icon && item.icon}</ListItemIconStyle>
                                        </Tooltip>
                                        <ListItemText disableTypography primary={item.title} sx={{fontSize: '12px'}}/>
                                    </ListItemStyle>
                                )
                            }

                        })}
                    </List>
                    <Divider />
                </Collapse>
            </>
        );
    }

    if (item.restrict && (user && !item.restrict.find(role => role === user.role))) {
        return <></>
    }

    return (
        // @ts-ignore
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                color: childItemPalette[depth].parent.text,
                bgcolor: childItemPalette[depth].parent.default,
                ...(isActiveRoot && activeParentStyle),
            }}
        >
            <Tooltip title={title} placement='right'>
                <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            </Tooltip>
            <ListItemText disableTypography primary={title} sx={{fontSize: '12px'}}/>
        </ListItemStyle>
    );
}

// Dashboard left sidebar menu, displays navigations 
export default function NavSection({navConfig, ...other}: { navConfig: Item[], [x: string]: any }) {
    const {pathname} = useLocation();

    // Function to check paths match
    const match = (path: string) => (path ? !!matchPath({path, end: false}, pathname) : false);

    return (
        <Box {...other} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 1}}>
            {/* <div sx={{ flex: 1 }}> */}
            <List disablePadding>
                {navConfig.map((item: Item) => (
                    <NavItem key={item.title} item={item} active={match}/>
                ))}
            </List>
            {/* </Box> */}
            {/* <Box> */}
            <List disablePadding>
                <NavItem
                    item={{
                        title: 'Logout',
                        path: '/logout',
                        icon: <LogoutIcon/>
                    }}
                    active={match}/>
                {/* </Box> */}
            </List>
        </Box>
    )
}
