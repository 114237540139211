import {DataTable, InfoTable, Page} from "../../components";
import {CircularProgress, Container, Grid, Typography} from "@mui/material";
import {useAppSelector} from "../../store/store";
import {getValuationModelLoading} from "../../store/valuationModel/selectors/generalSelectors";
import {CurveExportColumn, thirdPartyExportSelector} from "../../store/valuationModel/selectors/thirdPartyDataSelector";
import React from "react";
import {fPercent} from "../../utils/formatNumber";
import {formatDate} from "../../utils/DateUtils";


export default function ThirdPartyDataExport() {
    const thirdPartyData = useAppSelector(state => thirdPartyExportSelector(state));
    const loading = useAppSelector(state => getValuationModelLoading(state));

    return (
        <Page title="Metrics - Valuation Model - Data">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99}}>
                <Grid container direction='row' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Data
                        </Typography>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            {formatDate(thirdPartyData.valuationDate || new Date(), 'dd-MMM-yyyy')}
                        </Typography>
                    </Grid>
                    <Grid item container sx={{width: '100%'}} direction='row' >
                        <Grid item container xs={2} direction='row' alignContent={'flex-start'} padding={2} paddingRight={6}>
                            <Grid item xs={12} >
                                <Typography variant="h6" sx={{mb: 2, color: 'primary.main'}}>
                                    BBSW
                                </Typography>
                            </Grid>
                            {loading &&
                                <Grid item xs={12} alignItems='center' justifyContent='center' alignContent='center'>
                                    <CircularProgress/>
                                </Grid>
                            }
                            <Grid item xs={12} paddingBottom={2}>
                                <Typography variant="body1" sx={{mb: 2, color: 'primary.main'}}>
                                    {fPercent((thirdPartyData.bbsw || 0)/100)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant="h6" sx={{mb: 2, color: 'primary.main'}}>
                                    FX Rates
                                </Typography>
                            </Grid>
                            {loading &&
                                <Grid item xs={12} alignItems='center' justifyContent='center' alignContent='center'>
                                    <CircularProgress/>
                                </Grid>
                            }
                            <Grid item xs={12} >
                                <InfoTable data={
                                    thirdPartyData.fxRates.map(({currency, rate}) => ({
                                        label: currency,
                                        value: rate.toString()
                                    }))
                                }/>
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' xs={10} padding={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{mb: 2, color: 'primary.main'}}>
                                    Pricing Curves
                                </Typography>
                            </Grid>
                            {loading &&
                                <Grid item xs={12} alignItems='center' justifyContent='center' alignContent='center'>
                                    <CircularProgress/>
                                </Grid>
                            }
                            {thirdPartyData.curves.map(({label, data}) =>
                                <Grid item key={label} md={6} sx={{flex: 1, pb: 1, minHeight: 400}}>
                                    <DataTable
                                        showExport={false}
                                        title={label}
                                        columns={CurveExportColumn}
                                        data={data}
                                        loading={loading}
                                        sort={{field: 'rating', sort: 'asc'}}

                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}