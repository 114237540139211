import {ChangeEvent, MouseEvent, useEffect, useState} from 'react'
// Local Imports
import {VerticalTableColumn, VerticalTableProps, VerticalTableRow} from ".";
import {Order} from '../../types/GeneralTypes';
import {fCurrency} from '../../utils/formatNumber';
import {escapeRegExp, generateStatusColor, getComparator} from '../../utils/generalUtils';
// MUI
import {
    Box,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material"
import {visuallyHidden} from '@mui/utils';
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
// Components
import CustomToolbar from "./CustomToolbar";
import {CellType} from "../../types/InputTypes";

/**
 * Vertical Table
 * Utilised for data flowing in a Vertically
 * Allows for selectable rows
 *
 *
 * The table takes in data in a key format:
 - Array of Columns:
 * id: The string used to connect the data to each column
 * label: Label used at the top of the header,

 - Array of data:
 * id: this is the unique identifier for each row.
 * label: String used as column header,
 * ...strings that match the row ids to connect data.
 *
 */
export default function VerticalTable(props: VerticalTableProps) {
    const {
        title,
        columns,
        data,
        loading,

        defaultSort = 'id',
        defaultOrder = 'asc',
        secondarySort,
        secondaryOrder = 'asc',

        selectedRow,
        rowSelect = () => '',

        handleEdit,
        handleRemove,

        totalRow = false,
        search = false,
        create,

        highlightStatus = false
    } = props;

    const [rows, setRows] = useState<Array<VerticalTableRow>>([])
    // Handles State of search string in Custom Table Search Bar
    const [searchText, setSearchText] = useState('');
    // Handles Sort Parameters
    const [order, setOrder] = useState<Order>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultSort);
    // Totals 
    const [totals, setTotals] = useState<{ [x: string]: number }>({})

    // Copies data values to rows state as a mutable source
    useEffect(() => {
        setRows(data);
    }, [setRows, data])

    // Calculates totals row
    useEffect(() => {
        if (totalRow) {
            const allTotalFields: { [x: string]: number } = {}
            columns.forEach(c => {
                if (c.total) {

                    let colTotal = 0;

                    rows.forEach(r => {
                        colTotal = colTotal + r[c.id];
                    })

                    allTotalFields[c.id] = colTotal;

                }
            })
            setTotals(allTotalFields);
        }

    }, [columns, rows, totalRow])
    // Location of total row start
    const colIndex = columns.findIndex(c => c.total);

    // Handles Row Selection
    const handleRowSelection = (row: VerticalTableRow) => {
        if (row.id === selectedRow) rowSelect(null);
        else rowSelect(row.id);
    }

    // Handles Search of Strings in table
    useEffect(() => {
        const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
        const filteredRows = data.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                if (row[field]) {
                    return searchRegex.test(row[field].toString());
                }
                return false
            });
        });
        setRows(filteredRows);
    }, [searchText, columns, data])

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
    };

    // Handles Sorting requests
    const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    return (
        <Box
            sx={{
                p: 1,
                bgcolor: 'secondary.main',
                borderRadius: '5px',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <CustomToolbar
                title={title}
                search={search}
                value={searchText}
                onChange={(event: ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
                clearSearch={() => requestSearch('')}
                create={create}
            />
            <TableContainer
                component={Box}
                sx={{
                    width: '100%',
                    height: 'inherit',
                    bgcolor: 'common.white',
                }}
            >
                <Table
                    size='small'
                    stickyHeader
                    sx={{
                        height: 'inherit',
                        width: '100%',
                        '& .MuiTableCell-root': {
                            fontSize: 12.5
                        }
                    }}
                >
                    {/* HEADER ROW */}
                    <EnhancedTableHead
                        onRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        columns={columns}
                        editOrRemove={(!!handleEdit || !!handleRemove)}
                    />
                    {/* LOADING DATA */}
                    {loading ?
                        <TableBody sx={{flex: 1}}>
                            <TableRow sx={{height: '100%'}}>
                                <TableCell
                                    colSpan={columns.length}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}
                                    >
                                        <CircularProgress/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        // DATA ROWS
                        <TableBody sx={{flex: 1, overflowY: 'scroll'}}>
                            {rows.sort(getComparator(order, orderBy, secondarySort, secondaryOrder)).map((row, i) => {
                                return (
                                    <TableRow key={i}
                                              sx={{
                                                  maxHeight: '30px',
                                                  ...((!!selectedRow && row.id === selectedRow) ?
                                                          // SELECTED ROW FORMATTING
                                                          {
                                                              bgcolor: 'grey.300',
                                                              "&:hover": {
                                                                  bgcolor: 'grey.400',
                                                              }
                                                          }
                                                          :
                                                          // UNSELECTED ROW FORMATTING
                                                          {
                                                              "&:hover": {
                                                                  bgcolor: 'grey.300',
                                                              }
                                                          }
                                                  )
                                              }}
                                    >
                                        {/* LOOP DATA CELLS */}
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id + i}
                                                align={column.align ? column.align : 'center'}
                                                onClick={() => handleRowSelection(row)}
                                                sx={{
                                                    ...row.sx,
                                                    ...column.sx,
                                                    borderColor: 'primary.main',
                                                    px: 1,
                                                    ...(highlightStatus && row.status) ? {
                                                        ...generateStatusColor(row.status)
                                                    } : {},
                                                    ...(column.type === CellType.CURRENCY && (row[column.id] < 0)) ? {color: 'red'} : {}
                                                }}
                                            >
                                                <Typography>
                                                    {(!!column.formatter ?
                                                            column.formatter(row[column.id])
                                                            :
                                                            row[column.id]
                                                    )}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                        {/* EDIT AND REMOVE ICONS */}
                                        {(handleEdit || handleRemove) &&
                                            <TableCell
                                                sx={{
                                                    ...row.sx,
                                                    borderColor: 'primary.main',
                                                    px: 1,
                                                    width: 70,
                                                    ...(highlightStatus && row.status) ? {
                                                        ...generateStatusColor(row.status)
                                                    } : {}
                                                }}
                                            >
                                                <>
                                                    {handleEdit &&
                                                        <IconButton
                                                            sx={{color: 'primary.main'}}
                                                            style={{padding: '0px', outline: 'none'}}
                                                            onClick={() => handleEdit(row.id)}
                                                            size="small">
                                                            <EditIcon/>
                                                        </IconButton>
                                                    }
                                                    {handleRemove &&
                                                        <IconButton
                                                            sx={{color: 'primary.main'}}
                                                            style={{padding: '0px', outline: 'none'}}
                                                            onClick={() => handleRemove(row.id)}
                                                            size="small">
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }
                                                </>
                                            </TableCell>
                                        }

                                    </TableRow>
                                )
                            })}
                            <TableRow>
                                <TableCell colSpan={columns.length + ((handleEdit || handleRemove) ? 1 : 0)} sx={{height: '100%'}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                    </Box>
                                </TableCell>
                            </TableRow>
                            {totalRow && (colIndex !== -1) &&
                                <TableRow>
                                    <TableCell
                                        align='right'
                                        sx={{
                                            position: 'sticky',
                                            bottom: 0,
                                            zIndex: 2,
                                            bgcolor: 'primary.light',
                                            border: 1,
                                            borderColor: 'primary.light',
                                            fontWeight: 'bold',
                                            color: 'common.white',
                                            p: 1
                                        }}
                                        colSpan={colIndex}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                color: 'common.white',
                                            }}
                                        >
                                            Total
                                        </Typography>
                                    </TableCell>
                                    {columns.slice(colIndex).map((column, c) => (
                                        <TableCell
                                            key={c}
                                            align='right'
                                            sx={{
                                                position: 'sticky',
                                                bottom: 0,
                                                zIndex: 2,
                                                bgcolor: 'primary.light',
                                                border: 1,
                                                borderColor: 'primary.light',
                                                p: 1
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: 'common.white',
                                                }}
                                            >
                                                {fCurrency(totals[column.id])}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                    {(handleEdit || handleRemove) &&
                                        <TableCell
                                            sx={{
                                                position: 'sticky',
                                                bottom: 0,
                                                zIndex: 2,
                                                bgcolor: 'primary.light',
                                                border: 1,
                                                borderColor: 'primary.light',
                                                px: 1
                                            }}
                                        />
                                    }
                                </TableRow>
                            }

                        </TableBody>
                    }
                </Table>
            </TableContainer>
        </Box>
    )
}

interface TableHeaderProps {
    onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
    columns: Array<VerticalTableColumn>;
    editOrRemove: boolean;
}

// Table Header
function EnhancedTableHead(props: TableHeaderProps) {
    const {order, orderBy, onRequestSort, columns, editOrRemove} = props

    const createSortHandler =
        (property: string) => (event: MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {columns.map((column, d) => (
                    <TableCell
                        key={column.label + d}
                        align={column.align ? column.align : 'center'}
                        sortDirection={orderBy === column.id ? order : false}
                        sx={{
                            ...column.headSX,
                            position: "sticky",
                            top: 0,
                            bgcolor: 'primary.main',
                            color: 'common.white',
                            border: '1px',
                            px: 1,
                            zIndex: 1,
                            "&:hover": {
                                bgcolor: 'secondary.light',
                                cursor: "pointer"
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'common.white'
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                        >
                            <Typography sx={{color: 'common.white', fontWeight: 'bold'}}>{column.label}</Typography>

                            {orderBy === column.id ? (
                                <Box
                                    component="span"
                                    sx={{
                                        ...visuallyHidden,
                                    }}
                                >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {editOrRemove &&
                    <TableCell
                        sx={{
                            position: "sticky",
                            top: 0,
                            bgcolor: 'primary.main',
                            color: 'common.white',
                            border: '1px',
                            px: 1,
                            zIndex: 1,
                        }}
                    />
                }
            </TableRow>
        </TableHead>
    )
}