import {Box, SxProps, Table, TableBody, TableCell, TableRow} from "@mui/material";

interface RowType {
    label: string,
    value: string,
    sx?: SxProps
}

// Generic table like structure to display blocks of data
const InfoTable = ({data}: { data: Array<RowType> }) => {

    return (
        <Box>
            <Table>
                <TableBody>
                    {
                        data.map((row: RowType, d: number) => (
                            <TableRow key={d}>
                                <TableCell sx={{p: '2px', border: 'none', ...row.sx}}>
                                    <b>{row.label}:</b>
                                </TableCell>
                                <TableCell
                                    align='right'
                                    sx={{p: '2px', border: 'none', ...row.sx}}
                                >
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    )
}

export default InfoTable;