import {RootState} from "../../store";
import {GridCellParams, GridColDef, GridColTypeDef} from "@mui/x-data-grid";
import clsx from "clsx";
import {SxProps} from "@mui/material";
import _ from "lodash";
import {fCurrency, fNumber} from "../../../utils/formatNumber";
import {AxcessLoanCompare} from "../../../types/valuationModelTypes";
import {createSelector} from "@reduxjs/toolkit";
import {
    formatAssumptionLabel,
    formatCurveTypeLabel,
} from "../../../utils/valuationUtils";
import {calculateCouponRate} from "../../../utils/ValuationModelCalcs";
import {AssumptionType} from "../../../types/valuationModelEnums";
import {addValues} from "../../../utils/mathUtil";


const highlightFormatting: GridColTypeDef = {
    cellClassName: (params: GridCellParams) => {
        return clsx(' highlight', {
            new: (params.row.new),
            updated: (Object.keys(params.row.change).length > 0),
            assumptionGeneric: (!!params.row.assumption && (params.row.assumption !== AssumptionType.MATURED && params.row.assumption !== AssumptionType.PAST_MATURITY)),
            assumptionMatured: (!params.row.removed && !!params.row.assumption && (params.row.assumption === AssumptionType.MATURED || params.row.assumption === AssumptionType.PAST_MATURITY)),
            removed: (params.row.removed)
        })
    }
}

const highlightValFormatting: GridColTypeDef = {
    cellClassName: (params: GridCellParams) => {
        return clsx(' highlight', {
            assumption: (!!params.row.assumption)
        })
    }
}

export const highlightingDataGridSx: SxProps = {
    '& .highlight.removed': {
        bgcolor: 'error.light'
    },
    '& .highlight.new': {
        bgcolor: 'success.light'
    },
    '& .highlight.updated': {
        bgcolor: 'warning.light'
    },
    '& .highlight.assumptionGeneric': {
        bgcolor: 'warning.main'
    },
    '& .highlight.assumptionMatured': {
        bgcolor: 'info.lighter'
    }
}

export const PortfolioTableColumns: Array<GridColDef> = [
    {
        field: 'tranche_id',
        headerName: 'Tranche ID',
        width: 150,
        ...highlightFormatting
    },
    {
        field: 'asset',
        headerName: 'Asset',
        flex: 2,
        ...highlightFormatting
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        flex: 2,
        ...highlightFormatting
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        flex: 1,
        ...highlightFormatting
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        type: 'date',
        align: 'right',
        width: 150,
        ...highlightFormatting
    },
    {
        field: 'commitment',
        headerName: 'Commitment',
        type: 'number',
        width: 150,
        align: 'right',
        ...highlightFormatting,
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => (params.row.removed) ? 0 : params.row.commitment
    },
    {
        field: 'assumption',
        headerName: 'Assumption',
        flex: 1,
        ...highlightFormatting,
        valueGetter: (params) => {
            if (params.value) {
                return formatAssumptionLabel(params.value)
            } else if (params.row.removed) {
                return "Removed"
            } else if (params.row.new) {
                return "New"
            } else {
                return "-"
            }
        }
    },
    {
        field: 'removed',
        headerName: 'Closed',
        flex: 1,
        ...highlightFormatting,
        valueFormatter: (params) => (params.value) ? "Closed" : ""
    },
]

export const ValuationColumns: Array<GridColDef> = [
    {
        field: 'trancheId',
        headerName: 'ID',
        width: 60,
        ...highlightValFormatting
    },
    {
        field: 'fund',
        headerName: 'Fund',
        width: 80,
        ...highlightValFormatting
    },
    {
        field: 'investment_type',
        headerName: 'Sector',
        flex: 2,
        minWidth: 150,
        valueGetter: (params) => (params.row.sCurveOverride) ? formatCurveTypeLabel(params.row.sCurveOverride) : params.value,
        ...highlightValFormatting
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        flex: 2,
        minWidth: 150,
        ...highlightValFormatting
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        minWidth: 150,
        ...highlightValFormatting
    },
    {
        field: 'tranche_type',
        headerName: 'Type',
        minWidth: 150,
        hide: true,
        ...highlightValFormatting
    },
    {
        field: 'rating_mcp',
        headerName: 'Rating',
        width: 60,
        ...highlightValFormatting
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        width: 100,
        ...highlightValFormatting
    },
    {
        field: 'remaining_tenor_yrs',
        headerName: 'Tenor',
        type: 'number',
        width: 60,
        ...highlightValFormatting
    },
    {
        field: 'commitment',
        headerName: 'Commitment',
        type: 'number',
        width: 150,
        align: 'right',
        valueFormatter: (params) => fCurrency(params.value),
        ...highlightValFormatting
    },
    {
        field: 'drawn_margin',
        headerName: 'Margin',
        width: 60,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? Math.round(params.value * 10000) : "-",
        ...highlightValFormatting
    },
    {
        field: 'facility_fee_rate_p',
        headerName: 'Facility Fee',
        width: 100,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? Math.round(params.value * 10000) : "-",
        ...highlightValFormatting
    },
    {
        field: 'facility_fee_type',
        headerName: 'Fee Type',
        type: 'number',
        width: 150,
        ...highlightValFormatting
    },
    {
        field: 'netFacilityMarginFloor',
        headerName: 'Net Margin (bps)',
        type: 'number',
        width: 120,
        valueFormatter: (params) => (params.value) ? Math.round(params.value * 10000) : "-",
        valueGetter: (params) => calculateCouponRate(params.row.drawn_margin, params.row.facility_fee_type, params.row.facility_fee_rate_p, params.row.base_rate_floor || 0, params.row.bbsw),
        ...highlightValFormatting
    },
    {
        field: 'fairValueBps',
        headerName: 'Fair Value (bps)',
        type: 'number',
        width: 120,
        valueFormatter: (params) => (params.value) ? Math.round(params.value) : "-",
        ...highlightValFormatting
    },
    {
        field: 'yieldToMaturity',
        headerName: 'YTM',
        type: 'number',
        width: 60,
        valueFormatter: (params) => (params.value) ? Math.round(params.value) : "-",
        ...highlightValFormatting
    },
    {
        field: 'carry',
        headerName: 'Carry (c/$)',
        width: 80,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? fNumber(params.value) : "-",
        ...highlightValFormatting
    },
    {
        field: 'fairValue',
        headerName: 'Fair Value',
        width: 80,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? fNumber(params.value) : "-",
        ...highlightValFormatting
    },
    {
        field: 'cappedFairValue',
        headerName: 'Capped FV',
        width: 80,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => (params.value) ? fNumber(params.value) : "-",
        ...highlightValFormatting
    },
    {
        field: 'closingDiscount',
        headerName: 'Discount',
        width: 150,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => fCurrency(params.value),
        ...highlightValFormatting
    },
    {
        field: 'carryValue',
        headerName: 'Carry Value',
        width: 150,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => fCurrency(params.value),
        valueGetter: (params) => params.row.commitment * params.row.carry / 100,
        ...highlightValFormatting
    },
    {
        field: 'dailyLossGain',
        headerName: 'ITM/OTM',
        width: 100,
        type: 'number',
        align: 'right',
        valueFormatter: (params) => fCurrency(params.value),
        ...highlightValFormatting
    },
    {
        field: 'assumption',
        headerName: 'Assumption',
        width: 150,
        align: 'right',
        valueGetter: (params) => {
            if (params.value) {
                return formatAssumptionLabel(params.value)
            } else if (params.row.removed) {
                return "Removed"
            } else if (params.row.new) {
                return "New"
            } else {
                return "-"
            }
        },
        ...highlightValFormatting
    },
    {
        field: 'callProtected',
        headerName: 'Call Protected',
        width: 100,
        align: 'right',
        valueFormatter: (params) => (params.value) ? "Yes" : "No",
        ...highlightValFormatting
    }
]

// Retrieves formatted state of portfolio data
export const portfolioDataTable = createSelector(
    (state: RootState) => state.valuationModel.fund,
    (state: RootState) => state.valuationModel.valuationModelData.funds,
    (state: RootState) => _.cloneDeep(state.valuationModel.thirdPartyData.axcess?.portfolio || []),
    (state: RootState) => state.valuationModel.valuationModelData.assumptions,
    (fund, funds, portfolio, assumptions) => {
        if (fund !== "ALL_FUNDS") {
            const filteredFunds: string[] = [];
            if (fund === "ALL_VM_FUNDS") {
                funds.forEach(f => filteredFunds.push(f.name));
            } else {
                filteredFunds.push(fund);
                // TODO: Need to add a case to filter assets that are not values
            }
            portfolio = portfolio.filter(p => {
                for (let f of p.funds) {
                    if (filteredFunds.includes(f.fund)) {
                        return true
                    }
                }
                if (p.funds_before) {
                    for (let f of p.funds_before) {
                        if (filteredFunds.includes(f.fund)) {
                            return true
                        }
                    }
                }
                return false
            });
        }
        portfolio.forEach(t => {
            t.id = t.tranche_id
        });
        return {
            portfolio,
            highlighted: portfolio.filter(t => (t.removed || t.highlighted || t.new || t.assumption || Object.keys(t.change).length))
        }
    }
)

// Selector for Valuation Table on Portfolio page
export const valuationTableSelector = createSelector(
    (state: RootState) => state.valuationModel.fund,
    (state: RootState) => state.valuationModel.valuationModelData.funds,
    (state: RootState) => state.valuationModel.thirdPartyData.bbsw?.rate,
    (state: RootState) => state.valuationModel.valuationModelData.callProtected,
    (state: RootState) => state.valuationModel.valuationModelData.sCurveOverride,
    (state: RootState) => _.cloneDeep(state.valuationModel.thirdPartyData.axcess?.portfolio || []),
    (state: RootState) => state.valuationModel.valuationModelData.assumptions,
    (state: RootState) => state.valuationModel.valuationModelData.valuations,
    (fund, funds, bbsw, callProtectedAssets, sCurveOverrides, portfolio, assumptions, valuations) => {
        const filteredFunds: string[] = [];
        if (fund === "ALL_VM_FUNDS" || fund === "ALL_FUNDS") {
            funds.forEach(f => filteredFunds.push(f.name));
        } else {
            filteredFunds.push(fund);
        }
        const valuationsList = [];
        for (let t in valuations) {
            const trancheData = portfolio.find(p => p.tranche_id === parseInt(t));
            const assumption = assumptions.find(a => a.trancheId === parseInt(t))?.type;
            const callProtected = callProtectedAssets.some(c => c.trancheId === parseInt(t));
            const sCurveOverride = sCurveOverrides.find(s => s.trancheId === parseInt(t))?.curveType
            for (let f in valuations[t]) {
                if (filteredFunds.includes(valuations[t][f].fund)) {
                    valuationsList.push({
                        ...trancheData,
                        ...valuations[t][f],
                        ...(callProtected) ? {callProtected: callProtected} : {},
                        ...(assumption) ? {assumption: assumption} : {},
                        ...(sCurveOverride) ? {sCurveOverride: sCurveOverride} : {},
                        bbsw: bbsw,
                        id: t + f,
                    })
                }
            }
        }
        return valuationsList;
    }
)

// Retrieve Tranche By ID;
export const getTranches = (state: RootState, trancheId: number): AxcessLoanCompare | null => {
    let portfolio: Array<AxcessLoanCompare> = state.valuationModel.thirdPartyData.axcess?.portfolio || [];

    return portfolio.find(tranche => tranche.tranche_id === trancheId) || null;
}


export type BorrowerCommitment = {
    commitment: number,
    beforeCommitment: number,
    funds: Array<{fund: string, commitment: number}>,
    beforeFunds: Array<{fund: string, commitment: number}>
}

// Retrieve Borrower Level Commitments by Tranche Id
export const retrieveBorrowerByTranche = createSelector(
    (state: RootState) => state.valuationModel.thirdPartyData.axcess?.portfolio || [],
    (_state: RootState, trancheId: number) => trancheId,
    (portfolio, trancheId) => {
        const tranche = portfolio.find(t => t.tranche_id === trancheId);

        if (tranche) {
            const borrowerId = tranche.client_id;

            const borrower = portfolio.reduce((borrower, loan) => {
                if (loan.client_id === borrowerId) {
                    borrower.commitment = addValues(borrower.commitment, loan.commitment);

                    loan.funds.forEach(f => {
                        const fund = borrower.funds.get(f.fund);
                        if (!fund) {
                            borrower.funds.set(f.fund, f.commitment);
                        } else {
                            borrower.funds.set(f.fund, addValues(fund, f.commitment));
                        }
                    })

                    loan.funds_before.forEach(f => {
                        const fund = borrower.beforeFunds.get(f.fund);
                        if (!fund) {
                            borrower.beforeFunds.set(f.fund, f.commitment);
                        } else {
                            borrower.beforeFunds.set(f.fund, addValues(fund, f.commitment));
                        }
                        borrower.beforeCommitment = addValues(borrower.beforeCommitment, f.commitment, 3);
                    })
                }
                return borrower;
            }, {commitment: 0, beforeCommitment: 0, funds: new Map(), beforeFunds: new Map()})

            return {
                ...borrower,
                funds: Array.from(borrower.funds.entries()).map(([fund, commitment]) => ({fund, commitment})),
                beforeFunds: Array.from(borrower.beforeFunds.entries()).map(([fund, commitment]) => ({fund, commitment}))
            }
        }

        return null;
    }
)