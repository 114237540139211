import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
// Local imports
import {CapitalAction, CapitalCategory, CapitalTypes} from "../../../../types/capitalBudgetEnums";
import {MCPFund} from "../../../../types/capitalBudgetTypes";
import {FormInput} from "../../../../components";
import {InputTypes} from "../../../../types/InputTypes";
// MUI
import {Button, Divider, Grid} from "@mui/material";
// Store
import {retrieveFundsSelectFormat} from "../../../../store/capitalBudget/selectors";
import {useAppSelector} from "../../../../store/store";
import {retrieveAllCapitalTransactionNames} from "../../../../store/capitalBudget/selectors/capitalSelectors";

interface NewTransactionProps {
    editValues: any | null,
    onClose: () => void,
    submitTransaction: (values: any) => void,
    editTransaction: (values: any) => void
}

// New Capital Transaction
export default function NewTransactionForm({
                                               editValues,
                                               onClose,
                                               submitTransaction,
                                               editTransaction
                                           }: NewTransactionProps) {

    const funds = useAppSelector(state => retrieveFundsSelectFormat(state));
    const names = useAppSelector(state => retrieveAllCapitalTransactionNames(state));
    const fund = useAppSelector(state => state.capitalBudget?.misc.fund)

    const TransactionSchema = Yup.object().shape({
        name: Yup.object().required('Name is required'),
        investorType: Yup.mixed<CapitalTypes>().oneOf(Object.values(CapitalTypes)).required('Investor Type is required'),
        transactionType: Yup.mixed<CapitalAction>().oneOf(Object.values(CapitalAction)).required('Transaction Type is required'),
        date: Yup.date().typeError('Valid date required.').required('Transaction date is required'),
        amount: Yup.number().required('Amount is required'),
        category: Yup.mixed<CapitalCategory>().oneOf(Object.values(CapitalCategory)).required('Category is required'),
        fund: Yup.mixed<MCPFund>().required('Fund is required'),
    });

    const transactionValues = {
        name: '',
        investorType: CapitalTypes.INVESTOR,
        transactionType: '',
        date: '',
        fund: (fund) ? fund.id : '',
        amount: '',
        category: CapitalCategory.EXPECTED
    };

    return (
        <>
            <Formik
                initialValues={editValues?.id ? editValues : transactionValues}
                validationSchema={TransactionSchema}
                onSubmit={(values: any, actions) => {
                    const newValues = {...values};
                    newValues.name = newValues.name.value;
                    if (newValues.transactionType === CapitalAction.CANCELLATION || newValues.transactionType === CapitalAction.REDEMPTION) {
                        newValues.amount = -Math.abs(newValues.amount)
                    }
                    newValues.fundId = newValues.fund;
                    const fund = funds.find(f => f.value === newValues.fundId);
                    if (fund) {
                        newValues.fund = fund.label;
                        newValues.date = new Date(newValues.date).toDateString();

                        if (editValues?.id) {
                            editTransaction(newValues)
                        } else {
                            submitTransaction(newValues);
                        }
                    } else {
                        actions.setFieldError('fund', 'Invalid Fund Selected.')
                    }
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='name'
                                        label='Name'
                                        fieldType={InputTypes.SUGGESTION}
                                        values={names.map(name => {
                                            return {value: name.id, label: name.id}
                                        })}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                        disabled={!!(editValues && editValues.id)}
                                    />
                                    <FormInput
                                        id='investorType'
                                        label='Investor Type'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={[
                                            {label: 'Investor', value: CapitalTypes.INVESTOR},
                                            {label: 'Lender', value: CapitalTypes.LENDER},
                                        ]}
                                    />
                                    <FormInput
                                        id={`date`}
                                        label='Date'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    {values.investorType === CapitalTypes.INVESTOR ?
                                        <FormInput
                                            id={`transactionType`}
                                            label='Transaction Type'
                                            fieldType={InputTypes.SELECTION}
                                            layout={{xs: 6, md: 6, lg: 6}}

                                            values={[
                                                {label: 'Subscription', value: CapitalAction.SUBSCRIPTION},
                                                {label: 'Redemption', value: CapitalAction.REDEMPTION}
                                            ]}
                                        />
                                        :
                                        <FormInput
                                            id={`transactionType`}
                                            label='Transaction Type'
                                            fieldType={InputTypes.SELECTION}
                                            layout={{xs: 6, md: 6, lg: 6}}

                                            values={[
                                                {label: 'Commitment', value: CapitalAction.COMMITMENT},
                                                {label: 'Cancellation', value: CapitalAction.CANCELLATION}
                                            ]}
                                        />

                                    }
                                    <FormInput
                                        id='fund'
                                        label='Fund'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={funds}
                                    />
                                    <FormInput
                                        id='amount'
                                        label='Amount'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        numFormatProps={{allowNegative: false, decimalScale: 2}}
                                    />
                                    <FormInput
                                        id='category'
                                        label='Category'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}

                                        values={[
                                            {label: 'Expected', value: CapitalCategory.EXPECTED},
                                            {label: 'Target', value: CapitalCategory.TARGET},
                                            {label: 'Potential', value: CapitalCategory.POTENTIAL},
                                        ]}
                                        info={'Values are expected or targeted.'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item container direction='row'>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {`${(editValues?.id) ? 'Update' : 'Create'} Transaction`}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}