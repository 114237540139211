import React, {useMemo, useState} from "react";
import _ from "lodash";
import {AxcessLoanCompare, ScheduledAdjustment, Version} from "../../../../../../types/valuationModelTypes";
// MUI
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
// STORE
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {
    getScheduledAdjustments,
} from "../../../../../../store/valuationModel/selectors/adjustmentFeesSelector";
import {
    actionRow,
    AlertDialog,
    DataTable,
    defaultFormat,
    SideDrawer,
    valueCurrency,
    valueDate
} from "../../../../../../components";
import UpdateScheduledFeeForm from "./FeesAdjustmentsForm/UpdateScheduledFeeForm";
import AdjustmentFundFilter from "./AdjustmentFeeFilter";
import {removeScheduledAdjustment} from "../../../../../../store/valuationModel/valuationModelSlice";
import {AdjustmentTypeFields} from "../../../../../../types/valuationModelEnums";

const AdjustmentFeesColumns: Array<GridColDef> = [
    {
        ...defaultFormat,
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    {
        ...defaultFormat,
        field: 'transactionType',
        headerName: 'Transaction Type',
        width: 200,
        valueFormatter: (params) => AdjustmentTypeFields.find(a => a.value === params.value)?.label || params.value
    },
    {
        ...defaultFormat,
        field: 'fund',
        headerName: 'Fund',
        width: 100
    },
    {
        ...valueCurrency,
        field: 'amount',
        headerName: 'Amount',
        flex: 1
    },
    {
        ...valueDate,
        field: 'startDate',
        headerName: 'Start Date',
        flex: 1
    },
    {
        ...valueDate,
        field: 'endDate',
        headerName: 'End Date',
        flex: 1
    },
    {
        ...defaultFormat,
        field: 'comment',
        headerName: 'Comment',
        flex: 2
    },
]

const ScheduledAdjustmentsFees = ({trancheId, tranche, valuationFunds, version}: {
    trancheId: number,
    tranche: AxcessLoanCompare,
    valuationFunds: Array<string>,
    version: Version
}) => {
    const dispatch = useAppDispatch();

    const scheduledAdjustments = useAppSelector(state => getScheduledAdjustments(state, trancheId));

    const [filterFund, setFilterFund] = useState<string>('All');
    const filtered = useMemo(() => (filterFund !== 'All') ? _.cloneDeep(scheduledAdjustments).filter(af => af.fund === filterFund) : scheduledAdjustments, [scheduledAdjustments, filterFund])

    const [remove, setRemove] = useState<string | number | null>(null)
    const [update, setUpdate] = useState<ScheduledAdjustment | null>(null)

    return (
        <>
            <DataTable
                title={'Scheduled Adjustments & Fees'}
                columns={[
                    ...AdjustmentFeesColumns,
                    ...(!version.published) ?
                        [{
                        ...actionRow,
                        renderCell: (params: GridCellParams) => (
                            <>
                                <IconButton
                                    sx={{color: 'primary.main'}}
                                    style={{padding: '0px', outline: 'none'}}
                                    onClick={() => setUpdate(params.row)}
                                    size="small"
                                >
                                    <EditIcon/>
                                </IconButton>
                                <IconButton
                                    sx={{color: 'primary.main'}}
                                    style={{padding: '0px', outline: 'none'}}
                                    onClick={() => setRemove(params.row.id)}
                                    size="small"
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </>
                        )
                    }] : []
                ]}
                data={filtered}
                loading={false}
                showExport={false}
                customFilterComponent={<AdjustmentFundFilter funds={valuationFunds} filterFund={filterFund}
                                                             setFilterFund={(fund) => setFilterFund(fund)}/>}
            />
            <SideDrawer
                title='Edit Adjustment/Fee'
                open={!!update}
                onClose={() => setUpdate(null)}
            >
                {update &&
                    <UpdateScheduledFeeForm  tranche={tranche} adjustment={update} onClose={() => setUpdate(null)}/>
                }
            </SideDrawer>
            <AlertDialog
                title='Delete Adjustment/Fee'
                info='Are you sure you want to remove this Scheduled Adjustment or fee?'
                open={!!remove}
                handleClose={() => setRemove(null)}
                handleConfirm={() => {
                    dispatch(removeScheduledAdjustment(remove));
                    setRemove(null);
                }}
            />
        </>
    )
}

export default ScheduledAdjustmentsFees;