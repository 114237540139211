import {RootState} from "../../store";
import _ from "lodash";
import {fCurrency, fPercent} from "../../../utils/formatNumber";
import {addArrayValues, addValues} from "../../../utils/mathUtil";
import {HorizontalTableRow} from "../../../components";
import {CellType} from "../../../types/InputTypes";
import {summarisePeriod} from "../calculationUtils/summarisePeriod";
import {CapitalBudget} from "../../../types/capitalBudgetTypes";
import {createSelector} from "@reduxjs/toolkit";

export const capitalBudgetSummaryRows: Array<HorizontalTableRow> = [
    {id: 'ium', label: 'Investments ($)', formatter: fCurrency, headSX: {minWidth: 150}, type: CellType.CURRENCY},
    {
        id: 'contractualCapital',
        label: 'Contractual Available Capital ($)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {id: 'closingAvailableCap', label: 'Adjusted Available Capital ($)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'aum', label: 'AUM', formatter: fCurrency, headSX: {minWidth: 150}, type: CellType.CURRENCY},
    {id: 'startingAvailableCash', label: 'Cash ($)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'wavMargin', label: 'Weighted Average Margin', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'wavYield', label: 'Weighted Average Yield', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'targetRet', label: 'Target Return', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'cashPer', label: 'Cash (%)', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'wavTenor', label: 'WA Maturity (yrs)'},
    {id: 'wavInterest', label: 'WA Interest Rate (yrs)'},
    {id: 'wavRatingValue', label: 'WA Credit Rating'},
    {id: 'wavRating', label: 'WA Credit Rating'},
    {id: 'senior', label: 'Senior (%)', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'subordinated', label: 'Subordinated (%)', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'equity', label: 'Equity (%)', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'other', label: 'Other (%)', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'investmentGrade', label: 'Investment Grade (%)', formatter: fPercent, type: CellType.CURRENCY},
    {id: 'subInvestmentGrade', label: 'Sub-Investment Grade (%)', formatter: fPercent, type: CellType.CURRENCY},
    {
        id: 'assets',
        label: '# Assets',
        formatter: (assets) => {
            if (Array.isArray(assets)) {
                return assets.length.toString();
            } else {
                return '0';
            }
        },
        subRows: [
            {id: 'avgSize', label: 'Average Asset Size ($)', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'largestExposure', label: 'Largest Exposure ($)', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'largestExposurePer', label: 'Largest Exposure (%)', formatter: fPercent},
            {id: 'topTenExposure', label: 'Top 10 Exposure ($)', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'topTenExposurePer', label: 'Top 10 Exposure (%)', formatter: fPercent},
        ]
    },
    {
        id: 'exposure',
        label: 'Industry Exposure %',
        subRows: []
    },
    {id: 'realEstateExp', label: 'Real Estate (%)', formatter: fPercent},
    {id: 'domicile', label: 'Aus Domicile (%)', formatter: fPercent},
    {id: 'aud', label: 'AUD (%)', formatter: fPercent},
];

export const capitalPositionRows: Array<HorizontalTableRow> = [
    {
        id: 'contractualCapital',
        label: 'Contractual capital',
        formatter: fCurrency,
        sx: {fontWeight: 'bold', borderBottom: 4},
        headSX: {minWidth: 140}
    },
    {
        id: 'netInvFlow',
        label: 'Net Investor Flows',
        formatter: fCurrency,
        subRows: [
            {id: 'subscriptions', label: 'Investor Subscriptions', formatter: fCurrency},
            {id: 'redemptions', label: 'Investor Redemptions', formatter: fCurrency, sx: {color: 'red'}},
        ],
        type: CellType.CURRENCY,
    },
    {
        id: 'netDebtFac',
        label: 'Net Debt Facilities',
        formatter: fCurrency,
        subRows: [
            {id: 'commitments', label: 'Debt Commitments', formatter: fCurrency},
            {id: 'cancellations', label: 'Debt Cancellations', formatter: fCurrency, sx: {color: 'red'}},
        ]
    },
    {id: 'extension', label: 'Loan Extensions', formatter: fCurrency},
    {id: 'earlyRepayment', label: 'Early Repayments', formatter: fCurrency},
    {id: 'selldowns', label: 'Selldowns', formatter: fCurrency},
    {id: 'interfundTransfers', label: 'Interfund Transfers', formatter: fCurrency},
    {id: 'newLoans', label: 'New Loans', formatter: fCurrency, sx: {color: 'red'}},
    {id: 'wip', label: 'WIP', formatter: fCurrency, sx: {color: 'red'}},
    {
        id: 'closingAvailableCap',
        label: 'Adjusted Capital',
        formatter: fCurrency,
        sx: {fontWeight: 'bold', borderTop: 4}
    },
]

export const portfolioStatisticsRows = [
    {id: 'aum', label: 'AUM', formatter: fCurrency, headSX: {minWidth: 140}},
    {id: 'wavYield', label: 'Weighted Average Margin', formatter: fPercent},
    {id: 'targetRet', label: 'Target Return', formatter: fPercent},
    {id: 'contractualCapital', label: 'Contractual capital', formatter: fCurrency},
    {id: 'closingAvailableCap', label: 'Adjusted Capital', formatter: fCurrency},
    {id: 'wavTenor', label: 'WA Maturity (yrs)'},
    {id: 'wavRatingValue', label: 'WA Credit Rating'},
    {id: 'wavRating', label: 'WA Credit Rating'},
]

export const capitalBudgetSelector = (capitalBudget: CapitalBudget | null) => {
    const rows = _.cloneDeep(capitalBudgetSummaryRows);

    if (!capitalBudget) return {data: [], rows};

    const base = summarisePeriod(_.cloneDeep(capitalBudget.base));
    const weeks = _.cloneDeep(capitalBudget.weeks).map(week => summarisePeriod(week))
    const months = _.cloneDeep(capitalBudget.months).map(month => summarisePeriod(month))

    // const industries: Array<{ id: string, label: string, formatter: (v: number) => string }> = [];
    const industries: Array<string> = []
    const otherRankings: Array<string> = []

    base.industries.forEach((industry: { industry: string, percentage: number }) => {
        if (!industries.includes(industry.industry)) industries.push(industry.industry)
        base[`${industry.industry}`] = industry.percentage
    })
    for (const rank in base.otherRanking) {
        base[rank] = base.otherRanking[rank];
        otherRankings.push(rank);
    }

    base.headSX = {minWidth: '140px'}

    // Bring out industry fields to top level
    weeks.forEach(week => {
        week.industries.forEach((industry: { industry: string, percentage: number }) => {
            if (!industries.includes(industry.industry)) industries.push(industry.industry)
            week[`${industry.industry}`] = industry.percentage
        })
        week.headSX = {minWidth: '140px'}
        for (const rank in week.otherRanking) {
            week[rank] = week.otherRanking[rank];
            if (!otherRankings.includes(rank)) otherRankings.push(rank);
        }
    })

    // Bring out industry fields to top level
    months.forEach(month => {
        month.industries.forEach((industry: { industry: string, percentage: number }) => {
            if (!industries.includes(industry.industry)) industries.push(industry.industry)
            month[`${industry.industry}`] = industry.percentage
        })
        month.headSX = {minWidth: '140px'}
        for (const rank in month.otherRanking) {
            month[rank] = month.otherRanking[rank];
            if (!otherRankings.includes(rank)) otherRankings.push(rank);
        }
    })

    industries.sort()

    rows[20].subRows = industries.map(industry => {
        return {id: industry, label: industry, formatter: fPercent}
    });

    rows.splice(17, 0, ...otherRankings.map(r => ({id: r, label: `${r} (%)`, formatter: fPercent, type: CellType.CURRENCY})))

    months[0].sx = {borderLeft: 10};

    const data = [base, ...weeks, ...months]

    for (let i = 1; i < data.length; i += 2) {
        data[i].sx = {...data[i].sx, bgcolor: 'grey.100'}
    }

    return {
        data,
        rows
    };
}

// Retrieve data for Capital Position Report
export const capitalPositionSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget,
    (capitalBudget) => {
        const rows = capitalPositionRows;

        if (!capitalBudget) return {data: [], rows};

        const weeks = _.cloneDeep(capitalBudget.weeks)
        const months = _.cloneDeep(capitalBudget.months)
        //
        weeks.forEach(week => {
            week.netInvFlow = addValues(week.subscriptions, week.redemptions);
            week.netDebtFac = addValues(week.commitments, week.cancellations);

            week.headSX = {minWidth: '140px'}
        })

        months.forEach(month => {
            month.netInvFlow = addValues(month.subscriptions, month.redemptions);
            month.netDebtFac = addValues(month.commitments, month.cancellations);

            month.headSX = {minWidth: '140px'}
        })

        months[0].sx = {borderLeft: 2};

        const data = [...weeks, ...months]

        for (let i = 1; i < data.length; i += 2) {
            data[i].sx = {...data[i].sx, bgcolor: 'grey.100'}
        }

        return {
            data,
            rows
        };
    }
)

// Retrieves data for Portfolio statstics
export const portfolioStatisticsSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget,
    (capitalBudget) => {
        const rows = portfolioStatisticsRows;

        if (!capitalBudget) return {data: [], rows};

        const weeks = _.cloneDeep(capitalBudget.weeks);
        const months = _.cloneDeep(capitalBudget.months);

        weeks.forEach(week => {
            week.headSX = {minWidth: '140px'}
        })

        months.forEach(month => {
            month.headSX = {minWidth: '140px'}
        })

        months[0].sx = {borderLeft: 2};

        const data = [...weeks, ...months]

        for (let i = 1; i < data.length; i += 2) {
            data[i].sx = {...data[i].sx, bgcolor: 'grey.100'}
        }

        return {
            data,
            rows
        };
    }
)

export const _portfolioStatisticsSelector = (state: RootState) => {
    const rows = portfolioStatisticsRows;

    if (!state.capitalBudget.capitalBudget) return {data: [], rows};

    const base = _.cloneDeep(state.capitalBudget.capitalBudget.base);
    const weeks = _.cloneDeep(state.capitalBudget.capitalBudget.weeks);
    const months = _.cloneDeep(state.capitalBudget.capitalBudget.months);

    weeks.forEach(week => {
        week.headSX = {minWidth: '140px'}
    })

    months.forEach(month => {
        month.headSX = {minWidth: '140px'}
    })

    months[0].sx = {borderLeft: 2};

    const data = [base, ...weeks, ...months]

    for (let i = 1; i < data.length; i += 2) {
        data[i].sx = {...data[i].sx, bgcolor: 'grey.100'}
    }

    return {
        data,
        rows
    };
}

export const BudgetForecastRows = [
    {
        id: 'startingAvailableCap',
        label: 'Opening Available Capital',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY,
        sx: {bgcolor: 'info.light'}
    },
    {
        id: 'expectedRepayments',
        label: 'Contractual Loan Repayments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'contractualPos',
        label: 'Contractual Surplus/Deficit',
        formatter: fCurrency,
        sx: {minWidth: 120, bgcolor: 'info.lighter'},
        type: CellType.CURRENCY
    },
    {
        id: 'newCommitments',
        label: 'New Loan Commitments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'extensionOffset',
        label: 'Loan Extension - Contracted Maturity Offset',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'earlyRepayment',
        label: 'Early Loan Repayments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {id: 'selldowns', label: 'Asset Sell Downs', formatter: fCurrency, sx: {minWidth: 120}, type: CellType.CURRENCY},
    {
        id: 'transfersOut',
        label: 'Interfund Transfers (Out)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'transfersIn',
        label: 'Interfund Transfers (In)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'adjustment',
        label: 'Other Adjustments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'subscriptions',
        label: 'Forecasted Investor Subscriptions',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'redemptions',
        label: 'Forecasted Investor Redemptions',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'extension',
        label: 'Loan Extension - Repayment at New Maturity Date',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'earlyRepOffset',
        label: 'Early Loan Repayments - Contracted Maturity Offset',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'selldownOffset',
        label: 'Asset Sell Downs - Contracted Maturity Offset',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'transfersOutOffset',
        label: 'Interfund Transfers Offset (Out)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'transfersInOffset',
        label: 'Interfund Transfers Offset (In)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'repaymentAdjustments',
        label: 'Contractual Loan Repayments - Adjustments',
        formatter: fCurrency,
        sx: {minWidth: 120, bgcolor: 'info.lighter'},
        type: CellType.CURRENCY
    },
    {
        id: 'forecastAdjustment',
        label: 'Adjust - Contractual to Forecast',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'closingAvailableCap',
        label: 'Surplus/Deficit Available Capital',
        formatter: fCurrency,
        sx: {minWidth: 120, bgcolor: 'info.light'},
        type: CellType.CURRENCY
    },
];

export const budgetForecastSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget,
    (capitalBudget) => {
        const rows = BudgetForecastRows;

        if (!capitalBudget) return {data: [], rows};

        const weeks = _.cloneDeep(capitalBudget.weeks);
        let startingCap = weeks[0].startingAvailableCap;
        let forecastAdjustment = 0;
        weeks.forEach(week => {
            week.startingAvailableCap = startingCap;
            week.contractualPos = addValues(week.startingAvailableCap, week.expectedRepayments);
            startingCap = week.contractualPos;
            week.repaymentAdjustments = addArrayValues([week.extension, week.earlyRepOffset, week.selldownOffset, week.transfersOutOffset, week.transfersInOffset])
            week.forecastAdjustment = forecastAdjustment;
            const sumOfValues = addArrayValues([week.newCommitments, week.extensionOffset, week.earlyRepayment, week.selldowns, week.transfersOut, week.transfersIn, week.adjustment, week.subscriptions, week.redemptions, week.repaymentAdjustments, week.forecastAdjustment]);
            week.closingAvailableCap = addArrayValues([week.contractualPos, sumOfValues]);
            forecastAdjustment = sumOfValues;
        })

        const months = _.cloneDeep(capitalBudget.months);
        startingCap = months[0].startingAvailableCap;
        forecastAdjustment = 0;
        months.forEach(month => {
            month.startingAvailableCap = startingCap;
            month.contractualPos = addValues(month.startingAvailableCap, month.expectedRepayments);
            startingCap = month.contractualPos;
            month.repaymentAdjustments = addArrayValues([month.extension, month.earlyRepOffset, month.selldownOffset, month.transfersOutOffset, month.transfersInOffset])
            month.forecastAdjustment = forecastAdjustment;
            const sumOfValues = addArrayValues([month.newCommitments, month.extensionOffset, month.earlyRepayment, month.selldowns, month.transfersOut, month.transfersIn, month.adjustment, month.subscriptions, month.redemptions, month.repaymentAdjustments, month.forecastAdjustment]);
            month.closingAvailableCap = addArrayValues([month.contractualPos, sumOfValues]);
            forecastAdjustment = sumOfValues;
        })

        months[0].sx = {borderLeft: 2};

        const data = [...weeks, ...months]

        for (let i = 1; i < data.length; i += 2) {
            data[i].sx = {...data[i].sx, bgcolor: 'grey.100'}
        }

        return {
            data,
            rows
        };
    }
)

export const getReportDate = createSelector(
    (state: RootState) => state.capitalBudget.thirdPartyData?.dateOfDataSet,
    (dataOfDataSet) => {
        return dataOfDataSet || new Date()
    }
)