import {MCPFund, PortfolioHolding} from "../../../types/capitalBudgetTypes";
import {FundType} from "../../../types/capitalBudgetEnums";

function formatHoldings(rawHoldings: Array<any>, funds: Array<MCPFund>) {
    const holders: Array<PortfolioHolding> = [];
    const fundFilteredHolders: Array<PortfolioHolding> = [];

    rawHoldings.forEach(h => {
        const holder = holders.find(holder => h.holder === holder.label);
        if (holder) {
            holder.holdings.push({fund: h.issuer, percentage: h.amount})
        } else {
            holders.push({label: h.holder, holdings: [{fund: h.issuer, percentage: h.amount}]})
        }
    })


    funds.forEach(fund => {
        if (fund.type === FundType.FEEDER) {
            const holdings = holders.find(h => fund.label === h.label);
            if (holdings) {
                const issues: Array<{fund: string, percentage: number}> = [];
                holdings.holdings.forEach(i => {
                    if (funds.find(f => f.label === i.fund)) {
                        issues.push(i);
                    }
                })
                fund.holdings = issues;
                fundFilteredHolders.push({label: fund.label, holdings: issues})
            }
        }
    })

    return {funds, fundFilteredHolders}
}

export default formatHoldings;