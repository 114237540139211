import {Navigate, Route, Routes} from "react-router-dom";
// Pages
import Reports from "./Reports";
import Page404 from "../Page404";
import PortfolioReports from "./PortfolioReports";
import FinancialReports from "./FinancialReports";
import InvestorReports from "./InvestorReports";
import LoanAdminReports from "./LoanAdminReports";
import AuxiliaryReports from "./AuxiliaryReports";
import ITReports from "./ITReports";

// Routes For Power BI Reports
const PowerBIPages = () => {

    return (
        <Routes>
            <Route path='/' element={<Navigate to={'reports'}/>}/>
            <Route path='/reports' element={<Reports/>}/>

            <Route path='/portfolio/*' element={<PortfolioReports/>} />
            <Route path='/financial/*' element={<FinancialReports/>} />
            <Route path='/investor/*' element={<InvestorReports/>} />
            <Route path='/loan-admin/*' element={<LoanAdminReports/>} />
            <Route path='/it/*' element={<ITReports/>} />
            <Route path='/auxiliary/*' element={<AuxiliaryReports/>} />

            <Route path='/*' element={<Page404/>}/>
        </Routes>
    )
}

export default PowerBIPages;