import {useState} from 'react';
import {useSelector} from "react-redux";
import { RootState } from '../../../../store/store';
import {Grid, IconButton, SxProps, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {Add as AddIcon, Remove as RemoveIcon} from "@mui/icons-material";
import {fCurrency} from "../../../../utils/formatNumber";
import {
    CalculationLoanType,
    CapitalBudgetPeriod,
    CapitalTransaction,
    OtherTransaction
} from "../../../../types/capitalBudgetTypes";
import {CapitalAction, InvestmentType, LoanTags, OtherTransactionTypes} from "../../../../types/capitalBudgetEnums";
import {dateSortComparator, formatDate} from "../../../../utils/DateUtils";
import {addValues} from "../../../../utils/mathUtil";



export default function DrillDownPanel({data}: { data: CapitalBudgetPeriod | null }) {
    const fund = useSelector((state: RootState) => state.capitalBudget.misc.fund);
    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        <TableRow sx={{bgcolor: 'info.dark'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Cash Opening
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((data?.cashBalanceClosing || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(data?.cashBalanceOpening || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{bgcolor: 'primary.main'}}>
                            <TableCell colSpan={4}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'white'}}
                                >
                                    Sources
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {data &&
                            <>
                                {/*Investor Inflows*/}
                                <DrillDownSection
                                    header={'Investor Inflows'}
                                    value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.capitalTransactions.filter((c: CapitalTransaction) =>
                                            c.transactionType === CapitalAction.SUBSCRIPTION).map((ct: CapitalTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: ct.name,
                                                value: ct.amount
                                            }
                                        })
                                    }}
                                />
                                {/*Expected Repayments*/}
                                <DrillDownSection
                                    header={'Loan Repayments'}
                                    value={data?.expectedRepaymentsCash || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.REPAYMENT)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.endDate,
                                                name: l.name,
                                                value: l.drawn,
                                                ...(!l.amendment) ? {sx: {bgcolor: 'info.light'}} : {}
                                            }
                                        })
                                    }}
                                />
                                {/*Notified Early Repayments*/}
                                <DrillDownSection
                                    header={'Notified Early Repayments'}
                                    value={data?.earlyRepaymentCash || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.EARLY_REPAYMENT)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.amendedMaturity,
                                                name: l.name,
                                                value: l.drawn
                                            }
                                        })

                                    }}
                                />
                                {/*Extended Loans*/}
                                <DrillDownSection
                                    header={'Extended Loans'}
                                    value={data?.extensionCash || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.EXTENSION)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.amendedMaturity,
                                                name: l.name,
                                                value: l.drawn
                                            }
                                        })

                                    }}
                                />
                                {/*Selldowns/Partial Repayments*/}
                                <DrillDownSection
                                    header={'Asset Sell Downs/Partial Repayments'}
                                    value={data?.selldownsCash || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.SELLDOWN)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: (l.selldowns) ? l.selldowns[0].date : '',
                                                name: l.name,
                                                value: (l.selldowns) ? (l.selldowns[0].amount * (l.drawn / l.value)) : '',
                                            }
                                        })

                                    }}
                                />
                                {/*Transfers Out*/}
                                <DrillDownSection
                                    header={'Transfers Out'}
                                    value={data?.transfersOutCash || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.TRANSFER_OUT)).map((l: CalculationLoanType) => {
                                            const transferCash = (l.transfersOut && l.originalDrawnP) ? ((l.transfersOut?.originalAllocation - l.transfersOut?.newAllocation) * l.originalDrawnP) : 0
                                            return {
                                                id: l.id,
                                                date: (l.transfersOut) ? l.transfersOut.transferDate : '',
                                                name: l.name,
                                                value: transferCash
                                            }
                                        })

                                    }}
                                />
                                {/*Transfers In (Offset)*/}
                                <DrillDownSection
                                    header={'Transfers In Offset'}
                                    value={data?.transfersInOffsetCash || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.OFFSET_TRANSFER_IN)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: (l.transfersOut) ? l.transfersOut.transferDate : '',
                                                name: l.name,
                                                value: l.drawn * (l.drawn / l.value)
                                            }
                                        })

                                    }}
                                />
                                {/*Manual Required Debt Draw*/}
                                <DrillDownSection
                                    header={'Required Debt Drawn/Asset Transfer'}
                                    value={data?.debtDraw || 0}
                                    retrieveValues={() => {
                                        return data?.otherTransactions.filter((c: OtherTransaction) =>
                                            c.transactionType === OtherTransactionTypes.DEBT_DRAW).map((ct: OtherTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: (ct.notes) ? ct.notes : 'Required Debt Drawn/Asset Transfer',
                                                value: ct.amount
                                            }
                                        })
                                    }}
                                />
                                {/*Positive Adjustments*/}
                                <DrillDownSection
                                    header={'Other Adjustments'}
                                    value={data?.adjustmentsPos || 0}
                                    retrieveValues={() => {
                                        return data?.otherTransactions.filter((c: OtherTransaction) =>
                                            c.transactionType === OtherTransactionTypes.ADJUSTMENT && c.amount > 0).map((ct: OtherTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: (ct.notes) ? ct.notes : 'Positive Adjustment',
                                                value: ct.amount
                                            }
                                        })
                                    }}
                                />
                            </>
                        }
                        <TableRow sx={{bgcolor: 'primary.main'}}>
                            <TableCell colSpan={4}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'white'}}
                                >
                                    Uses
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {data &&
                            <>
                                {/*First Drawdown*/}
                                <DrillDownSection
                                    header={'New Loans (First Drawdown at FC)'}
                                    value={data?.firstDrawdown || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.NEW_LOAN)).map((l: CalculationLoanType) => {

                                            return {
                                                id: l.id,
                                                date: l.startDate,
                                                name: l.name,
                                                value: -l.drawn
                                            }
                                        })

                                    }}
                                />
                                <DrillDownSection
                                    header={'IC Approved Extended Loans'}
                                    value={data?.extensionOffsetCash || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.OFFSET_EXTENSION)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.endDate,
                                                name: l.name,
                                                value: -l.drawn
                                            }
                                        })

                                    }}
                                />
                                <DrillDownSection
                                    header={'Early Repayments Offset'}
                                    value={data?.earlyRepOffsetCash || 0}
                                    // value={data?.subscriptions || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.OFFSET_EARLY)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: l.amendedMaturity,
                                                name: l.name,
                                                value: -l.drawn
                                            }
                                        })

                                    }}
                                />
                                <DrillDownSection
                                    header={'Asset Sell Downs/Partial Repayments Offset'}
                                    value={data?.selldownOffsetCash || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.OFFSET_SELLDOWN)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: (l.selldowns) ? l.selldowns[0].date : '',
                                                name: l.name,
                                                value: (l.selldowns) ? -(l.selldowns[0].amount * (l.drawn / l.value)) : '',
                                            }
                                        })

                                    }}
                                />
                                <DrillDownSection
                                    header={'Transfers In'}
                                    value={data?.transfersInCash || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.TRANSFER_IN)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: (l.transfersOut) ? l.transfersOut.transferDate : '',
                                                name: l.name,
                                                value: -l.drawn
                                            }
                                        })
                                    }}
                                />
                                <DrillDownSection
                                    header={'Transfers Out Offset'}
                                    value={data?.transfersOutOffsetCash || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.OFFSET_TRANSFER_OUT)).map((l: CalculationLoanType) => {
                                            const transferCash = (l.transfersOut && l.originalDrawnP) ? ((l.transfersOut?.originalAllocation - l.transfersOut?.newAllocation) * l.originalDrawnP) : 0
                                            return {
                                                id: l.id,
                                                date: (l.transfersOut) ? l.transfersOut.transferDate : '',
                                                name: l.name,
                                                value: -transferCash
                                            }
                                        })
                                    }}
                                />
                                {/*Redemption Outflows*/}
                                <DrillDownSection
                                    header={'Investor Redemptions (Incl. 1 week buffer)'}
                                    value={data?.redemptionOffset || 0}
                                    retrieveValues={() => {
                                        return data?.capitalTransactions.filter((c: CapitalTransaction) =>
                                            c.transactionType === CapitalAction.REDEMPTION).map((ct: CapitalTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: ct.name,
                                                value: ct.amount
                                            }
                                        })
                                    }}
                                />
                                {/*CRE CTC*/}
                                <DrillDownSection
                                    header={'CRE (CTC)'}
                                    value={data?.totalCTCDrawdown || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.CRE_CTC)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: '',
                                                name: l.name,
                                                value: (l.creCtcDrawdown) ? -l.creCtcDrawdown : 0
                                            }
                                        })
                                    }}
                                />
                                <DrillDownSection
                                    header={'Corporate (CTC)'}
                                    value={data?.periodCorpCTCDrawdownNew || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => l.tags.includes(LoanTags.CORP_CTC_NEW)).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: '',
                                                name: l.name,
                                                value: (l.creCtcDrawdown) ? -l.creCtcDrawdown : 0
                                            }
                                        })
                                    }}
                                />
                                {/*Cash Distribution*/}
                                <DrillDownSection
                                    header={'Cash Distribution Net of DRP'}
                                    value={data?.cashDistribution || 0}
                                    retrieveValues={() => {
                                        return data?.otherTransactions.filter((c: OtherTransaction) =>
                                            c.transactionType === OtherTransactionTypes.CASH_DISTRIBUTION).map((ct: OtherTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: (ct.notes) ? ct.notes : 'Cash Distribution Net of DRP',
                                                value: ct.amount
                                            }
                                        })
                                    }}
                                />
                                {/*Revolver Drawdown */}
                                <DrillDownSection
                                    header={'Confirmed drawdowns from Revolvers'}
                                    value={data?.revolverDrawdown || 0}
                                    retrieveValues={() => {
                                        return data?.otherTransactions.filter((c: OtherTransaction) =>
                                            c.transactionType === OtherTransactionTypes.REVOLVER_DRAWDOWN).map((ct: OtherTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: (ct.notes) ? ct.notes : 'Confirmed drawdowns from Revolvers',
                                                value: ct.amount
                                            }
                                        })
                                    }}
                                />
                                {/*Negative Adjustments*/}
                                <DrillDownSection
                                    header={'Other Adjustments'}
                                    value={data?.adjustmentsNeg || 0}
                                    retrieveValues={() => {
                                        return data?.otherTransactions.filter((c: OtherTransaction) =>
                                            c.transactionType === OtherTransactionTypes.ADJUSTMENT && c.amount < 0).map((ct: OtherTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: (ct.notes) ? ct.notes : 'Negative Adjustment',
                                                value: ct.amount
                                            }
                                        })
                                    }}
                                />
                            </>
                        }
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Net Sources and Uses
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{color: ((data?.total || 0) >= 0) ? 'black' : 'red'}}
                                >
                                    {fCurrency(data?.total || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{bgcolor: 'info.dark'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Cash Closing (assuming no RCF, Capex draws)
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((data?.cashBalanceClosing || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(data?.cashBalanceClosing || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{bgcolor: 'primary.main'}}>
                            <TableCell colSpan={4}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'white'}}
                                >
                                    Debt Facilities
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {data &&
                            <>
                                <DrillDownSection
                                    header={'Available Facility'}
                                    value={data?.actualFacilityAva || 0}
                                    retrieveValues={() => {
                                        return []
                                    }}
                                    noExpand
                                />
                                <DrillDownSection
                                    header={'Increase Bank Facilities (In Pipeline)'}
                                    value={data?.newFacility || 0}
                                    retrieveValues={() => {
                                        return []
                                    }}
                                    noExpand
                                />
                            </>
                        }
                        <TableRow sx={{bgcolor: 'info.dark'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Total Available Facility
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((data?.cashBalanceClosing || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(data?.totalAvaFacility || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{bgcolor: 'primary.main'}}>
                            <TableCell colSpan={4}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'white'}}
                                >
                                    Net liquidity
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {data &&
                            <>
                                {/*Revolvers*/}
                                <DrillDownSection
                                    header={'Revolvers'}
                                    value={data?.revolversUndrawn || 0}
                                    retrieveValues={() => {
                                        const revolverDrawdown = data?.otherTransactions.filter((c: OtherTransaction) =>
                                            c.transactionType === OtherTransactionTypes.REVOLVER_DRAWDOWN).map((ct: OtherTransaction) => {
                                            return {
                                                id: ct.id,
                                                date: ct.date,
                                                name: (ct.notes) ? ct.notes : 'Confirmed drawdowns from Revolvers',
                                                value: ct.amount
                                            }
                                        });
                                        const loans = data?.loans.filter((l: CalculationLoanType) => (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType === 'Revolving')).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: '',
                                                name: l.name,
                                                value: -l.undrawn
                                            }
                                        })

                                        return [...revolverDrawdown, ...loans]
                                    }}
                                />
                                {/*Capex*/}
                                <DrillDownSection
                                    header={'Capex'}
                                    value={data?.capexUndrawn || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType === 'Capex')).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: '',
                                                name: l.name,
                                                value: -l.undrawn
                                            }
                                        })
                                    }}
                                />
                                {/*Other*/}
                                <DrillDownSection
                                    header={'Other'}
                                    value={data?.otherUndrawn || 0}
                                    retrieveValues={() => {
                                        return data?.loans.filter((l: CalculationLoanType) => (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType !== 'Capex' && l.trancheType !== 'Revolving')).map((l: CalculationLoanType) => {
                                            return {
                                                id: l.id,
                                                date: '',
                                                name: l.name,
                                                value: -l.undrawn
                                            }
                                        })
                                    }}
                                />
                            </>
                        }
                        <TableRow sx={{bgcolor: 'info.dark'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Total
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((data?.cashBalanceClosing || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(data?.totalLiquidity || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{bgcolor: 'secondary.main'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'white'}}
                                >
                                    Net Liquidity
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{color: ((data?.netLiquidity || 0) >= 0) ? 'white' : 'red'}}
                                >
                                    {fCurrency(data?.netLiquidity || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {!fund && data &&
                            <>
                                <DrillDownSection
                                    header={'Fund Finance Facilities - Unused limit'}
                                    value={data?.unusedFacilities || 0}
                                    retrieveValues={() => []}
                                    noExpand
                                />
                                <TableRow sx={{bgcolor: 'info.lighter'}}>
                                    <TableCell colSpan={3}>
                                        <Typography
                                            variant='h6'
                                        >
                                            Net Position
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Typography
                                            variant='h6'
                                            sx={{color: ((data?.netPosition || 0) >= 0) ? 'black' : 'red'}}
                                        >
                                            {fCurrency(data?.netPosition || 0)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </>
                        }

                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}

function DrillDownSection({header, value, retrieveValues, noExpand}: {
    header: string,
    value: number,
    retrieveValues: () => Array<{ id: any, date: Date | number, name: string, value: number, sx?: SxProps }>
    noExpand?: boolean
}) {
    const [expand, setExpand] = useState<boolean>(false);

    return (
        <>
            <TableRow sx={{height: 35}}>
                <TableCell
                    sx={{width: 47, py: 0}}
                    align='center'
                >
                    {(value !== 0 && (!noExpand)) &&
                        <IconButton
                            size='small'
                            onClick={() => setExpand(!expand)}
                        >
                            {expand ? <RemoveIcon/> : <AddIcon/>}
                        </IconButton>
                    }
                </TableCell>
                <TableCell colSpan={2} sx={{py: 0}} align='left'>{header}</TableCell>
                <TableCell sx={{py: 0, ...(value < 0) ? {color: 'red'} : {}}}
                           align='right'>{fCurrency(value)}</TableCell>
            </TableRow>
            {expand &&
                handleValueFunction(retrieveValues).map((row, id) => (
                    <TableRow key={id} sx={{...(row.sx) ? row.sx : {}}}>
                        <TableCell sx={{border: 'none'}}/>
                        <TableCell sx={{
                            border: 'none',
                            width: 100
                        }}><Typography>{formatDate(row.date)}</Typography></TableCell>
                        <TableCell sx={{
                            border: 'none',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis'
                        }}><Typography>{row.name}</Typography></TableCell>
                        <TableCell sx={{border: 'none'}}
                                   align='right'><Typography
                            sx={{...(row.value < 0) ? {color: 'red'} : {}}}>{fCurrency(row.value)}</Typography></TableCell>
                    </TableRow>
                ))

            }
        </>
    )
}

function handleValueFunction(retriever: () => Array<{
    id: any,
    date: Date | number,
    name: string,
    value: number,
    sx?: SxProps
}>) {
    try {
        return [...retriever().reduce((r: any, o: any) => {
            const key = o.date + '-' + o.name;
            const item = r.get(key) || Object.assign({}, 0, {
                id: o.id,
                date: o.date,
                name: o.name,
                value: 0,
                sx: o?.sx || {}
            });

            item.value = addValues(item.value, o.value);

            return r.set(key, item);
        }, new Map()).values()].sort((a, b) => dateSortComparator(a.date, b.date)).filter(v => v.value !== 0);
    } catch (e) {
        return []
    }
}

