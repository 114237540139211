import { useCallback } from "react";
import { defaultFileTypes, FileFieldProps } from "../../../types/InputTypes"
import {Accept, useDropzone} from 'react-dropzone';
// MUI
import { styled, useTheme } from "@mui/material/styles";
import {
    Box,
    FormControl,
    FormHelperText,
    Grid, 
    IconButton, 
    InputLabel,
    Theme, 
    Tooltip, 
    Typography
} from "@mui/material";
import {
    HelpOutline as HelpOutlineIcon,
    InsertDriveFile as FileIcon,
    Close as CloseIcon
} from "@mui/icons-material"

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

/**
 * File Input Field
 * Masks number input to include decimals, commas and '$' prefix
 */
export default function FileInputField(props: FileFieldProps) {
    const theme = useTheme();

    const {
        id,
        label,
        info = null,
        helperText,
        error,
        handleChange,
        multiple = false,
        fileType = defaultFileTypes
    } = props;

    const files: Array<File> = props.value;

    // Handles the upload of files
    const onDrop = useCallback((acceptedFiles: Array<File>) => {

        if (multiple) { // If multiple files combine existing list of files with new
            handleChange([...files, ...acceptedFiles], id)
        } else { // If single file, replace file
            handleChange(acceptedFiles, id)
        }
    }, [multiple, handleChange, files, id])

    // Handles the removal of file, finds file and removes according to order.
    const handleRemoveFile = (key: number) => {
        let updateFiles = files;

        updateFiles.splice(key, 1);

        handleChange(updateFiles, id);
    }

    // only allows valid types
    const { getRootProps, getInputProps } = useDropzone({ multiple, onDrop, accept: fileType as Accept });

    return (
        <GridStyle
            item
            xs={12}
            md={12}
            lg={12}
        >
            <FormControl
                sx={{ width: '100%' }}
            >
                <InputLabel error={error} htmlFor={label} shrink={true}>{label}</InputLabel>
                {/* Files input box */}
                {(multiple || files.length === 0) &&
                    <Box
                        sx={{
                            border: `2px dashed ${theme.palette.primary.main}`,
                            marginTop: 2,
                            marginBottom: 2,
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        {...getRootProps({ className: 'dropzone' })}
                    >
                        <input {...getInputProps()} />
                        <Typography>Click to Select file</Typography>
                        {/* Info and Tooltips */}
                        {info &&
                            <Tooltip title={info} placement='right'>
                                <IconButton edge="end">
                                    <HelpOutlineIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                }
                {
                    helperText != null &&
                    <FormHelperText error={error}>{helperText}</FormHelperText>
                }
                {/* List Files Submitted */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1
                    }}
                >
                    {files.map((file: File, f: number) =>
                        <Box key={f}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignContent: 'center',
                                alignItems: 'center',
                                paddingLeft: 3
                            }}
                        >
                            <FileIcon />
                            <Typography
                                sx={{
                                    padding: 1,
                                    paddingTop: 1.5
                                }}
                            >
                                {file.name}
                            </Typography>
                            <IconButton
                                edge="end"
                                size="small"
                                onClick={() => handleRemoveFile(f)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>)
                    }
                </Box>

            </FormControl>
        </GridStyle>
    )
}