import {createAsyncThunk} from '@reduxjs/toolkit';
import {Auth, Cache} from 'aws-amplify';
import {convertDMYDate} from '../../utils/DateUtils';
import {apiGetRequest, apiPatchRequest, apiPostRequest, apiPutRequest} from '../apiUtils';
import {addNotification} from '../notifications/notificationSlice';
import {RootState} from '../store';
import {userRoleTypes} from './userSlice';
// -----------------------------------------------------------------------------------

// Login Thunk Function
export const loginUser = createAsyncThunk('user/loginUser', async (cred: { email: string, password: string }, thunkAPI) => {
    try {
        const userTemp = await Auth.signIn(cred.email.toLowerCase(), cred.password);
        // Saves value to Local Storage application through Amplify Cache, used to check user session without Async call.
        Cache.setItem('COGNITO_USER', true);

        const user = {
            username: userTemp.username,
            ...userTemp.attributes
        }
        // Extract Role attribute
        if (user['custom:role']) {
            user.role = user['custom:role']
        } else {
            user.role = userRoleTypes.USER
        }

        // UI Notification
        let confirm = 'Successfully Logged In!';
        if (user.given_name) {
            confirm = `Welcome ${user.given_name}!`
        }
        thunkAPI.dispatch(addNotification(confirm, 'success'));

        return user;
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Incorrect Username or Password';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// REAUTHENTICATE USER
export const reauthenticateUser = createAsyncThunk('user/refreshUser', async (_) => {
    try {
        // Reauthenticate current users session
        const userTemp = await Auth.currentAuthenticatedUser();

        const user = {
            username: userTemp.username,
            ...userTemp.attributes
        }
        // Extract Role attribute
        if (user['custom:role']) {
            user.role = user['custom:role']
        } else {
            user.role = userRoleTypes.USER
        }
        return user;
    } catch (error) {
        Cache.clear();
        addNotification('Please sign in Again', 'error', false)
        console.log('error re-authenticating in', error);
    }
})

// SIGN OUT USER
export const signOut = createAsyncThunk('user/signOut', async (_, thunkAPI) => {
    try {
        // Terminate user session 
        await Auth.signOut({ global: true });

        // Remove user from cache
        Cache.clear();

        // UI Notification
        const state = thunkAPI.getState() as RootState;

        let confirm = 'Successfully Logged Out!';
        if (state.user?.user?.given_name) {
            confirm = `Goodbye ${state.user.user.given_name}!`
        }

        thunkAPI.dispatch(addNotification(confirm, 'success'));

        return;
    } catch (error) {
        thunkAPI.dispatch(addNotification('Error Signing out.', 'error'));
        console.log('Error signing out: ', error);
    }
})

// Quickly Identify is there is a user session
export const getSession = () => {
    const user = Cache.getItem('COGNITO_USER')
    return (!!user);
}

// Reset Password Thunk Function
export const forgotPassword = createAsyncThunk('user/forgotPassword', async (cred: { email: string }, thunkAPI) => {
    try {
        console.log('forget')
        const result = await Auth.forgotPassword(cred.email.toLowerCase());
        console.log(result)
        thunkAPI.dispatch(addNotification('Please check your emails for reset code', 'success'));

        return;
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred requesting reset code.';
        }
        thunkAPI.dispatch(addNotification(message, 'error', true));
        return thunkAPI.rejectWithValue(message);
    }
})

// Confirm Password Reset Thunk Function
export const forgotPasswordSubmit = createAsyncThunk('user/forgotPasswordSubmit', async (cred: { email: string, code: string, password: string }, thunkAPI) => {
    try {
        await Auth.forgotPasswordSubmit(cred.email.toLowerCase(), cred.code, cred.password)
        thunkAPI.dispatch(addNotification('Password changed.', 'success'));
        return;
    } catch (error: unknown) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred changing password.';
        }
        thunkAPI.dispatch(addNotification(message, 'error', true));
        return thunkAPI.rejectWithValue(message);
    }
})

function generatePassword() {
    const length = 9,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        symbolSet = '!@#$%^&*()',
        numset = "0123456789";
    let retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    for (var j = 0, m = symbolSet.length; j < length; ++j) {
        retVal += symbolSet.charAt(Math.floor(Math.random() * m));
    }
    for (var k = 0, o = symbolSet.length; k < length; ++k) {
        retVal += numset.charAt(Math.floor(Math.random() * o));
    }
    return retVal;
}

// Create New User 
export const createUser = createAsyncThunk('user/createUser', async (newUser: { givenName: string, familyName: string, email: string, birthdate: Date | string, role: userRoleTypes }, thunkAPI) => {
    try {
        const password = generatePassword();

        const auth = await Auth.signUp({
            username: newUser.email.toLowerCase(),
            password: password,
            attributes: {
                email: newUser.email.toLowerCase(),
                given_name: newUser.givenName,
                family_name: newUser.familyName,
                name: newUser.givenName + ' ' + newUser.familyName,
                birthdate: newUser.birthdate,
                'custom:role': newUser.role
            }
        })

        await apiPostRequest('users', {user: {
                thirdPartyId: auth.userSub,
                firstName: newUser.givenName,
                lastName: newUser.familyName,
                email: newUser.email.toLowerCase()
            }})

        thunkAPI.dispatch(addNotification('User created', 'success'));
        return {
            username: newUser.email.toLowerCase(),
            ...newUser
        };
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred creating user.';
        }
        thunkAPI.dispatch(addNotification(message, 'error', true));
        return thunkAPI.rejectWithValue(message);
    }
})

// Verify User From email and code
export const verifyUser = createAsyncThunk('user/verifyUser', async (user: { email: string, code: string }, thunkAPI) => {
    try {
        await Auth.confirmSignUp(user.email.toLowerCase(), user.code);
        thunkAPI.dispatch(forgotPassword({ email: user.email.toLowerCase() }))
        thunkAPI.dispatch(addNotification('User verified', 'success'));
        return;
    } catch (error) {
        let message;
        if (error instanceof Error) {
            if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                thunkAPI.dispatch(forgotPassword({ email: user.email.toLowerCase() }))
                thunkAPI.dispatch(addNotification('User has been confirmed.', 'success'));
                return;
            }
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred verifying user';
        }
        thunkAPI.dispatch(addNotification(message, 'error', true));
        return thunkAPI.rejectWithValue(message);
    }
})

// Changes authenticated users passwords
export const changePassword = createAsyncThunk('user/changePassword', async (cred: { currentPassword: string, password: string }, thunkAPI) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, cred.currentPassword, cred.password)
        thunkAPI.dispatch(addNotification('Password changed.', 'success'));
        return;
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred resetting password.';
        }
        thunkAPI.dispatch(addNotification(message, 'error', true));
        return thunkAPI.rejectWithValue(message);
    }
})


// Retrieves list of users for admin roles
export const getUsers = createAsyncThunk('user/getUsers', async (_, thunkAPI) => {
    try {
        const tempUsers = await apiGetRequest('users');
        const users: Array<any> = [];
        tempUsers.Users.forEach((tempUser: any) => {
            // Format user from cognito format to local format
            const user: any = {
                username: tempUser.Username,
            };
            tempUser.Attributes.forEach((attr: any) => {
                user[`${attr.Name}`] = attr.Value
            })
            user.status = tempUser.Enabled;
            user.id = user.sub;
            user.role = user['custom:role'];
            user.birthdate = convertDMYDate(user.birthdate);
            delete user.sub;
            delete user['custom:role'];

            users.push(user);
        });

        return users;
    } catch (error) {
        let message;
        if (error instanceof Error) {
            console.log(error)
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred retrieving users';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Update Users Attributes
export const updateUserAttributes = createAsyncThunk('user/updateUserAttr', async (user: { givenName: string, familyName: string, email: string, birthdate: Date | string, role: userRoleTypes }, thunkAPI) => {
    try {
        const body = {
            email: user.email.toLowerCase(),
            given_name: user.givenName,
            family_name: user.familyName,
            name: user.givenName + ' ' + user.familyName,
            birthdate: user.birthdate,
            role: user.role
        }
        await apiPutRequest(`users?user=${user.email.toLowerCase()}`, body);
        thunkAPI.dispatch(addNotification('User updated.', 'success'));

        thunkAPI.dispatch(getUsers());
        return;
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred updating user.';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Disable or re-enable User
export const enableDisableUser = createAsyncThunk('user/enableDisableUser', async (user: { email: string, status: boolean }, thunkAPI) => {
    try {
        await apiPatchRequest(`users?user=${user.email.toLowerCase()}&enableUser=${user.status}`, {});
        thunkAPI.dispatch(addNotification(`User '${user.email}' account ${user.status ? 'Enabled' : 'Disabled.'}`, 'success'));
        thunkAPI.dispatch(getUsers());
        return;
    } catch (error) {
        let message;
        console.log(error)
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Error occurred updating user.';
        }
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})