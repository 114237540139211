// @mui
import {Container, Grid, Typography} from '@mui/material';
// components
import {DataTable, Page} from '../../components';
import VersionConfigBar from "./VersionConfigBar";
import {useAppSelector} from "../../store/store";
import {getValuationModelLoading} from "../../store/valuationModel/selectors/generalSelectors";
import {
    ProfitLossSummaryColumns,
    profitLossSummarySelector, ValuationExportColumns,
    valuationExportSelector
} from "../../store/valuationModel/selectors/valuationExportSelector";


export default function ValuationExport() {
    const valuations = useAppSelector(state => valuationExportSelector(state));
    const profitLossSummary = useAppSelector(state => profitLossSummarySelector(state));
    const loading = useAppSelector(state => getValuationModelLoading(state));

    return (
        <Page title="Metrics - Valuation Model - Export">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Valuation Export
                        </Typography>
                        <VersionConfigBar/>
                    </Grid>
                    <Grid item container sx={{width: '100%', height: '100%', flex: 1}} direction="column">
                            <Grid item sx={{height: 300}}>
                                <DataTable
                                    title={'Profit Loss Summary (AUD)'}
                                    columns={ProfitLossSummaryColumns}
                                    data={profitLossSummary}
                                    loading={loading}
                                    sort={{field: 'tranche_id', sort: 'asc'}}
                                />
                            </Grid>
                            <Grid item sx={{flex: 1}}>
                                <DataTable
                                    title={'Valuations'}
                                    columns={ValuationExportColumns}
                                    data={valuations}
                                    loading={loading}
                                    sort={{field: 'tranche_id', sort: 'asc'}}
                                    search
                                    showColumns
                                    showFilter
                                />
                        </Grid>
                    </Grid>
                </Grid>

                {/*<Grid container spacing={2} direction='column' sx={{height: 1, flex: 1}}>*/}
                {/*    <Grid container spacing={1} direction='row' justifyContent='space-between'>*/}
                {/*        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>*/}
                {/*            Valuation Export*/}
                {/*        </Typography>*/}
                {/*        <VersionConfigBar/>*/}
                {/*    </Grid>*/}
                {/*    <Grid item container sx={{width: '100%'}}>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            <DataTable*/}
                {/*                title={'Profit Loss Summary'}*/}
                {/*                columns={ProfitLossSummaryColumns}*/}
                {/*                data={profitLossSummary}*/}
                {/*                loading={loading}*/}
                {/*                sort={{field: 'tranche_id', sort: 'asc'}}*/}
                {/*            />*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%'}}>*/}
                {/*        <DataTable*/}
                {/*            title={'Valuations'}*/}
                {/*            columns={ValuationExportColumns}*/}
                {/*            data={valuations}*/}
                {/*            loading={loading}*/}
                {/*            sort={{field: 'tranche_id', sort: 'asc'}}*/}
                {/*            search*/}
                {/*            showColumns*/}
                {/*            showFilter*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Container>
        </Page>
    )
}