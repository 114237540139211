import {useEffect} from 'react';
import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
import {fCurrency} from '../../../../utils/formatNumber';
// Local Imports
import {InputTypes} from '../../../../types/InputTypes';
// MUI
import {Button, Grid, Typography} from '@mui/material';
// Components
import {FormInput} from '../../../../components';
// Store
import {useAppDispatch, useAppSelector} from '../../../../store/store';
import {
    retrieveFundsSelectFormat, retrieveAllAxcessLoans,
    retrieveLoansAllocatedByTranche
} from "../../../../store/capitalBudget/selectors";
import {addTransfer} from "../../../../store/capitalBudget/capitalBudgetSlice";
import {addValues} from "../../../../utils/mathUtil";

// ----------------------------------------------------------------------
// Initial Form State
const defaultTransactionValues = {
    portfolio: null,
    from: '',
    to: '',
    transferDate: null,
    amount: 0,
    originalAllocation: 0,
}
// ----------------------------------------------------------------------

// Create Amendment Transaction
export default function AmendmentForm({onClose}: { onClose: () => void }) {
    const dispatch = useAppDispatch()

    const axcess = useAppSelector(state => retrieveAllAxcessLoans(state));
    const transfers = useAppSelector(state => state.capitalBudget.scenarioData?.transfers || []);
    const funds = useAppSelector(state => retrieveFundsSelectFormat(state));

    return (
        <>
            <Formik
                initialValues={defaultTransactionValues}
                validationSchema={Yup.object().shape({
                    transfer: Yup.object()
                        .test({
                            name: 'overlappedTransfer',
                            exclusive: false,
                            params: {},
                            message: 'This portfolio and fund already has an existing reallocation. Please refer and edit this portfolio loan in the Amended Allocations Table.',
                            test: function () {
                                const {portfolio, from} = this.parent;
                                if (portfolio && from) {
                                    const index = transfers.findIndex(t => t.trancheId === portfolio.tranche_id && t.fromFund === from);
                                    if (index !== -1) return false;
                                }
                                return true
                            }
                        }),
                    transferDate: Yup.date()
                        .typeError('Valid Date Required')
                        .required('Date is required'),
                    amount: Yup.string().min(0).test({
                        name: 'min',
                        exclusive: false,
                        params: {},
                        message: 'Reallocation must not exceed current allocated amount.',
                        test: function (value) {
                            const {originalAllocation} = this.parent;
                            return !!value && value <= originalAllocation;
                        }
                    }).required('Commitment Required.'),
                    from: Yup.string().required('Required.').required('Required.'),
                    to: Yup.string().test({
                        name: 'Fund-difference',
                        exclusive: false,
                        params: {},
                        message: 'Cannot be the same fund.',
                        test: function (value) {
                            return value !== this.parent.from
                        }
                    }).required('Required.').required('Required.')
                })}
                onSubmit={(values: any, actions) => {
                    const fund = funds.find(f => f.label === values.to);
                    if (fund) {
                        const transfer = {
                            axcessLoanId: values.portfolio.id,
                            trancheId: values.portfolio.tranche_id,
                            fromFund: values.from,
                            originalAllocation: values.originalAllocation,
                            newAllocation: addValues(values.originalAllocation, -values.amount, 10),
                            transferDate: new Date(values.transferDate).toDateString(),
                            transfers: [{
                                amount: values.amount,
                                fund: values.to,
                                fundId: fund.value
                            }]
                        }
                        dispatch(addTransfer(transfer));
                        onClose();
                    } else {
                        actions.setFieldError('to', 'Error identifying fund.')
                    }
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values
                    } = props;
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='portfolio'
                                        label='Portfolio'
                                        fieldType={InputTypes.SEARCH}
                                        values={axcess}
                                        labelFunc={(option) => `${option.tranche_id} - ${option.borrower}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <PortfolioAmendment trancheId={values.portfolio?.tranche_id || null}
                                                        formikProps={props}/>
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Add Transfer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}

type PortfolioAmendmentProps = {
    trancheId: number | null,
    formikProps: FormikProps<any>
}

const PortfolioAmendment = (props: PortfolioAmendmentProps) => {
    const {
        trancheId,
        formikProps
    } = props;

    const {
        errors,
        setFieldValue,
        values
    } = formikProps;

    const loans = useAppSelector(state => retrieveLoansAllocatedByTranche(state, trancheId));
    const funds = useAppSelector(state => retrieveFundsSelectFormat(state, true));

    const loan = loans.find(l => l.fund === values.from);

    useEffect(() => {
        if (!!loan) {
            setFieldValue('originalAllocation', loan.commitment)
            setFieldValue('amount', loan.commitment)
        }
    }, [trancheId, loan, setFieldValue])

    return (
        <>
            <FormInput
                id='from'
                label='From'
                fieldType={InputTypes.SELECTION}
                layout={{xs: 12, md: 12, lg: 12}}

                values={loans.map(loan => {
                    return {label: loan.fund, value: loan.fund}
                })}
                disabled={!trancheId}
            />
            {errors.transfer &&
                <Grid container item xs={12}>
                    <Typography sx={{color: 'error.main'}} align='center'>
                        {errors.transfer as string}
                    </Typography>
                </Grid>
            }
            <FormInput
                id='to'
                label='To'
                fieldType={InputTypes.SELECTION}
                layout={{xs: 12, md: 12, lg: 12}}

                values={funds.filter(fund => fund.label !== formikProps.values.from).map(fund => {
                    return {label: fund.label, value: fund.label};
                })}
                disabled={!values.from}
            />
            {loan &&
                <Grid container item xs={12}>
                    <Grid item xs={12} sx={{px: 2, py: 1}}>
                        <Typography variant='h4' sx={{color: 'primary.main'}}><b>Loan:</b></Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Start
                            Date:</b></Typography><Typography variant='body1' sx={{
                        pl: 5,
                        fontSize: 16
                    }}>{new Date(loan.start_date).toDateString()}</Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Maturity
                            Date:</b></Typography><Typography variant='body1' sx={{
                        pl: 5,
                        fontSize: 16
                    }}>{new Date(loan.maturity).toDateString()}</Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}><b>Current
                            Allocation:</b></Typography><Typography variant='body1' sx={{
                        pl: 5,
                        fontSize: 16
                    }}>{fCurrency(loan.commitment)}</Typography>
                    </Grid>
                </Grid>
            }
            <FormInput
                id='transferDate'
                label='Transfer Date'
                fieldType={InputTypes.DATE}
                layout={{xs: 12, md: 12, lg: 12}}
                minDate={(!!loan) ? new Date(loan.start_date) : new Date()}
                maxDate={(!!loan) ? new Date(loan.maturity) : undefined}
                disabled={!values.from}
            />
            <FormInput
                id='amount'
                label='Transfer Amount'
                fieldType={InputTypes.CURRENCY}
                layout={{xs: 12, md: 12, lg: 12}}

                disabled={!values.from}
            />
        </>
    )
}