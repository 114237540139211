import {MouseEventHandler} from 'react';
// MUI
import {AppBar, Box, Stack, Toolbar, IconButton, Typography} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
// components
import AccountPopover from './AccountPopover';
import {Logo} from '../../components';

// ----------------------------------------------------------------------

export default function DashboardNavbar({onOpenSidebar}: { onOpenSidebar: MouseEventHandler<HTMLButtonElement> }) {
    return (
        <AppBar
            position='fixed'
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                pl: 0, ...((process.env.REACT_APP_ENV === 'LOCAL_DEV') ? {bgcolor: '#bd0b0b'} : (process.env.REACT_APP_ENV === 'UAT') ? {bgcolor: '#1a8606'} : {})
            }}
            elevation={0}
        >
            <Toolbar
                disableGutters
                sx={{
                    pl: '16px',
                    pr: '16px',
                }}
            >
                <IconButton
                    onClick={onOpenSidebar}
                    sx={{mr: 0, color: 'white'}}
                >
                    <MenuIcon/>
                </IconButton>
                <Logo/>
                <Box sx={{flexGrow: 1}}/>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{xs: 0.5, sm: 1.5}}
                >
                    {(process.env.REACT_APP_ENV === 'DEV') &&
                        <Typography variant='h6'>DEVELOPMENT</Typography>
                    }
                    {/* <NotificationsPopover /> */}
                    <AccountPopover/>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
