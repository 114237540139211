// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {OtherTransaction} from "../../../types/capitalBudgetTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {checkDateSame} from "../../../utils/DateUtils";
import {checkObjectChanged} from "../../../utils/generalUtils";


// Reducers Related to Capital Budget - Other Transactions

const otherTransactionReducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create New Transaction
    addOtherTransaction: {
        prepare(newTransaction: OtherTransaction) {
            return {
                payload: {
                    ...newTransaction,
                    id: nanoid(),
                    status: SaveStatus.NEW
                }
            }
        },
        reducer(state, action: PayloadAction<OtherTransaction>) {
            if (state.scenarioData) {
                state.scenarioData.otherTransactions = [action.payload, ...state.scenarioData.otherTransactions];
            }
        }
    },
    // Update Transaction
    editOtherTransaction: (state, action) => {
        if (state.scenarioData) {
            const index = state.scenarioData?.otherTransactions.findIndex(ot => ot.id === action.payload.id && ot.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;

                if (state.scenarioData.otherTransactions[index].status !== SaveStatus.NEW) {
                    const existing = state.scenarioData.otherTransactions[index];
                    if (!checkDateSame(existing.date, action.payload.date)) changed = true;
                    changed = (checkObjectChanged(existing, action.payload, ['transactionType', 'amount', 'notes', 'capital', 'cash', 'fund']) ? true : changed);
                    if (changed) {
                        action.payload.previous = (!existing.previous) ? existing : existing.previous;
                        action.payload.status = SaveStatus.EDITED;
                        state.scenarioData.otherTransactions[index] = action.payload;
                    }
                } else {
                    state.scenarioData.otherTransactions[index] = action.payload;
                }
            }
        }
    },
    // Remove Transaction
    removeOtherTransaction: (state, action: PayloadAction<number>) => {
        if (state.scenarioData) {
            const index = state.scenarioData?.otherTransactions.findIndex(ot => ot.id === action.payload && ot.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.scenarioData.otherTransactions[index].status !== SaveStatus.NEW) {
                    state.scenarioData.otherTransactions[index].status = SaveStatus.REMOVED;
                } else {
                    const transactions = state.scenarioData.otherTransactions;
                    transactions.splice(index, 1);
                    state.scenarioData.otherTransactions = transactions;
                }
            }
        }
    }
}

export default otherTransactionReducers;