import { memo } from 'react';
// MUI
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import { Create as CreateIcon } from '@mui/icons-material';

// ----------------------------------------------------------------------
// Props

type CustomToolbarProps = {
    title?: string,
    create?: () => void // Function handling create button
}

// ----------------------------------------------------------------------

export default memo(function CustomToolbar(props: CustomToolbarProps) {
    const {
        title,
        create
    } = props;

    return (
        <Toolbar
            variant='dense'
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: 'secondary.main',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                maxHeight: '48px'
            }}
        >
            <AppBar
                color='secondary'
                position='absolute'
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 2
                }}
            >
                <Typography variant='h6'>
                    {title}
                </Typography>
                {create &&
                    <Button
                        onClick={create}
                        sx={{ pl: 2 }}
                        color='inherit'
                        startIcon={<CreateIcon />}
                    >
                        Create New
                    </Button>
                }
            </AppBar>
        </Toolbar>
    )
})