import { API, Auth } from 'aws-amplify';

// Prepares API Header
async function getAPIParams() {
    return {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'x-api-key': process.env.REACT_APP_AWS_API_KEY
        },
    }
}

// API GET REQUEST
export async function apiGetRequest(query: string): Promise<any> {
    return API
        .get('MCPAPI', `/${query}`, await getAPIParams())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(error)
            let message;

            message = determineError(error)

            throw Error(message);
        })
}

/**
 * Synchronous get form multiple api queries
 * Function will receive an objet of query strings and when complete will return in same format
 * @param queries - takes an object of queries with the api call in string
 */
export async function multipleAsyncGetRequest(queries: { [x: string]: string }) {
    const results: { [x: string]: any } = {};

    const queryPromises = Object.entries(queries).map(async ([key, value]) => results[key] = await apiGetRequest(value));

    await Promise.all(queryPromises)

    return results
}


// API PUT REQUEST
export async function apiPutRequest(query: string, body: any): Promise<any> {
    const params = await getAPIParams();

    return API
        .put('MCPAPI', `/${query}`, {body, ...params})
        .then(response => {
            return response.body;
        })
        .catch(error => {
            let message;

            message = determineError(error)

            throw Error(message);
        })
}

// API PATCH REQUEST
export async function apiPatchRequest(query: string, body: any): Promise<any> {
    const params = await getAPIParams();

    return API
        .patch('MCPAPI', `/${query}`, {body, ...params})
        .then(response => {
            return response.body;
        })
        .catch(error => {
            let message;

            message = determineError(error)

            throw Error(message);
        })
}

/**
 * Synchronous get form multiple api queries
 * Function will receive an objet of query strings and when complete will return in same format
 * @param queries - takes an object of queries with the api call in string
 */
export async function multipleAsyncPostRequest(queries: { [x: string]: { query: string, body: any } }) {
    const results: { [x: string]: any } = {};

    const queryPromises = Object.entries(queries).map(async ([key, request]) => results[key] = await apiPostRequest(request.query, request.body));

    await Promise.all(queryPromises)

    return results
}

//API POST REQUEST
export async function apiPostRequest(query: string, body: any): Promise<any> {
    const params = await getAPIParams();

    return API
        .post('MCPAPI', `/${query}`, {body, ...params})
        .then(response => {
            return response;
        })
        .catch(error => {
            let message;

            message = determineError(error)

            throw Error(message);
        })
}

// API PATCH REQUEST
export async function apiDeleteRequest(query: string, body: any): Promise<any> {
    const params = await getAPIParams();

    return API
        .del('MCPAPI', `/${query}`, {body, ...params})
        .then(response => {
            return response.body;
        })
        .catch(error => {
            let message;

            message = determineError(error)

            throw Error(message);
        })
}

const determineError = (error: any) => {
    let message;

    console.log(error.response)
    if (error.response) {
        switch (error.response.status) {
            case 400:
                message = error.response.data || 'Bad Request.'
                break;

            case 401:
                message = error.response.data || 'Unauthorized Access.'
                break;

            case 403:
                message = error.response.data || 'Forbidden. Restricted access.'
                break;

            case 404:
                message = error.response.data || 'API Resource not found.'
                break;

            default:
                message = error.response.data
                break;
        }
    } else {
        message = error.message;
    }

    return message
}