import {createAsyncThunk, nanoid} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {addNotification} from "../notifications/notificationSlice";
import {apiDeleteRequest, apiGetRequest, apiPostRequest, apiPutRequest, multipleAsyncGetRequest} from "../apiUtils";
import {
    checkDateBefore,
    checkDateSame,
    checkDateSameOrBefore,
    daysBetweenDates,
    formatDate
} from "../../utils/DateUtils";
import {SaveStatus} from "../../types/capitalBudgetEnums";
import {
    AccrualOverride,
    Adjustment,
    AdjustmentObject,
    Assumption,
    AxcessLoanCompare,
    CallProtected,
    Curve,
    RulesSet,
    ScheduledAdjustment,
    sCurveOverride,
    ThirdPartyData,
    Valuation,
    ValuationModelSet,
    ValuationObject,
    Version,
    VMFund
} from "../../types/valuationModelTypes";
import {
    convertAdjustmentsToIndexedArray,
    convertValuationToIndexedArray,
    extract3MBbsw,
    markValuationFunds
} from "../../utils/valuationUtils";
import {calculateValMod, determineScurveFairValueBps, recallValMod} from "../../utils/ValuationModelCalcs";
import {ValuationModelState} from "./valuationModelSlice";
import _ from "lodash";

/**
 * Thunk functions for Versions
 */

// Initialise Valuation model data.
export const retrieveValuationModel = createAsyncThunk('valuationModel/retrieveValuationModel', async (_, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;

        const version = state.version.version;

        if (version) {
            const valuationDate = formatDate(version.valuationDate, 'yyyy-MM-dd');
            const previousDate = formatDate(version.previousDate, 'yyyy-MM-dd')

            // Retrieve Third Party Data
            let {
                axcess,
                curves,
                bbsw,
                fxRates
            } = await multipleAsyncGetRequest({
                axcess: `external-data/axcess/compare?previousDate=${previousDate}&date=${valuationDate}`,
                curves: `external-data/scurve/${version.curveId}`,
                bbsw: `external-data/bbsw/${version.bbswId}`,
                fxRates: `external-data/fx/${version.fxId}`
            })

            const thirdPartyData: ThirdPartyData = {
                axcess: {
                    dateOfData: axcess.date,
                    comparisonDate: axcess.previousDate,
                    portfolio: axcess.portfolio
                },
                curves,
                bbsw: extract3MBbsw(bbsw),
                fxRates
            };

            let {
                rules,
                assumptions,
                funds
            } = await multipleAsyncGetRequest({
                rules: `valuation-model/rules?date=${valuationDate}`,
                assumptions: `valuation-model/assumptions?previousDate=${previousDate}&valuationDate=${valuationDate}`,
                funds: `valuation-model/funds?date=${valuationDate}`
            });

            markValuationFunds(axcess.portfolio, funds, assumptions)

            return {
                thirdPartyData,
                valuationModelData: {
                    ...await getValuationsData(version, rules, assumptions, funds, axcess.portfolio, curves.curves, bbsw.rates)
                }
            };
        } else {
            thunkAPI.dispatch(addNotification('No Valuation data could be retrieved.', 'warning'))
            return thunkAPI.rejectWithValue('No Valuation data could be retrieved.');
        }
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred retrieving Valuation Model Data';
        }
        console.log(error)
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})

// Retrieves Valuations data
async function getValuationsData(version: Version, rules: RulesSet, assumptions: Array<Assumption>, funds: Array<VMFund>, portfolio: Array<AxcessLoanCompare>, scurves: Array<Curve>, bbsw: number): Promise<ValuationModelSet> {
    // Get valuation data
    if (version.status === SaveStatus.NEW) {

        const scheduledAdjustments = generateScheduledAdjustments(version.previousDate, version.valuationDate, rules.scheduledAdjustments)

        const {
            valuations,
            adjustments
        } = await initialiseNewValuationData(version, scheduledAdjustments, rules, assumptions, portfolio, scurves, bbsw)

        return {
            valuations,
            adjustments,
            callProtected: rules.callProtected,
            sCurveOverride: rules.curveOverrides,
            accruedOverrides: rules.accrualOverrides,
            manualAccrual: rules.scheduledAdjustments,
            assumptions,
            funds
        }
    } else {
        const valuations = convertValuationToIndexedArray(await apiGetRequest(`valuation-model/versions/${version.id}/valuations`));
        const adjustments = convertAdjustmentsToIndexedArray(await apiGetRequest(`valuation-model/versions/${version.id}/adjustments`));

        // Check valuations
        runCalcForExistingValuations(version, valuations, adjustments, rules, assumptions, portfolio, scurves, bbsw);

        return {
            valuations,
            adjustments,
            callProtected: rules.callProtected,
            sCurveOverride: rules.curveOverrides,
            accruedOverrides: rules.accrualOverrides,
            manualAccrual: rules.scheduledAdjustments,
            assumptions,
            funds
        }
    }
}

function generateScheduledAdjustments(_previousDate: number | Date, valuationDate: number | Date, scheduledAdjustments: Array<ScheduledAdjustment>) {
    let adjustments: Array<Adjustment> = [];
    const previousDate = new Date(_previousDate)

    scheduledAdjustments.forEach(sa => {
        // If adjust begins before valuation date and has an end date
        if (checkDateSameOrBefore(new Date(sa.startDate), valuationDate) && sa.endDate) {
            let days = daysBetweenDates(previousDate, valuationDate);

            // If adjustment ends before valuation date remove additional days
            if (checkDateBefore(new Date(sa.endDate), valuationDate)) {
                days = days - daysBetweenDates(new Date(sa.endDate), valuationDate);
            }
            // If adjustment starts before valuation date remove additional days
            if (checkDateBefore(previousDate, new Date(sa.startDate))) {
                days = days - daysBetweenDates(previousDate, new Date(sa.startDate));
            }
            adjustments.push({
                id: nanoid(),
                trancheId: sa.trancheId,
                valuationDate: valuationDate,
                fund: sa.fund,
                transactionType: sa.transactionType,
                amount: sa.amount * days,
                isManual: true,
                comment: "*From Scheduled* \n" + sa.comment,
                status: SaveStatus.NEW
            })
        } else if (checkDateSame(new Date(sa.startDate), valuationDate)) {
            adjustments.push({
                id: nanoid(),
                trancheId: sa.trancheId,
                valuationDate: valuationDate,
                fund: sa.fund,
                transactionType: sa.transactionType,
                amount: sa.amount,
                isManual: true,
                comment: "*From Scheduled* \n" + sa.comment,
                status: SaveStatus.NEW
            })
        }
    })
    return convertAdjustmentsToIndexedArray(adjustments)
}


// Initialises New Valuation Data
async function initialiseNewValuationData(version: Version, scheduledAdjustments: AdjustmentObject, rules: RulesSet, assumptions: Array<Assumption>, portfolio: Array<AxcessLoanCompare>, scurves: Array<Curve>, bbsw: number) {
    const historicalValuations: Array<Valuation> = await apiGetRequest(`valuation-model/versions/${version.previousVersionId}/valuations`);

    const generalValuation = {
        appliedPl: 0,
        carry: 0,
        fairValue: 0,
        cappedFairValue: 0,
        yieldToMaturity: 0,
        dailyLossGain: 0,
        adjustedOpeningBalance: 0,
        netRepaymentBasedPL: 0,
        status: SaveStatus.NEW
    }

    const valuations: ValuationObject = {};
    const adjustments: AdjustmentObject = {};

    portfolio.forEach(p => {

        const portfolioValuations: { [x: string]: Valuation } = {}
        const portfolioAdjustments: { [x: string]: Array<Adjustment> } = {}

        // Calculate Fair Value for Loan
        const fairValueBps = determineScurveFairValueBps(scurves, rules.curveOverrides, p);

        p.valuationFunds.forEach(fund => {
            const previousFund = p.funds_before.find(f => f.fund === fund);
            const currentFund = p.funds.find(f => f.fund === fund);
            const previousValuation = historicalValuations.find(v => v.trancheId === p.tranche_id && v.fund === fund);

            let valuation = {
                ...generalValuation,

                id: nanoid(),
                trancheId: p.tranche_id,
                fund: fund,
                previousDate: version.previousDate,
                valuationDate: version.valuationDate,
                startDate: p.start_date,
                maturity: p.maturity,
                previousCommitment: previousFund?.commitment || 0,
                commitment: currentFund?.commitment || 0,
                openingDiscountValuationDate: 0,
                previousAccruals: 0,
                postRepaymentAdjustedDiscount: 0,
                openingDiscount: 0,
                closingDiscount: 0
            }

            if (previousValuation) {
                valuation = {
                    ...valuation,
                    openingDiscount: previousValuation.closingDiscount,
                    closingDiscount: previousValuation.closingDiscount,
                }
            }

            // Calculate valuation and generate auto adjustments
            const result = calculateValMod(p, valuation, scheduledAdjustments?.[p.tranche_id]?.[fund] || [], bbsw, fairValueBps || 0, rules, true)

            portfolioValuations[fund] = result.valuation;
            portfolioAdjustments[fund] = result.adjustments;

        })

        valuations[p.tranche_id] = portfolioValuations;
        adjustments[p.tranche_id] = portfolioAdjustments
    })

    return {
        valuations,
        adjustments
    }
}

function runCalcForExistingValuations(version: Version, valuations: ValuationObject, adjustments: AdjustmentObject, rules: RulesSet, assumptions: Array<Assumption>, portfolio: Array<AxcessLoanCompare>, scurves: Array<Curve>, bbsw: number) {
    portfolio.forEach(p => {

        // Calculate Fair Value for Loan
        const fairValueBps = determineScurveFairValueBps(scurves, rules.curveOverrides, p);

        p.valuationFunds.forEach(fund => {
            const previousFund = p.funds_before.find(f => f.fund === fund);
            const currentFund = p.funds.find(f => f.fund === fund);

            const valuation = valuations[p.tranche_id][fund]
            valuation.previousDate = version.previousDate;
            valuation.valuationDate = version.valuationDate;
            valuation.startDate = p.start_date;
            valuation.maturity = p.maturity;
            valuation.previousCommitment = previousFund?.commitment || 0;
            valuation.commitment = currentFund?.commitment || 0;

            // run valuation function
            if (version.published) {
                valuations[p.tranche_id][fund] = recallValMod(p, valuation, adjustments?.[p.tranche_id]?.[fund] || [], bbsw, fairValueBps || 0)
            } else {
                const result = calculateValMod(p, valuation, adjustments?.[p.tranche_id]?.[fund] || [], bbsw, fairValueBps || 0, rules)
                valuations[p.tranche_id][fund] = result.valuation;
                if (!adjustments[p.tranche_id]) adjustments[p.tranche_id] = {}
                if (!adjustments[p.tranche_id][fund]) adjustments[p.tranche_id][fund] = []
                adjustments[p.tranche_id][fund] = result.adjustments;
            }
        })
    })
}

/**
 * Common function to recalculation valuation model for tranche/fund in state
 * @param state
 * @param trancheId
 * @param fund
 */
export function runValuationReCalc(state: ValuationModelState, trancheId: number, fund: string) {
    const tranche = state.thirdPartyData.axcess?.portfolio.find(t => t.tranche_id === trancheId) || null;
    const bbsw = state.thirdPartyData.bbsw?.rate || null
    const scurves = state.thirdPartyData.curves?.curves || null
    const valuation = state.valuationModelData.valuations[trancheId][fund];
    const adjustments = _.cloneDeep(state.valuationModelData.adjustments[trancheId][fund].filter(a => a.status !== SaveStatus.REMOVED));

    if (!tranche || !bbsw || !scurves || !valuation || !adjustments) return state;

    const rules: RulesSet = {
        accrualOverrides: state.valuationModelData.accruedOverrides.filter(a => a.status !== SaveStatus.REMOVED),
        callProtected: state.valuationModelData.callProtected.filter(a => a.status !== SaveStatus.REMOVED),
        curveOverrides: state.valuationModelData.sCurveOverride.filter(a => a.status !== SaveStatus.REMOVED),
        scheduledAdjustments: state.valuationModelData.manualAccrual.filter(a => a.status !== SaveStatus.REMOVED)

    }
    const overrides = state.valuationModelData.sCurveOverride;

    const fairValueBps = determineScurveFairValueBps(scurves, overrides, tranche) || 0;

    const result = calculateValMod(tranche, valuation, adjustments, bbsw, fairValueBps, rules);
    state.valuationModelData.valuations[trancheId][fund] = result.valuation;
    state.valuationModelData.adjustments[trancheId][fund] = [...result.adjustments, ...state.valuationModelData.adjustments[trancheId][fund].filter(a => a.status === SaveStatus.REMOVED)];
}

export const saveScheduledAdjustments = createAsyncThunk('valuationModel/saveScheduledAdjustments', async (_, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;

        const adjustments = state.valuationModel.valuationModelData.manualAccrual || null;

        if (adjustments.length > 0) {
            const changes: {[x: string]: {curveOverrides: Array<sCurveOverride>, accrualOverrides: Array<AccrualOverride>, callProtected: Array<CallProtected>, scheduledAdjustments: Array<ScheduledAdjustment>}, } = {
                create: {
                    curveOverrides: [],
                    accrualOverrides: [],
                    callProtected: [],
                    scheduledAdjustments: []
                },
                update: {
                    curveOverrides: [],
                    accrualOverrides: [],
                    callProtected: [],
                    scheduledAdjustments: []
                },
                delete: {
                    curveOverrides: [],
                    accrualOverrides: [],
                    callProtected: [],
                    scheduledAdjustments: []
                },
            }

            let create = false;
            let update = false;
            let remove = false;

            adjustments.forEach(sa => {
                const adjustment = {
                    ...sa,
                    startDate: formatDate(sa.startDate, 'yyyy-MM-dd'),
                    endDate: sa.endDate ? formatDate(sa.endDate, 'yyyy-MM-dd') : sa.endDate,
                } as unknown as ScheduledAdjustment
                switch (adjustment.status) {
                    case SaveStatus.NEW:
                        create = true;
                        changes.create.scheduledAdjustments.push(adjustment);
                        break;
                    case SaveStatus.EDITED:
                        update = true;
                        changes.update.scheduledAdjustments.push(adjustment);
                        break;
                    case SaveStatus.REMOVED:
                        remove = true;
                        changes.delete.scheduledAdjustments.push(adjustment);
                        break;
                    default:
                        break;
                }
            })

            if (create) {
                await apiPostRequest('valuation-model/rules', {rules: changes.create})
            }
            if (update) {
                await apiPutRequest('valuation-model/rules', {rules: changes.update})
            }
            if (remove) {
                await apiDeleteRequest('valuation-model/rules', {rules: changes.delete})
            }
        }

        const rules = await apiGetRequest('valuation-model/rules');

        return rules.scheduledAdjustments as Array<ScheduledAdjustment>;

    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred saving scheduled Adjustments';
        }
        console.log(error)
        thunkAPI.dispatch(addNotification(message, 'error'));
        return thunkAPI.rejectWithValue(message);
    }
})