import {useState} from 'react';
import {formatDate} from "../../utils/DateUtils";
// @mui
import {Container, Grid, Typography} from '@mui/material';
// components
import {
    AlertDialog,
    SideDrawer,
    HorizontalTable,
    HorizontalTableRow,
    Page,
    VerticalTable,
    VerticalTableColumn
} from '../../components';
import {SelectionBar} from './SelectionBar';
// Sections
import {NewTransactionForm} from '../../sections/dashboard/capitalBudget/capital';
// Store
import {useAppDispatch, useAppSelector} from '../../store/store';
import {capitalTableSelector} from "../../store/capitalBudget/selectors";
import {
    addCapitalTransaction,
    editCapitalTransaction,
    removeCapitalTransaction
} from "../../store/capitalBudget/capitalBudgetSlice";
import {fCurrency} from "../../utils/formatNumber";
import {retrieveCapitalTransactions} from "../../store/capitalBudget/selectors/capitalSelectors";
import {CellType} from "../../types/InputTypes";

const _ = require('lodash');

// ----------------------------------------------------------------------
// Column definitions
let CapitalColumns: Array<VerticalTableColumn> = [
    {
        id: 'name',
        label: 'Name',
        sortable: true,
        align: 'left',
    },
    {
        id: 'investorType',
        label: 'Investor Type',
        sortable: true,
        align: 'left',
    },
    {
        id: 'transactionType',
        label: 'Transaction Type',
        sortable: true,
        align: 'left',
    },
    {
        id: 'date',
        label: 'Date',
        sortable: true,
        formatter: (value: number) => formatDate(new Date(value), 'dd-MM-yyyy'),
        align: 'left',
    },
    {
        id: 'fund',
        label: 'Fund',
        sortable: true,
        align: 'left',
    },
    {
        id: 'amount',
        label: 'Amount',
        sortable: true,
        align: 'right',
        formatter: (value: number) => fCurrency(value),
        total: true,
        type: CellType.CURRENCY
    },
    {
        id: 'category',
        label: 'Category',
        sortable: true,
        align: 'left',
    }
];
// ----------------------------------------------------------------------


// Capital Budget Capital App Page
export default function Capital() {
    const dispatch = useAppDispatch();

    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const capitalTransactions = useAppSelector(state => retrieveCapitalTransactions(state));
    const scenarioId = useAppSelector(state => state.scenarios.scenario?.id);

    const capitalForecast = useAppSelector(state => capitalTableSelector(state));
    // CREATE
    const [form, setFormOpen] = useState<any | null>(null);

    const [open, setOpen] = useState<any | null>(null)

    const [row, selectRow] = useState<null | HorizontalTableRow>(null)

    // Sends an editable version on capital Entity to update form
    const handleEditTransaction = (row: number) => {
        const tempTransaction = _.cloneDeep(capitalTransactions.find(e => e.id === row));

        tempTransaction.name = {value: tempTransaction.name, label: tempTransaction.name}
        tempTransaction.fund = tempTransaction.fundId;
        tempTransaction.date = new Date(tempTransaction.date);

        setFormOpen(tempTransaction)
    }

    return (
        <>
            <Page title="Metrics - Capital Budget - Capital">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                                Capital
                            </Typography>
                            <SelectionBar/>
                        </Grid>
                        <Grid item sx={{width: '100%', height: 300}}>
                            <HorizontalTable
                                rows={capitalForecast.rows}
                                data={capitalForecast.columns}

                                selectedRow={row}
                                rowSelect={selectRow}
                            />
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                <VerticalTable
                                    title={'Capital Transactions'}
                                    loading={dataLoading}

                                    columns={CapitalColumns}
                                    data={capitalTransactions}

                                    defaultOrder={'desc'}
                                    defaultSort={'date'}
                                    secondarySort={'name'}
                                    secondaryOrder={'asc'}

                                    create={() => setFormOpen(true)}

                                    handleEdit={(rowId) => handleEditTransaction(rowId)}
                                    handleRemove={(rowId) => setOpen(rowId)}

                                    search
                                    totalRow
                                    highlightStatus
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            <SideDrawer
                title='Create Capital'
                open={(!!form)}
                onClose={() => setFormOpen(null)}
                size={'large'}
            >
                <NewTransactionForm
                    editValues={form}
                    onClose={() => setFormOpen(null)}
                    submitTransaction={(values) => {
                        if (scenarioId) {
                            values.scenarioId = scenarioId;
                            dispatch(addCapitalTransaction(values));
                            setFormOpen(null);
                        }
                    }}
                    editTransaction={(values) => {
                        dispatch(editCapitalTransaction(values));
                        setFormOpen(null);
                    }}
                />
            </SideDrawer>
            <AlertDialog
                title='Delete Capital'
                info="Are you sure you want to remove this?"
                open={!!open}
                handleClose={() => {
                    setOpen(null)
                }}
                handleConfirm={() => {
                    dispatch(removeCapitalTransaction(open))
                    setOpen(null)
                }}
            />
        </>
    )
}