import { scaleLinear } from 'd3'
import {SaveStatus} from "../types/capitalBudgetEnums";

export enum colors {
    midnightBlue = 'rgb(36, 25, 45)', // Midnight-Blue
    navyBlue = 'rgb(28, 44, 85)', // Navy-Blue
    midBlue = 'rgb(03, 85, 149)', // Mid-Blue
    lightBlue = 'rgb(00, 129, 174)', // Light-Blue
    aquaBlue = 'rgb(03, 181, 195)', // Aqua-Blue
}



// Metrics Colour Palette
const colorPalette = [
    colors.midnightBlue,
    colors.navyBlue,
    colors.midBlue,
    colors.lightBlue,
    colors.aquaBlue,
]

// Generates the colors for graphs
export function genColours(colorCount: number): Array<string> {
    switch (true) {

        // Returns Mid-Blue
        case colorCount === 1:
            return [colorPalette[2]];

        // Returns Midnight-Blue and Aqua-Blue
        case colorCount === 2:
            return [colorPalette[0], colorPalette[4]];

        // Returns Midnight-Blue, Mid-Blue and Aqua-Blue
        case colorCount === 3:
            return [colorPalette[0], colorPalette[2], colorPalette[4]];

        // Returns Midnight-Blue, Navy-Blue, Mid-Blue and Light-Blue
        case colorCount === 4:
            return [colorPalette[0], colorPalette[1], colorPalette[2], colorPalette[4]];

        // Returns default colour palette
        case colorCount === 5:
            return colorPalette;

        // Returns a scaled number of colours including Midnight-Blue and Aqua-Blue
        default:
            const colors = scaleLinear([1, colorCount - 2], ['rgb(36, 25, 45)', 'rgb(03, 181, 195)'])
            const genColors = [];

            genColors.push('rgb(36, 25, 45)');

            for (let i = 0; i < colorCount - 2; i++) {
                genColors.push(colors(i));
            }

            genColors.push('rgb(03, 181, 195)');

            return genColors;
    }
}

// Takes genColours and applies opacity (0.98) to core colour
export function genColorsWithBorder(colorCount: number): { borderColors: Array<string>, colors: Array<string> } {
    const borderColors = genColours(colorCount);
    const colors = [];

    for (let i = 0; i < colorCount; i++) {
        let color = borderColors[i]
        color = color.replace(/rgb/i, "rgba");
        color = color.replace(/\)/i, ',0.98)');
        colors.push(color)
    }

    return {
        borderColors,
        colors
    }
}

// Filters the Save status color for edited or new data
export function determineStatusColor(status: SaveStatus | undefined): string | undefined {
    switch (status) {
        case SaveStatus.NEW:
            return 'success';
        case SaveStatus.EDITED:
             return 'warning';
        case SaveStatus.REMOVED:
            return 'error';
        default:
            return;
    }
}