import {useState} from 'react';
// MUI
import {Grid, Container, Typography} from '@mui/material';
// components
import {HorizontalTable, HorizontalTableRow, HorizontalTableColumn, Page} from '../../components';
import {SelectionBar} from './SelectionBar';
// Sections
import {ComparisonHandler, WIPTransactions} from '../../sections/dashboard/capitalBudget/newDeals';
// Store
import {useAppSelector} from '../../store/store';
import {portfolioStatisticsSelector} from "../../store/capitalBudget/selectors";

// Capital Budget App Page
export default function NewDeals() {

    const loading = useAppSelector(state => state.capitalBudget.loading);
    const {data, rows} = useAppSelector(state => portfolioStatisticsSelector(state));

    const [row, selectRow] = useState<HorizontalTableRow | null>(null);
    const [column, selectCol] = useState<HorizontalTableColumn | null>(null);

    return (
        <Page title="Metrics - Capital Budget - New Deals">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                <Grid container spacing={2} direction='column' sx={{height: 1, flex: 1}}>
                    <Grid container spacing={1} direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            New Deals
                        </Typography>
                        <SelectionBar/>
                    </Grid>
                    <Grid item container sx={{width: '100%'}}>
                        <ComparisonHandler/>
                        <Grid item xs={12}>
                            <HorizontalTable
                                rows={rows}
                                data={(data) ? data : []}
                                loading={loading}

                                selectedRow={row}
                                selectedCol={column}
                                rowSelect={selectRow}
                                colSelect={selectCol}

                                selectableCell
                            />
                        </Grid>
                    </Grid>
                    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%'}}>
                        <WIPTransactions/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}