import {useState} from 'react';
// MUI
import {Grid, Container, Typography} from '@mui/material';
// components
import {HorizontalTable, Page, SideDrawer} from '../../components';
import {SelectionBar} from './SelectionBar';
// Store
import {useAppSelector} from '../../store/store'
// Data
import {sourcesUsesSelector} from "../../store/capitalBudget/selectors/sourcesUsesSelector";
import {DrillDownPanel} from "../../sections/dashboard/capitalBudget/sourcesUses";

// Capital Budget Summary App Page
export default function SourcesUses() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const {data, rows} = useAppSelector(state => sourcesUsesSelector(state));

    // DRILL DOWN INFORMATION
    const [column, setColumn] = useState<any | null>(null);

    return (
        <>
            <Page title="Metrics - Capital Budget - Sources Uses">
                <Container maxWidth={false} sx={{maxWidth: '100%'}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Sources and Uses
                        </Typography>
                        <SelectionBar/>
                    </Grid>
                    <Grid container sx={{height: 1, width: '100%'}}>
                        <Grid item xs={12} md={12} lg={12} sx={{bgColor: 'red', pb: 1}}>
                            <HorizontalTable
                                rows={rows}
                                data={(data) ? data : []}
                                loading={dataLoading}

                                selectedCol={column}

                                colSelect={(r) => setColumn(r)}

                                selectableCell
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            <SideDrawer title={(!!column) ? `Sources and Uses: ${column.label} - ${column.labelTwo}` : ''} open={(!!column)} onClose={() => setColumn(null)} size={'large'}>
                <DrillDownPanel
                    data={column}
                />
            </SideDrawer>
        </>
    )
}