import * as Yup from 'yup';
import YupPassword from 'yup-password';

import { Formik, FormikProps } from 'formik';
// material
import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
// component
import { FormInput } from '../../../components';
import { InputTypes } from '../../../types/InputTypes'
// ----------------------------------------------------------------------
// STATE MANAGEMENT
import {verifyUser} from '../../../store/user/userFunctions';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { Link, useSearchParams } from 'react-router-dom';
// ----------------------------------------------------------------------

YupPassword(Yup);

export default function ConfirmUserForm() {
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();

    const loading = useAppSelector(state => state.user.loading);

    const ResetSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        code: Yup.number().required('Reset Code is required'),
    });

    const code = searchParams.get('code')
    const email = searchParams.get('email')

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Confirm User
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Please confirm the following email is correct:</Typography>
            <Formik
                initialValues={{
                    email: email,
                    code: code,
                    password: '',
                    confirmPassword: ''
                }}
                validationSchema={ResetSchema}
                onSubmit={async () => {
                    if (!loading && code && email) {
                        dispatch(verifyUser({code: code, email: email}))
                    }
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit} id='login'>
                                <Grid container >
                                    <FormInput
                                        id='email'
                                        label='Email'
                                        fieldType={InputTypes.STRING}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                        disabled={true}
                                    />
                                    <FormInput
                                        id='code'
                                        label='Reset Code'
                                        fieldType={InputTypes.NUMBER}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                        disabled={true}
                                    />

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    {loading ?
                                        <Grid container justifyContent='center' direction='row'>
                                            <CircularProgress />
                                        </Grid>
                                        :
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            sx={{ mx: 1, my: 2 }}
                                        >
                                            Verify Email
                                        </Button>
                                    }
                                    <Grid container justifyContent='flex-start' direction='row'>
                                        <Link to="/login">
                                            <Typography sx={{ py: 2, mx: 1, color: 'primary.main' }} align='right'>
                                                &lt;- Return to Login.
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    );
}
