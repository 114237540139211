import _ from "lodash";
import {RootState} from "../../store";
import {fCurrency} from "../../../utils/formatNumber";
import {CellType} from "../../../types/InputTypes";
import {PeriodSummaryResults} from "../calculationUtils/summarisePeriod";
import {addArrayValues, addValues} from "../../../utils/mathUtil";
import {CapitalTransaction} from "../../../types/capitalBudgetTypes";
import {CapitalAction, InvestmentType, LoanTags, OtherTransactionTypes} from "../../../types/capitalBudgetEnums";
import {HorizontalTableRow} from "../../../components";
import {createSelector} from "@reduxjs/toolkit";


export const sourcesUsesRowsFirst: Array<HorizontalTableRow> = [
    {
        id: 'cashBalanceOpening',
        label: 'Cash Opening',
        headSX: {
            minWidth: 250,
            bgcolor: 'info.dark',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'primary.main',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        formatter: fCurrency, type: CellType.CURRENCY
    },
    {
        id: 'sourceHeader',
        label: 'Sources',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main'}
    },
    {id: 'subscriptions', label: 'Investor Inflows', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'expectedRepayments', label: 'Loan Repayments', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'expectedRepaymentsCash', label: 'Loan Repayments (CASH)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'earlyRepayment', label: 'Notified Early Repayments', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'earlyRepaymentCash',
        label: 'Notified Early Repayments (CASH)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {id: 'extension', label: 'Extended Loans', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'extensionCash', label: 'Extended Loans (CASH)', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'selldownsCash',
        label: 'Asset Sell Downs/Partial Repayments (CASH)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {id: 'transfersOutCash', label: 'Transfers Out (CASH)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'transfersInOffsetCash', label: 'Transfers In Offset  (CASH)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'debtDraw', label: 'Required Debt Drawn/Asset Transfer', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'adjustmentsPos',
        label: 'Other Adjustments',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {borderColor: 'primary.main'}
    },
    {
        id: 'useHeader',
        label: 'Uses',
        headSX: {minWidth: 150, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main'}
    },
    {id: 'firstDrawdown', label: 'New Loans (First Drawdown at FC)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'extensionOffset', label: 'IC Approved Extended Loans', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'extensionOffsetCash',
        label: 'IC Approved Extended Loans (CASH)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {id: 'earlyRepOffset', label: 'Early Repayments Offset', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'earlyRepOffsetCash', label: 'Early Repayments Offset (CASH)', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'selldownOffsetCash',
        label: 'Asset Sell Downs/Partial Repayments Offset (CASH)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {id: 'transfersInCash', label: 'Transfers In (CASH)', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'transfersOutOffsetCash',
        label: 'Transfers Out Offset  (CASH)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {
        id: 'redemptionOffset',
        label: 'Investor Redemptions (Incl. 1 week buffer)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {id: 'totalCTCDrawdown', label: 'CRE (CTC)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'periodCorpCTCDrawdownNew', label: 'Coporate (CTC)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'cashDistribution', label: 'Cash Distribution Net of DRP', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'revolverDrawdown',
        label: 'Confirmed drawdowns from Revolvers',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {
        id: 'adjustmentsNeg',
        label: 'Other Adjustments',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {borderColor: 'info.lighter'}
    },
    {
        id: 'total',
        label: 'Net Sources and Uses',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'cashBalanceClosing',
        label: 'Cash Closing (assuming no RCF, Capex draws)',
        headSX: {
            minWidth: 250,
            bgcolor: 'info.dark',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'primary.main',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.dark', fontWeight: 'bold'},
        formatter: fCurrency, type: CellType.CURRENCY
    },
    {
        id: 'debtFacilitiesHeader',
        label: 'Debt Facilities',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main', textAlign: 'center'}
    },
];

const sourcesUsesRowsSecond = [
    {
        id: 'newFacility',
        label: 'Increase Bank Facilities (In Pipeline)',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {borderColor: 'secondary.light'}
    },
    {
        id: 'totalAvaFacility',
        label: 'Total Available Facilities',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            minWidth: 250,
            bgcolor: 'info.dark',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'primary.main',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.dark', fontWeight: 'bold'},
    },
    {
        id: 'netLiquidityHeader',
        label: 'Net Liquidity',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main', textAlign: 'center'}
    },
    {id: 'revolversUndrawn', label: 'Revolvers', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'capexUndrawn', label: 'Capex', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'otherUndrawn',
        label: 'Other',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        sx: {borderColor: 'secondary.light'}
    },
    {
        id: 'totalLiquidity',
        label: 'Total',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            minWidth: 250,
            bgcolor: 'info.dark',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'primary.main',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.dark', fontWeight: 'bold'},
    },
    {
        id: 'netLiquidity',
        label: 'Net Liquidity',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
    },
];

const sourcesUsesFinanceUnusedRows = [
    {
        id: 'unusedFacilities',
        label: 'Fund Finance facilities - Unused limit',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {
        id: 'netPosition',
        label: 'Net Position',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            borderColor: 'primary.main',
            color: 'black',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'primary.main'},
            fontWeight: 'bold'
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
]

const sumColumns = [
    'subscriptions',
    'expectedRepayments',
    'expectedRepaymentsCash',
    'earlyRepayment',
    'earlyRepaymentCash',
    'extension',
    'extensionCash',
    'selldownsCash',
    'transfersOutCash',
    'transfersInOffsetCash',
    'debtDraw',
    'firstDrawdown',
    'extensionOffset',
    'extensionOffsetCash',
    'earlyRepOffset',
    'earlyRepOffsetCash',
    'selldownOffsetCash',
    'transfersInCash',
    'transfersOutOffsetCash',
    'redemptionOffset',
    'totalCTCDrawdown',
    'periodCorpCTCDrawdownNew',
    'cashDistribution',
    'revolverDrawdown',
    'adjustmentsPos',
    'adjustmentsNeg',
]

const sumColumnsCash = [
    'subscriptions',
    'expectedRepaymentsCash',
    'earlyRepaymentCash',
    'extensionCash',
    'selldownsCash',
    'transfersOutCash',
    'transfersInOffsetCash',
    'debtDraw',
    'firstDrawdown',
    'extensionOffsetCash',
    'earlyRepOffsetCash',
    'selldownOffsetCash',
    'transfersInCash',
    'transfersOutOffsetCash',
    'redemptionOffset',
    'totalCTCDrawdown',
    'periodCorpCTCDrawdownNew',
    'cashDistribution',
    'revolverDrawdown',
]

function trancheComparator(a: any, b: any) {
    if (a.tranche > b.tranche) return 1;
    if (a.tranche < b.tranche) return -1;
    else return (a.currency > b.currency) ? 1 : -1
}

// For each fund facility determine data and rows required.
function formatDebtFacility(facility: any) {
    const openingFacility: any = {};
    const facilityRows: Array<HorizontalTableRow> = [];

    if (facility.fund === 'DASLF') {
        openingFacility['DASLF Facility Limit'] = facility.facilityLimit;
        facilityRows.push({
            id: 'DASLF Facility Limit',
            label: 'DASLF Facility Limit',
            formatter: fCurrency,
            type: CellType.CURRENCY
        })
        facility.tranches.sort((a: any, b: any) => trancheComparator(a, b)).forEach((t: any) => {
            if (t.drawn > 0) {
                const rowString = `DASLF Facility ${t.tranche} - ${(t.loanType === 'Bank Guarantee') ? 'Bank guarantees' : `${t.currency} Drawn`}`
                openingFacility[rowString] = -t.drawn;
                facilityRows.push({id: rowString, label: rowString, formatter: fCurrency, type: CellType.CURRENCY})
            }
        })
        openingFacility['DASLF Undrawn Balance Available'] = addValues(facility.facilityLimit, -facility.drawn);
        facilityRows.push({
            id: 'DASLF Undrawn Balance Available',
            label: 'DASLF Undrawn Balance Available',
            formatter: fCurrency,
            type: CellType.CURRENCY,
            headSX: {
                bgcolor: 'info.lighter',
                color: 'black',
                fontWeight: 'bold',
                '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
            },
            sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
        })

    } else {
        const rowString = `${facility.fund} Available Facility (Limit: ${fCurrency(facility.facilityLimit / 1000000)}m)`
        openingFacility[rowString] = addValues(facility.facilityLimit, -facility.drawn);
        facilityRows.push({id: rowString, label: rowString, formatter: fCurrency, type: CellType.CURRENCY})
    }

    return {
        openingFacility,
        facilityRows,
    };
}

// Filter and group facilities by fund returning if any the data of the fund and the rows to add to table.
export const getFacilitiesByFund = (state: RootState) => {
    if (!state.capitalBudget.thirdPartyData) return {
        openingFacility: {},
        facilityRows: []
    };

    const fund = state.capitalBudget.misc.fund;

    const debtFacilities = _.cloneDeep(state.capitalBudget.thirdPartyData.axcess.debtFacilities);

    let facilities = [...debtFacilities.reduce((r: any, o: any) => {
        const fundRegex = /MCP (.*) Debt Facility/.exec(o.borrower_issuer)

        if (!fundRegex) return r;
        const key = fundRegex[1];

        let itemObject: any = {fund: key, facilityLimit: 0, drawn: 0, tranches: []};
        const item = r.get(key) || itemObject;
        const tranche = {
            tranche: o.tranche,
            loanType: o.loan_type,
            currency: o.local_currency,
            drawn: o.drawn_tranche_currency,
            facilityLimit: o.facility_limit,
        }
        item.tranches.push(tranche);

        return r.set(key, item);
    }, new Map()).values()]

    facilities.forEach(f => {
        const facilityTranches: Array<string> = [];

        f.tranches = [...f.tranches.reduce((r: any, o: any) => {
            f.drawn = addValues(f.drawn, o.drawn)
            if (!facilityTranches.includes(o.tranche)) {
                facilityTranches.push(o.tranche);
                f.facilityLimit = addValues(f.facilityLimit, o.facilityLimit);
            }
            const key = (o.loanType === 'Bank Guarantee' ? 'BankG' : (o.tranche + o.currency))

            const itemObject = {...o}

            let item = r.get(key)

            if (item) {
                item.drawn = addValues(item.drawn, o.drawn);
            } else {
                item = itemObject;
            }

            return r.set(key, item);
        }, new Map()).values()]

    });

    facilities = facilities.sort((a, b) => (a.fund > b.fund) ? 1 : -1);

    let openingFacility: any = {};
    let facilityRows: Array<HorizontalTableRow> = [];
    let facilitiesAvailable = 0;

    if (fund) {
        const facility = facilities.find(f => f.fund === fund.label);
        if (facility) {
            const formattedFacility = formatDebtFacility(facility);
            openingFacility = formattedFacility.openingFacility;
            facilityRows = formattedFacility.facilityRows;
            facilitiesAvailable = addValues(facility.facilityLimit, -facility.drawn);
        }
    } else {
        facilities.forEach(f => {
            const formattedFacility = formatDebtFacility(f);
            openingFacility = {
                ...openingFacility,
                ...formattedFacility.openingFacility
            };
            facilityRows.push(...formattedFacility.facilityRows);
            facilitiesAvailable = addArrayValues([facilitiesAvailable, f.facilityLimit, -f.drawn]);

        })
    }

    return {
        openingFacility,
        facilityRows,
        facilitiesAvailable
    }
}


export const sourcesUsesSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget,
    (state: RootState) => state.capitalBudget.scenarioData,
    (state: RootState) => state.capitalBudget.thirdPartyData,
    (state: RootState) => state.capitalBudget.misc,
    getFacilitiesByFund,
    (capitalBudget, scenarioData, thirdPartyData, misc, debtFacilities) => {
        let rows = [...sourcesUsesRowsFirst, ...sourcesUsesRowsSecond];
        const data: any[] = [];

        if (!capitalBudget || !scenarioData || !thirdPartyData) return {data, rows}

        const weeks = _.cloneDeep(capitalBudget.weeks);
        const months = _.cloneDeep(capitalBudget.months);

        const fund = misc.fund;

        let runningCashBalance = weeks[0].startingAvailableCash;

        // Calculate and format debt Facilities
        rows = [...sourcesUsesRowsFirst, ...debtFacilities.facilityRows, ...sourcesUsesRowsSecond, ...((!fund) ? sourcesUsesFinanceUnusedRows : [])];

        // Running Balances
        let totalPeriod: any = {
            label: 'Total',
            labelTwo: '(Weeks)',

            cashBalanceOpening: runningCashBalance,
            subscriptions: 0,
            expectedRepayments: 0,
            expectedRepaymentsCash: 0,
            earlyRepayment: 0,
            earlyRepaymentCash: 0,
            extension: 0,
            extensionCash: 0,
            selldownsCash: 0,
            transfersOutCash: 0,
            transfersInOffsetCash: 0,
            debtDraw: 0,
            firstDrawdown: 0,
            extensionOffset: 0,
            extensionOffsetCash: 0,
            earlyRepOffset: 0,
            earlyRepOffsetCash: 0,
            selldownOffsetCash: 0,
            transfersInCash: 0,
            transfersOutOffsetCash: 0,
            redemptionOffset: 0,
            totalCTCDrawdown: 0,
            periodCorpCTCDrawdownNew: 0,
            cashDistribution: 0,
            revolverDrawdown: 0,
            adjustmentsPos: 0,
            adjustmentsNeg: 0,
            newFacility: 0,
            totalAvaFacility: 0,
            revolversUndrawn: 0,
            capexUndrawn: 0,
            otherUndrawn: 0,
            totalLiquidity: 0,
            debtFacilitiesHeader: 'Closing',
            sx: {bgcolor: 'info.lighter', fontWeight: 'bold'},
            capitalTransactions: [],
            otherTransactions: [],
            loans: [],
            ...debtFacilities.openingFacility
        }

        weeks[0] = {...weeks[0], ...debtFacilities.openingFacility, facilitiesAvailable: debtFacilities.facilitiesAvailable}
        months[0] = {
            ...months[0], ...debtFacilities.openingFacility,
            facilitiesAvailable: debtFacilities.facilitiesAvailable
        }

        // handle weeks
        totalPeriod = handleSetOfPeriods(weeks, totalPeriod, runningCashBalance, data)

        totalPeriod.total = addArrayValues(sumColumnsCash.map(s => totalPeriod[s]));
        totalPeriod.cashBalanceClosing = addValues(totalPeriod.total, totalPeriod.cashBalanceOpening);
        totalPeriod.netLiquidity = addArrayValues([totalPeriod.cashBalanceClosing, totalPeriod.totalAvaFacility, totalPeriod.totalLuqidity]);
        data.push(totalPeriod);

        totalPeriod = {
            ...totalPeriod,
            subscriptions: 0,
            expectedRepayments: 0,
            expectedRepaymentsCash: 0,
            earlyRepayment: 0,
            earlyRepaymentCash: 0,
            extension: 0,
            extensionCash: 0,
            selldownsCash: 0,
            transfersOutCash: 0,
            transfersInOffsetCash: 0,
            debtDraw: 0,
            firstDrawdown: 0,
            extensionOffset: 0,
            extensionOffsetCash: 0,
            earlyRepOffset: 0,
            earlyRepOffsetCash: 0,
            selldownOffsetCash: 0,
            transfersInCash: 0,
            transfersOutOffsetCash: 0,
            redemptionOffset: 0,
            totalCTCDrawdown: 0,
            periodCorpCTCDrawdownNew: 0,
            cashDistribution: 0,
            revolverDrawdown: 0,
            adjustmentsPos: 0,
            adjustmentsNeg: 0,
            newFacility: 0,
            totalAvaFacility: 0,
            capitalTransactions: [],
            otherTransactions: [],
            loans: []
        }

        runningCashBalance = totalPeriod.cashBalanceOpening;

        totalPeriod = handleSetOfPeriods(months, totalPeriod, runningCashBalance, data);

        totalPeriod.total = addArrayValues(sumColumnsCash.map(s => totalPeriod[s]))
        totalPeriod.cashBalanceClosing = addValues(totalPeriod.total, totalPeriod.cashBalanceOpening)
        totalPeriod.netLiquidity = addArrayValues([totalPeriod.cashBalanceClosing, totalPeriod.totalAvaFacility, totalPeriod.totalLiquidity]);
        data.push(totalPeriod);

        months[0].sx = {borderLeft: 2};

        return {
            data,
            rows
        };
    }
)

const handleSetOfPeriods = (periods: Array<PeriodSummaryResults>, totalPeriod: any, runningCashBalance: number, data: any[]) => {
    let redemptionOffset = 0;
    let previousRedemptions: Array<CapitalTransaction> = [];
    let previousFundFacilities = 0;

    periods.forEach(period => {
        const tempRedemptions: Array<CapitalTransaction> = [];
        period = {
            ...period,
            cashBalanceOpening: runningCashBalance,
            adjustmentsPos: 0,
            adjustmentsNeg: 0,
            redemptionOffset: redemptionOffset,
            // offset 1 week for Redemptions
            capitalTransactions: period.capitalTransactions.filter((ct: CapitalTransaction) => {
                if (ct.transactionType === CapitalAction.REDEMPTION) {
                    tempRedemptions.push(ct);
                    return false;
                }
                return true;
            }),
            actualFacilityAva: totalPeriod.totalAvaFacility,
            totalAvaFacility: addValues(period.facilitiesAvailable || 0, totalPeriod.totalAvaFacility),
            revolversUndrawn: -period.revolverDrawdown,
            capexUndrawn: 0,
            otherUndrawn: 0,
            unusedFacility: 0,
            unusedFacilities: previousFundFacilities,
        }
        period.loans.forEach(l => {
            if (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE) {
                switch (l.trancheType) {
                    case 'Revolving':
                        period.revolversUndrawn = addValues(period.revolversUndrawn, -l.undrawn);
                        break;
                    case 'Capex':
                        period.capexUndrawn = addValues(period.capexUndrawn, -l.undrawn);
                        break;
                    default:
                        period.otherUndrawn = addValues(period.otherUndrawn, -l.undrawn);
                        break;
                }
            }
        })
        period.totalLiquidity = addArrayValues([period.revolversUndrawn, period.capexUndrawn, period.otherUndrawn])
        // separate +ve/-ve transactions
        period.otherTransactions.forEach(ot => {
            if (ot.transactionType === OtherTransactionTypes.ADJUSTMENT && ot.cash) {
                if (ot.amount > 0) {
                    period.adjustmentsPos = addValues(period.adjustmentsPos, ot.amount)
                } else {
                    period.adjustmentsNeg = addValues(period.adjustmentsNeg, ot.amount)
                }
            }
            if (ot.transactionType === OtherTransactionTypes.INCREASED_BANK_FACILITIES) {
                period.newFacility = addValues(period.newFacility, ot.amount);
                period.totalAvaFacility = addValues(period.totalAvaFacility, ot.amount);
                totalPeriod.newFacility = addValues(totalPeriod.newFacility, ot.amount)
            }
            if (ot.transactionType === OtherTransactionTypes.FUND_FACILITIES_UNDRAWN) {
                period.unusedFacilities = ot.amount;
            }
        })
        period.capitalTransactions.push(...previousRedemptions)
        totalPeriod = {
            ...totalPeriod,
            revolversUndrawn: period.revolversUndrawn,
            capexUndrawn: period.capexUndrawn,
            otherUndrawn: period.otherUndrawn,
            totalLiquidity: period.totalLiquidity,
            totalAvaFacility: period.totalAvaFacility
        }
        totalPeriod.capitalTransactions.push(...period.capitalTransactions);
        totalPeriod.otherTransactions.push(...period.otherTransactions);
        totalPeriod.loans.push(...period.loans);
        previousRedemptions = tempRedemptions;

        sumColumns.forEach((s: string) => {
            totalPeriod[s] = addValues(totalPeriod[s], period[s])
        })
        previousFundFacilities = period.unusedFacilities;
        period.total = addArrayValues(sumColumnsCash.map(s => period[s]));
        period.cashBalanceClosing = addValues(period.total, runningCashBalance);
        period.netLiquidity = addArrayValues([period.cashBalanceClosing, period.totalAvaFacility, period.totalLiquidity])
        period.netPosition = addValues(period.netLiquidity, -period.unusedFacilities)
        runningCashBalance = period.cashBalanceClosing;

        redemptionOffset = period.redemptions;
        data.push(period);
    })

    return totalPeriod;
}