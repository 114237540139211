import {useState} from "react";
import {FormikProps} from "formik";
// Local imports
import {InputTypes} from "../../../../types/InputTypes";
import {addValues} from "../../../../utils/mathUtil";
import {formatDate} from "../../../../utils/DateUtils";
//MUI
import {Box, Grid, IconButton} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";
// Components
import {
    AlertDialog,
    DataDisplay,
    EditableTable,
    EditableTableColumn,
    EditableTableRow,
    SideDrawer
} from '../../../../components';
// Section
import AmendmentForm from "./AmendmentForm";
// Store
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {retrieveAllFunds, transferSelector} from "../../../../store/capitalBudget/selectors/";
import {FundTransfers, MCPFund} from "../../../../types/capitalBudgetTypes";
import {fCurrency, fPercent} from "../../../../utils/formatNumber";
import {SaveStatus} from "../../../../types/capitalBudgetEnums";
import {removeTransfer, updateTransfer} from "../../../../store/capitalBudget/capitalBudgetSlice";

// Column Definitions for Editable Table Column
let transferColumns: EditableTableColumn[] = [
    {
        id: 'transferDate',
        label: 'Transfer Date',
        align: 'right',
        sx: {
            minWidth: '120px',
            align: 'right'
        },
        formatter: (value: string) => {
            if (value === null) return 'N/A'
            else return formatDate(new Date(value), 'dd-MM-yyyy')
        }
    },
    {
        id: 'borrower',
        label: 'Borrower',
        align: 'left',
        edit: {
            fieldType: InputTypes.STRING,
            disabled: true
        },
        sx: {
            width: '120px'
        }
    },
    {
        id: 'tranche_id',
        label: 'Tranche ID',
        align: 'right',
        edit: {
            fieldType: InputTypes.NUMBER,
            disabled: true
        },
        sx: {
            width: '80px'
        }
    },
    {
        id: 'fromFund',
        label: 'Allocation From',
        align: 'left',
    },
    {
        id: 'commitment' +
            '',
        label: 'Commitment',
        align: 'right',
        total: true,
        headSX: {
            borderRight: '1',
            borderColor: 'common.white',
            fixedDecimalScale: true
        },
        edit: {
            fieldType: InputTypes.CURRENCY,
            numFormatProps: {
                allowNegative: false,
                decimalScale: 2,
                fixedDecimalScale: true
            },
            disabled: true,
        },
        sx: {
            borderRight: 2,
            minWidth: '110px'
        },
        formatter: (value) => fCurrency(value)
    }
]

export default function AllocationAmendment() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.capitalBudget.loading);
    const funds = useAppSelector(state => retrieveAllFunds(state, true));
    const transfers = useAppSelector(state => transferSelector(state));

    const columns: EditableTableColumn[] = [
        ...transferColumns,
        ...funds.map((fund: MCPFund) => {
            return {
                id: `${fund.label}`,
                label: fund.label,
                align: 'right',
                edit: {
                    fieldType: InputTypes.CURRENCY,
                    numFormatProps: {
                        allowNegative: false,
                        decimalScale: 2,
                        fixedDecimalScale: true
                    },
                },
                sx: {
                    minWidth: '110px'
                },
                formatter: (value) => fCurrency(value)
            } as EditableTableColumn
        })
    ]

    const [openForm, setFormOpen] = useState<boolean>(false); // Opens transfer panel
    const [selected, setSelected] = useState<any>(null); // State of selected transfer to display info
    const [remove, setRemove] = useState<number | string | null>(null); // set for removal

    const handleSelectedRow = (row: EditableTableRow | null) => {
        if (row) {
            const data = transfers.find((t: any) => t.id === row.id)
            setSelected(data);
        } else {
            setSelected(null);
        }
    }

    const handleEditRow = (row: EditableTableRow) => {
        const update: FundTransfers = {
            id: row.id as string,
            axcessLoanId: row.axcessLoanId,
            trancheId: row.trancheId,
            fromFundId: row.fromFundId,
            fromFund: row.fromFund,
            transferDate: row.transferDate,
            originalAllocation: row.commitment, //CHANGE
            newAllocation: row[row.fromFund], //CHANGE
            drawnCommitmentPer: row.drawnCommitmentPer,
            transfers: [],
            status: (row.status === SaveStatus.NEW) ? SaveStatus.NEW : SaveStatus.EDITED
        }
        funds.forEach(fund => {
            if (fund.id !== update.fromFundId && row[fund.id] !== 0) {
                update.transfers.push({
                    amount: row[fund.label],
                    fundId: fund.id,
                    fund: fund.label
                })
            }
        })
        dispatch(updateTransfer(update))
    }

    return (
        <>
            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                <EditableTable
                    title={'Amended Allocations'}
                    columns={columns}
                    data={transfers}
                    handleRemove={setRemove}

                    rowSelect={handleSelectedRow}
                    selectedRow={(selected) ? selected.id : null}

                    defaultSort='transferDate'
                    defaultOrder='asc'

                    loading={loading}

                    search

                    create={() => setFormOpen(true)}

                    expand

                    handleRowEdit={handleEditRow}
                    formRowValueFunction={(values: any, props: FormikProps<any>, tableColumns?: Array<EditableTableColumn>): Array<EditableTableColumn> | undefined => {
                        // Calculate allocation and check for overflow
                        let calc = values.commitment;
                        funds.forEach(fund => {
                            if (fund.label !== values.fromFund) calc = addValues(calc, -values[fund.label], 10);
                        })

                        if (values[values.fromFund] !== calc && !props.errors[values.fromFund]) {
                            if (calc < 0) {
                                props.setFieldError(values.fromFund, 'Can not distribute more than available.');
                            } else {
                                if (values[values.fromFund] !== calc) {
                                    props.setFieldValue(values.fromFund, calc);
                                }
                            }
                        }
                        // Determine which column field to lock
                        if (tableColumns) {
                            tableColumns.forEach(c => {
                                if (c.id === values.fromFund && c.edit) {
                                    c.edit.disabled = true;
                                }
                            })
                            return tableColumns;
                        }
                    }}
                />
            </Grid>
            {selected &&
                <SideDrawer
                    title='Details'
                    open={!!selected}
                    onClose={() => setSelected(null)}
                >
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <IconButton size='small' onClick={() => setSelected(null)}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{p: 3, pt: 1}}>
                        <DataDisplay
                            dataValues={[
                                {label: 'Borrower', data: selected.borrower},
                                {label: 'Asset', data: selected.asset},
                                {label: 'Tranche', data: selected.tranche},
                                {label: 'Tranche Type', data: selected.tranche_type},
                                {label: 'Investment Type', data: selected.investment_type},
                                {label: 'State', data: selected.state},
                                {label: 'S&P Sector', data: selected.sp_sector},
                                {label: 'S&P Industry', data: selected.sp_industry},
                                {label: 'S&P Sub Industry', data: selected.sp_sub_industry},
                                {label: 'Seniority Rank', data: selected.seniority_rank},
                                {label: 'Security', data: (selected.security ? 'YES' : 'NO')},
                                {label: 'Base Currency', data: selected.base_currency},
                                {label: 'APRA Lending Category', data: selected.apra_lending_category},
                                {label: 'APRA Business Type', data: selected.apra_business_type},
                                {label: 'APRA Industry', data: selected.apra_industry},
                                {label: 'APRA Business Type', data: selected.apra_business_type},
                                {label: 'APRA Facility Type', data: selected.apra_facility_type},
                                {label: 'APRA Purpose', data: selected.apra_purpose},
                                {label: 'Pricing Type', data: selected.pricing_type},
                                {label: 'Interest Type', data: selected.interest_type},
                                {label: 'Rating', data: selected.rating_mcp},
                                {label: 'Start Date', data: selected.start_date ? formatDate(selected.start_date) : null},
                                {label: 'Maturity Date', data: selected.maturity ? formatDate(selected.maturity) : null},
                                {label: 'Remaining Tenor (years)', data: selected.remaining_tenor_yrs},
                                {label: 'Commitment', data: selected.commitment ? fCurrency(selected.commitment) : null},
                                {label: 'Drawn', data: selected.drawn ? fCurrency(selected.drawn) : null},
                                {label: 'Undrawn', data: selected.undrawn ? fCurrency(selected.undrawn) : null},
                                {label: 'Drawn (%)', data: selected.drawn_p ? fPercent(selected.drawn_p) : null},
                                {label: 'Drawn Margin', data: selected.drawn_margin ? fPercent(selected.drawn_margin) : null},
                                {label: 'Base Rate', data: selected.base_rate ? fPercent(selected.base_rate) : null},
                                {label: 'Facility Fee Rate', data: selected.facility_fee_rate_p ? fPercent(selected.facility_fee_rate_p) : null},
                                {label: 'Facility Fee Type', data: selected.facility_fee_type},
                                {label: 'Accrued Fees', data: selected.accrued_fees ? fCurrency(selected.accrued_fees) : null},
                                {label: 'Accrued Interest', data: selected.accrued_interest ? fCurrency(selected.accrued_interest) : null},
                                {label: 'Total Accruals', data: selected.total_accruals ? fCurrency(selected.total_accruals) : null},
                                {label: 'Total Drawn Rate', data: selected.total_drawn_rate ? fPercent(selected.total_drawn_rate) : null},
                                {label: 'Base Rate Floor', data: selected.base_rate_floor ? fPercent(selected.base_rate_floor) : null}
                            ]}
                        />
                    </Box>
                </SideDrawer>
            }
            <SideDrawer
                title='Create Transfer'
                open={openForm}
                onClose={() => setFormOpen(false)}
            >
                <AmendmentForm onClose={() => setFormOpen(false)}/>
            </SideDrawer>
            <AlertDialog
                title="Delete Transfer"
                info='Are you sure you want to delete this transfer?'
                open={!!remove}
                handleClose={() => setRemove(null)}
                handleConfirm={() => {
                    if (!!remove) dispatch(removeTransfer(remove))
                    setRemove(null);
                }}

            />
        </>
    )
}