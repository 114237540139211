import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { genColorsWithBorder } from '../../utils/colorGenUtils';
import {faker} from "@faker-js/faker";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieGraph = () => {

    let labels: Array<string> = [];
    let dataValues: Array<number> = [];

    const sets = faker.datatype.number({ min: 5, max: 12 })

    for (let i = 0; i < sets; i++) {
        labels.push(faker.commerce.department());
        dataValues.push(faker.datatype.number({ min: 100, max: 1000 }))
    }


    const chartColors = genColorsWithBorder(sets);

    const data = {
        labels,
        datasets: [
            {
                label: '# of Votes',
                data: dataValues,
                backgroundColor: chartColors.colors,
                borderColor: chartColors.borderColors
            }
        ],
        options: {
            maintainAspectRatio: false,
        }
    };

    return (
        <>
            <Pie data={data} height={250} />
        </>
    )
}

export default PieGraph;