import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { Link } from 'react-router-dom';
// material
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
// component
import { FormInput } from '../../../components';
import { InputTypes } from '../../../types/InputTypes'
// ----------------------------------------------------------------------
// STATE MANAGEMENT
import { loginUser } from '../../../store/user/userFunctions';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useEffect } from 'react';
import { resetChanged } from '../../../store/user/userSlice';
// ----------------------------------------------------------------------

export default function LoginForm() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.user.loading);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    useEffect(() => {
        dispatch(resetChanged())
    }, [dispatch])

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values: any) => {
                    dispatch(loginUser({ email: values.email, password: values.password }));
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                    } = props;
                    return (
                        <>
                            <form onSubmit={handleSubmit} id='login'>
                                <Grid container>
                                    <FormInput
                                        id='email'
                                        label='Email'
                                        fieldType={InputTypes.STRING}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                    />
                                    <FormInput
                                        id='password'
                                        label='Password'
                                        fieldType={InputTypes.PASSWORD}
                                        layout={{ xs: 12, md: 12, lg: 12 }}
                                    />
                                    {loading ?
                                        <Grid container justifyContent='center' direction='row'>
                                            <CircularProgress />
                                        </Grid>
                                        :
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            sx={{ mx: 1, my: 2 }}
                                        >
                                            Login
                                        </Button>
                                    }
                                    <Grid container justifyContent='flex-end' direction='row'>
                                        <Link to="/forgotPassword">
                                            <Typography sx={{ py: 2, mx: 1, color: 'primary.main' }} align='right'>
                                                Forgot Password?
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    );
}
