import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

// Standard Page wrap to include page titles and formatting
const Page = forwardRef(({ children, title = '', meta, ...other }: { children: any, title: string, meta?: any }, ref) => (
    <>
        <Helmet>
            <title>{`${title}`}</title>
            {meta}
        </Helmet>
        <Box
            ref={ref}
            {...other}
            sx={{
                padding: 0,
                overflow: 'none',
                maxWidth: '100%',
                height: '100%',
                overflowX: 'visible'
            }}
        >
            {children}
        </Box>
    </>
));

export default Page;
