export enum AdjustmentType {
    NEW_ASSET = 'NEW_ASSET',
    FEE_RECEIVED = 'FEE_RECEIVED',
    ASSET_PURCHASE = 'ASSET_PURCHASE',
    ASSET_SALE = 'ASSET_SALE',
    NET_REPAYMENT_PL = 'NET_REPAYMENT_PL',
    PL_ACCRUAL = 'PL_ACCRUAL',
    WRITE_DOWN = 'WRITE_DOWN',
    APPLIED_PL_OVERRIDE = 'APPLIED_PL_OVERRIDE'
}

export const AdjustmentTypeFields = [
    {label: 'Upfront Fee Received', value: AdjustmentType.NEW_ASSET},
    {label: 'Fee Received (Borrower)', value: AdjustmentType.FEE_RECEIVED},
    {label: 'Fee Received (Asset Transfer)', value: AdjustmentType.ASSET_PURCHASE},
    {label: 'Fee Paid (Asset Transfer)', value: AdjustmentType.ASSET_SALE},
    {label: 'Net Repayment Based Profit/Loss', value: AdjustmentType.NET_REPAYMENT_PL},
    {label: 'Profit/Loss Accrual', value: AdjustmentType.PL_ACCRUAL},
    {label: 'Asset Write Down', value: AdjustmentType.WRITE_DOWN},
    {label: 'Override Applied Profit/Loss', value: AdjustmentType.APPLIED_PL_OVERRIDE}
]

export enum AssumptionType {
    MATURED = 'MATURED',
    PAST_MATURITY = 'PAST_MATURITY',
    EARLY_OR_SOLD_EXT = 'EARLY_OR_SOLD_EXT',
    FUND_TRANSFER = 'FUND_TRANSFER',
    PARTIAL_REPAYMENT = 'PARTIAL_REPAYMENT',
    LOAN_EXTENSION = 'LOAN_EXTENSION',
    MATURITY_CHANGED = 'MATURITY_CHANGED',
    COMMITMENT_INCREASE = 'COMMITMENT_INCREASE'
}

export const AssumptionTypeFields = [
    {label: 'Maturity Reached', value: AssumptionType.MATURED},
    {label: 'Past Maturity', value: AssumptionType.PAST_MATURITY},
    {label: 'Repaid Early | External Sale', value: AssumptionType.EARLY_OR_SOLD_EXT},
    {label: 'Fund Transfer', value: AssumptionType.FUND_TRANSFER},
    {label: 'Partial Repayment', value: AssumptionType.PARTIAL_REPAYMENT},
    {label: 'Loan Extension', value: AssumptionType.LOAN_EXTENSION},
    {label: 'Maturity Changed', value: AssumptionType.MATURITY_CHANGED},
    {label: 'Commitment Increase', value: AssumptionType.COMMITMENT_INCREASE},
]

export enum CurveType {
    CURRENT = 'CURRENT',
    LEV_BANK = 'LEV_BANK',
    LEV_INSTITUTIONAL = 'LEV_INSTITUTIONAL',
    LEV_UNIT_TRANCHE = 'LEV_UNIT_TRANCHE',
    REAL_ESTATE = 'REAL_ESTATE',
    PROJ_FIN = 'PROJ_FIN',
    CORPORATE = 'CORPORATE'
}

export const CurveTypeFields = [
    {label: "Current", value: CurveType.CURRENT},
    {label: "Leveraged Bank", value: CurveType.LEV_BANK},
    {label: "Leveraged Institutional", value: CurveType.LEV_INSTITUTIONAL},
    {label: "Leveraged Unit Tranche", value: CurveType.LEV_UNIT_TRANCHE},
    {label: "Real Estate", value: CurveType.REAL_ESTATE},
    {label: "Project Finance", value: CurveType.REAL_ESTATE},
    {label: "Corporate", value: CurveType.CORPORATE}
]