import {ChangeEvent, memo, ReactNode} from 'react';
// MUI
import {
    AppBar,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import {Clear as ClearIcon, Create as CreateIcon, Search as SearchIcon} from '@mui/icons-material';
import {GridToolbarColumnsButton, GridToolbarExport, GridToolbarFilterButton} from '@mui/x-data-grid';

type CustomToolbarProps = {
    title: string,
    search: boolean, // allow search field
    showFilter: boolean, // show filter options
    showColumns: boolean, // show option for selecting columns to show
    showExport: boolean, // show export function
    value: string // Search string value
    onChange: (e: ChangeEvent<HTMLInputElement>) => void, // Handles search string value change
    clearSearch: () => void, // Function clearing search field
    customFilter: { label: string, active: boolean, setFilter: (e: ChangeEvent<HTMLInputElement>) => void }, // Add Custom filter option
    customFilterComponent?: ReactNode,
    create: () => void // Function handling create button
}

/**
 * Custom Toolbar for Data Grid
 */
export default memo(function CustomToolbar(props: CustomToolbarProps) {
    const {
        title,
        search = true,
        showFilter,
        showColumns,
        showExport,
        value,
        onChange,
        clearSearch,
        customFilter,
        customFilterComponent,
        create
    } = props;


    return (
        <Toolbar
            variant='dense'
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                // height: (title !== '') ? 64 : 32,
                bgcolor: 'primary.main',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                padding: 1
            }}
        >
            <AppBar
                color='primary'
                position='absolute'
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // height: (title !== '') ? 64 : 32,
                    padding: 1,
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px'
                }}
            >
                <Grid
                    sx={{
                        spacing: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        color: 'white',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant='h6'>
                        {title}
                    </Typography>
                    {create &&
                        <Button
                            onClick={create}
                            sx={{pl: 2}}
                            color='inherit'
                            startIcon={<CreateIcon/>}
                        >
                            Create New
                        </Button>
                    }
                </Grid>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {customFilterComponent}
                    {customFilter &&
                        <FormControlLabel

                            control={
                                <Checkbox
                                    checked={customFilter.active}
                                    onChange={customFilter.setFilter}
                                    name={customFilter.label}
                                    color='info'
                                />
                            }
                            label={customFilter.label}
                        />
                    }
                    {search &&
                        <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                            <TextField
                                variant="standard"
                                value={value}
                                onChange={onChange}
                                placeholder="Search…"
                                InputProps={{
                                    startAdornment: <SearchIcon fontSize="small"/>,
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{visibility: value ? 'visible' : 'hidden'}}
                                            onClick={clearSearch}
                                        >
                                            <ClearIcon fontSize="small"/>
                                        </IconButton>
                                    ),
                                }}
                                sx={{
                                    bgcolor: 'common.white',
                                    color: 'common.white',
                                    pl: 1,
                                    pr: 1,
                                    pb: 0.5,
                                    borderRadius: '5px'
                                }}
                            />
                        </div>
                    }
                    {showColumns && <GridToolbarColumnsButton sx={{color: 'common.white'}}/>}
                    {showFilter && <GridToolbarFilterButton sx={{color: 'common.white'}}/>}
                    {showExport && <GridToolbarExport sx={{color: 'common.white'}}/>}
                </div>
            </AppBar>
        </Toolbar>
    )
})