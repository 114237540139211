import { useState } from 'react'
// MUI
import { Grid, Container, Typography } from '@mui/material';
// components
import { HorizontalTable, HorizontalTableColumn, HorizontalTableRow, Page } from '../../components';
import { SelectionBar } from './SelectionBar';
// Store
import { useAppSelector } from '../../store/store';
import { BarGraph, LineGraph, PieGraph } from '../../_mock/DummyGraphs';
import {capitalPositionSelector} from "../../store/capitalBudget/selectors";

// Capital Position App Page
export default function CapitalPosition() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const {data, rows} = useAppSelector(state => capitalPositionSelector(state));

    const [row, selectRow] = useState<HorizontalTableRow | null>(null);
    const [column, selectCol] = useState<HorizontalTableColumn | null>(null);

    return (
        <Page title="Metrics - Capital Budget - Scenarios">
            <Container maxWidth={false} sx={{ maxWidth: '100%', overflowX: 'visible' }}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Typography variant="h4" sx={{ mb: 3, color: 'primary.main' }}>
                        Capital Position
                    </Typography>
                    <SelectionBar />
                </Grid>
                <Grid container item sx={{ width: '100%' }}>
                    <Grid item xs={12} md={12} lg={12} >
                        <HorizontalTable
                            rows={rows}
                            data={(data) ? data : []}
                            loading={dataLoading}

                            selectedRow={row}
                            selectedCol={column}
                            rowSelect={selectRow}
                            colSelect={selectCol}

                            selectableCell
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        {column &&
                            <>
                                <Typography variant="h4" sx={{ mb: 2, color: 'secondary.main', pt: 4, pb: 0 }}>
                                    {column.label}
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <BarGraph />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LineGraph />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PieGraph />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}