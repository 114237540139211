import {
    accountBalanceDataSet,
    axcessDataSet,
    holdingDataSet,
    ncinoDataSet
} from "../../types/capitalBudgetTypes";
import {createSlice} from "@reduxjs/toolkit";
import {retrieveExternalDataLists} from "./externalDataFunction";

// External Data Slice used as holder and reducer for list of external data lists

export interface ExternalDataSlice {
    accountBalance: Array<accountBalanceDataSet>
    axcess: Array<axcessDataSet>,
    ncino: Array<ncinoDataSet>,
    holding: Array<holdingDataSet>,
    loading: boolean
}

const initialState: ExternalDataSlice = {
    accountBalance: [],
    axcess: [],
    ncino: [],
    holding: [],
    loading: false
}

// REDUCER
export const externalDataSlice = createSlice({
    name: 'external-data',
    initialState,
    reducers: {
        clearLists: (state) => {
            state.accountBalance = [];
            state.axcess = [];
            state.ncino = [];
            state.holding = [];
        }
    },
    extraReducers(builder) {
        builder
            // Retrieve External Data lists
            .addCase(retrieveExternalDataLists.pending, (state) => {
                state.loading = true;
                state.accountBalance = [];
                state.axcess = [];
                state.ncino = [];
                state.holding = [];
            })
            .addCase(retrieveExternalDataLists.fulfilled, (state, action) => {
                state.loading = false;
                state.accountBalance = action.payload.accountBalance;
                state.axcess = action.payload.axcess;
                state.ncino = action.payload.ncino;
                state.holding = action.payload.holding;
            })
            .addCase(retrieveExternalDataLists.rejected, (state) => {
                state.loading = false;
                state.accountBalance = [];
                state.axcess = [];
                state.ncino = [];
                state.holding = [];
            })
    }
})

export const {clearLists} = externalDataSlice.actions;

export default externalDataSlice.reducer;