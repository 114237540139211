import {useMemo, useState} from "react";
// @mui
import {Checkbox, Container, FormControlLabel, FormGroup, Grid, Typography} from '@mui/material';
// components
import {DataTable, Page, SideDrawer} from '../../components';
// Store
import {useAppSelector} from "../../store/store";
import {getValuationModelLoading} from "../../store/valuationModel/selectors/generalSelectors";
// Local imports
import {
    PortfolioTableColumns,
    highlightingDataGridSx,
    portfolioDataTable, ValuationColumns, valuationTableSelector
} from "../../store/valuationModel/selectors/portfolioSelector";
import LoanWizard from "../../sections/dashboard/valuationModel/Portfolio/LoanWizard";
import VersionConfigBar from "./VersionConfigBar";
import {AssumptionType} from "../../types/valuationModelEnums";

// ----------------------------------------------------------------------

export default function ValuationWorksheet() {
    const {portfolio, highlighted} = useAppSelector(state => portfolioDataTable(state));
    const valuations = useAppSelector(state => valuationTableSelector(state));
    const loading = useAppSelector(state => getValuationModelLoading(state));

    const [selectedRow, selectRow] = useState<number | null>(null);
    const [selectHighlighted, setHighlighted] = useState<boolean>(true);
    const [selectMatured, setMatured] = useState<boolean>(true);

    const filtered = useMemo(() => {
        let tranches = selectHighlighted ? highlighted : portfolio
        if (selectMatured) {
            tranches = tranches.filter(a => a.assumption !== AssumptionType.PAST_MATURITY || a.removed)
        }
        return tranches
    }, [selectHighlighted, selectMatured, highlighted, portfolio])

    return (
        <Page title="Metrics - Valuation Model - Worksheet">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Valuation Worksheet
                        </Typography>
                        <VersionConfigBar/>
                    </Grid>
                    <Grid item container sx={{width: '100%', flex: 1}} direction='column'>
                        <Grid item xs={5} md={5} lg={5} sx={{flex: 1, pb: 1}}>
                            <DataTable
                                title={'Tranches'}
                                columns={PortfolioTableColumns}
                                data={filtered}
                                loading={loading}

                                selectedRow={selectedRow}
                                selectRow={(row) => selectRow(row as number)}

                                sort={{field: 'tranche_id', sort: 'asc'}}
                                search
                                showColumns
                                showExport={false}
                                showFilter

                                additionalDataGridSx={highlightingDataGridSx}
                                customFilterComponent={
                                    <FormGroup row={true}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectHighlighted}
                                                    sx={{
                                                        color: "common.white",
                                                        '&.Mui-checked': {
                                                            color: "common.white"
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Highlighted"
                                            onChange={() => setHighlighted(!selectHighlighted)}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={false}
                                                    sx={{
                                                        color: "common.white",
                                                        '&.Mui-checked': {
                                                            color: "common.white"
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Past Maturity"
                                            onChange={() => setMatured(!selectMatured)}
                                        />
                                    </FormGroup>
                                }
                            />
                        </Grid>
                        <Grid item xs={7} md={7} lg={7} sx={{flex: 1, pb: 1}}>
                            <DataTable
                                title={'Valuations'}
                                columns={ValuationColumns}
                                data={valuations}
                                loading={loading}

                                selectedRow={selectedRow}
                                selectRow={(row) => selectRow(row as number)}
                                selectKey={'trancheId'}

                                sort={{field: 'tranche_id', sort: 'asc'}}
                                search
                                showColumns
                                showExport={false}
                                showFilter

                                additionalDataGridSx={highlightingDataGridSx}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {(selectedRow) &&
                <SideDrawer
                    title='Tranche Details'
                    open={(!!selectedRow)}
                    onClose={() => selectRow(null)}
                    size='x-large'
                >
                    <LoanWizard trancheId={selectedRow}/>
                </SideDrawer>
            }
        </Page>
    );
}
