import {
    CapitalBudget,
    CapitalBudgetPeriodResults,
    MCPFund,
    ScenarioDataSet,
    ThirdPartyData
} from "../../types/capitalBudgetTypes";
import {createSlice} from "@reduxjs/toolkit";
import {
    calculateCapitalBudget,
    calculateBaseCapitalBudget,
    clearCapitalBudgetScenario,
    getCapitalBudgetData
} from "./capitalBudgetFunctions";
import {default as capitalBudgetReducers} from './reducers'

export interface CapitalBudgetState {
    thirdPartyData: ThirdPartyData | null,
    scenarioData: ScenarioDataSet | null,
    misc: {
        // periods: { weeks: Array<Period>, months: Array<Period> },
        weeks: number,
        months: number,
        fund: null | MCPFund
    },
    capitalBudget: CapitalBudget | null,
    base: {
        baseBudget: CapitalBudget | null,
        calculating: boolean
    },
    error: boolean,
    loading: boolean,
    calculating: boolean,
}

const initialState: CapitalBudgetState = {
    thirdPartyData: null,
    scenarioData: null,
    misc: {
        // periods: generatePeriods(6, 2),
        weeks: 8,
        months: 3,
        fund: null
    },
    capitalBudget: null,
    base: {
        baseBudget: null,
        calculating: false
    },
    error: false,
    loading: false,
    calculating: false
}

// REDUCER
export const capitalBudgetSlice = createSlice({
    name: 'capitalBudget',
    initialState,
    reducers: capitalBudgetReducers,
    extraReducers(builder) {
        builder
            // RETRIEVE SCENARIOS
            .addCase(getCapitalBudgetData.pending, (state) => {
                state.loading = true;
                state.thirdPartyData = null;
                state.scenarioData = null;
            })
            .addCase(getCapitalBudgetData.fulfilled, (state, action) => {
                state.loading = false;
                state.thirdPartyData = action.payload.thirdPartyData;
                state.scenarioData = action.payload.scenarioData;
            })
            .addCase(getCapitalBudgetData.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })

            // CLEAR CURRENT SCENARIO
            .addCase(clearCapitalBudgetScenario.pending, (state) => {
                state.loading = true;
            })
            .addCase(clearCapitalBudgetScenario.fulfilled, (state, action) => {
                state.loading = false;
                state.scenarioData = action.payload;
            })
            .addCase(clearCapitalBudgetScenario.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })

            // CALCULATE CAPITAL BUDGET
            .addCase(calculateCapitalBudget.pending, (state) => {
                state.calculating = true;
                state.capitalBudget = null;
            })
            .addCase(calculateCapitalBudget.fulfilled, (state, action) => {
                state.calculating = false;
                const { base, weeks, months } = action.payload;
                state.capitalBudget = {
                    base: base as CapitalBudgetPeriodResults,
                    weeks: weeks as Array<CapitalBudgetPeriodResults>,
                    months: months as Array<CapitalBudgetPeriodResults>,
                }
            })
            .addCase(calculateCapitalBudget.rejected, (state) => {
                state.calculating = false;
            })

            // CALCULATE base CAPITAL BUDGET
            .addCase(calculateBaseCapitalBudget.pending, (state) => {
                state.base = {
                    baseBudget: null,
                    calculating: true,
                }
            })
            .addCase(calculateBaseCapitalBudget.fulfilled, (state, action) => {
                state.calculating = false;
                const { base, weeks, months } = action.payload;
                state.base.baseBudget = {
                    base: base as CapitalBudgetPeriodResults,
                    weeks: weeks as Array<CapitalBudgetPeriodResults>,
                    months: months as Array<CapitalBudgetPeriodResults>,
                }
            })
            .addCase(calculateBaseCapitalBudget.rejected, (state) => {
                state.base = {
                    baseBudget: null,
                    calculating: false,
                }
            })
    },

})

export const {
    setFund,
    setWeeks,
    setMonths,
    updateBalancesReducers,
    addCapitalTransaction,
    addOtherTransaction,
    addPortfolioLoanChange,
    addSellRepTransaction,
    addTransfer,
    editCapitalTransaction,
    editOtherTransaction,
    updateNewDeal,
    updateSelldownRepayment,
    updateTransfer,
    removeCapitalTransaction,
    removeOtherTransaction,
    removePortfolioLoanChange,
    removeSelldownRepayment,
    removeTransfer,
    clearAllScenerio
} = capitalBudgetSlice.actions;

export default capitalBudgetSlice.reducer;