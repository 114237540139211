import {useState} from "react";
// Chart JS
import {Bar} from 'react-chartjs-2';
// MATERIAL
import {Box, Grid, ToggleButton, ToggleButtonGroup} from "@mui/material";
// SELECTOR
import {useAppSelector} from "../../../../store/store";
import {colors} from "../../../../utils/colorGenUtils";
import {fCurrency, fRoundedCurrency} from "../../../../utils/formatNumber";
import {formatDate} from "../../../../utils/DateUtils";
import {CapitalBudgetPeriodResults} from "../../../../types/capitalBudgetTypes";

// const Graph

export default function BudgetForecastGraphs() {
    const [increment, setIncrement] = useState<'weeks' | 'months'>('weeks')

    // Initialise Data
    let labels: Array<{ label: string, info: string }> = [];
    let closingCap: Array<number> = [];
    let expectedRep: Array<number> = [];

    let extOffset: Array<number> = [];
    let earlyRep: Array<number> = [];
    let loanExt: Array<number> = [];
    let earlyRepOffset: Array<number> = [];

    let transfersIn: Array<number> = [];
    let transfersInOffset: Array<number> = [];
    let transfersOut: Array<number> = [];
    let transfersOutOffset: Array<number> = [];

    let subs: Array<number> = [];
    let reds: Array<number> = [];


    // Retrieve Data
    const capitalBudget = useAppSelector(state => state.capitalBudget.capitalBudget);
    const fund = useAppSelector(state => state.capitalBudget.misc.fund);

    if (!!capitalBudget) {
        capitalBudget[increment].forEach((incr: CapitalBudgetPeriodResults, i) => {
            if (i === 0) {
                labels.push({
                    label: incr.label,
                    info: `${formatDate(incr.lastDate, 'dd-MMM-yy')}`
                });
            } else {
                labels.push({
                    label: incr.label,
                    info: `${formatDate(incr.startDate, 'dd-MMM-yy')} - ${formatDate(incr.lastDate, 'dd-MMM-yy')}`
                });
            }
            closingCap.push(incr.closingAvailableCap);
            expectedRep.push(incr.expectedRepayments)
            extOffset.push(-incr.extensionOffset)
            earlyRep.push(incr.earlyRepayment)
            loanExt.push(incr.extension)
            earlyRepOffset.push(-incr.earlyRepOffset)

            transfersIn.push(incr.transfersIn);
            transfersInOffset.push(incr.transfersInOffset);
            transfersOut.push(incr.transfersOut);
            transfersOutOffset.push(incr.transfersOutOffset);

            subs.push(incr.subscriptions)
            reds.push(incr.redemptions)
        })
    }

    return (
        <Box sx={{pb: 4, height: '50vh'}}>
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            // @ts-ignore
                            type: 'line',
                            label: 'Closing Available Capital',
                            data: closingCap,
                            borderColor: colors.midnightBlue,
                            backgroundColor: colors.midnightBlue,
                        },
                        {
                            label: 'Contractual Loan Repayments',
                            data: expectedRep,
                            borderColor: colors.navyBlue,
                            backgroundColor: colors.navyBlue,
                            stack: 'Repayments'
                        },
                        {
                            label: 'Loan Extension',
                            data: loanExt,
                            borderColor: colors.midBlue,
                            backgroundColor: colors.midBlue,
                            stack: 'Repayments'
                        },
                        {
                            label: 'Early Repayments',
                            data: earlyRep,
                            borderColor: colors.lightBlue,
                            backgroundColor: colors.lightBlue,
                            stack: 'Repayments'
                        },
                        {
                            label: 'Loan Extension - Offset',
                            data: extOffset,
                            borderColor: colors.aquaBlue,
                            backgroundColor: colors.aquaBlue,
                            stack: 'Repayments'
                        },
                        {
                            label: 'Early Repayment - Offset',
                            data: earlyRepOffset,
                            borderColor: colors.aquaBlue,
                            backgroundColor: colors.aquaBlue,
                            stack: 'Repayments'
                        },
                        ...(fund) ? [
                            {
                                label: 'Interfund Transfers In',
                                data: transfersIn,
                                borderColor: 'grey',
                                backgroundColor: 'grey',
                                stack: 'Transfer'
                            },
                            {
                                label: 'Interfund Transfers In (Offset)',
                                data: transfersInOffset,
                                borderColor: colors.midnightBlue,
                                backgroundColor: colors.midnightBlue,
                                stack: 'Transfer'
                            },
                            {
                                label: 'Interfund Transfers Out',
                                data: transfersOut,
                                borderColor: colors.midnightBlue,
                                backgroundColor: colors.midnightBlue,
                                stack: 'Transfer'
                            },
                            {
                                label: 'Interfund Transfers Out (Offset)',
                                data: transfersOutOffset,
                                borderColor: 'grey',
                                backgroundColor: 'grey',
                                stack: 'Transfer'
                            },
                        ] : [],
                        {
                            label: 'Subscriptions',
                            data: subs,
                            borderColor: 'rgb(17, 140, 79)',
                            backgroundColor: 'rgb(17, 140, 79)',
                            stack: 'Capital'
                        },
                        {
                            label: 'Redemptions',
                            data: reds,
                            borderColor: 'orange',
                            backgroundColor: 'orange',
                            stack: 'Capital'
                        },
                    ]
                }}
                options={{
                    scales: {
                        y: {
                            ticks: {
                                // @ts-ignore
                                callback: function (value: number) {
                                    return fRoundedCurrency(value);
                                }
                            }

                        },
                        x: {
                            labels: labels.map(l => l.label)
                        }
                    },
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Budget Forecast',
                            font: {
                                size: 20,
                            },
                            color: colors.midnightBlue
                        },
                        legend: {
                            position: 'top' as const,
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    let label = context.dataset.label || '';
                                    if (label && context.parsed.y) {
                                        return `${label}: ${fCurrency(context.parsed.y)}`
                                    } else {
                                        return label;
                                    }
                                },
                                title(context): string | string[] {
                                    return `${context[0].label}: ${labels[context[0].dataIndex].info}`
                                }
                            }
                        },

                    },
                    maintainAspectRatio: false
                }}
            />
            <Grid container justifyContent={'center'} sx={{py: 2}}>
                <ToggleButtonGroup
                    color='primary'
                    value={increment}
                    exclusive
                    onChange={(e, value) => {
                        if (value) setIncrement(value)
                    }}
                >
                    <ToggleButton value={'weeks'}>Weeks</ToggleButton>
                    <ToggleButton value={'months'}>Months</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Box>
    )
}