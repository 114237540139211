import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
// material
import { Button, Grid } from '@mui/material'
// component
import { FormInput } from '../../../components';
import { InputTypes } from '../../../types/InputTypes'
// ----------------------------------------------------------------------

const initialValues = {
    email: '',
    password: '',
    numberOne: '',
    numberTwo: '',
    numberThree: '',
    textBox: '',
    checkboxOne: false,
    checkboxTwo: false,
    checkboxThree: true,
    currencyOne: 0,
    currencyTwo: 0,   
    percentageOne: 0,
    percentageTwo: 0,
    dateOne: null,
    dateTwo: null,
    formattedOne: '01 2345 6789',
    formattedTwo: '123-456',
    selectionOne: 3,
    selectionTwo: 1,
    searchOne: null,
    searchTwo: null,
    radioOne: '',
    radioTwo: '',
    files:  [],
    file: []
}

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    textBox: Yup.string().required('Text Required'),
    file: Yup.array().min(1, 'File is required!').required('File is Required'),
    radioOne: Yup.string().required('Please choose an option'),
    currencyTwo: Yup.number().min(1, 'Must be greater than one').required('Currency Required'),
    dateTwo: Yup.date().typeError('Valid date Required.').required('Date is required.'),
    formattedTwo: Yup.string().required('Field Required'),
    percentageTwo: Yup.number().min(1, 'Must be greater than 1').required('Percentage Required.'),
    selectionTwo: Yup.object().typeError('Select 2 is required').required('Select 2 is required'),
    searchTwo: Yup.object().typeError('Search 2 is required').required('Search 2 is required')
});

export default function FormExample() {

    return (
        <>
            <Grid container>
                <Formik
                    initialValues={initialValues}
                    validationSchema={LoginSchema}
                    onSubmit={(values: any) => {
                        console.log(values)
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Grid item xs={8}>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container>
                                            <FormInput
                                                id='email'
                                                label='String Email'
                                                fieldType={InputTypes.STRING}
                                                layout={{ xs: 12, md: 12, lg: 12 }}
                                            />
                                            <FormInput
                                                id='password'
                                                label='Password'
                                                fieldType={InputTypes.PASSWORD}
                                                layout={{ xs: 12, md: 12, lg: 12 }}
                                            />
                                            <FormInput
                                                id='numberOne'
                                                label='Number 1'
                                                fieldType={InputTypes.NUMBER}
                                                layout={{ xs: 4, md: 4, lg: 4 }}
                                            />
                                            <FormInput
                                                id='numberTwo'
                                                label='Number 2'
                                                fieldType={InputTypes.NUMBER}
                                                layout={{ xs: 4, md: 4, lg: 4 }}
                                            />
                                            <FormInput
                                                id='numberThree'
                                                label='Number 4'
                                                fieldType={InputTypes.NUMBER}
                                                layout={{ xs: 4, md: 4, lg: 4 }}

                                                info='This is a test Info Message'
                                            />
                                            <FormInput
                                                id='textBox'
                                                label='Text Box'
                                                fieldType={InputTypes.TEXT_BOX}
                                                layout={{ xs: 12, md: 12, lg: 12 }}

                                                info='This is a test'
                                            />
                                            <FormInput
                                                id='checkboxOne'
                                                label='Check Box'
                                                fieldType={InputTypes.CHECKBOX}
                                                layout={{ xs: 4, md: 4, lg: 4 }}
                                            />
                                            <FormInput
                                                id='checkboxTwo'
                                                label='Check Box 2'
                                                fieldType={InputTypes.CHECKBOX}
                                                layout={{ xs: 4, md: 4, lg: 4 }}
                                            />
                                            <FormInput
                                                id='checkboxThree'
                                                label='Check Box 3'
                                                fieldType={InputTypes.CHECKBOX}
                                                layout={{ xs: 4, md: 4, lg: 4 }}

                                                info='Test Info'
                                            />
                                            <FormInput
                                                id='currencyOne'
                                                label='Currency Format'
                                                fieldType={InputTypes.CURRENCY}
                                                layout={{ xs: 6, md: 6, lg: 6 }}
                                            />
                                            <FormInput
                                                id='currencyTwo'
                                                label='Currency Format'
                                                fieldType={InputTypes.CURRENCY}
                                                layout={{ xs: 6, md: 6, lg: 6 }}

                                                info='Test Currency'
                                            />
                                            <FormInput
                                                id='percentageOne'
                                                label='Percentage Format'
                                                fieldType={InputTypes.PERCENTAGE}
                                                layout={{ xs: 6, md: 6, lg: 6 }}
                                            />
                                            <FormInput
                                                id='percentageTwo'
                                                label='Percentage Format'
                                                fieldType={InputTypes.PERCENTAGE}
                                                layout={{ xs: 6, md: 6, lg: 6 }}

                                                info='Test Percentage'
                                            />
                                            <FormInput
                                                id='dateOne'
                                                label='Date One'
                                                fieldType={InputTypes.DATE}
                                                layout={{ xs: 6, md: 6, lg: 6 }}
                                                minDate={new Date('01/01/2021')}
                                            />
                                            <FormInput
                                                id='dateTwo'
                                                label='Date Two'
                                                fieldType={InputTypes.DATE}
                                                layout={{ xs: 6, md: 6, lg: 6 }}

                                                info='Test Percentage'
                                            />
                                            <FormInput
                                                id='formattedOne'
                                                label='Phone Number'
                                                fieldType={InputTypes.FORMATTED}
                                                layout={{ xs: 6, md: 6, lg: 6 }}

                                                mask='00 0000 0000'
                                            />
                                            <FormInput
                                                id='formattedTwo'
                                                label='BSB'
                                                fieldType={InputTypes.FORMATTED}
                                                layout={{ xs: 6, md: 6, lg: 6 }}

                                                mask='000-000'

                                                info='Test BSB info'
                                            />
                                            <FormInput
                                                id='selectionOne'
                                                label='Select One'
                                                fieldType={InputTypes.SELECTION}
                                                layout={{ xs: 6, md: 6, lg: 6 }}

                                                values={[
                                                    { label: 'Option 1', value: 1 },
                                                    { label: 'Option 2', value: 2 },
                                                    { label: 'Option 3', value: 3 },
                                                ]}
                                            />
                                            <FormInput
                                                id='selectionTwo'
                                                label='Select Two'
                                                fieldType={InputTypes.SELECTION}
                                                layout={{ xs: 6, md: 6, lg: 6 }}

                                                values={[
                                                    { label: 'Option 1', value: 1 },
                                                    { label: 'Option 2', value: 2 },
                                                    { label: 'Option 3', value: 3 },
                                                ]}

                                                info='Test Selection info'
                                            />
                                            <FormInput
                                                id='searchOne'
                                                label='Search One'
                                                fieldType={InputTypes.SEARCH}
                                                layout={{ xs: 6, md: 6, lg: 6 }}
                                                values={[
                                                    { label: 'Option 1', year: 2001, id: 1 },
                                                    { label: 'Option 2', year: 2002, id: 2 },
                                                    { label: 'Option 3', year: 2003, id: 3 },
                                                ]}
                                                labelFunc={(option) => `${option.label} - ${option.year}`}
                                            />
                                            <FormInput
                                                id='searchTwo'
                                                label='Search Two'
                                                fieldType={InputTypes.SEARCH}
                                                layout={{ xs: 6, md: 6, lg: 6 }}
                                                values={[
                                                    { label: 'Option 1', year: 2001, id: 1 },
                                                    { label: 'Option 2', year: 2002, id: 2 },
                                                    { label: 'Option 3', year: 2003, id: 3 },
                                                ]}
                                                labelFunc={(option) => {
                                                    return `${option.label} - ${option.year}`
                                                }}
                                                info='Test Search info'
                                            />
                                            <FormInput
                                                id='radioOne'
                                                label='Radio One'
                                                fieldType={InputTypes.RADIO}
                                                layout={{ xs: 6, md: 6, lg: 6 }}
                                                info={'PLEASE HELP'}
                                                values={[
                                                    { label: 'Option 1', value: 1, info: 'PLEASE HELP ADDITIONAL INFORMATION' },
                                                    { label: 'Option 2', value: 2 },
                                                    { label: 'Option 3', value: 3 },
                                                ]}
                                            />
                                            <FormInput
                                                id='files'
                                                label='Submit Files - (multiple)'
                                                fieldType={InputTypes.FILE}

                                                multiple={true}
                                                info='Allows multiple files to be uploaded'
                                            />
                                            <FormInput
                                                id='file'
                                                label='Submit Files - (single)'
                                                fieldType={InputTypes.FILE}

                                                multiple={false}
                                                info='test'
                                            />
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </form>
                                </Grid>
                                <Grid item xs={6}>
                                </Grid>
                            </>
                        )
                    }}
                </Formik>

            </Grid>
        </>
    )
}