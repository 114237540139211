import {useState} from "react";
import _ from "lodash";
// Local import
import {dateSortComparator, formatDate} from "../../utils/DateUtils";
// MUI
import {
    Box,
    Button, CircularProgress,
    FormControl,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
    KeyboardArrowDown as ExpandIcon,
    KeyboardArrowUp as CloseIcon
} from "@mui/icons-material"
// Components
import {AlertDialog, SideDrawer} from "../../components";
// Store
import {useAppDispatch, useAppSelector} from '../../store/store';
import {setScenarioById} from "../../store/scenarios/scenerioSlice";
import {SaveScenario} from "../../sections/dashboard/capitalBudget/scenarios";
import {calculateCapitalBudget} from "../../store/capitalBudget/capitalBudgetFunctions";
import {setFund, setMonths, setWeeks} from "../../store/capitalBudget/capitalBudgetSlice";
import {setMillions} from "../../store/general/generalSlice";
import {millionsSelector} from "../../store/general";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    "& .MuiToggleButtonGroup-grouped": {
        "&.MuiToggleButton-root": {
            width: 50,
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            width: 50,
        },

    }
}));

// Capital Budget Scenario selection bar
export function SelectionBar() {
    const dispatch = useAppDispatch();

    const scenarios = useAppSelector(state => state.scenarios.scenarios)
    const reportAsOf = useAppSelector(state => state.scenarios.scenario?.reportAsOf || null);
    const balancesAsOf = useAppSelector(state => state.capitalBudget.thirdPartyData?.accountBalance?.date || null);
    const axcessAsOf = useAppSelector(state => state.capitalBudget.thirdPartyData?.axcess.date || null);
    const ncinoAsOf = useAppSelector(state => state.capitalBudget.thirdPartyData?.ncino.date || null);
    const holdingAsOf = useAppSelector(state => state.capitalBudget.thirdPartyData?.holding.date || null);
    const currentScenario = useAppSelector(state => state.scenarios.scenario);
    const funds = useAppSelector(state => state.capitalBudget.scenarioData?.funds);
    const fund = useAppSelector(state => state.capitalBudget.misc.fund);
    const weeks = useAppSelector(state => state.capitalBudget.misc.weeks);
    const months = useAppSelector(state => state.capitalBudget.misc.months);
    const user = useAppSelector(state => state.user.user);
    const loading = useAppSelector(state => state.capitalBudget.loading);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const millions = useAppSelector(state => millionsSelector(state));

    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [switchScenario, setSwitch] = useState<number | null>(null)
    const [save, setSave] = useState<{ open: boolean, saveAs: boolean }>({open: false, saveAs: false})

    const [maxDisplayMulti, setMulti] = useState<number>(1)

    let displayValues = _.cloneDeep(scenarios).sort((a, b) => -dateSortComparator(a.reportAsOf, b.reportAsOf));

    if (currentScenario) {
        const index = displayValues.findIndex(selected => selected.id === currentScenario.id);
        if (index) {
            const current = {...displayValues[index]}
            displayValues.splice(index, 1);
            displayValues.unshift(current);
        }
        displayValues = displayValues.slice(0, maxDisplayMulti * 10);
    }

    const handleSetFund = (fund: string) => {
        if (funds) {
            const value = funds.find(f => f.label === fund)
            dispatch(setFund(value))
            dispatch(calculateCapitalBudget())
        }
    }

    return (
        <>
            <Box sx={{flex: 1, pl: 2}}>
                <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                    <Grid item>
                        {reportAsOf &&
                            <Box sx={{display: 'flex', direction: 'row', alignItems: 'center'}}>
                                <Typography align='right'>
                                    <b>Capital Budget as
                                        of {formatDate(new Date(reportAsOf), 'do MMMM yyyy')}</b>
                                </Typography>
                                <IconButton size="small" onClick={() => setDateOpen(!dateOpen)}>
                                    {dateOpen ? <CloseIcon/> : <ExpandIcon/>}
                                </IconButton>
                            </Box>
                        }
                        {dateOpen &&
                            <>
                                {balancesAsOf &&
                                    <Typography color="grey" align='right'>
                                        Account Balance Data as of {formatDate(new Date(balancesAsOf), 'do MMMM yyyy')}
                                    </Typography>
                                }
                                {axcessAsOf &&
                                    <Typography color="grey" align='right'>
                                        Axcess Data as of {formatDate(new Date(axcessAsOf), 'do MMMM yyyy')}
                                    </Typography>
                                }
                                {ncinoAsOf &&
                                    <Typography color="grey" align='right'>
                                        nCino Data as of {formatDate(new Date(ncinoAsOf), 'do MMMM yyyy h.mm a')}
                                    </Typography>
                                }
                                {holdingAsOf &&
                                    <Typography color="grey" align='right'>
                                        Portfolio Holding Data as of {formatDate(new Date(holdingAsOf), 'do MMMM yyyy')}
                                    </Typography>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item>
                        <Typography>
                            Scenarios
                        </Typography>
                    </Grid>
                    <Grid item sx={{width: 500}}>
                        <FormControl fullWidth>
                            <InputLabel sx={{bgcolor: 'grey.200', zIndex: 1}}>Descriptions</InputLabel>
                            <Select
                                value={(currentScenario && scenarios.length > 0) ? `${currentScenario.id}` : ''}
                                size="small"
                                label="Scenarios"
                                onChange={(event: SelectChangeEvent) => setSwitch(parseInt(event.target.value))}
                                disabled={(loading || calculating)}
                            >
                                {scenarios.length > 0 && displayValues.map((d: any) => (
                                    <MenuItem
                                        key={d.id}
                                        value={d.id}>{d.description} - {d.creator.firstName} {d.creator.lastName} - {(d.modifier) ? `Last Modified ${formatDate(new Date(d.modifiedDate), 'dd-MM-yyyy hh:mm a')}` : `Created ${formatDate(new Date(d.createdDate))}`}
                                    </MenuItem>
                                ))}
                                {((maxDisplayMulti * 10) < scenarios.length) &&
                                    <MenuItem onClick={() => setMulti(maxDisplayMulti + 1)}>
                                        ...Show more...
                                    </MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {user?.username === currentScenario?.creator.email &&
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={() => setSave({open: true, saveAs: false})}
                                disabled={(loading || calculating)}
                            >
                                Save
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={() => setSave({open: true, saveAs: true})}
                            disabled={(loading || calculating)}
                        >
                            Save As
                        </Button>
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={2} sx={{py: 1}}>
                    <Grid item>
                        <StyledToggleButtonGroup
                            disabled={(loading || calculating)}
                            value={millions}
                            exclusive
                            onChange={(e, value) => dispatch(setMillions(value))}
                            size='small'
                        >
                            <ToggleButton value={false}>$</ToggleButton>
                            <ToggleButton value={true}>$m</ToggleButton>
                        </StyledToggleButtonGroup>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth sx={{width: 100}}>
                            <InputLabel id="demo-simple-select-label">Fund</InputLabel>
                            <Select
                                id="Fund"
                                value={(fund) ? fund.label : 'All'}
                                label="Fund"
                                size="small"
                                onChange={(event: SelectChangeEvent) => handleSetFund(event.target.value as string)}
                                disabled={(loading || calculating)}
                            >
                                <MenuItem value={'All'}>All</MenuItem>
                                {funds &&
                                    funds.map(f =>
                                        <MenuItem value={f.label} key={f.label}>
                                            {f.label}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Scenario Periods
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth sx={{width: 100}}>
                            <InputLabel id="demo-simple-select-label">Weeks</InputLabel>
                            <Select
                                id="Weeks"
                                value={(weeks || 0).toString()}
                                label="Weeks"
                                size="small"
                                onChange={(event: SelectChangeEvent) => {
                                    dispatch(setWeeks(parseInt(event.target.value)));
                                    dispatch(calculateCapitalBudget())
                                }}
                                disabled={(loading || calculating)}
                            >
                                {Array.from({length: 12}).map((x, n) => (
                                    <MenuItem value={(n + 1).toString()} key={n}>
                                        {n + 1}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth sx={{width: 100}}>
                            <InputLabel id="demo-simple-select-label">Months</InputLabel>
                            <Select
                                id="Months"
                                value={(months || 0).toString()}
                                label="Months"
                                size="small"
                                onChange={(event: SelectChangeEvent) => {
                                    dispatch(setMonths(parseInt(event.target.value)));
                                    dispatch(calculateCapitalBudget())
                                }}
                                disabled={(loading || calculating)}
                            >
                                {Array.from({length: 12}).map((x, n) => (
                                    <MenuItem value={(n + 1).toString()} key={n}>
                                        {n + 1}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        {calculating ?
                            <CircularProgress/>
                            :
                            <Button
                                variant="contained"
                                onClick={() => dispatch(calculateCapitalBudget())}
                                disabled={(loading || calculating)}
                            >
                                Recalculate
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>
            <AlertDialog
                title='Confirm Scenario Switch'
                info='Warning! Any Unsaved Changes will be lost when switching scenarios.'

                open={!!switchScenario}
                handleClose={() => setSwitch(null)}
                handleConfirm={() => {
                    if (switchScenario) dispatch(setScenarioById(switchScenario))
                    setSwitch(null)
                }}
            />
            <SideDrawer
                title={(save.saveAs) ? 'Confirm Save As' : 'Confirm Save'}
                open={save.open}
                onClose={() => setSave({open: false, saveAs: false})}
            >
                <SaveScenario onClose={() => setSave({open: false, saveAs: false})} saveAs={save.saveAs}/>
            </SideDrawer>
        </>
    )
}