import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
// Local import
import {InputTypes} from '../../../../types/InputTypes';
import {checkDateBefore, checkDateSame, checkValidDate} from '../../../../utils/DateUtils';
// Store
import {useAppDispatch} from "../../../../store/store";
import {addPortfolioLoanChange, removePortfolioLoanChange} from "../../../../store/capitalBudget/capitalBudgetSlice";
// MUI
import {Button, Grid} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
// Components
import {FormInput} from '../../../../components';
import {AmendmentType} from "../../../../types/capitalBudgetEnums";
// ----------------------------------------------------------------------
// Validation Schema
const LoanSchema = Yup.object().shape({
    amendment: Yup.string().required('Amendment is required.'),
    amendedMaturity: Yup.date()
        .nullable()
        .typeError('Valid Date Required'),
    amendedCommitment: Yup.number().nullable()
});
// ----------------------------------------------------------------------

// Portfolio Amendment Form
export default function AmendPortfolio({
                                           values,
                                           onClose,
                                       }: { values: any, onClose: () => void }) {
    const dispatch = useAppDispatch();

    const deleteAmendment = (values && (values.amendedCommitment || values.amendedMaturity) && (values.amendedMaturity !== values.maturity || values.amendedCommitment !== values.commitment));

    return (
        <>
            <Formik
                initialValues={{
                    ...values,
                    ...(values) ? {
                        maturity: new Date(values.maturity),
                        amendedMaturity: (values.amendedMaturity) ? new Date(values.amendedMaturity) : null
                    } : {}
                }}
                validationSchema={LoanSchema}
                onSubmit={(newValues: any) => {
                    dispatch(addPortfolioLoanChange({
                        id: newValues.id,
                        scenarioId: newValues.scenarioId,
                        trancheId: newValues.trancheId,
                        amendedMaturity: new Date(newValues.amendedMaturity).toDateString(),
                        amendedCommitment: null,
                        amendment: newValues.amendment
                    }))
                    onClose();
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        setFieldValue,
                        values,
                    } = props

                    if (values.amendedMaturity !== null && values.amendedMaturity !== "") {
                        // const amendmentDate = new Date(values.amendedMaturity);
                        if (checkValidDate(new Date(values.amendedMaturity))) {
                            const amendment = (checkDateBefore(values.maturity, new Date(values.amendedMaturity))) ?
                                AmendmentType.EXTENSION
                                :
                                (!checkDateSame(values.maturity, new Date(values.amendedMaturity))) ?
                                    AmendmentType.EARLY_REPAYMENT
                                    :
                                    '';
                            if (values.amendment !== amendment) {
                                setFieldValue('amendment', amendment);
                            }
                        }
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                {values && deleteAmendment !== "" &&
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', px: 2}}/>
                                        <Grid item sx={{width: '50%', px: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                color={'error'}
                                                endIcon={<CloseIcon/>}
                                                onClick={() => {
                                                    dispatch(removePortfolioLoanChange(values));
                                                    onClose();
                                                }}
                                            >
                                                Clear Amendment
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='trancheId'
                                        label='Tranche ID'
                                        fieldType={InputTypes.NUMBER}
                                        layout={{xs: 3, md: 3, lg: 3}}
                                        disabled
                                    />
                                    <FormInput
                                        id='borrower'
                                        label='Borrower'
                                        fieldType={InputTypes.STRING}
                                        layout={{xs: 9, md: 9, lg: 9}}
                                        disabled
                                    />
                                    <FormInput
                                        id='amendment'
                                        label='Amendment'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 12, md: 12, lg: 12}}

                                        values={[
                                            {label: AmendmentType.EXTENSION, value: AmendmentType.EXTENSION},
                                            {
                                                label: AmendmentType.EARLY_REPAYMENT,
                                                value: AmendmentType.EARLY_REPAYMENT
                                            },
                                            {label: AmendmentType.COMMITMENT, value: AmendmentType.COMMITMENT},
                                        ]}
                                        disabled
                                    />
                                    <FormInput
                                        id='maturity'
                                        label='Current Maturity'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        disabled
                                    />
                                    <FormInput
                                        id='amendedMaturity'
                                        label='Amended Maturity'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    {/*<FormInput*/}
                                    {/*    id='commitment'*/}
                                    {/*    label='Current Commitment'*/}
                                    {/*    fieldType={InputTypes.CURRENCY}*/}
                                    {/*    layout={{xs: 6, md: 6, lg: 6}}*/}
                                    {/*    disabled*/}
                                    {/*/>*/}
                                    {/*<FormInput*/}
                                    {/*    id='amendedCommitment'*/}
                                    {/*    label='Amended Commitment'*/}
                                    {/*    fieldType={InputTypes.CURRENCY}*/}
                                    {/*    layout={{xs: 6, md: 6, lg: 6}}*/}
                                    {/*    disabled*/}
                                    {/*/>*/}
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Amend Loan
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}