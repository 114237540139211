import { MouseEvent, useRef, useState } from 'react';
// @mui
import {
	Box,
	Divider,
	Typography,
	// Stack, 
	MenuItem,
	Avatar,
	IconButton,
} from '@mui/material';
// components
import { MenuPopover } from '../../components';
// store
import { useAppSelector } from '../../store/store';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function AccountPopover() {
	const navigate = useNavigate();

	const user = useAppSelector(state => state.user.user)	
	
	const anchorRef = useRef(null);

	const [open, setOpen] = useState<HTMLButtonElement | null>(null);

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					p: 0,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
						},
					}),
				}}
			>
				<Avatar
					sx={{
						bgcolor: 'primary.main',
						border: 1,
						BorderColor: 'common.white'
					}}
				>
					{user?.name &&
						`${user?.name.split(' ')[0][0]}${user?.name.split(' ')[1][0]}`
					}
				</Avatar>
			</IconButton>

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
					p: 0,
					mt: 1.5,
					ml: 0.75,
					'& .MuiMenuItem-root': {
						typography: 'body2',
						borderRadius: 0.75,
					},
				}}
			>
				<Box sx={{ py: 1.5, px: 2.5 , bgcolor: 'secondary.main'}}>
					<Typography variant="subtitle2" noWrap sx={{color: 'common.white'}}>
						{user?.name}
					</Typography>
					<Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
						{user?.email}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: 'dashed' }} />

				<MenuItem onClick={() => navigate('/dashboard/profile')} sx={{ m: 1 }}>
					Profile
				</MenuItem>
				<MenuItem onClick={() => navigate('/logout')} sx={{ m: 1 }}>
					Logout
				</MenuItem>
			</MenuPopover>
		</>
	);
}