import { useState } from 'react';
// MUI
import { Grid, Container, Typography, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Edit as EditIcon } from "@mui/icons-material";
// components
import {actionRow, DataTable, SideDrawer, Page, valueCurrency, valueDate} from '../../components';
import { addDays, checkDateBefore } from '../../utils/DateUtils';
// Dummy data
import { AmendPortfolio } from '../../sections/dashboard/capitalBudget/portfolio';
import { SelectionBar } from './SelectionBar';
// Store
import { useAppSelector } from '../../store/store';
import {getReportDate, portfolioLoansAll} from "../../store/capitalBudget/selectors";
import {generalRow} from "../../components/DataTable/DataTableUtils";

let columns: GridColDef[] = [
    { field: 'tranche_id', headerName: 'Tranche ID', width: 100, ...generalRow },
    {
        field: 'borrower',
        headerName: 'Borrower',
        flex: 1,
        ...generalRow
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        flex: 0.5,
        ...generalRow
    },
    {
        field: 'amendment',
        headerName: 'Amendment',
        width: 125,
        ...generalRow
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        ...valueDate,
        width: 100,
    },
    {
        field: 'amendedMaturity',
        headerName: 'Expected Maturity',
        ...valueDate,
        width: 150,
    },
    {
        ...valueCurrency,
        field: 'commitment',
        headerName: 'Commitment',
        width: 130,
    },
];

// ----------------------------------------------------------------------

// Capital Budget App Page
export default function PortfolioPage() {
    const [form, setFormOpen] = useState<any | null>(null);

    const loading = useAppSelector(state => state.capitalBudget.loading);
    const portfolio = useAppSelector(state => portfolioLoansAll(state));
    const reportAsOf = useAppSelector(state => getReportDate(state));

    const [showOnlyAmended1, setShow1] = useState<boolean>(false);
    const [showOnlyAmended2, setShow2] = useState<boolean>(false);

    const handleSetEdit = (row: any) => {
        const editObject = {
            id: row.id,
            scenarioId: row.scenarioId,
            trancheId: row.tranche_id,
            borrower: row.borrower,
            amendment: "",
            maturity: row.maturity,
            amendedMaturity: row.amendedMaturity || '',
            commitment: row.commitment,
            amendedCommitment: null,
        }
        setFormOpen(editObject)
    }


    return (
        <Page title="Metrics - Capital Budget - Portfolio">
            <Container maxWidth={false} sx={{ maxWidth: '100%', height: 1 }}>
                <Grid container direction='column' sx={{ height: 1 }}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{ mb: 3, color: 'primary.main' }}>
                            All Loans
                        </Typography>
                        <SelectionBar />
                    </Grid>
                    <Grid item container sx={{ width: '100%', flex: 1 }} >
                        <Grid item xs={12} md={12} lg={12} sx={{ flex: 1, pb: 1 }}>
                            <DataTable
                                title={'Loans'}
                                columns={[...columns, {
                                    ...actionRow,
                                    renderCell: (params: GridCellParams) =>
                                        <IconButton
                                            sx={{ color: 'primary.main' }}
                                            style={{ padding: '0px', outline: 'none' }}
                                            onClick={() => handleSetEdit(params.row)}
                                            size="small">
                                            <EditIcon />
                                        </IconButton>
                                }]}
                                data={portfolio.filter((p: any) => {
                                    return !(showOnlyAmended1 && !p.amendment);
                                })}
                                loading={loading}
                                showExport={false}
                                sort={{ field: 'maturity', sort: 'asc' }}
                                search
                                customFilter={{
                                    label: 'Show only Amended',
                                    active: showOnlyAmended1,
                                    setFilter: () => setShow1(!showOnlyAmended1)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sx={{ flex: 1, pb: 1 }}>
                            <DataTable
                                title={'Loans Maturing Next [90/180] days'}
                                columns={[...columns, {
                                    ...actionRow,
                                    renderCell: (params: GridCellParams) =>
                                        <IconButton
                                            sx={{ color: 'primary.main' }}
                                            style={{ padding: '0px', outline: 'none' }}
                                            onClick={() => handleSetEdit(params.row)}
                                            size="small">
                                            <EditIcon />
                                        </IconButton>
                                }]}
                                data={portfolio.filter((d: any) => {

                                    const dayNinety = addDays(reportAsOf, 90);
                                    const dayOneEighty = addDays(reportAsOf, 180);
                                    if (showOnlyAmended2 && !d.amendment) return false;

                                    const dateCheck = (d.amendedMaturity) ? d.amendedMaturity : d.maturity;

                                    return checkDateBefore(dateCheck, dayOneEighty) && checkDateBefore(dayNinety, dateCheck)
                                })}
                                loading={loading}
                                showExport={false}
                                sort={{ field: 'maturity', sort: 'asc' }}
                                hideFooter
                                search
                                customFilter={{
                                    label: 'Show only Amended',
                                    active: showOnlyAmended2,
                                    setFilter: () => setShow2(!showOnlyAmended2)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <SideDrawer
                title='Loan Amendment'
                open={(!!form)}
                onClose={() => setFormOpen(null)}
            >
                <AmendPortfolio
                    values={form}
                    onClose={() => setFormOpen(null)}
                />
            </SideDrawer>
        </Page>
    )
}